import "../../css/Containers/ContainerStyles.css";
import React, { CSSProperties, ReactNode } from "react";
import { ModifiedTextMessageDisplay } from "../DisplayItem/ModifiedTextMessageDisplay";
import { Colors } from "../../utils/colors";

type PreviewBoxContainerProps = {
  headerText: string;
  subHeaderComp: ReactNode;
  headerRightComp: ReactNode;
  message: string;
  containerStyles?: CSSProperties;
};

// This is used to display a preview of a message, ie. The Ticket Text, the event text timeline, etc
const PreviewBoxContainer = (props: PreviewBoxContainerProps) => {
  const {
    headerText,
    subHeaderComp,
    headerRightComp,
    message,
    containerStyles,
  } = props;

  return (
    <div
      style={{
        borderRadius: "8px",
        padding: "14px",
        backgroundColor: Colors.GRAY6,
        ...containerStyles,
      }}
    >
      <div className="AlignedRowSpaced">
        <div className="ColumnNormal" style={{ gap: 4 }}>
          <span className="AboutSubtitle" style={{ fontSize: 14 }}>
            {headerText}
          </span>
          <div style={{ display: "flex" }}>{subHeaderComp}</div>
        </div>
        <div>{headerRightComp}</div>
      </div>
      <div
        style={{
          backgroundColor: Colors.WHITE,
          padding: 10,
          marginTop: 10,
          borderRadius: 8,
          height: 40,
          overflow: "hidden",
        }}
      >
        <h4
          style={{
            fontWeight: "400",
            fontSize: 13,
            overflowWrap: "break-word",
            whiteSpace: "pre-line",
          }}
        >
          <ModifiedTextMessageDisplay message={message} />
        </h4>
      </div>
    </div>
  );
};

export default PreviewBoxContainer;
