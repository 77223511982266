import React, { memo } from "react";
import {
  AccountData,
  FormAnswersV2,
  SavedFormQuestion,
} from "@markit/common.types";
import { Colors } from "../../utils/colors";
import { useTheme } from "../../hooks/useTheme";
import SingularQuestionInput from "../../components/UserInfoContainers/SingularQuestionInput";

type FormQuestionsInputProps = {
  formQuestions: SavedFormQuestion[];
  answers: FormAnswersV2;
  setAnswers: (newAnswers: FormAnswersV2) => void;
  formErrorIds: string[];
  setFormErrorIds: (errorIds: string[]) => void;
  disabled: boolean;
  creatorData?: AccountData; // defined if this is for following a user
};

export const FormQuestionsInput = memo(function FormQuestionsInputFn(
  props: FormQuestionsInputProps
) {
  const {
    formQuestions,
    answers,
    setAnswers,
    formErrorIds,
    setFormErrorIds,
    disabled,
    creatorData,
  } = props;

  const { theme } = useTheme();

  return (
    <div>
      <div style={{ marginBottom: 14 }}>
        <h4 className="NameAndNumber" style={theme.PrimaryText}>
          Questions
        </h4>
        {disabled ? (
          <div>
            <span style={{ color: Colors.GRAY2, fontSize: 12 }}>
              Available after info entered
            </span>
          </div>
        ) : (
          <div>
            <span style={{ color: Colors.GRAY2, fontSize: 12 }}>
              {creatorData ? creatorData.fullName : "The host"} is requesting
              the following info
            </span>
          </div>
        )}
      </div>
      <hr style={{ ...theme.DividerColor, marginBottom: 0 }} />
      {formQuestions.map((question) => {
        return (
          <SingularQuestionInput
            key={question.id}
            question={question}
            formQuestions={formQuestions}
            answers={answers}
            setAnswers={setAnswers}
            formErrorIds={formErrorIds}
            setFormErrorIds={setFormErrorIds}
            disabled={disabled}
          />
        );
      })}
    </div>
  );
});
