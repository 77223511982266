import React, { useCallback, useEffect, useRef, useState } from "react";
import { Colors } from "../../utils/colors";

type CreatorPanelTabsProps = {
  tabValue: number;
  setTabValue: (tabValue: number) => void;
  tabNames: string[];
  containerStyles?: React.CSSProperties;
};

// Used for the panel tabs in creator mode
export const CreatorPanelTabs = (props: CreatorPanelTabsProps) => {
  const { tabValue, setTabValue, tabNames, containerStyles } = props;
  const tabsRef = useRef<(HTMLElement | null)[]>([]);
  const [tabUnderlineWidth, setTabUnderlineWidth] = useState(0);
  const [tabUnderlineLeft, setTabUnderlineLeft] = useState(0);
  const [isFocused, setIsFocused] = useState(-1);

  useEffect(() => {
    if (tabsRef.current) {
      const currentTab = tabsRef.current[tabValue] as HTMLElement;
      setTabUnderlineLeft(currentTab.offsetLeft);
      setTabUnderlineWidth(currentTab.clientWidth);
    } else {
      setTabUnderlineLeft(0);
      setTabUnderlineWidth(0);
    }
  }, [tabValue]);

  const styles = {
    buttonContainer: {
      backgroundColor: Colors.GRAY6,
      minHeight: 44,
      borderRadius: 14,
      padding: 4,
    },
    toggleContainer: {
      zIndex: 2,
      cursor: "pointer",
    },
  };

  const handleChange = useCallback(
    (index) => {
      setTabValue(index);
    },
    [setTabValue]
  );

  return (
    <div
      className="AlignedRowSelect"
      style={{
        ...styles.buttonContainer,
        gap: 7,
        position: "relative",
        zIndex: 1,
        width: "fit-content",
      }}
    >
      {tabNames.map((name, index) => (
        <div
          key={index}
          ref={(el) => (tabsRef.current[index] = el)}
          style={{
            ...styles.toggleContainer,
            paddingInline: 16,
            paddingBlock: 14,
          }}
          onMouseEnter={() => setIsFocused(index !== tabValue ? index : -1)}
          onMouseLeave={() => setIsFocused(-1)}
          onClick={() => handleChange(index)}
        >
          <span
            className="sectionTitle"
            style={{ opacity: isFocused === index ? 0.6 : 1 }}
          >
            {name}
          </span>
        </div>
      ))}
      <div
        style={{
          backgroundColor: Colors.WHITE,
          borderRadius: 12,
          paddingInline: 16,
          paddingBlock: 14,
          boxSizing: "border-box",
          boxShadow: "0px 1px 1px 0px #b9b9b9",
          position: "absolute",
          left: tabUnderlineLeft,
          width: tabUnderlineWidth,
          transitionDuration: "0.3s",
          transitionTimingFunction: "cubic-bezier(0.4, 0, 0.2, 1)",
          transitionBehavior: "normal",
        }}
      >
        <span className="OneLineText" style={{ color: Colors.WHITE }}>
          {tabNames[tabValue]}
        </span>
      </div>
    </div>
  );
};
