import {
  Message,
  GroupChat,
  Comment,
  WishMark,
  Event,
  TicketV2,
  DiscussionPost,
  Referral,
  Activity,
  Collection,
  PhoneInfo,
  AccountData,
  APNDevice,
  SubConverters,
  SpreadsheetUser,
  Conversation,
  AudienceList,
  AudienceListMembers,
  SubscriptionReferral,
  MassText,
  MassTextAttachment,
  TextRecipient,
  LinkMapping,
  EventLinkTracker,
  TrackingData,
  Follower,
  Following,
  SavedFormQuestion,
  SavedFormResponse,
  FormResponseV2,
  Spreadsheet,
  FeaturedUser,
  FeaturedEvent,
  Campaign,
} from "@markit/common.types";
import { QueryDocumentSnapshot } from "../firebase";
import { DocumentData, SnapshotOptions } from "firebase/firestore";

export const accountConverter = {
  toFirestore: (accountData: AccountData): DocumentData =>
    SubConverters.accountToFirestore(accountData),
  fromFirestore: (
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): AccountData => SubConverters.accountFromFirestore(snapshot.data(options)),
};

export const phoneNumberConverter = {
  toFirestore: (phoneInfo: PhoneInfo): DocumentData =>
    SubConverters.phoneNumberToFirestore(phoneInfo),
  fromFirestore: (
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): PhoneInfo =>
    SubConverters.phoneNumberFromFirestore(snapshot.data(options)),
};

export const commentConverter = {
  toFirestore: (comment: Comment): DocumentData =>
    SubConverters.commentToFirestore(comment),
  fromFirestore: (
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): Comment => SubConverters.commentFromFirestore(snapshot.data(options)),
};

export const groupChatConverter = {
  toFirestore: (groupChat: GroupChat): DocumentData =>
    SubConverters.groupChatToFirestore(groupChat),

  fromFirestore: (
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): GroupChat => SubConverters.groupChatFromFirestore(snapshot.data(options)),
};

export const wishMarkConverter = {
  toFirestore: (wishMark: WishMark): DocumentData =>
    SubConverters.wishMarkToFirestore(wishMark),
  fromFirestore: (
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): WishMark => SubConverters.wishMarkFromFirestore(snapshot.data(options)),
};

export const eventConverter = {
  toFirestore: (event: Event): DocumentData =>
    SubConverters.eventToFirestore(event),
  fromFirestore: (
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): Event => SubConverters.eventFromFirestore(snapshot.data(options)),
};

export const ticketConverter = {
  toFirestore: (ticket: TicketV2): DocumentData =>
    SubConverters.ticketToFirestore(ticket),
  fromFirestore: (snapshot: QueryDocumentSnapshot): TicketV2 =>
    SubConverters.ticketFromFirestore(snapshot.data()),
};

export const formResponseConverter = {
  toFirestore: (formResponse: FormResponseV2): DocumentData =>
    SubConverters.formResponseToFirestore(formResponse),
  fromFirestore: (snapshot: QueryDocumentSnapshot): FormResponseV2 =>
    SubConverters.formResponseFromFirestore(snapshot.data()),
};

export const discussionConverter = {
  toFirestore: (discussionPost: DiscussionPost): DocumentData =>
    SubConverters.discussionToFirestore(discussionPost),
  fromFirestore: (
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): DiscussionPost =>
    SubConverters.discussionFromFirestore(snapshot.data(options)),
};

export const messageConverter = {
  toFirestore: (message: Message): DocumentData =>
    SubConverters.messageToFirestore(message),
  fromFirestore: (
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): Message => SubConverters.messageFromFirestore(snapshot.data(options)),
};

export const activityConverter = {
  toFirestore: (activity: Activity): DocumentData =>
    SubConverters.activityToFirestore(activity),
  fromFirestore: (
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): Activity => SubConverters.activityFromFirestore(snapshot.data(options)),
};

export const collectionConverter = {
  toFirestore: (collection: Collection): DocumentData =>
    SubConverters.collectionToFirestore(collection),
  fromFirestore: (
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): Collection =>
    SubConverters.collectionFromFirestore(snapshot.data(options)),
};

export const referralConverter = {
  toFirestore: (referral: Referral): DocumentData =>
    SubConverters.referralToFirestore(referral),
  fromFirestore: (
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): Referral => SubConverters.referralFromFirestore(snapshot.data(options)),
};

export const subscriptionReferralConverter = {
  toFirestore: (subscriptionReferral: SubscriptionReferral): DocumentData =>
    SubConverters.subscriptionReferralToFirestore(subscriptionReferral),
  fromFirestore: (snapshot: QueryDocumentSnapshot): SubscriptionReferral =>
    SubConverters.subscriptionReferralFromFirestore(snapshot.data()),
};

export const apnDeviceConverter = {
  toFirestore: (device: APNDevice): DocumentData =>
    SubConverters.apnDeviceToFirestore(device),
  fromFirestore: (snapshot: QueryDocumentSnapshot): APNDevice =>
    SubConverters.apnDeviceFromFirestore(snapshot.data()),
};

export const campaignConverter = {
  toFirestore: (campaign: Campaign): DocumentData =>
    SubConverters.campaignToFirestore(campaign),
  fromFirestore: (snapshot: QueryDocumentSnapshot): Campaign =>
    SubConverters.campaignFromFirestore(snapshot.data()),
};

export const massTextConverter = {
  toFirestore: (massText: MassText): DocumentData =>
    SubConverters.massTextToFirestore(massText),
  fromFirestore: (snapshot: QueryDocumentSnapshot): MassText =>
    SubConverters.massTextFromFirestore(snapshot.data()),
};

export const massTextAttachmentConverter = {
  toFirestore: (massTextAttachment: MassTextAttachment): DocumentData =>
    SubConverters.massTextAttachmentToFirestore(massTextAttachment),
  fromFirestore: (snapshot: QueryDocumentSnapshot): MassTextAttachment =>
    SubConverters.massTextAttachmentFromFirestore(snapshot.data()),
};

export const textRecipientConverter = {
  toFirestore: (textRecipient: TextRecipient): DocumentData =>
    SubConverters.textRecipientToFirestore(textRecipient),
  fromFirestore: (snapshot: QueryDocumentSnapshot): TextRecipient =>
    SubConverters.textRecipientFromFirestore(snapshot.data()),
};

export const linkMappingConverter = {
  toFirestore: (linkMapping: LinkMapping): DocumentData =>
    SubConverters.linkMappingToFirestore(linkMapping),
  fromFirestore: (snapshot: QueryDocumentSnapshot): LinkMapping =>
    SubConverters.linkMappingFromFirestore(snapshot.data()),
};

export const eventLinkTrackerConverter = {
  toFirestore: (eventLinkTracker: EventLinkTracker): DocumentData =>
    SubConverters.eventLinkTrackerToFirestore(eventLinkTracker),
  fromFirestore: (snapshot: QueryDocumentSnapshot): EventLinkTracker =>
    SubConverters.eventLinkTrackerFromFirestore(snapshot.data()),
};

export const trackingDataConverter = {
  toFirestore: (trackingData: TrackingData): DocumentData =>
    SubConverters.TrackingDataToFirestore(trackingData),
  fromFirestore: (snapshot: QueryDocumentSnapshot): TrackingData =>
    SubConverters.TrackingDataFromFirestore(snapshot.data()),
};

export const spreadsheetUserConverter = {
  toFirestore: (spreadsheetUser: SpreadsheetUser): DocumentData =>
    SubConverters.spreadsheetUserToFirestore(spreadsheetUser),
  fromFirestore: (snapshot: QueryDocumentSnapshot): SpreadsheetUser =>
    SubConverters.spreadsheetUserFromFirestore(snapshot.data()),
};

export const spreadsheetConverter = {
  toFirestore: (spreadsheet: Spreadsheet): DocumentData =>
    SubConverters.spreadsheetToFirestore(spreadsheet),
  fromFirestore: (snapshot: DocumentData): Spreadsheet =>
    SubConverters.spreadsheetFromFirestore(snapshot.data()),
};

export const conversationConverter = {
  toFirestore: (conversation: Conversation): DocumentData =>
    SubConverters.conversationToFirestore(conversation),
  fromFirestore: (snapshot: DocumentData): Conversation =>
    SubConverters.conversationFromFirestore(snapshot.data()),
};

export const audienceListConverter = {
  toFirestore: (audienceList: AudienceList): DocumentData =>
    SubConverters.audienceListToFirestore(audienceList),
  fromFirestore: (snapshot: DocumentData): AudienceList =>
    SubConverters.audienceListFromFirestore(snapshot.data()),
};

export const audienceListMemberConverter = {
  toFirestore: (audienceListMember: AudienceListMembers): DocumentData =>
    SubConverters.audienceListMembersToFirestore(audienceListMember),
  fromFirestore: (snapshot: DocumentData): AudienceListMembers =>
    SubConverters.audienceListMembersFromFirestore(snapshot.data()),
};

export const savedFormQuestionConverter = {
  toFirestore: (savedFormQuestion: SavedFormQuestion): DocumentData =>
    SubConverters.savedFormQuestionToFirestore(savedFormQuestion),
  fromFirestore: (snapshot: QueryDocumentSnapshot): SavedFormQuestion =>
    SubConverters.savedFormQuestionFromFirestore(snapshot.data()),
};

export const savedFormResponseConverter = {
  toFirestore: (savedFormResponse: SavedFormResponse): DocumentData =>
    SubConverters.savedFormResponseToFirestore(savedFormResponse),
  fromFirestore: (snapshot: QueryDocumentSnapshot): SavedFormResponse =>
    SubConverters.savedFormResponseFromFirestore(snapshot.data()),
};

export const followerConverter = {
  toFirestore: (followers: Follower): DocumentData =>
    SubConverters.followerToFirestore(followers),
  fromFirestore: (snapshot: DocumentData): Follower =>
    SubConverters.followerFromFirestore(snapshot.data()),
};

export const followingConverter = {
  toFirestore: (following: Following): DocumentData =>
    SubConverters.followingToFirestore(following),
  fromFirestore: (snapshot: DocumentData): Following =>
    SubConverters.followingFromFirestore(snapshot.data()),
};

export const featuredUserConverter = {
  toFirestore: (user: FeaturedUser): DocumentData =>
    SubConverters.FeaturedUserToFirestore(user),
  fromFirestore: (snapshot: DocumentData): FeaturedUser =>
    SubConverters.FeaturedUserFromFirestore(snapshot.data()),
};

export const featuredEventsConverter = {
  toFirestore: (event: FeaturedEvent): DocumentData =>
    SubConverters.FeaturedEventToFirestore(event),
  fromFirestore: (snapshot: DocumentData): FeaturedEvent =>
    SubConverters.FeaturedEventFromFirestore(snapshot.data()),
};
