import { useState } from "react";
import { getUserFollowersRef } from "../../utils/FirebaseUtils";
import { getCountFromServer, query, where } from "../../firebase";
import { useDispatch, useSelector } from "react-redux";
import { getAccountState } from "../../redux/slices/accountSlice";
import { SpreadsheetStatus, FollowerStatus } from "@markit/common.types";
import useAsyncEffect from "../useAsyncEffect";

export const useLiveUpdatingImportAnalytics = () => {
  const dispatch = useDispatch();
  const { accountData } = useSelector(getAccountState).account;
  const [
    liveTotalSubscribedImportContacts,
    setLiveTotalSubscribedImportContacts,
  ] = useState<number>(0);
  const [loaded, setLoaded] = useState(false);

  useAsyncEffect(
    // eslint-disable-next-line react-hooks/exhaustive-deps
    async (_deps, isLatest) => {
      if (!loaded) {
        // total import contacts
        const followersRef = getUserFollowersRef(accountData.uid);
        const subscribedImportQuery = query(
          followersRef,
          where("spreadsheetData.status", "==", SpreadsheetStatus.ACCEPTED),
          where("status", "==", FollowerStatus.SUBSCRIBED)
        );
        const snapshot = await getCountFromServer(subscribedImportQuery);
        if (isLatest()) {
          setLiveTotalSubscribedImportContacts(snapshot.data().count);
        }

        setLoaded(true);
      }
    },
    [accountData.uid, dispatch, loaded]
  );

  return {
    liveTotalSubscribedImportContacts,
    loaded,
  };
};
