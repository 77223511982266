import React, { useCallback, useContext, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getAccountState,
  logoutUser,
} from "../../../redux/slices/accountSlice";
import "../../../css/CreatorDashboardMassTextsPanel.css";
import { Icon } from "@iconify/react";
import { Colors } from "../../../utils/colors";
import {
  MARKITAI,
  MARKIT_WEBSITE,
  hasSubscription,
  mostRecentSubscription,
  mostRecentSubscriptionPlan,
} from "@markit/common.utils";
import { MixpanelContext } from "../../../context/AnalyticsService";
import { connectToStripeAccount } from "../../../utils/stripeAccountUtils";
import GradientButton from "../../Buttons/GradientButton";
import { RWebShare } from "react-web-share";
import { firebaseAuth } from "../../../firebase";
import { GetLongDate } from "../../../utils/GetLongDate";
import RectangleButton from "../../Buttons/RectangleButton";
import PopupModalContainer from "../../Containers/PopupModalContainer";
import { isDesktop } from "react-device-detect";
import { onShareLink } from "../../../utils/shareLink";
import PaymentFailedButtonModal from "../../DisplayModal/UpdatePaymentInfoModal";
import AlertContainer from "../../Containers/AlertContainer";
import { useNavigate } from "../../../hooks/useNavigate";
import StandardBorderedContainer from "../../Containers/StandardBorderedContainer";

type ProfileAccountDetailsProps = {
  altBackgroundColor?: string;
};

const ProfileAccountDetails = (props: ProfileAccountDetailsProps) => {
  const { altBackgroundColor } = props;
  const { account } = useSelector(getAccountState);
  const { accountData } = account;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const mixpanel = useContext(MixpanelContext);
  const [feedbackText, setFeedbackText] = useState("");
  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);
  const [paymentFailedVisible, setPaymentFailedVisible] = useState(false);

  const [alertText, setAlertText] = useState({ heading: "", subHeading: "" });

  const styles = {
    headerText: { fontSize: 14, fontWeight: "500" },
    subtext: { fontSize: 12, color: Colors.GRAY1 },
  };

  const config = {
    name: "Markit Feedback",
    email: "hello@markitai.com",
  };

  const recentSubscription = mostRecentSubscription(accountData);
  const subscriptionPlan = useMemo(
    () => mostRecentSubscriptionPlan(accountData),
    [accountData]
  );

  const trialSubscription = useMemo(
    () => accountData.customer.state === "TRIAL",
    [accountData.customer.state]
  );

  const getSubscription = useCallback(() => {
    navigate("/subscription");
  }, [navigate]);

  const changeSubscription = useCallback(() => {
    navigate("/subscription", { state: { changePlan: true } });
    window.scrollTo(0, 0);
  }, [navigate]);

  const navigateCancelOnPress = useCallback(() => {
    // TODO: Account for downgrading
    navigate("/subscription/cancel", {
      state: {
        isCancelSubscription: true,
        isFreezeSubscription: false,
      },
    });
    window.scrollTo(0, 0);
  }, [navigate]);

  const unfreezeSubscription = useCallback(() => {
    navigate("/subscription/freeze", {
      state: {
        isCancelSubscription: false,
        isFreezeSubscription: true,
      },
    });
    window.scrollTo(0, 0);
  }, [navigate]);

  const visitWebsite = useCallback(() => {
    window.open(MARKIT_WEBSITE);
  }, []);

  const shareProfileMobile = useCallback(async () => {
    await onShareLink("Share Markit with your friends!", MARKITAI);
  }, []);

  const shareFeedback = useCallback(() => {
    window.open(
      `mailto:${config.email}?subject=${config.name}&body=${feedbackText}`,
      "_blank"
    );
  }, [config.email, config.name, feedbackText]);

  const manageStripe = useCallback(async () => {
    await connectToStripeAccount(accountData.uid);
  }, [accountData.uid]);

  const logout = useCallback(() => {
    try {
      mixpanel.track("Webapp: User Logout", {
        distinct_id: accountData.uid,
      });
      dispatch(logoutUser());
      navigate("/welcome");
    } catch (e: any) {
      setAlertText({
        heading: "There was an error logging out.",
        subHeading: "Please try again.",
      });
      console.log(e.message);
    }
  }, [accountData.uid, dispatch, mixpanel, navigate]);

  // const deleteAccount = useCallback(async () => {
  //   const lastSignIn = firebaseAuth.currentUser?.metadata.lastSignInTime;
  //   if (
  //     lastSignIn === undefined ||
  //     (new Date().getTime() - new Date(lastSignIn).getTime()) / 60 / 1000 > 5
  //   ) {
  //     setAlertText({
  //       heading: "You must sign out and sign in again to do this operation",
  //       subHeading: "",
  //     });
  //     return false;
  //   }
  // }, []);

  const renderSelectItem = useCallback(
    (title: string, onPress: () => void) => (
      <div className="AlignedRowSpacedSelect" onClick={onPress}>
        <span style={styles.headerText}>{title}</span>
        <Icon icon="mdi:chevron-right" height={26} color={Colors.GRAY1} />
      </div>
    ),
    [styles.headerText]
  );

  return (
    <>
      <div className="ColumnNormal">
        <StandardBorderedContainer
          containerStyles={{
            padding: 14,
            borderRadius: 14,
            backgroundColor: altBackgroundColor ?? Colors.GRAY6,
          }}
        >
          <div style={{ width: "100%" }}>
            <div className="AlignedRow" style={{ gap: 7 }}>
              <span className="AboutSubtitle">Your Account</span>
              <Icon icon="mdi:account-circle-outline" height={20} />
            </div>
            <hr style={{ marginBlock: 10 }} />
            <div className="ColumnNormal" style={{ gap: 5 }}>
              {accountData.customer.state === "FROZEN" ? (
                <div
                  style={{
                    borderRadius: 8,
                    padding: 10,
                    marginBlock: 5,
                    backgroundColor: Colors.BLACK,
                  }}
                  className={"AlignedRowSpacedSelect"}
                  onClick={unfreezeSubscription}
                >
                  <div className="ColumnNormal" style={{ gap: 3 }}>
                    <span
                      style={{
                        color: Colors.WHITE,
                        ...styles.headerText,
                      }}
                    >
                      Unfreeze Account
                    </span>
                    <span style={{ fontSize: 11, color: Colors.GRAY2 }}>
                      You must unfreeze your account to use Markit+
                    </span>
                  </div>
                  <Icon
                    icon="mdi:chevron-right"
                    height={26}
                    color={Colors.WHITE}
                  />
                </div>
              ) : accountData.customer.state === "PAYMENT_FAILED" ? (
                <div style={{ marginBlock: 5 }}>
                  <PaymentFailedButtonModal
                    isVisible={paymentFailedVisible}
                    setIsVisible={setPaymentFailedVisible}
                    button={
                      <GradientButton
                        title="Update Billing"
                        subtext={
                          <span style={{ fontSize: 11, color: Colors.WHITE }}>
                            You must update your billing info
                          </span>
                        }
                        onPress={() => setPaymentFailedVisible(true)}
                        gradientCss="redGradient"
                      />
                    }
                    location="Profile Account"
                  />
                </div>
              ) : (
                <>
                  <span style={styles.headerText}>
                    {accountData.customer.state === "TRIAL"
                      ? "Free Trial"
                      : subscriptionPlan
                      ? subscriptionPlan.name
                      : "Free Plan"}
                  </span>
                  <span style={styles.subtext}>
                    {accountData.customer.state === "TRIAL"
                      ? "You are currently on a free trial of Markit+. Get Markit+ to access additional features, texting and support."
                      : subscriptionPlan
                      ? `You are currently on the ${subscriptionPlan.name} plan of Markit+.`
                      : "You are currently on the free plan of Markit. Get Markit+ to access additional features, texting and support."}
                  </span>
                  {accountData.customer.cancelAtEnd && recentSubscription ? (
                    <span style={styles.subtext}>
                      Cancels: {GetLongDate(recentSubscription.end)}
                    </span>
                  ) : null}
                  {accountData.customer.state === "INACTIVE" ? (
                    <div style={{ marginBlock: 5 }}>
                      <GradientButton
                        title="Get Markit+"
                        subtext={
                          <span style={{ fontSize: 11, color: Colors.WHITE }}>
                            Get a unique phone number and more for $10
                          </span>
                        }
                        onPress={getSubscription}
                        gradientCss="blueGradient"
                      />
                    </div>
                  ) : null}
                </>
              )}
            </div>
            <hr style={{ marginBlock: 10 }} />
            <div className="ColumnNormal" style={{ gap: 14 }}>
              {hasSubscription(accountData)
                ? renderSelectItem("Upgrade", changeSubscription)
                : null}
              {!isDesktop && hasSubscription(accountData)
                ? renderSelectItem(
                    "Cancel " + (trialSubscription ? "Trial" : "Subscription"),
                    navigateCancelOnPress
                  )
                : null}
              {renderSelectItem("Visit Website", visitWebsite)}
            </div>
          </div>
        </StandardBorderedContainer>
        <StandardBorderedContainer
          containerStyles={{
            marginTop: 14,
            padding: 14,
            borderRadius: 14,
            backgroundColor: altBackgroundColor ?? Colors.GRAY6,
          }}
        >
          <div style={{ width: "100%" }}>
            <div className="ColumnNormal" style={{ gap: 14 }}>
              {renderSelectItem("Manage Stripe", manageStripe)}
              <RWebShare data={{ url: MARKITAI }}>
                <div
                  className="AlignedRowSpacedSelect"
                  onClick={isDesktop ? undefined : shareProfileMobile}
                >
                  <span style={styles.headerText}>Share Markit</span>
                  <Icon
                    icon="mdi:chevron-right"
                    height={26}
                    color={Colors.GRAY1}
                  />
                </div>
              </RWebShare>
              {isDesktop
                ? renderSelectItem("Share Feedback", () =>
                    setFeedbackModalOpen(true)
                  )
                : null}
              {renderSelectItem("Delete Account", () =>
                setAlertText({
                  heading: "You can delete your account in the mobile app.",
                  subHeading: "",
                })
              )}
              {!isDesktop ? renderSelectItem("Sign Out", logout) : null}
            </div>
          </div>
        </StandardBorderedContainer>
      </div>
      {feedbackModalOpen ? (
        <PopupModalContainer
          headerComp={
            <div className="ColumnNormal" style={{ gap: 4 }}>
              <span className="AboutSubtitle">Share Feedback</span>
              <span style={{ color: Colors.GRAY1, fontSize: 14 }}>
                Share any issues or thoughts with the Markit team.
              </span>
            </div>
          }
          valueComp={
            <div className="ColumnNormal" style={{ gap: 14 }}>
              <div className="descriptionTextAreaContainer">
                <textarea
                  value={feedbackText}
                  placeholder={"Write your feedback here..."}
                  onChange={(e) => setFeedbackText(e.target.value)}
                  className="descriptionTextArea"
                  maxLength={2000}
                />
                <div
                  className="AlignedRow"
                  style={{ marginTop: 14, justifyContent: "space-between" }}
                >
                  <h4
                    style={{
                      ...styles.headerText,
                      color: Colors.GRAY1,
                    }}
                  >
                    {feedbackText.length}/2000
                  </h4>
                </div>
              </div>
              <RectangleButton
                buttonLabel="Share"
                altColor={Colors.BLACK}
                disabled={feedbackText.length === 0}
                onPress={shareFeedback}
              />
            </div>
          }
          closeModal={() => {
            setFeedbackModalOpen(false);
            setFeedbackText("");
          }}
        />
      ) : null}
      <AlertContainer
        headerComp={alertText.heading}
        subHeaderComp={
          alertText.subHeading !== "" ? alertText.subHeading : undefined
        }
        closeModal={() => setAlertText({ heading: "", subHeading: "" })}
        hideModal={alertText.heading === "" && alertText.subHeading === ""}
      />
    </>
  );
};

export default ProfileAccountDetails;
