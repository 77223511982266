import React from "react";
import { isDesktop } from "react-device-detect";
import { Colors } from "../../utils/colors";

export const EmptySearchState = (props: {
  mainText: string;
  altItemColor?: string;
  altTextColor?: string;
  altBottomPadding?: number;
}) => {
  const { mainText, altItemColor, altTextColor, altBottomPadding } = props;
  return (
    <div>
      <div
        style={{
          position: "relative",
          marginBottom: altBottomPadding || (isDesktop ? "40px" : "60px"),
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "14px",
          }}
        >
          {[1].map((_, index) => (
            <div key={index}>
              <div
                style={{
                  width: "152px",
                  height: "16px",
                  backgroundColor: altItemColor || "#EDEDED",
                }}
              />
              <div
                style={{
                  width: "81px",
                  height: "16px",
                  backgroundColor: altItemColor || "#EDEDED",
                }}
              />
            </div>
          ))}
        </div>
        <span
          style={{
            fontSize: "14px",
            fontWeight: 400,
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            color: altTextColor || Colors.BLACK,
          }}
        >
          {mainText}
        </span>
      </div>
    </div>
  );
};
