import React, { memo, useCallback } from "react";
import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { CustomTicketV2 } from "@markit/common.types";
import { Icon } from "@iconify/react";
import { useTheme } from "../../hooks/useTheme";

type TicketQuantityBoxProps = {
  ticketQuantity: number;
  setTicketQuantity: (ticketQuantity: number) => void;
  customTicket: CustomTicketV2;
  currTickets: number;
  disabled?: boolean;
};

export const TicketQuantityBox = memo(function TicketQuantityBoxFn(
  props: TicketQuantityBoxProps
) {
  const {
    ticketQuantity,
    setTicketQuantity,
    customTicket,
    currTickets,
    disabled,
  } = props;

  const { theme } = useTheme();

  const handleChange = useCallback(
    (event: SelectChangeEvent) => {
      setTicketQuantity(event.target.value as unknown as number);
    },
    [setTicketQuantity]
  );

  const items: number[] = Array.from(
    {
      length:
        customTicket.maxQuantity -
        customTicket.minQuantity -
        (currTickets || 0) +
        1,
    },
    (_, i) => i + (customTicket.minQuantity ?? 1)
  );

  return (
    <FormControl variant="outlined">
      <Select
        labelId="select-ticket-quantity-label"
        id="select-ticket-quantity"
        value={ticketQuantity as unknown as string}
        defaultValue={customTicket.minQuantity.toString()}
        onChange={handleChange}
        MenuProps={{
          disableScrollLock: true,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          PaperProps: {
            sx: {
              borderRadius: "12px",
              width: "211px",
              boxShadow: "0px 24px 62px 2px rgba(0, 0, 0, 0.27)",
              "& .MuiMenuItem-root:hover": {
                ...theme.HoverBG,
              },
              "& .MuiMenuItem-root.Mui-selected:hover": {
                ...theme.HoverSelectBG,
              },
            },
          },
          sx: {
            opacity: 0.98,
            "&& .Mui-selected": {
              ...theme.LinkDropdownBG,
            },
          },
          MenuListProps: {
            disablePadding: true,
          },
        }}
        sx={{
          ".MuiSvgIcon-root ": {
            fill: "#929292",
          },
        }}
        style={{
          ...theme.TertiaryBG,
          borderRadius: "8px",
          height: 34,
          width: 58,
          fontWeight: "600",
        }}
        disabled={disabled}
        renderValue={(value) => <div style={theme.PrimaryText}>{value}</div>}
      >
        {items.map((i, index) => {
          return (
            <MenuItem
              key={index}
              divider={i < items.length}
              value={i}
              sx={{
                ...theme.LinkDropdownBG,
                ...theme.SubDividerColor,
                display: "flex",
                flexDirection: "row",
                gap: "5px",
              }}
            >
              {i === ticketQuantity ? (
                <span
                  style={{
                    ...theme.PrimaryText,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Icon
                    icon="ion:checkmark"
                    height={16}
                    style={{ paddingRight: 5 }}
                  />{" "}
                  {i}
                </span>
              ) : (
                <span style={{ ...theme.PrimaryText, paddingLeft: 21 }}>
                  {i}
                </span>
              )}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
});
