import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { Colors } from "../../utils/colors";
import { Icon } from "@iconify/react";
import { LightTheme, useTheme } from "../../hooks/useTheme";
import {
  detectedCensored,
  getTimezoneNameOffset,
  isExternalGenericLink,
  monthNameArray,
  truncateString,
} from "@markit/common.utils";
import dayjs from "dayjs";
import { invalidEndDate } from "../../utils/makeEvent";
import ContentViolationTagModal from "../DeliveryScore/ContentViolationTagModal";
import { Event, EventType } from "@markit/common.types";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { useOnMount } from "../../hooks/useOnMount";
import TextModal from "../TextModal";
import CreateLocationModal from "./CreateModals/CreateLocationModal";

type CreateEventTitleDescriptionFormProps = {
  eventSettings: Event;
  updateEventSettings: (update: Partial<Event>) => void;
  showError: boolean;
  isEditing: boolean;
};

const CreateEventTitleDescriptionForm = (
  props: CreateEventTitleDescriptionFormProps
) => {
  const { eventSettings, updateEventSettings, showError, isEditing } = props;
  const { theme } = useTheme(eventSettings.theme);

  const [timezoneData, setTimezoneData] = useState<{
    name: string;
    offset: string | undefined;
  }>();
  const [isLocationModalOpen, setIsLocationModalOpen] = useState(false);
  const [isEventDescriptionModalOpen, setIsEventDescriptionModalOpen] =
    useState(false);
  const [modalEventDesc, setModalEventDesc] = useState<string>("");
  const [locationAddress, setLocationAddress] = useState<string>("");

  useEffect(() => {
    setModalEventDesc(eventSettings.description);
  }, [eventSettings.description]);

  useEffect(() => {
    if (isEditing) {
      if (locationAddress === "") {
        setLocationAddress(eventSettings.googleDescription);
      }
    }
  }, [
    eventSettings.customTickets,
    eventSettings.googleDescription,
    isEditing,
    locationAddress,
  ]);

  useOnMount(() => {
    const { name, offset } = getTimezoneNameOffset();
    setTimezoneData({ name, offset });
  });

  const getAddress = useMemo(() => {
    return !eventSettings.isVirtual && eventSettings.formattedAddress !== ""
      ? eventSettings.formattedAddress
      : eventSettings.isVirtual
      ? "Virtual location"
      : "Physical or virtual location";
  }, [eventSettings.formattedAddress, eventSettings.isVirtual]);

  const onChangeStartDate = useCallback(
    (newValue) => {
      const date = dayjs(newValue).toDate();
      const difference = newValue - new Date(eventSettings.start).getTime();
      const newEndDate = new Date(
        new Date(eventSettings.end).getTime() + difference
      );

      updateEventSettings({
        start: date.toISOString(),
      });
      updateEventSettings({
        end: newEndDate.toISOString(),
      });
    },
    [eventSettings.end, eventSettings.start, updateEventSettings]
  );

  const renderEventDescriptionModal = useCallback(() => {
    const closeModal = () => {
      setIsEventDescriptionModalOpen(false);
      setModalEventDesc(eventSettings.description);
    };

    const onSave = () => {
      updateEventSettings({
        description: modalEventDesc,
      });
    };

    return (
      <TextModal
        eventSettings={eventSettings}
        title={"Event Description"}
        inputText={modalEventDesc}
        inputPlaceholder={"Describe your event here..."}
        onChange={(e) => setModalEventDesc(e.target.value)}
        maxLength={4000}
        messageLength={modalEventDesc.trim().length}
        onSave={() => {
          onSave();
          closeModal();
        }}
        onClose={closeModal}
      />
    );
  }, [eventSettings, modalEventDesc, updateEventSettings]);

  return (
    <>
      <div className="PageDesktop" style={theme.SecondaryBG}>
        <div className="Container">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <input
              className={
                showError && eventSettings.title === ""
                  ? "titleInputError"
                  : "titleInput"
              }
              style={{
                ...theme.SecondaryBG,
                color: theme === LightTheme ? Colors.BLACK : Colors.WHITE,
                fontStyle:
                  showError && eventSettings.title === "" ? "italic" : "",
              }}
              type="text"
              maxLength={100}
              defaultValue={eventSettings.title}
              placeholder={
                isExternalGenericLink(eventSettings.eventType)
                  ? "Enter Link Name..."
                  : "Event Name..."
              }
              onChange={(e) =>
                updateEventSettings({
                  title: e.target.value,
                })
              }
            />
            <div
              className="AlignedRow"
              style={{ paddingTop: 7, marginBottom: 7, justifyContent: "end" }}
            >
              {eventSettings.title.length === 100 ? (
                <h4
                  style={{
                    fontSize: 14,
                    fontWeight: 400,
                    color: Colors.GRAY2,
                  }}
                >
                  {eventSettings.title.length}/100
                </h4>
              ) : null}
              {/* Multi-line text input draft */}
              {/* <textarea
                className={
                  showError && eventSettings.title === ""
                    ? "titleInputError"
                    : "titleInput"
                }
                style={{
                  ...theme.SecondaryBG,
                  color: theme === LightTheme ? Colors.BLACK : Colors.WHITE,
                  fontStyle:
                    showError && eventSettings.title === "" ? "italic" : "",
                  width: "100%",
                  height: eventSettings.title.length > 42 ? 120 : "auto",
                  fontSize: 22,
                  fontWeight: 600,
                  padding: 10,
                  boxSizing: "border-box",
                  resize: "none",
                }}
                maxLength={100}
                defaultValue={eventSettings.title}
                placeholder="Event Name..."
                onChange={(e) =>
                  updateEventSettings({
                    title: e.target.value,
                  })
                }
              /> */}
              {/* <div
                style={{
                  position: "absolute",
                  bottom: 10,
                  right: 10,
                  fontSize: 14,
                  fontWeight: 400,
                  color: Colors.GRAY2,
                }}
              >
                {eventSettings.title.length}/100
              </div> */}
              {/* End of multi-line text input draft */}
              {detectedCensored(eventSettings.title) ? (
                <ContentViolationTagModal
                  message={eventSettings.title}
                  theme={theme}
                  alternateTitle="Content Violation"
                />
              ) : null}
            </div>
            <div className="AlignedRow" style={{ gap: 7 }}>
              <div
                className="InfoContainerIcon"
                style={{
                  alignSelf: "flex-start",
                }}
              >
                <p
                  style={{
                    fontWeight: 600,
                    fontSize: "12px",
                    ...theme.PrimaryText,
                  }}
                >
                  {
                    monthNameArray[
                      eventSettings.start
                        ? new Date(eventSettings.start).getMonth()
                        : 1
                    ]
                  }
                </p>
                <p
                  style={{
                    color: "#FE296C",
                    fontWeight: 600,
                    fontSize: "14px",
                  }}
                >
                  {new Date(eventSettings.start).getDate()}
                </p>
              </div>
              <div
                className="datesContainer"
                style={{ overflow: "hidden", ...theme.TertiaryBG }}
              >
                <div className="dateWrapper">
                  <h4
                    className="AboutSubtitle"
                    style={{ fontSize: 14, ...theme.PrimaryText }}
                  >
                    Start
                  </h4>
                  <div className="dateContainer" style={theme.SecondaryBG}>
                    <DatePicker
                      slotProps={{
                        textField: {
                          size: "small",
                          inputProps: {
                            style: { ...theme.PrimaryText },
                          },
                          sx: {
                            "& fieldset": {
                              border: "none",
                            },
                            "& .MuiButtonBase-root": {
                              color:
                                theme === LightTheme
                                  ? Colors.GRAY3
                                  : Colors.WHITE,
                            },
                          },
                        },
                      }}
                      disablePast
                      value={dayjs(
                        new Date(new Date(eventSettings.start).getTime())
                      )}
                      onChange={(newValue) => {
                        onChangeStartDate(newValue);
                      }}
                    />
                    <TimePicker
                      slotProps={{
                        textField: {
                          size: "small",
                          inputProps: {
                            style: { ...theme.PrimaryText },
                          },
                          sx: {
                            "& fieldset": {
                              border: "none",
                              borderLeft: "0.5px solid #B9B9B9",
                              borderRadius: "0",
                            },
                            "& .MuiButtonBase-root": {
                              color:
                                theme === LightTheme
                                  ? Colors.GRAY3
                                  : Colors.WHITE,
                            },
                          },
                        },
                      }}
                      value={dayjs(
                        new Date(new Date(eventSettings.start).getTime())
                      )}
                      onChange={(newValue) => {
                        onChangeStartDate(newValue);
                      }}
                    />
                  </div>
                </div>
                <div className="dateWrapper" style={{ marginTop: 7 }}>
                  <h4
                    className="AboutSubtitle"
                    style={{ fontSize: 14, ...theme.PrimaryText }}
                  >
                    {isExternalGenericLink(eventSettings.eventType)
                      ? "Expires"
                      : "End"}
                  </h4>
                  <div className="dateContainer" style={theme.SecondaryBG}>
                    <DatePicker
                      slotProps={{
                        textField: {
                          size: "small",
                          inputProps: {
                            style: { ...theme.PrimaryText },
                          },
                          sx: {
                            "& fieldset": {
                              border: invalidEndDate(eventSettings)
                                ? "1px solid #DF4840"
                                : "none",
                              borderColor: Colors.RED1,
                            },
                            "& .MuiButtonBase-root": {
                              color:
                                theme === LightTheme
                                  ? Colors.GRAY3
                                  : Colors.WHITE,
                            },
                          },
                        },
                      }}
                      disablePast
                      value={dayjs(
                        new Date(new Date(eventSettings.end).getTime())
                      )}
                      onChange={(newValue) => {
                        const date = dayjs(newValue).toDate();
                        updateEventSettings({
                          end: date.toISOString(),
                        });
                      }}
                    />
                    <TimePicker
                      slotProps={{
                        textField: {
                          size: "small",
                          inputProps: {
                            style: { ...theme.PrimaryText },
                          },
                          sx: {
                            "& fieldset": {
                              border: invalidEndDate(eventSettings)
                                ? "1px solid #DF4840"
                                : "none",
                              borderLeft: invalidEndDate(eventSettings)
                                ? "1px solid #DF4840"
                                : "0.5px solid #B9B9B9",
                              borderRadius: "0",
                            },
                            "& .MuiButtonBase-root": {
                              color:
                                theme === LightTheme
                                  ? Colors.GRAY3
                                  : Colors.WHITE,
                            },
                          },
                        },
                      }}
                      value={dayjs(
                        new Date(new Date(eventSettings.end).getTime())
                      )}
                      onChange={(newValue) => {
                        const date = dayjs(newValue).toDate();
                        updateEventSettings({
                          end: date.toISOString(),
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="dateWrapper" style={{ marginTop: 14 }}>
                  <div className="AlignedRow">
                    <h4
                      className="AboutSubtitle"
                      style={{ fontSize: 14, ...theme.PrimaryText }}
                    >
                      Timezone
                    </h4>
                  </div>
                  <div
                    className="AlignedRow"
                    style={{
                      justifyContent: timezoneData
                        ? "space-between"
                        : "flex-end",
                      border: "0.5px solid #B9B9B9",
                      borderRadius: "8px",
                      padding: "7px 14px",
                      gap: 10,
                    }}
                  >
                    {timezoneData ? (
                      <>
                        <h4 style={{ fontWeight: "400", ...theme.PrimaryText }}>
                          {timezoneData.offset}
                        </h4>
                        <h4 style={{ fontWeight: "400", ...theme.PrimaryText }}>
                          {timezoneData.name}
                        </h4>
                      </>
                    ) : (
                      <span style={theme.PrimaryText}>Undetermined</span>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {!isExternalGenericLink(eventSettings.eventType) ? (
              <div
                className="AlignedRow"
                style={{ gap: 7, marginTop: 14, width: "100%" }}
              >
                <div className="InfoContainerIcon">
                  <Icon
                    icon={"mdi:map-marker-outline"}
                    height={25}
                    style={{ color: Colors.BLUE5 }}
                  />
                </div>
                <div
                  className="datesContainer"
                  style={{
                    border:
                      showError &&
                      !eventSettings.formattedAddress &&
                      !eventSettings.isVirtual
                        ? "1px solid #DF4840"
                        : "",
                    alignItems: "center",
                    overflow: "hidden",
                    paddingBlock: 10,
                    cursor: "pointer",
                    ...theme.TertiaryBG,
                  }}
                  onClick={() => setIsLocationModalOpen(true)}
                >
                  <div
                    className="AlignedRowSpaced"
                    style={{ cursor: "pointer" }}
                  >
                    <div style={{ cursor: "pointer" }}>
                      <h4
                        className="AboutSubtitle"
                        style={{ fontSize: 14, ...theme.PrimaryText }}
                      >
                        {eventSettings.isVirtual
                          ? "Virtual Event"
                          : eventSettings.googleDescription !== ""
                          ? truncateString(
                              eventSettings.googleDescription,
                              54,
                              "..."
                            )
                          : "Choose Location"}
                      </h4>
                      <span
                        style={{
                          color: Colors.GRAY2,
                          fontSize: "12px",
                          overflowWrap: "break-word",
                          whiteSpace: "pre-line",
                        }}
                      >
                        {getAddress}
                      </span>
                    </div>
                    <Icon
                      icon={"feather:edit"}
                      height={18}
                      style={{
                        color:
                          theme === LightTheme ? Colors.BLACK : Colors.WHITE,
                      }}
                    />
                  </div>
                </div>
              </div>
            ) : null}
            {eventSettings.eventType === EventType.MARKIT ? (
              <div
                className="AlignedRow"
                style={{ gap: 7, marginTop: 14, width: "100%" }}
              >
                <div className="InfoContainerIcon">
                  <Icon
                    icon={"bi:stars"}
                    height={25}
                    style={{ color: Colors.GRAY1 }}
                  />
                </div>
                <div
                  className="datesContainer AlignedRowSpaced"
                  style={{
                    overflow: "hidden",
                    paddingBlock: 10,
                    cursor: "pointer",
                    ...theme.TertiaryBG,
                  }}
                  onClick={() => setIsEventDescriptionModalOpen(true)}
                >
                  <div
                    style={{
                      cursor: "pointer",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      width: "90%",
                    }}
                  >
                    <h4
                      className="AboutSubtitle"
                      style={{ fontSize: 14, ...theme.PrimaryText }}
                    >
                      Description
                    </h4>
                    <span
                      style={{
                        color: Colors.GRAY2,
                        fontSize: 12,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "inline-block",
                      }}
                    >
                      {eventSettings.description !== ""
                        ? eventSettings.description
                        : "Add an optional event description"}
                    </span>
                  </div>
                  <div style={{ cursor: "pointer" }}>
                    <Icon
                      icon={"feather:edit"}
                      height={18}
                      style={{
                        color:
                          theme === LightTheme ? Colors.BLACK : Colors.WHITE,
                      }}
                    />
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      {/* Modal for setting event description and texts */}
      {isEventDescriptionModalOpen ? renderEventDescriptionModal() : null}
      {/* Modal for setting location */}
      {isLocationModalOpen ? (
        <CreateLocationModal
          eventSettings={eventSettings}
          updateEventSettings={updateEventSettings}
          setLocationAddress={setLocationAddress}
          setIsLocationModalOpen={setIsLocationModalOpen}
        />
      ) : null}
    </>
  );
};

export default memo(CreateEventTitleDescriptionForm);
