import {
  AccountData,
  AudienceList,
  AudienceListVisibility,
  StripeAccountStatus,
} from "@markit/common.types";
import { communityWelcomePlaceholder } from "@markit/common.utils";
import { generate } from "shortid";

// Instantiates an empty accountData object
const makeEmptyAccount = () => {
  const newAccount: AccountData = {
    email: "",
    uid: "",
    fullName: "",
    username: "",
    profileLink: "",
    profilePicURL: "",
    profileReferrals: [],
    bio: "",
    blockedUsers: [],
    completedPromoterPanel: false,
    completedCreatorSetup: false,
    completedCreatorSetupTimestamp: "",
    contactUrl: "",
    contactCustomMessage: communityWelcomePlaceholder(false),
    contactCustomMediaUrl: "",
    contactDayAfterMessage: "",
    contactPregameMessage: "",
    contactUploadAudienceMessage: "",
    contactPhoneMessages: 0,
    contactPhoneNumber: "",
    currentMarkitBalance: 0,
    customer: {
      subAccountSid: "",
      subAuthToken: "",
      subMessagingService: "",
      subTemplateSid: "",
      state: "INACTIVE",
      subscription: [],
      sid: "",
      phone: "",
      contactCardUrl: "",
      cancelAtEnd: false,
      frozenDate: "",
      freeTierMessages: 0,
    },
    numFreeTierReplies: 0,
    importContactsApproval: false,
    interests: [],
    isAdmin: false,
    isAmbassador: false,
    isEventPartner: false,
    phoneNumber: "",
    university: -1,
    filterSetting: {
      distance: 10,
      startDate: new Date().toISOString(),
      endDate: new Date(Date.now() + 6.048e8).toISOString(),
    },
    following: [],
    followers: [],
    formQuestions: [],
    eventbriteInfo: {
      token: "",
      userId: "",
      organizationId: "",
      autoAddPhoneQuestion: false,
      connectedOn: "",
      syncedOn: "",
    },
    excludedPhone: [],
    expoPushToken: "",
    activityIndicator: false,
    haveNotFinishedStartup: false,
    haveNotFinishedInitialStartup: false,
    hideProfileEvents: false,
    profilePasswordProtect: "",
    searchHistory: {
      marks: [],
      users: [],
      places: [],
    },
    signedAgreement: false,
    showImportantMessage: false,
    showedAndroidAgreement: false,
    showedCreatorToolsSetup: false,
    showedCreatorModeOnboarding: {
      massTextingScreen: false,
      eventTextsScreen: false,
    },
    showedSwipeFeedTutorial: false,
    showedWeeklyCommunityUpdates: false,
    showedEducationModals: {
      textCategoriesModal: false,
    },
    showedTooltips: {
      showedEventFeedTooltip: false,
      showedCreateEventTooltip: false,
      showedCreateEventAdvancedTooltip: false,
      showedPlanTooltip: false,
    },
    showedUniversityModal: false,
    showedReferralProgramModal: false,
    instagram: "",
    linkedin: "",
    twitter: "",
    tiktok: "",
    spotify: "",
    stripeCustomerId: "",
    stripeAccountId: "",
    stripeAccountStatus: StripeAccountStatus.INCOMPLETE,
    subscriptionFollowMessage: "",
    toggleCreatorTools: {
      pregameText: false,
      dayAfterText: false,
    },
    twilioUserId: "",
    userReferrer: "",
    customList: {
      individualUsersList: [],
      communitiesList: [],
    },
    unreadGroups: [],
    inCreatorMode: false,
    joinedWaitlist: false,
  };

  return newAccount;
};

// Instantiates an empty audienceList object
const makeEmptyAudienceList = (name?: string) => {
  const audienceList: AudienceList = {
    id: generate(),
    name: name ?? "",
    description: "",
    numberMembers: 0,
    visibility: AudienceListVisibility.PRIVATE,
  };
  return audienceList;
};

export { makeEmptyAccount, makeEmptyAudienceList };
