import React, { useState, useCallback, useMemo, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  createAudienceList,
  getAccountState,
  updateAudienceList,
} from "../../../../redux/slices/accountSlice";
import { Colors } from "../../../../utils/colors";
import LargePopupModalContainer from "../../../Containers/LargePopupModalContainer";
import { BackButton } from "../../../Buttons/BackButton";
import StandardBorderedContainer from "../../../Containers/StandardBorderedContainer";
import { Icon } from "@iconify/react";
import CustomTextField from "../../../CustomTextField";
import {
  AccountData,
  AudienceList,
  AudienceListVisibility,
  FollowerStatus,
} from "@markit/common.types";
import { makeEmptyAudienceList } from "../../../../utils/makeEmptyData";
import SwitchToggleItem from "../../../SwitchToggleItem";
import SearchBoxContainer from "../../../Containers/SearchBoxContainer";
import filter from "lodash.filter";
import FlatList from "flatlist-react/lib";
import EmptyStateButton from "../../../Buttons/EmptyStateButton";
import { CircularProgress } from "@mui/material";
import RectangleButton from "../../../Buttons/RectangleButton";
import AudienceListDropdownMenu from "../../../DropdownsAndTabs/AudienceListDropdownMenu";
import ConfirmDeleteModal from "../../../Containers/ConfirmPopups/ConfirmDeleteModal";
import isEqual from "lodash.isequal";
import SimpleSelectRecipientsPanel from "../../MassTexts/SelectRecipientsFlow/SimpleSelectRecipientsPanel";
import { HorizontalDivider } from "../../../Dividers/HorizontalDivider";
import { MassTextsPersonItem } from "../../MassTexts/Items/MassTextsPersonItem";
import FullProfilePreviewModal from "../../../FollowerProfile/FullProfilePreviewModal";
import { useLoadUserList } from "../../../../hooks/useLoadUserList";
import {
  FAVORITES_LIST_NAME,
  filterFollowersByStatus,
  hasSubscriptionUnlockedAdvancedData,
} from "@markit/common.utils";
import { EmptyStateFlatlist } from "../../../EmptyStates/EmptyStateFlatlist";
import { useLiveUpdatingListAnalytics } from "../../../../hooks/useLiveHooks/useLiveUpdatingListAnalytics";
import ListDropdown from "../../../DropdownsAndTabs/ListDropdown";
import { useOnMount } from "../../../../hooks/useOnMount";
import { MarkitPlusModal } from "../../../Subscription/MarkitPlusModal";
import { BinaryConfirmActions } from "../../../Containers/ConfirmPopups/ConfirmActionPopup";

type AudienceListPopupPanelProps = {
  isVisible: boolean;
  setIsVisible: (isVisible: boolean) => void;
  existingList: AudienceList | undefined;
  setConfirmAction?: (ticketTypeAction: BinaryConfirmActions) => void;
  initialMembersToAdd?: string[]; // If there are members we want to add initially on new list
};

const AudienceListPopupPanel = (props: AudienceListPopupPanelProps) => {
  const {
    isVisible,
    setIsVisible,
    existingList,
    setConfirmAction,
    initialMembersToAdd,
  } = props;
  const dispatch = useDispatch();
  const { accountData, followersData, audienceLists } =
    useSelector(getAccountState).account;
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [audienceList, setAudienceList] = useState<AudienceList>(
    existingList ?? makeEmptyAudienceList()
  );
  const [currentMembers, setCurrentMembers] = useState<string[]>([]);
  const [excludedMembers, setExcludedMembers] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [listNameError, setListNameError] = useState<{
    isError: boolean;
    message: string;
  }>({ isError: false, message: "" });
  const [loadingMembers, setLoadingMembers] = useState(true);
  const [savingList, setSavingList] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [selectRecipientsVisible, setSelectRecipientsVisible] = useState(false);
  const [profileSelected, setProfileSelected] = useState<AccountData>();
  const [initialListMembers, setInitialListMembers] = useState<string[]>([]);
  const [dropdownValue, setDropdownValue] = useState(0);
  const [markitPlusModalVisible, setMarkitPlusModalVisible] = useState(false);

  const styles = {
    headerText: { fontSize: 20, fontWeight: 500 },
    bodyMedium: { fontSize: 14, fontWeight: 500 },
    bodySmall: { fontSize: 12, color: Colors.GRAY1 },
    dropdownComponent: { paddingBottom: 10, paddingTop: 4 },
    sectionTitle: { fontSize: 16, fontWeight: 500 },
    errorText: { fontSize: 12, color: Colors.RED2 },
  };

  const { liveListContactUids, liveTotalListContacts, loaded } =
    useLiveUpdatingListAnalytics({
      listId: existingList ? existingList.id : "",
      full: true,
    });

  const existingListLoading = useMemo(
    () => existingList && !loaded,
    [existingList, loaded]
  );

  useOnMount(() => {
    // Show markit plus if user does not have advanced subscription and has created three audience lists
    if (
      !hasSubscriptionUnlockedAdvancedData(accountData) &&
      audienceLists.filter((list) => list.name !== FAVORITES_LIST_NAME)
        .length >= 3 &&
      !existingList
    ) {
      setMarkitPlusModalVisible(true);
    }
    if (initialMembersToAdd) {
      setCurrentMembers(initialMembersToAdd);
    }
  });

  const netListMembers = useMemo(
    () =>
      currentMembers
        ? currentMembers.filter((userId) => !excludedMembers?.includes(userId))
        : [],
    [excludedMembers, currentMembers]
  );

  const subscribedMembers = useMemo(
    () =>
      filterFollowersByStatus(
        liveListContactUids,
        followersData,
        FollowerStatus.SUBSCRIBED
      ),
    [followersData, liveListContactUids]
  );

  const unsubscribedMembers = useMemo(
    () =>
      filterFollowersByStatus(
        liveListContactUids,
        followersData,
        FollowerStatus.UNSUBSCRIBED
      ),
    [followersData, liveListContactUids]
  );

  const removedMembers = useMemo(
    () =>
      filterFollowersByStatus(
        liveListContactUids,
        followersData,
        FollowerStatus.REMOVED
      ),
    [followersData, liveListContactUids]
  );

  const {
    isFinished,
    isLoading,
    fetchedUserData,
    loadMoreUsers,
    reloadMoreUsers,
    loadSearchResultsThrottled,
  } = useLoadUserList({
    userIdList: netListMembers.concat(initialMembersToAdd ?? []),
    windowSize: 20,
  });

  const {
    isFinished: isSubscribedFinished,
    isLoading: isSubscribedLoading,
    fetchedUserData: fetchedSubscribedUserData,
    loadMoreUsers: loadMoreSubscribedUsers,
    loadSearchResultsThrottled: loadSubscribedSearchResultsThrottled,
  } = useLoadUserList({
    userIdList: !existingListLoading ? subscribedMembers : [],
    windowSize: 20,
  });
  const {
    isFinished: isUnsubscribedFinished,
    isLoading: isUnsubscribedLoading,
    fetchedUserData: fetchedUnsubscribedUserData,
    loadMoreUsers: loadMoreUnsubscribedUsers,
    loadSearchResultsThrottled: loadUnsubscribedSearchResultsThrottled,
  } = useLoadUserList({
    userIdList: !existingListLoading ? unsubscribedMembers : [],
    windowSize: 20,
  });

  const {
    isFinished: isRemovedFinished,
    isLoading: isRemovedLoading,
    fetchedUserData: fetchedRemovedUserData,
    loadMoreUsers: loadMoreRemovedUsers,
    loadSearchResultsThrottled: loadRemovedSearchResultsThrottled,
  } = useLoadUserList({
    userIdList: !existingListLoading ? removedMembers : [],
    windowSize: 20,
  });

  useEffect(() => {
    // existing audience list
    if (existingList && !existingListLoading) {
      setCurrentMembers(liveListContactUids);
      setInitialListMembers(liveListContactUids);
    }
  }, [liveListContactUids, existingListLoading, existingList]);

  useEffect(() => {
    if ((!existingList || (existingList && isEditing)) && loadingMembers) {
      // For loading when editing
      loadMoreUsers();
      setLoadingMembers(false);
    } else if (
      !isEditing &&
      existingList &&
      !existingListLoading &&
      loadingMembers
    ) {
      // for loading when viewing
      loadMoreSubscribedUsers();
      loadMoreUnsubscribedUsers();
      loadMoreRemovedUsers();
      setLoadingMembers(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existingListLoading, isEditing, loadingMembers]);

  const existingListNotEditing = useMemo(
    () => existingList && !isEditing,
    [existingList, isEditing]
  );

  const disableEditingInfo = useMemo(() => {
    const favoritesList = audienceLists.find(
      (list) => list.name === FAVORITES_LIST_NAME
    );
    return favoritesList && favoritesList.id === audienceList.id && isEditing;
  }, [audienceList.id, audienceLists, isEditing]);

  // when editing or creating a new list
  const makingChanges = useMemo(
    () => isEditing || !existingList,
    [existingList, isEditing]
  );

  const contains = useCallback((member: AccountData, query: string) => {
    return member.fullName.toLowerCase().includes(query);
  }, []);

  const handleSearch = (text: string) => {
    setTimeout(() => {
      const lowercaseText = text.toLowerCase();
      setSearchTerm(lowercaseText);
      if (makingChanges) {
        loadSearchResultsThrottled(lowercaseText);
      } else if (dropdownValue === 0) {
        loadSubscribedSearchResultsThrottled(lowercaseText);
      } else if (dropdownValue === 1) {
        loadUnsubscribedSearchResultsThrottled(lowercaseText);
      } else {
        loadRemovedSearchResultsThrottled(lowercaseText);
      }
    }, 100);
  };

  const listMembersToShow = useMemo(() => {
    if (existingListLoading || (isEditing && loadingMembers)) {
      return [];
    }
    let listMembers: AccountData[] = makingChanges
      ? fetchedUserData
      : dropdownValue === 0
      ? fetchedSubscribedUserData
      : dropdownValue === 1
      ? fetchedUnsubscribedUserData
      : fetchedRemovedUserData;
    if (searchTerm !== "") {
      listMembers = filter(listMembers, (member: AccountData) => {
        return contains(member, searchTerm);
      });
    }
    return listMembers;
  }, [
    contains,
    dropdownValue,
    existingListLoading,
    fetchedRemovedUserData,
    fetchedSubscribedUserData,
    fetchedUnsubscribedUserData,
    fetchedUserData,
    isEditing,
    loadingMembers,
    makingChanges,
    searchTerm,
  ]);

  const updateAudienceListSettings = useCallback(
    (newListSettings: Partial<AudienceList>) => {
      setAudienceList((prevListSettings) => {
        return { ...prevListSettings, ...newListSettings };
      });
    },
    []
  );

  const backOnPress = useCallback(() => {
    setIsVisible(false);
  }, [setIsVisible]);

  const initialBackOnPress = useCallback(() => {
    const originalList: AudienceList = {
      ...(existingList ?? makeEmptyAudienceList()),
      id: audienceList.id,
    };
    if (
      (!isEqual(originalList, audienceList) ||
        !isEqual(initialListMembers, netListMembers)) &&
      (!existingList || isEditing)
    ) {
      setShowDeleteModal(true);
    } else if (isEditing) {
      setIsEditing(false);
    } else {
      backOnPress();
    }
  }, [
    audienceList,
    backOnPress,
    existingList,
    initialListMembers,
    isEditing,
    netListMembers,
  ]);

  const deleteOnPress = useCallback(() => {
    if (setConfirmAction) setConfirmAction(BinaryConfirmActions.NEGATIVE);
    initialBackOnPress();
  }, [initialBackOnPress, setConfirmAction]);

  const confirmListOnPress = useCallback(async () => {
    if (existingListNotEditing) {
      setIsEditing(true);
      setLoadingMembers(true);
      return;
    }

    if (audienceList.name === "") {
      setListNameError({
        isError: true,
        message: "You must enter a list name.",
      });
      return;
    } else if (
      audienceList.name === FAVORITES_LIST_NAME.toLowerCase() &&
      !existingList
    ) {
      setListNameError({
        isError: true,
        message: 'Can not make list called "Favorites"',
      });
      return;
    } else if (
      audienceLists.find((list) => list.name === audienceList.name) &&
      !existingList
    ) {
      setListNameError({
        isError: true,
        message: "Can not name a list the same as another list",
      });
      return;
    }

    setSavingList(true);
    if (existingList && isEditing) {
      dispatch(
        updateAudienceList(accountData.uid, audienceList, netListMembers)
      );
    } else {
      dispatch(
        createAudienceList(accountData.uid, audienceList, netListMembers)
      );
    }
    // TODO (jonathan): Weird to close out of side popup on save
    // Was struggling to find better solution, but we'll eventually move lists out of side panel, so going to change in future anyways
    if (setConfirmAction) setConfirmAction(BinaryConfirmActions.AFFIRMATIVE);
    backOnPress();
    setSavingList(false);
  }, [
    existingListNotEditing,
    audienceList,
    existingList,
    audienceLists,
    isEditing,
    setConfirmAction,
    backOnPress,
    dispatch,
    accountData.uid,
    netListMembers,
  ]);

  const addPeopleOnPress = useCallback(() => {
    setIsEditing(true);
    setSelectRecipientsVisible(true);
  }, []);

  const renderEmptyView = useMemo(() => {
    return (
      <EmptyStateFlatlist
        searchTerm={searchTerm}
        isLoading={existingListLoading || loadingMembers}
        containerStyles={{ paddingTop: 120 }}
        nonSearchEmptyView={
          <EmptyStateButton
            title={
              isEditing || dropdownValue === 0 ? "Add People" : "No People"
            }
            description={
              isEditing || dropdownValue === 0
                ? "People that join or are added to your list will show up here."
                : ""
            }
            icon={<Icon icon="ion:people" height={64} color={Colors.GRAY1} />}
            iconBox={84}
            btnText={isEditing || dropdownValue === 0 ? "Add People" : ""}
            onPress={addPeopleOnPress}
            containerStyles={{ paddingTop: 100 }}
          />
        }
      />
    );
  }, [
    addPeopleOnPress,
    dropdownValue,
    existingListLoading,
    isEditing,
    loadingMembers,
    searchTerm,
  ]);

  return (
    <>
      <LargePopupModalContainer
        showModal={isVisible}
        containerStyles={{
          paddingInline: 0,
          height: "97%",
          overflow: "hidden",
        }}
        valueComp={
          !selectRecipientsVisible ? (
            <>
              <div className="AlignedRowSpaced" style={{ padding: 14 }}>
                <BackButton
                  iconName={isEditing ? undefined : "mdi:close"}
                  onPress={initialBackOnPress}
                />
                <div className="AlignedRow" style={{ gap: 14 }}>
                  {existingList && !isEditing ? (
                    <AudienceListDropdownMenu
                      list={existingList}
                      deleteOnPress={deleteOnPress}
                    />
                  ) : null}
                  <RectangleButton
                    buttonLabel={existingListNotEditing ? "Edit" : "Save"}
                    onPress={confirmListOnPress}
                    loading={savingList}
                    disabled={savingList}
                    altColor={Colors.BLACK}
                    altPaddingHorz={14}
                    altPaddingVert={10}
                    altBorderRadius={100}
                    iconRight={
                      existingListNotEditing ? (
                        <Icon
                          icon="feather:edit"
                          height={14}
                          color={Colors.WHITE}
                          style={{ marginTop: -1 }}
                        />
                      ) : undefined
                    }
                  />
                </div>
              </div>
              <HorizontalDivider />
              <div
                className="ColumnNormal"
                style={{ gap: 24, padding: 14, height: "100%" }}
              >
                <div className="ColumnNormal" style={{ gap: 14 }}>
                  <div className="ColumnNormal" style={{ gap: 7 }}>
                    <span style={styles.headerText}>
                      {existingList
                        ? !isEditing
                          ? existingList.name
                          : "Edit List"
                        : "New List"}
                    </span>
                    {existingList && !isEditing && existingList.description ? (
                      <span style={{ ...styles.bodySmall, fontSize: 14 }}>
                        {existingList.description}
                      </span>
                    ) : null}
                  </div>
                  {makingChanges ? (
                    <div className="ColumnNormal" style={{ gap: 10 }}>
                      <div className="ColumnNormal" style={{ gap: 7 }}>
                        <CustomTextField
                          value={audienceList.name}
                          placeholder="List Name..."
                          inputMode="text"
                          borderRadius={12}
                          backgroundColor={Colors.WHITE1}
                          onChange={(change: any) => {
                            setListNameError({ isError: false, message: "" });
                            updateAudienceListSettings({
                              name: change.target.value,
                            });
                          }}
                          disabled={disableEditingInfo}
                          error={listNameError.isError}
                          altMarginBottom={0}
                        />
                        {listNameError.isError ? (
                          <span style={styles.errorText}>
                            {listNameError.message}
                          </span>
                        ) : null}
                      </div>
                      <CustomTextField
                        value={audienceList.description}
                        placeholder="List Description..."
                        inputMode="text"
                        borderRadius={12}
                        backgroundColor={Colors.WHITE1}
                        onChange={(change: any) => {
                          updateAudienceListSettings({
                            description: change.target.value,
                          });
                        }}
                        disabled={disableEditingInfo}
                        altMarginBottom={0}
                      />
                    </div>
                  ) : null}
                </div>
                <StandardBorderedContainer
                  containerStyles={{
                    backgroundColor: Colors.GRAY6,
                    padding: 14,
                  }}
                >
                  <SwitchToggleItem
                    title={
                      (audienceList.visibility ===
                        AudienceListVisibility.PRIVATE && existingListNotEditing
                        ? "Private"
                        : "Public") + " List"
                    }
                    description={
                      existingListNotEditing
                        ? audienceList.visibility ===
                          AudienceListVisibility.PRIVATE
                          ? "This list is private."
                          : "This list is public and joinable when people follow your profile."
                        : "Turn on to make your list public. This means anyone can add themselves to your list when following your profile."
                    }
                    toggleValue={
                      audienceList.visibility === AudienceListVisibility.PUBLIC
                    }
                    disabled={disableEditingInfo}
                    onChange={() => {
                      updateAudienceListSettings({
                        visibility:
                          audienceList.visibility ===
                          AudienceListVisibility.PRIVATE
                            ? AudienceListVisibility.PUBLIC
                            : AudienceListVisibility.PRIVATE,
                      });
                    }}
                    hideToggle={existingListNotEditing}
                  />
                </StandardBorderedContainer>
                <div
                  className="ColumnNormal"
                  style={{ gap: 10, height: "100%" }}
                >
                  <span style={styles.sectionTitle}>
                    Contacts{" "}
                    {makingChanges
                      ? `(${netListMembers.length})`
                      : existingList && loaded
                      ? `(${liveTotalListContacts})`
                      : ""}
                  </span>
                  <SearchBoxContainer
                    placeholder="Search People..."
                    onChange={(e) => handleSearch(e.target.value)}
                    containerStyles={{ marginTop: 0 }}
                  />
                  {!makingChanges ? (
                    <div className="RowNormal">
                      <ListDropdown
                        dropdownLabels={[
                          "Subscribed",
                          "Unsubscribed",
                          "Removed",
                          //   "Invalid Number",
                          //   "Blocked",
                        ]}
                        dropdownNumbers={[
                          existingListLoading ? -1 : subscribedMembers.length,
                          existingListLoading ? -1 : unsubscribedMembers.length,
                          existingListLoading ? -1 : removedMembers.length,
                        ]}
                        selectedValue={dropdownValue}
                        onChange={setDropdownValue}
                        containerStyles={styles.dropdownComponent}
                      />
                    </div>
                  ) : null}
                  <div className="HideScrollbar" style={{ overflow: "auto" }}>
                    {makingChanges && currentMembers.length > 0 ? (
                      <>
                        <div
                          onClick={addPeopleOnPress}
                          className="AlignedRowSpacedSelect"
                          style={{ paddingBlock: 7, paddingLeft: 7 }}
                        >
                          <span
                            style={{
                              ...styles.bodyMedium,
                              color: Colors.BLUE5,
                            }}
                          >
                            Add People...
                          </span>
                          <Icon
                            icon={"ion:add-circle"}
                            height={24}
                            style={{ color: Colors.BLUE5 }}
                          />
                        </div>
                        <HorizontalDivider altMargin={10} />
                      </>
                    ) : null}
                    <FlatList
                      list={listMembersToShow}
                      renderItem={(item) => {
                        return (
                          <MassTextsPersonItem
                            user={item}
                            unsavedPeople={netListMembers}
                            setUnsavedPeople={setCurrentMembers}
                            setProfileSelected={setProfileSelected}
                            disableCheck={!isEditing}
                          />
                        );
                      }}
                      renderWhenEmpty={() => renderEmptyView}
                      hasMoreItems={
                        makingChanges
                          ? !isFinished
                          : dropdownValue === 0
                          ? !isSubscribedFinished
                          : dropdownValue === 1
                          ? !isUnsubscribedFinished
                          : !isRemovedFinished
                      }
                      loadMoreItems={() => {
                        setTimeout(() => {
                          if (searchTerm.trim() === "") {
                            if (makingChanges && !isFinished && !isLoading) {
                              loadMoreUsers();
                            } else if (
                              dropdownValue === 0 &&
                              !isSubscribedFinished &&
                              !isSubscribedLoading
                            ) {
                              loadMoreSubscribedUsers();
                            } else if (
                              dropdownValue === 1 &&
                              !isUnsubscribedFinished &&
                              !isUnsubscribedLoading
                            ) {
                              loadMoreUnsubscribedUsers();
                            } else if (
                              dropdownValue === 2 &&
                              !isRemovedFinished &&
                              !isRemovedLoading
                            ) {
                              loadMoreRemovedUsers();
                            }
                          }
                        }, 50); // The set index has a tiny delay
                      }}
                      paginationLoadingIndicator={() =>
                        (
                          makingChanges
                            ? isLoading && !isFinished
                            : dropdownValue === 0
                            ? isSubscribedLoading && !isSubscribedFinished
                            : dropdownValue === 1
                            ? isUnsubscribedLoading && !isUnsubscribedFinished
                            : isRemovedLoading && !isRemovedFinished
                        ) ? (
                          <CircularProgress
                            style={{
                              color: Colors.GRAY1,
                              alignSelf: "center",
                              marginTop: "24px",
                              marginLeft: "14px",
                            }}
                            size={30}
                          />
                        ) : null
                      }
                    />
                    <div style={{ height: 400 }} />
                  </div>
                </div>
              </div>
            </>
          ) : (
            <SimpleSelectRecipientsPanel
              allSelectedPeople={currentMembers}
              setAllSelectedPeople={setCurrentMembers}
              allExcludedPeople={excludedMembers}
              setAllExcludedPeople={setExcludedMembers}
              setIsVisible={(isVisible: boolean) => {
                // need to reset the state for edits
                reloadMoreUsers();
                setLoadingMembers(true);
                setSelectRecipientsVisible(isVisible);
              }}
            />
          )
        }
      />
      {markitPlusModalVisible ? (
        <MarkitPlusModal
          closeModal={() => {
            setMarkitPlusModalVisible(false);
            setIsVisible(false);
          }}
        />
      ) : null}
      {profileSelected ? (
        <FullProfilePreviewModal
          profileSelected={profileSelected}
          setProfileSelected={setProfileSelected}
        />
      ) : null}
      <ConfirmDeleteModal
        heading="If you go back, your changes will be discarded."
        subtext="Continue?"
        deleteButtonText="Discard Changes"
        hideModal={!showDeleteModal}
        setIsVisible={setShowDeleteModal}
        deleteOnPress={backOnPress}
      />
    </>
  );
};

export default AudienceListPopupPanel;
