import { Campaign } from "@markit/common.types";
import { Icon } from "@iconify/react";
import { useCallback, useState } from "react";
import ConfirmDeleteModal from "../../../Containers/ConfirmPopups/ConfirmDeleteModal";
import ConfirmActionPopup from "../../../Containers/ConfirmPopups/ConfirmActionPopup";
import { API } from "../../../../API";
import { Colors } from "../../../../utils/colors";
import { NavigationId } from "../../../../navigation/AppParamList";
import { useLocation, useNavigate } from "react-router-dom";
import AlertContainer from "../../../Containers/AlertContainer";
import { useDispatch } from "react-redux";
import { campaignActions } from "../../../../redux/slices/campaignSlice";

type CampaignConfirmDeleteModalProps = {
  campaign: Campaign | undefined;
  showDeleteModal: boolean;
  setShowDeleteModal: (showDeleteModal: boolean) => void;
  preventDelete: boolean;
};

export const CampaignConfirmDeleteModal = (
  props: CampaignConfirmDeleteModalProps
) => {
  const { campaign, showDeleteModal, setShowDeleteModal, preventDelete } =
    props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const pathname = useLocation().pathname;
  const [confirmAction, setConfirmAction] = useState(false);

  const deleteCampaignOnPress = useCallback(async () => {
    if (campaign) {
      const { success } = await API.text.deleteTextCampaign({
        userId: campaign.createdBy,
        campaign: campaign,
      });
      if (success) {
        dispatch(campaignActions.removeCampaign(campaign.id));
        setConfirmAction(true);
        if (pathname !== NavigationId.HOME_CAMPAIGNS) {
          navigate(NavigationId.HOME_CAMPAIGNS);
        }
      } else {
        alert("An error occurred when deleting the campaign.");
      }
    }
  }, [campaign, dispatch, navigate, pathname]);

  return (
    <>
      {preventDelete ? (
        <AlertContainer
          headerComp={
            <div className="ColumnNormal" style={{ gap: 4 }}>
              <Icon
                icon={"ion:trash"}
                height={35}
                style={{ color: Colors.RED3 }}
              />
              <span style={{ fontSize: 16, fontWeight: "500" }}>
                Cannot Delete
              </span>
            </div>
          }
          subHeaderComp={
            "You cannot delete this campaign because a text has been sent."
          }
          closeModal={() => setShowDeleteModal(false)}
          hideModal={!showDeleteModal}
        />
      ) : (
        <ConfirmDeleteModal
          heading="Delete Campaign"
          subtext="Are you sure you want to delete this campaign? This cannot be undone."
          deleteButtonText="Delete Campaign"
          icon={
            <Icon
              icon={"ion:trash"}
              height={35}
              style={{ color: Colors.RED3 }}
            />
          }
          hideModal={!showDeleteModal}
          setIsVisible={setShowDeleteModal}
          deleteOnPress={deleteCampaignOnPress}
        />
      )}
      {confirmAction ? (
        <ConfirmActionPopup
          title={"Deleted!"}
          negativeTitle="Deleted!"
          isNegative={true}
          altIcon={"ion:trash-outline"}
          onDisappear={() => setConfirmAction(false)}
        />
      ) : null}
    </>
  );
};
