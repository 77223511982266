import "../../css/Subscription.css";
import "../../css/GlobalStyles.css";
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import {
  stripePromise,
  testStripe,
  themeMui,
} from "../../components/FullEvent/FullEventInputForm";
import { API } from "../../API";
import { Stripe, StripeElements } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import mixpanel from "mixpanel-browser";
import SubscriptionCheckoutForm from "../../components/Subscription/SubscriptionCheckoutForm";
import AgreeToTerms from "../../components/AgreeToTerms";
import { useSelector } from "react-redux";
import { getAccountState } from "../../redux/slices/accountSlice";
import { Colors } from "../../utils/colors";
import { createSubscriptionReferral } from "../../utils/subscriptionUtils";
import { getUrlParamState } from "../../redux/slices/urlParamSlice";
import {
  PlanTypeV4,
  SubscriptionPlanType,
  subscriptionPricesV4,
  testSubscriptionPricesV4,
} from "@markit/common.types";
import RectangleButton from "../../components/Buttons/RectangleButton";
import AlertContainer from "../../components/Containers/AlertContainer";
import { SubscriptionStage } from "../../screens/Subscription/SubscriptionPlan";
import StandardBorderedContainer from "../Containers/StandardBorderedContainer";
import { Icon } from "@iconify/react";
import CustomTextField from "../CustomTextField";
import { CircularProgress } from "@mui/material";
import { isDesktop } from "react-device-detect";

type SubscriptionCheckoutProps = {
  subscriptionPlan: SubscriptionPlanType;
  setSubscriptionStage: (subscriptionStage: SubscriptionStage) => void;
  email: string;
  setEmail: (email: string) => void;
};

const SubscriptionCheckout = memo(function SubscriptionCheckoutFn(
  props: SubscriptionCheckoutProps
) {
  const { subscriptionPlan, setSubscriptionStage, email, setEmail } = props;
  const { account } = useSelector(getAccountState);
  const { accountData } = account;
  const { referral } = useSelector(getUrlParamState);
  const [showCodeInput, setShowCodeInput] = useState(false);
  const [codeInput, setCodeInput] = useState<string>("");
  const [applyCodeError, setApplyCodeError] = useState(false);
  const [trialSubscription, setTrialSubscription] = useState(false);
  const [showingPaymentSheet, setShowingPaymentSheet] =
    useState<boolean>(false);
  const [options, setOptions] = useState<{
    clientSecret: string;
    appearance: {};
  }>();
  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const [agreeToTermsError, setAgreeToTermsError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [alertText, setAlertText] = useState({ heading: "", subHeading: "" });

  const styles = {
    headerText: { color: Colors.BLACK, fontSize: 14, fontWeight: "500" },
    buttonText: { fontWeight: "500", fontSize: 14 },
    errorText: { fontSize: 12, color: Colors.RED3 },
  };

  const CODEWORDS = useMemo(() => ["FREETRIAL30", "TECHSTARS"], []); // Trial Codes

  const subscriptionOptionIndex = useMemo(
    () =>
      subscriptionPlan === PlanTypeV4.ESSENTIALS
        ? 0
        : subscriptionPlan === PlanTypeV4.PROFESSIONAL
        ? 1
        : 2,
    [subscriptionPlan]
  );

  const [purchasing, setPurchasing] = useState(false);

  useEffect(() => {
    if (agreedToTerms) {
      mixpanel.track("Webapp: Agreed to Terms", {
        subsription_plan: subscriptionPlan.name,
        is_trial: trialSubscription,
      });
    }
  }, [agreedToTerms, subscriptionPlan.name, trialSubscription]);

  const fetchPaymentSheetParams = useCallback(
    async (isTrial: boolean) => {
      setOptions(undefined);

      (testStripe
        ? API.user.initializeTestSubscription
        : API.user.initializeSubscription)({
        uid: accountData.uid,
        email: email,
        prices: testStripe
          ? [
              testSubscriptionPricesV4[subscriptionOptionIndex].id,
              testSubscriptionPricesV4[subscriptionOptionIndex].overflow,
            ]
          : [
              subscriptionPricesV4[subscriptionOptionIndex].id,
              subscriptionPricesV4[subscriptionOptionIndex].overflow,
            ],
        isTrial: isTrial,
      })
        .then(async (res: any) => {
          const { clientSecret } = res;
          // get stripe info
          const stripeOptions = {
            // passing the client secret obtained in step 2
            clientSecret: clientSecret,
            // Fully customizable with appearance API.
            appearance: {
              theme: "stripe",
              variables: {
                colorBackground: Colors.WHITE,
              },
            },
          };
          setOptions(stripeOptions);
        })
        .catch((err: any) => {
          console.error("Error on initialize: " + err.message);
        });
    },
    [accountData.uid, email, subscriptionOptionIndex]
  );

  const verifyCodeOnPress = useCallback(() => {
    if (codeInput.length === 0) {
      setAlertText({
        heading: "You must enter a code to apply.",
        subHeading: "",
      });
      return;
    }

    mixpanel.track("Webapp: Entered Code For Trial Subscription", {
      subscription_plan: subscriptionPlan.name,
      subscription_plan_amount: subscriptionPlan.price,
      code_input: codeInput,
      successful_code: CODEWORDS.includes(codeInput),
    });
    if (CODEWORDS.includes(codeInput)) {
      setTrialSubscription(true);
      setApplyCodeError(false);
      // Re-fetch payment sheet if it's currently visible
      if (showingPaymentSheet) {
        fetchPaymentSheetParams(true);
      }
    } else {
      setApplyCodeError(true);
    }
  }, [
    CODEWORDS,
    codeInput,
    fetchPaymentSheetParams,
    showingPaymentSheet,
    subscriptionPlan.name,
    subscriptionPlan.price,
  ]);

  const subscribe = useCallback(async () => {
    if (purchasing) {
      return;
    }
    let showError = false;
    if (!agreedToTerms) {
      setAgreeToTermsError(true);
      showError = true;
    }
    if (email === "") {
      setEmailError(true);
      showError = true;
    }
    if (showError) {
      return;
    }
    setShowingPaymentSheet(true);
    fetchPaymentSheetParams(trialSubscription);
  }, [
    agreedToTerms,
    email,
    fetchPaymentSheetParams,
    purchasing,
    trialSubscription,
  ]);

  const paymentOrRsvpElement = useMemo(() => {
    if (options !== undefined) {
      return (
        <div className="SubscriptionCheckoutPaymentSheet">
          <Elements
            key={options.clientSecret}
            stripe={stripePromise}
            options={options}
          >
            <SubscriptionCheckoutForm
              theme={themeMui}
              userId={accountData.uid}
              subscriptionOption={subscriptionPlan}
              trialSubscription={trialSubscription}
              processing={purchasing}
              setProcessing={setPurchasing}
              isReadyForPayment={true}
              clientSecret={options.clientSecret}
              setSubscriptionStage={setSubscriptionStage}
              onPress={async (stripe: Stripe, elements: StripeElements) => {
                if (mixpanel) {
                  mixpanel.track("Webapp: Attempting Getting Subscription", {
                    subscription_plan: subscriptionPlan.name,
                    is_trial: trialSubscription,
                    is_referral: referral !== "",
                  });
                }
                setPurchasing(true);
                const { error } = trialSubscription
                  ? await stripe.confirmSetup({
                      // For TRIAL Subscriptions
                      // `Elements` instance that was used to create the Payment Element
                      elements,
                      confirmParams: {
                        return_url:
                          "https://markitai.com/subscription/onboarding",
                      },
                      redirect: "if_required",
                    })
                  : await stripe.confirmPayment({
                      // For PURCHASING Subscriptions
                      // `Elements` instance that was used to create the Payment Element
                      elements,
                      confirmParams: {
                        return_url:
                          "https://markitai.com/subscription/onboarding",
                      },
                      redirect: "if_required",
                    });

                if (error) {
                  // This point will only be reached if there is an immediate error when
                  // confirming the payment. Show error to your customer (for example, payment
                  // details incomplete)
                  console.error(error.message);
                  setAlertText({
                    heading:
                      error.message ??
                      "An error occurred when entering your info.",
                    subHeading: "Please try again.",
                  });
                  mixpanel.track("Webapp: Error Getting Subscription", {
                    subscription_plan: subscriptionPlan.name,
                    is_trial: trialSubscription,
                    is_referral: referral !== "",
                    error_message: error.message,
                  });
                  setPurchasing(false);
                } else {
                  if (mixpanel) {
                    mixpanel.track("Webapp: Getting Subscription", {
                      distinct_id: accountData.uid,
                      subscription_plan: subscriptionPlan.name,
                      is_trial: trialSubscription,
                      is_referral: referral !== "",
                    });
                  }
                  // create referral document in Firebase
                  if (referral) {
                    await createSubscriptionReferral(referral, accountData.uid);
                  }
                  // dispatch(accountActions.updateSubscriptionPhone())
                  setSubscriptionStage(SubscriptionStage.RECEIPT);
                }
              }}
            />
          </Elements>
        </div>
      );
    } else if (showingPaymentSheet) {
      return (
        <div className="ColumnCentering">
          <CircularProgress style={{ color: Colors.GRAY1 }} size={20} />
        </div>
      );
    } else {
      return undefined;
    }
  }, [
    accountData.uid,
    options,
    purchasing,
    referral,
    setSubscriptionStage,
    showingPaymentSheet,
    subscriptionPlan,
    trialSubscription,
  ]);

  return (
    <>
      <div className="ColumnNormal">
        <div className="ColumnCentering" style={{ gap: 24, paddingBottom: 28 }}>
          <StandardBorderedContainer
            containerStyles={{
              padding: 14,
              display: "flex",
              flexDirection: "row",
              gap: 7,
              cursor: "pointer",
            }}
            onPress={() => setSubscriptionStage(SubscriptionStage.OVERVIEW)}
          >
            <Icon
              icon="mdi:chevron-left"
              height={20}
              color={Colors.BLACK}
              style={{ marginTop: -1 }}
            />
            <span style={styles.headerText}>
              Continuing with {subscriptionPlan.name}
            </span>
          </StandardBorderedContainer>
          <span style={{ fontSize: 32, fontWeight: 500 }}>Payment</span>
        </div>
        <div
          className="ColumnNormal HideScrollbar"
          style={{
            alignItems: "center",
            backgroundColor: Colors.WHITE1,
            padding: "30px 80px 24px 80px",
            minHeight: isDesktop ? "70vh" : undefined,
          }}
        >
          <StandardBorderedContainer
            containerStyles={{
              backgroundColor: Colors.WHITE,
              padding: 14,
              borderRadius: 20,
              width: 362,
              display: "flex",
              flexDirection: "column",
              gap: 24,
            }}
          >
            <div className="ColumnNormal" style={{ gap: 14 }}>
              <div className="ColumnNormal" style={{ gap: 7 }}>
                <span style={styles.headerText}>
                  Enter email{" "}
                  <span style={{ fontSize: 12, color: Colors.GRAY2 }}>
                    (required to receive receipt)
                  </span>
                </span>
                <div className="ColumnNormal" style={{ gap: 4 }}>
                  <CustomTextField
                    value={email}
                    inputMode="email"
                    placeholder="Enter Email"
                    borderRadius={12}
                    backgroundColor={Colors.WHITE}
                    onChange={(change: any) => {
                      setEmailError(false);
                      setEmail(change.target.value);
                    }}
                    error={emailError}
                  />
                  {emailError ? (
                    <span style={styles.errorText}>Email required</span>
                  ) : null}
                </div>
              </div>
              <div>
                {!showCodeInput ? (
                  <RectangleButton
                    buttonLabel="Redeem a Promo Code"
                    onPress={() => setShowCodeInput(!showCodeInput)}
                    altColor={Colors.WHITE1}
                    altTextColor={Colors.BLACK}
                    containerStyles={{ border: `1.5px solid ${Colors.GRAY11}` }}
                  />
                ) : (
                  <div className="PromoCodeInputView" style={{ marginTop: 0 }}>
                    <div className="PromoCodeInput">
                      <CustomTextField
                        value={codeInput}
                        disabled={trialSubscription}
                        inputMode="text"
                        placeholder="Enter Code"
                        borderRadius={12}
                        onChange={(name: any) => {
                          setApplyCodeError(false);
                          setCodeInput(name.target.value);
                        }}
                        error={applyCodeError}
                        endAdornment={
                          trialSubscription || applyCodeError ? (
                            <Icon
                              icon={
                                trialSubscription
                                  ? "ion:checkmark-circle"
                                  : "ion:close-circle"
                              }
                              height={20}
                              style={{
                                marginRight: 10,
                                color: trialSubscription
                                  ? Colors.GREEN2
                                  : Colors.RED3,
                              }}
                            />
                          ) : undefined
                        }
                        width="50%"
                      />
                      <span onClick={verifyCodeOnPress}>
                        <div className="PromoCodeApplyView">
                          {trialSubscription ? (
                            <span style={styles.buttonText}>
                              -${subscriptionPlan.price / 100}
                            </span>
                          ) : (
                            <span className="PromoCodeApplyText">
                              Apply Promo
                            </span>
                          )}
                        </div>
                      </span>
                    </div>
                    {applyCodeError ? (
                      <span style={{ ...styles.errorText, marginLeft: 14 }}>
                        Invalid code
                      </span>
                    ) : null}
                  </div>
                )}
              </div>
            </div>
            <div className="ColumnNormal" style={{ gap: 14 }}>
              <div className="AlignedRowSpaced">
                <div className="AlignedRow" style={{ gap: 2 }}>
                  <span style={{ fontWeight: 500 }}>Total</span>
                  {/* <Icon
                    icon={"mdi:chevron-down"}
                    height={14}
                    color={Colors.GRAY1}
                  /> */}
                </div>
                <span style={{ fontSize: 24, fontWeight: 500 }}>
                  ${trialSubscription ? "0.00" : subscriptionPlan.price / 100}
                </span>
              </div>
              <AgreeToTerms
                agreed={agreedToTerms}
                setAgreed={setAgreedToTerms}
                error={agreeToTermsError}
                setError={setAgreeToTermsError}
                disabled={showingPaymentSheet}
                nonRefundable
              />
            </div>
            <div>
              {paymentOrRsvpElement}
              {!showingPaymentSheet ? (
                <RectangleButton
                  buttonLabel="Continue to Payment"
                  onPress={subscribe}
                  altPaddingVert={14}
                  altPaddingHorz={14}
                  altColor={Colors.BLACK}
                  altTextColor={Colors.WHITE}
                />
              ) : null}
            </div>
          </StandardBorderedContainer>
        </div>
      </div>
      <AlertContainer
        headerComp={alertText.heading}
        subHeaderComp={
          alertText.subHeading !== "" ? alertText.subHeading : undefined
        }
        closeModal={() => {
          // window.location.reload();
          setAlertText({ heading: "", subHeading: "" });
        }}
        hideModal={alertText.heading === "" && alertText.subHeading === ""}
      />
    </>
  );
});

export default SubscriptionCheckout;
