import { useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { firebaseAuth, onAuthStateChanged, onSnapshot } from "../firebase";
import { getUserRef } from "../utils/FirebaseUtils";
import {
  accountActions,
  fetchSavedFormQuestions,
  getAccountState,
  fetchAudienceLists,
  fetchReadyForPayment,
  createAudienceList,
  fetchAllUserFollowersData,
} from "../redux/slices/accountSlice";
import { fetchCreatorEvents } from "../redux/slices/eventSlice";
import { fetchCurrUserWishList } from "../redux/slices/wishMarkSlice";
import { checkUserFavoritesListExists } from "../utils/userUtils";
import { AudienceList } from "@markit/common.types";
import { makeEmptyAudienceList } from "../utils/makeEmptyData";
import { FAVORITES_LIST_NAME } from "@markit/common.utils";
import useAsyncOnMount from "./useAsyncEffectOnMount";

export const useInitialAppLoad = () => {
  const { appInitialized } = useSelector(getAccountState).account;
  const dispatch = useDispatch();
  const unsubscribeRef = useRef<(() => void) | void>();

  // Create Favorites list if not created yet
  const createInitialFavoritesList = useCallback(
    async (userId: string) => {
      const favoritesListExists = await checkUserFavoritesListExists(userId);
      if (!favoritesListExists) {
        const favoritesList: AudienceList =
          makeEmptyAudienceList(FAVORITES_LIST_NAME);
        dispatch(createAudienceList(userId, favoritesList));
      }
    },
    [dispatch]
  );

  // handle all initial app loading
  useAsyncOnMount(async () => {
    const unsubscribeAuth = onAuthStateChanged(firebaseAuth, async (user) => {
      if (unsubscribeRef.current) {
        unsubscribeRef.current();
        unsubscribeRef.current = undefined;
      }
      if (user) {
        const unsubscribe = onSnapshot(
          getUserRef(user.uid),
          async (doc) => {
            const userData = doc.data();
            if (userData) {
              const start = new Date().getTime();
              dispatch(accountActions.login(userData));
              if (!appInitialized) {
                await Promise.all([
                  dispatch(fetchAllUserFollowersData(userData.uid)),
                  dispatch(fetchCreatorEvents(userData.uid)),
                  dispatch(fetchCurrUserWishList(userData.uid)),
                  dispatch(fetchReadyForPayment(userData.uid)),
                  dispatch(fetchSavedFormQuestions(userData.uid)),
                  dispatch(fetchAudienceLists(userData.uid)),
                  createInitialFavoritesList(userData.uid),
                ]);
                dispatch(accountActions.setAppInitialized(true));
              }
              const end = new Date().getTime();
              console.log(
                "Total fetch time (app initialization): " + (end - start) / 1000
              );
            }
          },
          (error: any) => {
            console.error(error);
          }
        );
        unsubscribeRef.current = unsubscribe;
      }
    });

    return () => {
      unsubscribeAuth();
      if (unsubscribeRef.current) {
        unsubscribeRef.current();
        unsubscribeRef.current = undefined;
      }
    };
  });

  return undefined;
};
