import "../../css/Buttons/RectangleButtonStyles.css";
import React from "react";
import { Colors } from "../../utils/colors";

type ShowMoreButtonProps = {
  onPress: () => void;
  containerStyles?: React.CSSProperties;
};

const ShowMoreButton = (props: ShowMoreButtonProps) => {
  const {
    onPress,

    containerStyles,
  } = props;

  return (
    <div onClick={onPress} style={{ ...containerStyles, cursor: "pointer" }}>
      <span
        style={{
          color: Colors.BLUE5,
          fontSize: 16,
          fontWeight: "500",
        }}
      >
        Show More...
      </span>
    </div>
  );
};

export default ShowMoreButton;
