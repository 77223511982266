import "../../css/App.css";
import "../../css/FullEvent/FullEvent.css";
import "../../css/FullEvent/ShareTickets.css";
import "../../css/GlobalStyles.css";
import React, { memo, useCallback, useMemo, useState } from "react";
import { AccountData, TicketReceipt, TicketV2 } from "@markit/common.types";
import {
  GetDateWithTime,
  MARKIT_ATTENDEE_FAQ,
  MARKIT_HOTLINE_NUMBER,
  getTimezone,
  isEventTicketsPaid,
} from "@markit/common.utils";
import { formatPhoneNumber } from "../../utils/FormatPhoneNumber";
import {
  getUserTicket,
  userCurrentNumberOfTickets,
  userCurrentNumberOfPendingTickets,
  getUserEventTicketsMapped,
} from "../../utils/eventUtils/userTicketUtils";
import Calendar from "../../components/Calendar";
import CreatorTextManual from "../../components/CreatorTextManual";
import {
  DataLoaders,
  getLoadedEvent,
  getLoadedUser,
  getLoadedUsers,
} from "../../redux/slices/dataSlice";
import {
  useAccountSelector,
  useEventSelector,
  useParametricSelector,
} from "../../hooks/useParametricSelector";
import LoadingScreen from "../LoadingScreen";
import { useQuery } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  LoginState,
  accountActions,
  getAccountState,
} from "../../redux/slices/accountSlice";
import { isRCA } from "../../utils/globalUtils";
import { getUrlParamState } from "../../redux/slices/urlParamSlice";
import { Colors } from "../../utils/colors";
import { Icon } from "@iconify/react";
import Footer from "../../components/Footer";
import { LightTheme, useTheme } from "../../hooks/useTheme";
import Banner, { BannerType } from "../../components/Banner";
import RectangleButton from "../../components/Buttons/RectangleButton";
import {
  getTicketData,
  getTicketsRef,
  getUserData,
} from "../../utils/FirebaseUtils";
import { API } from "../../API";
import TopHeader from "../../components/TopHeader";
import { isDesktop } from "react-device-detect";
import { FullEventManageTickets } from "../../components/FullEvent/FullEventManageTickets";
import { getDocs, limit, query, where } from "../../firebase";
import { testStripe } from "../../components/FullEvent/FullEventInputForm";
import FullEventHosts from "../../components/FullEvent/FullEventHosts";
import FullEventTimeLocation from "../../components/FullEvent/FullEventTimeLocation";
import StandardBorderedContainer from "../../components/Containers/StandardBorderedContainer";
import FullEventOrganizerConfirmation from "../../components/FullEvent/FullEventOrganizerConfirmation";
import Skeleton from "react-loading-skeleton";
import { useOnMount } from "../../hooks/useOnMount";
import ConfirmActionPopup, {
  BinaryConfirmActions,
} from "../../components/Containers/ConfirmPopups/ConfirmActionPopup";
import { GuestListSummary } from "../../components/FullEventSubComponents/GuestListSummary";
import {
  checkIfUserIsUnsubscribed,
  checkIfUserIsUnsubscribedMarkit,
} from "../../utils/userUtils";
import OptedOutBanner from "../../components/Verification/OptedOutBanner";
import useAsyncEffect from "../../hooks/useAsyncEffect";

const FullEventFinal = memo(function FullEventFinalFn() {
  const dispatch = useDispatch();
  const location = useLocation() || {};
  const { pathname, state } = location;
  const { ticketId } = useSelector(getUrlParamState);
  const navigate = useNavigate();
  const showTwilioIssuesMessage = false;
  const [mappedTickets, setMappedTickets] = useState<Map<string, number>>(
    new Map()
  );
  const [numOfTickets, setNumOfTickets] = useState(0);
  const [numOfApprovedTickets, setNumOfApprovedTickets] = useState(0);
  const [numOfPendingTickets, setNumOfPendingTickets] = useState(0);
  const [roleUserData, setRoleUserData] = useState<AccountData>();
  const [alreadyHaveRole, setAlreadyHaveRole] = useState<boolean>();
  const [showTicketReceipts, setShowTicketReceipts] = useState(false);
  const [ticketReceipts, setTicketReceipts] = useState<TicketReceipt[]>([]);
  const [displayRefundRequest, setDisplayRefundRequest] =
    useState<boolean>(false);
  const [optedOut, setOptedOut] = useState(false);
  const [optedOutCreator, setOptedOutCreator] = useState(false);
  const [loading, setLoading] = useState(true);
  const [confirmPopupTicketConfirmation, setConfirmPopupTicketConfirmation] =
    useState<BinaryConfirmActions>(BinaryConfirmActions.NONE);

  const { account } = useSelector(getAccountState);
  const { isRCA: isRCABool } = account;

  const eventId = useEventSelector();
  const {
    isLoading: isLoadingEventData,
    data: event,
    isError: isErrorEventData,
  } = useParametricSelector(getLoadedEvent, eventId);
  const userId = useAccountSelector();
  const {
    isLoading: isLoadingUserData,
    data: userData,
    isError: isErrorUserData,
  } = useParametricSelector(getLoadedUser, userId);
  const {
    isLoading: isLoadingHostData,
    data: host,
    isError: isErrorHostData,
  } = useParametricSelector(getLoadedUser, event?.createdBy ?? "");

  useOnMount(() => {
    if (state !== null && state !== undefined) {
      setConfirmPopupTicketConfirmation(
        state.isTicketBoughtSuccessfully === undefined
          ? BinaryConfirmActions.NONE
          : state.isTicketBoughtSuccessfully
          ? BinaryConfirmActions.AFFIRMATIVE
          : BinaryConfirmActions.NEGATIVE
      );
    }
  });

  // TODO (jonathan): the following role logic all duplicated in FullEventFinalDesktop, find way in future to consolidate
  // for organizer role ticket cases
  const { data: roleTicket } = useQuery(
    ["roleTicket", eventId, userId],
    async () => {
      if (eventId && userId) {
        const userTicket: TicketV2 | undefined = await getUserTicket(
          eventId,
          userId
        );
        if (userTicket && userTicket.customTicketId === "") {
          const fetchedRoleUserData = await getUserData(userTicket.uid);
          setRoleUserData(fetchedRoleUserData);
          // in case someone was trying to redeem a role even if they already have one
          if (ticketId && userTicket.id !== ticketId) {
            setAlreadyHaveRole(true);
          }
          return userTicket;
        }
        if (ticketId) {
          const unassignedRoleTicket = await getTicketData(eventId, ticketId);
          if (
            unassignedRoleTicket &&
            unassignedRoleTicket.customTicketId === ""
          ) {
            if (unassignedRoleTicket.uid !== "") {
              const fetchedRoleUserData = await getUserData(
                unassignedRoleTicket.uid
              );
              setRoleUserData(fetchedRoleUserData);
            }
            return unassignedRoleTicket;
          }
        }
      }
      return undefined;
    }
  );

  // used for when a user has one ticket and we need to display the correct one-click actions on confirmation page
  const { data: userTicket } = useQuery(
    ["userTicket", eventId, userId],
    async () => {
      if (eventId && userId) {
        const ticketsRef = getTicketsRef(eventId);
        const ticketsQuery = query(
          ticketsRef,
          where("uid", "==", userId),
          limit(1)
        );
        const ticket = (await getDocs(ticketsQuery)).docs.map((doc) =>
          doc.data()
        )[0];
        return ticket;
      }
      return undefined;
    }
  );

  const isUserRCA = useMemo(
    () => (event && isRCA(event.createdBy, event.cohosts)) ?? false,
    [event]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useAsyncEffect(async () => {
    if (event == null || userData == null) {
      setLoading(false);
      return;
    }
    setLoading(true);
    // dispatch(accountActions.setIsRCA(isUserRCA));
    const [
      numTickets,
      numPendingTickets,
      mappedTickets,
      receiptsResponse,
      isUnsubscribedCreator,
      isUnsubscribedMarkit,
    ] = await Promise.all([
      userCurrentNumberOfTickets(event.id, userData.uid),
      userCurrentNumberOfPendingTickets(event.id, userData.uid),
      getUserEventTicketsMapped(event.id, userData.uid),
      (testStripe
        ? API.testPayment.fetchTicketReceipts
        : API.payment.fetchTicketReceipts)({
        attendeeUid: userData.uid,
        eventId: event.id,
      }),
      checkIfUserIsUnsubscribed(userData.uid, event.createdBy),
      checkIfUserIsUnsubscribedMarkit(userData.phoneNumber),
    ]);
    const { receipts } = receiptsResponse;
    setNumOfApprovedTickets(numTickets);
    setNumOfPendingTickets(numPendingTickets);
    setNumOfTickets(numTickets + numPendingTickets);
    setMappedTickets(mappedTickets);
    setTicketReceipts(receipts);
    setOptedOut(isUnsubscribedMarkit);
    setOptedOutCreator(isUnsubscribedCreator);
    setLoading(false);
  }, [dispatch, event, isUserRCA, userData]);

  const loginUser = useCallback(() => {
    dispatch(accountActions.setRedirectPath(pathname));
    navigate("/welcome");
  }, [dispatch, pathname, navigate]);

  const roleTicketExists = useMemo(
    () => roleTicket && roleTicket.customTicketId === "",
    [roleTicket]
  );

  const cohostsList: AccountData[] = useParametricSelector(
    getLoadedUsers,
    event?.cohosts ?? []
  )
    .map((user) => user?.data)
    .filter((user) => user !== undefined) as AccountData[];

  const hostAndCohosts: AccountData[] = useMemo(
    () => (cohostsList && host ? [host, ...cohostsList] : host ? [host] : []),
    [cohostsList, host]
  );

  const viewQRCode = useCallback(() => {
    if (event && userData) {
      dispatch(DataLoaders.user(userData.uid));
      navigate(`/e/${event.id}/i/${userData.uid}/myTicket`, {
        state: { eventId: event.id, userId: userData.uid },
      });
    } else {
      alert(
        "You are not eligible to view QR code." +
          " This may be because you have not received a ticket yet."
      );
    }
  }, [dispatch, event, navigate, userData]);

  const onViewEventClick = useCallback(() => {
    window.open(`https://markitai.com/e/${eventId}`, "_blank", "noreferrer");
  }, [eventId]);

  const handleTicketConfirmPopupDisappear = useCallback(() => {
    setConfirmPopupTicketConfirmation(BinaryConfirmActions.NONE);
    window.history.replaceState(
      { ...state, isTicketBoughtSuccessfully: null },
      ""
    );
  }, [state]);

  const { theme } = useTheme();

  const isTicketStatusPending = useMemo(
    () => numOfApprovedTickets === 0 && numOfTickets !== 0,
    [numOfApprovedTickets, numOfTickets]
  );

  if (isLoadingEventData || isLoadingUserData || isLoadingHostData) {
    return <LoadingScreen />;
  }
  if (
    isErrorEventData ||
    isErrorHostData ||
    isErrorUserData ||
    event == null ||
    host == null
  ) {
    return <LoadingScreen error />;
  }

  const renderCalendar = (
    <Calendar
      event={event}
      full={true}
      hasNoTickets={numOfTickets === 0}
      numOfTickets={numOfTickets}
      hideLocation={event.privateLocation && numOfApprovedTickets === 0}
      pending={isTicketStatusPending}
      loading={loading}
    />
  );

  const commonContent = (
    <div style={{ padding: "14px", paddingTop: "0px" }}>
      <>
        {roleTicket && roleTicketExists && userData ? (
          <FullEventOrganizerConfirmation
            roleTicket={roleTicket}
            roleUserData={roleUserData}
            alreadyHaveRole={alreadyHaveRole ?? false}
            userData={userData}
            event={event}
            numOfTickets={numOfTickets}
            viewQRCode={viewQRCode}
            calendar={renderCalendar}
          />
        ) : (
          <div className="ConfirmContainer" style={theme.SecondaryBG}>
            {isTicketStatusPending ? (
              <>
                <Icon
                  icon={"mdi:dots-circle"}
                  height={35}
                  style={{
                    color: theme === LightTheme ? Colors.BLACK : Colors.WHITE,
                  }}
                />
                <div
                  className="FinalTitleText"
                  style={{
                    ...theme.PrimaryText,
                    marginBlock: 10,
                  }}
                >
                  Pending Approval
                </div>
              </>
            ) : numOfTickets > 0 || loading ? (
              <>
                <Icon
                  icon={"ion:checkmark-circle"}
                  height={35}
                  style={{
                    color: theme === LightTheme ? Colors.BLACK : Colors.WHITE,
                  }}
                />
                <div
                  className="FinalTitleText"
                  style={{
                    ...theme.PrimaryText,
                    marginBlock: 10,
                  }}
                >
                  You&apos;re Going!
                </div>
              </>
            ) : (
              <>
                <Icon
                  icon={"ion:ticket"}
                  height={35}
                  style={{
                    color: Colors.GRAY2,
                  }}
                />
                <div
                  className="FinalTitleText"
                  style={{
                    ...theme.PrimaryText,
                    marginBlock: 10,
                  }}
                >
                  No Tickets
                </div>
              </>
            )}
            <div className="PurchaseText" style={{ marginBottom: 24 }}>
              {loading ? (
                <div className="ColumnNormal" style={{ gap: 7, width: "100%" }}>
                  <Skeleton
                    width={144}
                    height={14}
                    baseColor={theme.TertiaryBG.backgroundColor}
                  />
                  <Skeleton
                    width={88}
                    height={14}
                    baseColor={theme.TertiaryBG.backgroundColor}
                  />
                </div>
              ) : numOfTickets > 0 && userData ? (
                <>
                  {!showTwilioIssuesMessage && !optedOut && !optedOutCreator ? (
                    <p style={{ fontSize: 14 }}>
                      {userData.phoneNumber !== "" ? (
                        isTicketStatusPending ? (
                          <>
                            We&apos;ll send you a text to{" "}
                            <span
                              style={{
                                ...theme.PrimaryText,
                                fontWeight: "500",
                              }}
                            >
                              {formatPhoneNumber(userData.phoneNumber)}
                            </span>
                            <br />
                            if you&apos;re approved.
                          </>
                        ) : (
                          <>
                            Your tickets were texted to{" "}
                            <span
                              style={{
                                ...theme.PrimaryText,
                                fontWeight: "500",
                              }}
                            >
                              {formatPhoneNumber(userData.phoneNumber)}
                            </span>
                            <br />
                          </>
                        )
                      ) : null}
                    </p>
                  ) : null}
                  <div style={{ fontSize: 14 }}>
                    {!isTicketStatusPending
                      ? "View or share your tickets below"
                      : null}
                  </div>
                  {showTwilioIssuesMessage ? (
                    <div
                      className="FinalErrorContainer"
                      style={theme.DividerColor}
                    >
                      <div className="AlignedRow" style={{ paddingInline: 14 }}>
                        <Icon
                          icon="ion:alert-circle"
                          height={18}
                          style={{ marginRight: 7, color: "#F92D48" }}
                        />
                        <div className="FinalErrorTitle">
                          Carrier Network Issue
                        </div>
                      </div>
                      <hr style={theme.DividerColor} />
                      <div
                        style={{
                          fontSize: 14,
                          color: Colors.GRAY1,
                          paddingInline: 14,
                        }}
                      >
                        Your carrier network is currently experiencing issues.
                        If you don&apos;t receive a text with your ticket,
                        please contact our hotline
                        <br></br>
                        <a
                          href={`tel:${MARKIT_HOTLINE_NUMBER}`}
                          style={theme.PrimaryText}
                        >
                          {"(" + formatPhoneNumber(MARKIT_HOTLINE_NUMBER) + ")"}
                        </a>{" "}
                        to be sent your ticket.
                      </div>
                    </div>
                  ) : optedOut || optedOutCreator ? (
                    <OptedOutBanner
                      host={host}
                      optedOut={optedOut}
                      optedOutCreator={optedOutCreator}
                      containerStyles={{ marginTop: 24 }}
                    />
                  ) : numOfPendingTickets > 0 && !isTicketStatusPending ? (
                    <>
                      <div style={{ fontSize: 14, marginTop: 14 }}>
                        We&apos;ll send you a text to you if you&apos;re
                        approved for your requested tickets
                      </div>
                    </>
                  ) : null}
                </>
              ) : (
                <div style={{ fontSize: 14 }}>
                  {
                    "If you believe there was a mistake or error with your ticket, visit our "
                  }
                  <span
                    style={{
                      ...theme.LinkColor,
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      window.open(MARKIT_ATTENDEE_FAQ);
                    }}
                  >
                    FAQ
                  </span>
                  {" to contact us and be assisted."}
                </div>
              )}
            </div>
            {(numOfTickets > 0 && mappedTickets.size > 0) || loading ? (
              <StandardBorderedContainer
                containerStyles={{
                  display: "flex",
                  width: "100%",
                  marginBlock: 10,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    padding: 14,
                    justifyContent: "space-between",
                  }}
                >
                  {loading ? (
                    <div
                      className="ColumnNormal"
                      style={{ gap: 7, width: "100%" }}
                    >
                      <Skeleton
                        width={144}
                        height={14}
                        baseColor={theme.TertiaryBG.backgroundColor}
                      />
                      <div className="AlignedRowSpaced">
                        <Skeleton
                          width={88}
                          height={14}
                          baseColor={theme.TertiaryBG.backgroundColor}
                        />
                        <Skeleton
                          width={67}
                          height={14}
                          baseColor={theme.TertiaryBG.backgroundColor}
                        />
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="ColumnNormal" style={{ gap: 7 }}>
                        {Array.from(mappedTickets.entries()).map(
                          ([key, value]) => (
                            <div key={key} className="FinalSubText">
                              ({value}) {key}
                            </div>
                          )
                        )}
                      </div>
                      {ticketReceipts.length > 0 ? (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "flex-end",
                          }}
                          onClick={() => setShowTicketReceipts(true)}
                        >
                          <div
                            className="AlignedRow FinalSubText"
                            style={{ fontWeight: "500" }}
                          >
                            <span>Receipt</span>
                            <Icon icon="ion:chevron-forward" height={16} />
                          </div>
                        </div>
                      ) : null}
                    </>
                  )}
                </div>
              </StandardBorderedContainer>
            ) : null}

            {!isTicketStatusPending ? (
              <>
                <RectangleButton
                  buttonLabel={
                    numOfTickets > 0
                      ? "Your Ticket" + (numOfTickets > 1 ? "s" : "")
                      : "No Tickets"
                  }
                  onPress={viewQRCode}
                  altColor={
                    isTicketStatusPending
                      ? Colors.GRAY3
                      : numOfTickets > 0 || loading
                      ? isUserRCA
                        ? "#FE296C"
                        : theme.PrimaryText.color
                      : Colors.GRAY6
                  }
                  altTextColor={
                    numOfTickets > 0 || loading
                      ? theme === LightTheme
                        ? Colors.WHITE
                        : Colors.BLACK
                      : Colors.GRAY2
                  }
                  altPaddingVert={14}
                  altBorderRadius={12}
                  disabled={numOfTickets === 0}
                  loading={loading}
                  containerStyles={{ marginBottom: 10 }}
                />
              </>
            ) : null}
            {!loading ? (
              <div className="AlignedRow" style={{ gap: 10 }}>
                <FullEventManageTickets
                  event={event}
                  userId={userId}
                  mainUserTicket={userTicket}
                  numOfTickets={numOfTickets}
                  isTicketStatusPending={isTicketStatusPending}
                />
                {renderCalendar}
              </div>
            ) : null}
          </div>
        )}

        <div className="PageDesktop" style={theme.SecondaryBG}>
          <div className="Container" style={{ paddingInline: 0 }}>
            <div style={{ marginInline: 14 }}>
              <Icon
                icon="ion:enter"
                height={35}
                style={{ ...theme.PrimaryText, marginBottom: 14 }}
              />
              <div className="FinalEventPageText" style={theme.PrimaryText}>
                View Event Page
              </div>
              <div className="FinalSubText">
                View event page for more details
              </div>
            </div>
            <hr style={theme.DividerColor} />
            <div style={{ marginInline: 14 }}>
              <FullEventTimeLocation
                event={event}
                isRCABool={isRCABool}
                numOfApprovedTickets={numOfApprovedTickets}
              />
            </div>
            <div style={{ marginInline: 14 }}>
              <RectangleButton
                buttonLabel="Event Page"
                onPress={onViewEventClick}
                altColor={theme.TertiaryBG.backgroundColor}
                altTextColor={theme.PrimaryText.color}
                altPaddingVert={14}
                altBorderRadius={12}
                containerStyles={{
                  marginTop: 14,
                }}
                iconRight={
                  <Icon
                    icon="iconoir:arrow-up-right"
                    height={16}
                    style={theme.PrimaryText}
                  />
                }
              />
            </div>
          </div>
        </div>
      </>
      <div className="PageDesktop" style={theme.SecondaryBG}>
        <div style={{ paddingBlock: 14 }}>
          <div
            style={{
              ...theme.PrimaryText,
              fontWeight: "500",
              fontSize: 16,
              paddingInline: 14,
            }}
          >
            More Options
          </div>
          <hr style={theme.DividerColor} />
          <FullEventHosts final host={host} hostAndCohosts={hostAndCohosts} />
          <div style={{ marginTop: 14, marginInline: 14 }}>
            {host && host.customer.phone !== "" ? (
              <Banner
                type={BannerType.TEXTHOST}
                hostPhone={host.customer.phone}
                bannerStyles={{ paddingBlock: 14, borderRadius: 12 }}
              />
            ) : (
              <Banner
                type={BannerType.TEXTHOST_FREE}
                bannerStyles={{ paddingBlock: 14, borderRadius: 12 }}
              />
            )}
          </div>

          {isEventTicketsPaid(event) ? (
            <div style={{ marginInline: 14 }}>
              <RectangleButton
                buttonLabel="Request Refund"
                onPress={() => setDisplayRefundRequest(true)}
                altColor={theme.TertiaryBG.backgroundColor}
                altTextColor={theme.PrimaryText.color}
                altPaddingVert={14}
                altBorderRadius={12}
                containerStyles={{
                  marginTop: 10,
                }}
              />
            </div>
          ) : null}
        </div>
      </div>

      {displayRefundRequest ? (
        <div
          style={{
            ...theme.TransparentBG,
            zIndex: 3,
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backdropFilter: "blur(30px)",
          }}
        >
          <div
            style={{
              width: isDesktop ? "97%" : "90%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              paddingTop: 24,
            }}
          >
            <p className="FinalTitleText" style={{ ...theme.PrimaryText }}>
              Request Refund
            </p>
            <Icon
              icon="gridicons:cross-circle"
              height={30}
              color={Colors.GRAY1}
              onClick={() => setDisplayRefundRequest(false)}
              style={{ cursor: "pointer" }}
            />
          </div>
          <div
            className={
              isDesktop
                ? "OverlayWrapperDesktop OverlayModalView"
                : "OverlayWrapperMobile"
            }
            style={{ ...theme.SecondaryBG }}
          >
            {event.canRefund ? (
              <div
                className="PageDesktop"
                style={{ ...theme.SecondaryBG, padding: 14, marginBottom: 0 }}
              >
                <div className="FinalSubText">
                  Contact the event host for refund inquiries
                </div>
                <div style={{ marginTop: 14 }}>
                  {host && host.customer.phone !== "" ? (
                    <Banner
                      type={BannerType.TEXTHOST}
                      hostPhone={host.customer.phone}
                      bannerStyles={{ paddingBlock: 14 }}
                    />
                  ) : (
                    <Banner type={BannerType.TEXTHOST_FREE} />
                  )}
                </div>
              </div>
            ) : (
              <div
                className="PageDesktop"
                style={{ ...theme.SecondaryBG, padding: 14, marginBottom: 0 }}
              >
                <Icon
                  icon="ion:close-circle"
                  height={35}
                  color="#F92D48"
                  style={{ marginBottom: 14 }}
                />
                <div className="FinalEventPageText" style={theme.PrimaryText}>
                  This event has a no refunds policy
                </div>
                <div className="FinalSubText">
                  The event host has chosen to make tickets to this event
                  non-refundable
                </div>
              </div>
            )}
          </div>
        </div>
      ) : null}
      <GuestListSummary event={event} />
      {account.loggedIn !== LoginState.LOGGED_IN ? (
        <div className="PageDesktop" style={theme.SecondaryBG}>
          <div className="Container">
            <div className="FinalEventPageText" style={theme.PrimaryText}>
              Sign In
            </div>
            <div className="FinalSubText">
              Sign in to see your tickets or requests at any time.
            </div>
            <RectangleButton
              buttonLabel="Sign In"
              onPress={loginUser}
              altColor={theme.TertiaryBG.backgroundColor}
              altTextColor={theme.PrimaryText.color}
              altPaddingVert={14}
              altBorderRadius={12}
              containerStyles={{
                marginTop: 14,
              }}
            />
          </div>
        </div>
      ) : null}
      {!roleTicketExists ? <CreatorTextManual host={host} /> : null}

      {showTicketReceipts ? (
        <div className="GuestList" style={{ ...theme.TransparentBG }}>
          <div
            className="AlignedRowSpaced"
            style={{ width: "90%", marginTop: 10 }}
          >
            <span
              style={{ ...theme.PrimaryText, fontWeight: "500", fontSize: 20 }}
            >
              Receipts
            </span>
            <Icon
              icon="gridicons:cross-circle"
              height={30}
              color={Colors.GRAY1}
              onClick={() => setShowTicketReceipts(false)}
              style={{ cursor: "pointer", marginBottom: 3 }}
            />
          </div>
          <div
            className="ColumnNormal"
            style={{
              ...theme.SecondaryBG,
              padding: 14,
              borderRadius: 8,
              margin: 14,
              gap: 8,
              position: "absolute",
              top: 40,
              left: 0,
              right: 0,
            }}
          >
            {ticketReceipts.map((receipt, index) => {
              return (
                <div
                  className="ColumnNormal"
                  style={{
                    gap: 5,
                    padding: 14,
                    borderRadius: 8,
                    ...theme.TertiaryBG,
                  }}
                  key={index}
                >
                  <span
                    style={{
                      ...theme.PrimaryText,
                      fontSize: 15,
                      fontWeight: "500",
                    }}
                  >
                    Receipt {index + 1}: {GetDateWithTime(receipt.createdAt)}{" "}
                    {getTimezone()}
                  </span>
                  <a
                    style={{ fontSize: 15 }}
                    href={receipt.receiptUrl}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span style={{ color: Colors.BLUE5 }}>View Receipt</span>
                  </a>
                </div>
              );
            })}
          </div>
        </div>
      ) : null}
    </div>
  );

  return (
    <div>
      <div className="Wrapper" style={theme.GradientBG}>
        <TopHeader showRCA />
        {commonContent}
        {confirmPopupTicketConfirmation ? (
          <ConfirmActionPopup
            title="Success"
            negativeTitle="Failure"
            isNegative={
              confirmPopupTicketConfirmation === BinaryConfirmActions.NEGATIVE
            }
            onDisappear={handleTicketConfirmPopupDisappear}
          />
        ) : null}
        <div style={{ ...theme.PrimaryBG, paddingTop: "7px" }}>
          <Footer showRCA host={host} />
        </div>
      </div>
    </div>
  );
});

export default FullEventFinal;
