import { useCallback, useMemo, useRef, useState } from "react";
import {
  getDocs,
  onSnapshot,
  limit,
  orderBy,
  query,
  where,
  startAfter,
} from "../firebase";
import { filterUndefinedValues, uniqueVals } from "@markit/common.utils";
import {
  AccountData,
  AudienceListMembers,
  Follower,
} from "@markit/common.types";
import { getUserAudienceListMembersRef } from "../utils/FirebaseUtils";
import { loadAlgoliaUsersSearchResults } from "../utils/algoliaUtils";
import { accountActions, getAccountState } from "../redux/slices/accountSlice";
import { batch, useDispatch, useSelector } from "react-redux";
import { fetchSingleFollower, fetchSingleUser } from "../utils/FetchSingleData";
import useOnUnmount from "./useOnUnmount";

type useLoadAudienceListMembersProps = {
  userId: string;
  audienceListId: string;
  followerStatus: string;
  windowSize: number;
};
export const useLoadAudienceListMembers = (
  props: useLoadAudienceListMembersProps
) => {
  const { userId, audienceListId, followerStatus, windowSize } = props;

  const { followingAccountData, followersData } =
    useSelector(getAccountState).account;
  const dispatch = useDispatch();
  const [fetchedUnorderedMemberUserData, setFetchedUnorderedMemberUserData] =
    useState<AccountData[]>([]);
  const [fetchedMemberFollowerData, setFetchedMemberFollowerData] = useState<
    Follower[]
  >([]);
  const unsubscribeArrayRef = useRef<(() => void)[]>([]);

  const [isLoading, setIsLoading] = useState(false);
  const [timeoutEvent, setTimeoutEvent] = useState<
    NodeJS.Timeout | undefined
  >();

  const [lastVisible, setLastVisible] = useState<string>(
    new Date().toISOString()
  );
  const [loadedAllMessages, setLoadedAllMessages] = useState(false);

  const fetchedMemberUserData: AccountData[] = useMemo(() => {
    const followerUidOrder = fetchedMemberFollowerData.map(
      (follower) => follower.uid
    );
    return fetchedUnorderedMemberUserData.sort(
      (a, b) =>
        followerUidOrder.indexOf(a.uid) - followerUidOrder.indexOf(b.uid)
    );
  }, [fetchedMemberFollowerData, fetchedUnorderedMemberUserData]);

  useOnUnmount(
    (latestDeps) => {
      batch(() => {
        dispatch(
          accountActions.addMultipleFollowingAccountData(
            latestDeps[0] as AccountData[]
          )
        );
        dispatch(
          accountActions.addMultipleFollowerData(latestDeps[1] as Follower[])
        );
      });
      unsubscribeArrayRef.current.forEach(
        (unsubscribe) => unsubscribe && unsubscribe()
      );
      unsubscribeArrayRef.current = []; // Clear the array after cleanup
    },
    [fetchedUnorderedMemberUserData, fetchedMemberFollowerData]
  );

  const addUserData = useCallback((newUserData: AccountData[]) => {
    setFetchedUnorderedMemberUserData((fetchedUserData) =>
      uniqueVals(
        newUserData.concat(fetchedUserData),
        (userData) => userData.uid
      )
    );
  }, []);

  const removeUserData = useCallback((userDataUid: string) => {
    setFetchedUnorderedMemberUserData((fetchedUserData) => {
      const index = fetchedUserData.findIndex(
        (fetched) => fetched.uid === userDataUid
      );
      if (index !== -1) {
        fetchedUserData.splice(index, 1);
      }
      return [...fetchedUserData];
    });
  }, []);

  const addFollowerData = useCallback((newFollowers: Follower[]) => {
    setFetchedMemberFollowerData((fetchedFollowerData) =>
      uniqueVals(
        newFollowers.concat(fetchedFollowerData),
        (followerData) => followerData.uid
      ).sort(
        (a, b) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      )
    );
  }, []);

  const removeFollowerData = useCallback((newFollowerUid: string) => {
    setFetchedMemberFollowerData((fetchedFollowerData) => {
      const index = fetchedFollowerData.findIndex(
        (fetched) => fetched.uid === newFollowerUid
      );
      if (index !== -1) {
        fetchedFollowerData.splice(index, 1);
      }
      return [...fetchedFollowerData];
    });
  }, []);

  const isFinished = useMemo(() => loadedAllMessages, [loadedAllMessages]);

  const updateData = useCallback(
    async (foundMemberData: AudienceListMembers[]) => {
      const membersData = await Promise.all(
        foundMemberData.map(async (member) => {
          const userData = await fetchSingleUser(
            member.uid,
            followingAccountData
          );
          const followerData = await fetchSingleFollower(
            userId,
            member.uid,
            followersData
          );
          if (userData && followerData) {
            return {
              membersUserData: userData,
              membersFollowerData: followerData,
            };
          } else {
            return undefined;
          }
        })
      );

      const definedMembersData: {
        membersUserData: AccountData;
        membersFollowerData: Follower;
      }[] = filterUndefinedValues(membersData);

      const followerStatusMembersData: {
        membersUserData: AccountData;
        membersFollowerData: Follower;
      }[] = definedMembersData.filter(
        (member) => member.membersFollowerData.status === followerStatus
      );

      addUserData(
        followerStatusMembersData.map((member) => member.membersUserData)
      );
      addFollowerData(
        followerStatusMembersData.map((member) => member.membersFollowerData)
      );
    },
    [
      addFollowerData,
      addUserData,
      followerStatus,
      followersData,
      followingAccountData,
      userId,
    ]
  );

  const initialUpdateData = useCallback(
    async (foundMemberData: AudienceListMembers[]) => {
      if (foundMemberData.length) {
        setLastVisible(foundMemberData[foundMemberData.length - 1].uid);
      }
      if (foundMemberData.length < windowSize) {
        setLoadedAllMessages(true);
      }

      if (foundMemberData.length > 0) {
        await updateData(foundMemberData);
      }
    },
    [updateData, windowSize]
  );

  const liveUpdateData = useCallback(
    async (
      type: "added" | "modified" | "removed",
      member: AudienceListMembers,
      foundMemberData: AudienceListMembers[]
    ) => {
      if (
        (type === "added" &&
          !foundMemberData.some(
            (memberData) => memberData.uid === member.uid
          )) ||
        type === "modified"
      ) {
        updateData([member]);
      } else if (type === "removed") {
        removeFollowerData(member.uid);
        removeUserData(member.uid);
      }
    },
    [removeFollowerData, removeUserData, updateData]
  );

  const loadAudienceListUsers = useCallback(async () => {
    if (!isFinished && !isLoading) {
      setIsLoading(true);
      const audienceListMembersRef = getUserAudienceListMembersRef(
        userId,
        audienceListId
      );

      const audienceListMembersQuery = query(
        audienceListMembersRef,
        orderBy("uid", "desc"),
        limit(windowSize)
      );

      const foundAudienceListMembers = (
        await getDocs(audienceListMembersQuery)
      ).docs.map((doc) => doc.data());
      await initialUpdateData(foundAudienceListMembers);

      const unsubscribe = onSnapshot(audienceListMembersQuery, (snapshot) => {
        snapshot.docChanges().forEach(async (message, index) => {
          const memberData = message.doc.data();
          liveUpdateData(message.type, memberData, foundAudienceListMembers);
        });
      });
      unsubscribeArrayRef.current.push(unsubscribe);

      setIsLoading(false);
    }
  }, [
    audienceListId,
    isFinished,
    isLoading,
    liveUpdateData,
    initialUpdateData,
    userId,
    windowSize,
  ]);

  const loadMoreAudienceListUsers = useCallback(async () => {
    if (!isFinished && !isLoading) {
      setIsLoading(true);
      const audienceListMembersRef = getUserAudienceListMembersRef(
        userId,
        audienceListId
      );
      const audienceListMembersQuery = query(
        audienceListMembersRef,
        orderBy("uid", "desc"),
        startAfter(lastVisible),
        limit(windowSize)
      );

      const foundAudienceListMembers = (
        await getDocs(audienceListMembersQuery)
      ).docs.map((doc) => doc.data());
      await initialUpdateData(foundAudienceListMembers);

      const unsubscribe = onSnapshot(audienceListMembersQuery, (snapshot) => {
        snapshot.docChanges().forEach(async (message, index) => {
          const memberData = message.doc.data();
          liveUpdateData(message.type, memberData, foundAudienceListMembers);
        });
      });
      unsubscribeArrayRef.current.push(unsubscribe);

      setIsLoading(false);
    }
  }, [
    audienceListId,
    isFinished,
    isLoading,
    lastVisible,
    liveUpdateData,
    initialUpdateData,
    userId,
    windowSize,
  ]);

  /*
   * Start searching utils
   */
  const checkIfAddUserData = useCallback(
    async (uid: string) => {
      const userData = await fetchSingleUser(uid, followingAccountData);
      if (userData) {
        const followerData = await fetchSingleFollower(
          userId,
          userData.uid,
          followersData
        );
        if (followerData) {
          if (followerData.status === followerStatus) {
            addUserData([userData]);
            addFollowerData([followerData]);
          }
        }
      }
    },
    [
      addFollowerData,
      addUserData,
      followerStatus,
      followersData,
      followingAccountData,
      userId,
    ]
  );

  const loadSearchResults = useCallback(
    async (searchTerm: string) => {
      const fetchListMembers = async (userIds: string[]) => {
        setIsLoading(true);
        const unsubscribeArray = await Promise.all(
          userIds.map(async (uid) => {
            const audienceListMembersRef = getUserAudienceListMembersRef(
              userId,
              audienceListId
            );
            const query_ = query(
              audienceListMembersRef,
              where("uid", "==", uid)
            );

            const unsubscribe = onSnapshot(query_, (snapshot) => {
              snapshot.docChanges().forEach(async (message) => {
                if (message.type === "added" || message.type === "modified") {
                  const memberUserData = message.doc.data();
                  checkIfAddUserData(memberUserData.uid);
                }
              });
            });
            return unsubscribe;
          })
        );
        unsubscribeArrayRef.current.push(...unsubscribeArray);
        setIsLoading(false);
      };

      await loadAlgoliaUsersSearchResults(
        searchTerm,
        `following:${userId}`,
        (userIds: string[]) => fetchListMembers(userIds)
      );
    },
    [audienceListId, checkIfAddUserData, userId]
  );

  const loadSearchResultsThrottled = useCallback(
    (searchTerm: string) => {
      if (timeoutEvent) {
        clearTimeout(timeoutEvent);
      }
      setTimeoutEvent(
        setTimeout(() => {
          if (searchTerm.length > 1) {
            loadSearchResults(searchTerm);
          }
        }, 500)
      );
    },
    [loadSearchResults, timeoutEvent]
  );
  /*
   * End searching utils
   */

  return {
    isFinished,
    isLoading,
    fetchedMemberUserData,
    loadAudienceListUsers,
    loadMoreAudienceListUsers,
    loadSearchResultsThrottled,
  };
};
