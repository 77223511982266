import { EventType } from "@markit/common.types";
import { isExternalGenericLink } from "@markit/common.utils";

// Determines what pathname to navigate to when navigating to a create page
export const onCreateNavigatePath = (eventType: EventType) => {
  return `/create${isExternalGenericLink(eventType) ? "?link" : ""}`;
};

// Determines what pathname to navigate to when navigating to createLink page
export const onCreateLinkNavigatePath = (eventType: EventType) => {
  return `/createLink${isExternalGenericLink(eventType) ? "" : "?event"}`;
};

// Helper to navigate to the campaign dashboard
export const onCampaignNavigatePath = (campaignId: string) => {
  return `/c/${campaignId}`;
};
