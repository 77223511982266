import React, { useCallback, useMemo, useState } from "react";
import "../../../css/CreateEvent.css";
import {
  Event,
  EventTextType,
  MassText,
  SubSelectionStatusId,
  SubSelectionType,
  TextTimelineItem,
  ThemeType,
} from "@markit/common.types";
import { LightTheme, useTheme } from "../../../hooks/useTheme";
import { Colors } from "../../../utils/colors";
import { Icon } from "@iconify/react";
import {
  GetDateWithTime,
  LessThanDate,
  eventHasRequestTickets,
  eventTextTimelineDate,
  findNextUpcomingEventText,
  generateSuggestedActionItem,
  isEventExternalLink,
  isExternalGenericLink,
  isRequestTickets,
  massTextMessageConverter,
} from "@markit/common.utils";
import { useSelector } from "react-redux";
import { getAccountState } from "../../../redux/slices/accountSlice";
import { generateDefaultEventTexts } from "../../../utils/textingUtils";
import isEqual from "lodash.isequal";
import { AnimatePresence, LazyMotion, domAnimation, m } from "framer-motion";
import { IoAddCircleOutline } from "react-icons/io5";
import { API } from "../../../API";
import { ModifiedTextMessageDisplay } from "../../DisplayItem/ModifiedTextMessageDisplay";
import MassTextPanel, { TextPopupConfirmActions } from "./MassTextPanel";
import LargePopupModalContainer from "../../Containers/LargePopupModalContainer";
import { useLiveUpdatingEventTexts } from "../../../hooks/useLiveUpdatingEventTexts";
import { CircularProgress } from "@mui/material";
import SuggestedActionButton from "../../Buttons/SuggestedActionButton";
import ConfirmDeleteModal from "../../Containers/ConfirmPopups/ConfirmDeleteModal";
import PreviewBoxContainer from "../../Containers/PreviewBoxContainer";
import BlackHoverButton from "../../Buttons/BlackHoverButton";
import TicketTextPopup from "../TicketTextPopup";
import { BackButton } from "../../Buttons/BackButton";
import {
  MassTextSelectedType,
  MassTextsWrapperType,
} from "./MassTextWrapperManager";

interface EventTextsProps {
  isVisible: boolean;
  openEventTexts: () => void;
  closeEventTexts: () => void;
  event: Event;
  totalGuests: number;
  totalRequested: number;
}

const EventTexts = (props: EventTextsProps) => {
  const {
    isVisible,
    openEventTexts,
    closeEventTexts,
    event,
    totalGuests,
    totalRequested,
  } = props;
  const { account } = useSelector(getAccountState);
  const { accountData } = account;
  const { theme } = useTheme(ThemeType.Light);
  const [ticketTextVisible, setTicketTextVisible] = useState(false);
  const [selectedTextType, setSelectedTextType] =
    useState<MassTextSelectedType>({ massText: undefined, isVisible: false });
  const [showDeleteMassTextModal, setShowDeleteMassTextModal] =
    useState<boolean>(false);
  const [confirmPopupTextConfirmation, setConfirmPopupTextConfirmation] =
    useState<TextPopupConfirmActions>(TextPopupConfirmActions.NONE);
  const [defaultScheduled, setDefaultScheduled] = useState(false);

  const {
    eventTextsLoading,
    regularTextsLoading,
    eventMassTexts,
    textTimelineToShow,
    regularMassTextsInRange,
    massTextAttachments,
  } = useLiveUpdatingEventTexts({ eventId: event.id });

  const isHost = useMemo(
    () => accountData.uid === event.createdBy || accountData.isAdmin,
    [accountData.isAdmin, accountData.uid, event.createdBy]
  );

  const hasRequest = useMemo(() => eventHasRequestTickets(event), [event]);

  const onlyRequest = useMemo(() => isRequestTickets(event), [event]);

  const isExternalLink = useMemo(
    () => isEventExternalLink(event.eventType),
    [event.eventType]
  );

  const nextUpcomingEventText = useMemo(
    () => findNextUpcomingEventText(eventMassTexts, new Date().toISOString()),
    [eventMassTexts]
  );
  const lastFilledIn = useMemo(
    () =>
      textTimelineToShow.length > 0 &&
      LessThanDate(
        textTimelineToShow[textTimelineToShow.length - 1].sentAt,
        new Date().toISOString()
      ),
    [textTimelineToShow]
  );
  const allEventTextsPassed = useMemo(
    () => eventMassTexts.every((text) => text.queued),
    [eventMassTexts]
  );

  const defaultEventTexts: MassText[] = useMemo(
    () => generateDefaultEventTexts(accountData.uid, event),
    [accountData.uid, event]
  );

  const newTextOnPress = useCallback(() => {
    setSelectedTextType({ massText: undefined, isVisible: true });
    setDefaultScheduled(true);
  }, []);

  const deleteEventText = useCallback(
    async (currText: MassText) => {
      if (event.createdBy !== accountData.uid) {
        alert("Only the host can delete event texts.");
        return;
      }
      setShowDeleteMassTextModal(false);
      setSelectedTextType({ massText: undefined, isVisible: false });
      await API.text
        .deleteMassTextMessage({
          userId: accountData.uid,
          campaignId: currText.campaignId,
          massTextId: currText.id,
        })
        .then((response) => {
          if (response.success) {
            setConfirmPopupTextConfirmation(
              TextPopupConfirmActions.TEXT_DELETE
            );
          }
        });
    },
    [event.createdBy, accountData.uid]
  );

  const renderEventTextsOptions = (
    title: string,
    label: JSX.Element | string,
    message: string,
    currText: MassText | undefined
  ) => {
    return (
      <div
        style={{
          borderRadius: "8px",
          padding: "14px",
          marginBlock: 3,
          ...theme.TertiaryBG,
        }}
      >
        <div className="AlignedRowSpaced">
          <div className="ColumnNormal" style={{ gap: 3 }}>
            <span
              className="AboutSubtitle"
              style={{ ...theme.PrimaryText, fontSize: 14 }}
            >
              {title}
            </span>
            <div className="AlignedRow" style={{ gap: 4 }}>
              <Icon
                icon={"ion:timer-outline"}
                height={14}
                color={theme.PrimaryText.color}
              />
              <span style={{ ...theme.SubText, fontSize: 12 }}>{label}</span>
            </div>
            {currText?.catchUpEnabled ? (
              <div className="AlignedRow" style={{ gap: 4 }}>
                <Icon
                  icon={"mdi:fast-forward-outline"}
                  height={14}
                  color={theme.PrimaryText.color}
                />
                <span style={{ ...theme.SubText, fontSize: 12 }}>
                  Catch-up sending enabled
                </span>
              </div>
            ) : null}
          </div>
          <div className="AlignedRow" style={{ gap: 14 }}>
            {currText &&
            !LessThanDate(currText.sentAt, new Date().toISOString()) ? (
              <Icon
                className="TrashButton"
                icon={"ion:trash-outline"}
                height={20}
                onClick={() => {
                  if (isHost) {
                    setSelectedTextType({
                      massText: currText,
                      isVisible: false,
                    });
                    setShowDeleteMassTextModal(true);
                  }
                }}
                style={{
                  cursor: !isHost ? "auto" : "pointer",
                  pointerEvents: !isHost ? "none" : "auto",
                }}
              />
            ) : null}
            <Icon
              icon={"feather:edit"}
              height={20}
              className={
                "EditButton " +
                (theme === LightTheme ? "EditButtonLight" : "EditButtonDark")
              }
              onClick={() => {
                if (isHost) {
                  setSelectedTextType({ massText: currText, isVisible: true });
                }
              }}
              style={{
                cursor: !isHost ? "auto" : "pointer",
                pointerEvents: !isHost ? "none" : "auto",
              }}
            />
          </div>
        </div>
        <div
          style={{
            ...theme.SecondaryBG,
            padding: 10,
            marginTop: 10,
            borderRadius: 8,
            height: 45,
            display: "flex",
          }}
        >
          <span
            style={{
              whiteSpace: "pre-wrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              fontWeight: "400",
              fontSize: 13,
              color: Colors.GRAY1,
            }}
          >
            <ModifiedTextMessageDisplay message={message} />
          </span>
        </div>
      </div>
    );
  };

  return (
    <LargePopupModalContainer
      showModal={isVisible}
      headerComp={
        <BackButton
          iconName="mdi:close"
          theme={theme}
          onPress={closeEventTexts}
        />
      }
      valueComp={
        <>
          {!eventTextsLoading ? (
            <div
              className="PageDesktop HideScrollbar ColumnNormal"
              style={{
                borderRadius: 0,
                gap: 14,
                height: "calc(100vh - 120px)",
                overflow: "scroll",
                ...theme.SecondaryBG,
              }}
            >
              <AnimatePresence>
                <LazyMotion features={domAnimation}>
                  <m.div
                    initial={{ height: 0, opacity: 0 }}
                    animate={{ height: "auto", opacity: 1 }}
                    exit={{ height: 0, opacity: 0 }}
                    transition={{ duration: 0.4, ease: "easeIn" }}
                    className="HideScrollbar"
                  >
                    <div className="ColumnNormal" style={{ gap: 10 }}>
                      {!isHost ? (
                        <SuggestedActionButton
                          event={event}
                          loadingSuggestedActions={
                            eventTextsLoading || regularTextsLoading
                          }
                          isHost={isHost}
                          eventMassTexts={eventMassTexts}
                          regularMassTextsInRange={regularMassTextsInRange}
                          massTextAttachments={massTextAttachments}
                          openEventTexts={openEventTexts}
                          navigateEventTextBlast={() => {
                            setSelectedTextType({
                              massText: undefined,
                              isVisible: true,
                            });
                            setDefaultScheduled(false);
                          }}
                          cohostEventTexts={!isHost}
                        />
                      ) : null}
                      <div
                        className="ColumnNormal"
                        style={{ gap: 10, opacity: !isHost ? 0.5 : 1 }}
                      >
                        <PreviewBoxContainer
                          message={event.creatorTicketPurchaseMessage}
                          headerText={
                            isExternalLink ? "Welcome Text" : "Ticket Text"
                          }
                          subHeaderComp={
                            <span style={{ fontSize: 12, color: Colors.GRAY1 }}>
                              {isExternalLink
                                ? "Sent After Submission (Required)"
                                : onlyRequest
                                ? "Sent Upon Initial Request (Required)"
                                : `Sent Upon RSVP${
                                    hasRequest ? " or Initial Request" : ""
                                  } (Required)`}
                            </span>
                          }
                          headerRightComp={
                            <BlackHoverButton
                              leftIconName="feather:edit"
                              leftIconSize={18}
                              onPress={() => setTicketTextVisible(true)}
                              containerStyles={{ padding: 8 }}
                            />
                          }
                        />
                        <h2 className="AboutSubtitle" style={theme.PrimaryText}>
                          Text Timeline
                        </h2>
                        {textTimelineToShow.map((item, index) => {
                          const timelineDateToShow = eventTextTimelineDate(
                            item.sentAt,
                            event.start,
                            event.end
                          );

                          const filled = LessThanDate(
                            item.sentAt,
                            new Date().toISOString()
                          );
                          return (
                            <>
                              <div
                                style={{
                                  flexDirection: "row",
                                  alignItems: "flex-start",
                                  position: "relative",
                                }}
                              >
                                <div style={{ alignItems: "center" }}>
                                  {(item as MassText).message ? (
                                    <div
                                      style={{
                                        display: "flex",
                                        marginBlock: 10,
                                        width: 12,
                                        height: 12,
                                        border: "2px solid #000",
                                        borderRadius: 24,
                                        position: "absolute",
                                        left: 0,
                                        backgroundColor: filled
                                          ? theme === LightTheme
                                            ? Colors.BLACK
                                            : Colors.WHITE
                                          : "",
                                        borderColor:
                                          filled ||
                                          index === 0 ||
                                          nextUpcomingEventText?.id === item.id
                                            ? theme === LightTheme
                                              ? Colors.BLACK
                                              : Colors.WHITE
                                            : theme === LightTheme
                                            ? Colors.GRAY2
                                            : Colors.GRAY8,
                                      }}
                                    />
                                  ) : null}
                                  {index < textTimelineToShow.length ? (
                                    <div
                                      style={{
                                        display: "flex",
                                        position: "absolute",
                                        width: 2,
                                        left: 7,
                                        top: (item as MassText).message
                                          ? 30
                                          : 0,
                                        height: (item as MassText).message
                                          ? (item as MassText).catchUpEnabled
                                            ? 145
                                            : 130
                                          : 30,
                                        backgroundColor:
                                          filled ||
                                          ((item.type === EventTextType.START ||
                                            item.type === EventTextType.END) &&
                                            nextUpcomingEventText &&
                                            nextUpcomingEventText.sentAt >
                                              item.sentAt) ||
                                          allEventTextsPassed
                                            ? theme === LightTheme
                                              ? Colors.BLACK
                                              : Colors.WHITE
                                            : theme === LightTheme
                                            ? Colors.GRAY2
                                            : Colors.GRAY8,
                                      }}
                                    />
                                  ) : null}
                                </div>
                                {item.type === EventTextType.START ||
                                item.type === EventTextType.END ? (
                                  <div
                                    className="AlignedRow"
                                    style={{ marginLeft: 28, gap: 8 }}
                                  >
                                    <span
                                      style={{
                                        ...theme.PrimaryText,
                                        fontWeight: "500",
                                        fontSize: 14,
                                      }}
                                    >
                                      {(item as TextTimelineItem).type ===
                                      EventTextType.START
                                        ? isEventExternalLink(event.eventType)
                                          ? "Start"
                                          : "Event Start"
                                        : isEventExternalLink(event.eventType)
                                        ? isExternalGenericLink(event.eventType)
                                          ? "Expires"
                                          : "End"
                                        : "Event End"}
                                    </span>
                                    <span
                                      style={{
                                        ...theme.SubText,
                                        fontSize: 14,
                                      }}
                                    >
                                      {GetDateWithTime(
                                        (item as TextTimelineItem).displayTime,
                                        true
                                      )}
                                    </span>
                                  </div>
                                ) : item.type === EventTextType.NEW ? null : (
                                  <div style={{ marginLeft: 28 }}>
                                    {renderEventTextsOptions(
                                      timelineDateToShow,
                                      <div
                                        className="AlignedRow"
                                        style={{ gap: 5 }}
                                      >
                                        <span
                                          style={{
                                            ...theme.SubText,
                                            fontSize: 12,
                                          }}
                                        >
                                          {GetDateWithTime(item.sentAt, true)}
                                          {defaultEventTexts.some((def) =>
                                            isEqual(def, item)
                                          )
                                            ? " (Suggested)"
                                            : ""}
                                        </span>
                                      </div>,
                                      massTextMessageConverter(
                                        (item as MassText).message,
                                        massTextAttachments
                                      ),
                                      item as MassText
                                    )}
                                  </div>
                                )}
                              </div>
                            </>
                          );
                        })}
                        <div className="AlignedRow">
                          <div
                            style={{
                              marginBlock: 10,
                              width: 12,
                              height: 12,
                              borderRadius: 24,
                              backgroundColor:
                                lastFilledIn || allEventTextsPassed
                                  ? theme === LightTheme
                                    ? Colors.BLACK
                                    : Colors.WHITE
                                  : "",
                              opacity: allEventTextsPassed ? 1 : 0.8,
                              border:
                                theme === LightTheme
                                  ? "2px solid #B9B9B9"
                                  : "2px solid #3D3D3D",
                            }}
                          />
                          <div
                            className="AlignedRowSelect"
                            style={{
                              marginLeft: 14,
                              padding: 12,
                              borderRadius: 8,
                              border: "1px solid #B9B9B9",
                              justifyContent: "center",
                              width: "95%",
                              gap: 7,
                              cursor: !isHost ? "auto" : "pointer",
                              pointerEvents: !isHost ? "none" : "auto",
                            }}
                            onClick={newTextOnPress}
                          >
                            <span
                              style={{
                                ...theme.PrimaryText,
                                fontSize: 14,
                                fontWeight: "500",
                              }}
                            >
                              New{" "}
                              {isExternalGenericLink(event.eventType)
                                ? ""
                                : "Event"}{" "}
                              Text
                            </span>
                            <IoAddCircleOutline
                              size={18}
                              color={
                                theme === LightTheme
                                  ? Colors.BLACK
                                  : Colors.WHITE
                              }
                              style={{ marginBottom: -1 }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </m.div>
                </LazyMotion>
              </AnimatePresence>
            </div>
          ) : (
            <CircularProgress
              style={{ color: "#929292", alignSelf: "center" }}
              size={20}
            />
          )}
          {selectedTextType.isVisible ? (
            <MassTextPanel
              wrapperType={MassTextsWrapperType.MODAL}
              selectedTextType={selectedTextType}
              setSelectedTextType={(selectedTextType: MassTextSelectedType) => {
                // resets state of event texts back to original
                // need to reset default scheduled to original false state or else when you navigate somewhere else, it stays as true
                if (!selectedTextType.isVisible) {
                  setSelectedTextType(selectedTextType);
                  setDefaultScheduled(false);
                }
              }}
              campaign={undefined}
              event={event}
              defaultScheduled={defaultScheduled}
              suggestedAction={
                isExternalLink
                  ? generateSuggestedActionItem(SubSelectionType.ALL_PEOPLE, "")
                  : generateSuggestedActionItem(
                      SubSelectionType.STATUS,
                      totalGuests === 0 && totalRequested > 0
                        ? SubSelectionStatusId.REQUESTED
                        : SubSelectionStatusId.ATTENDEES
                    )
              }
              confirmPopupTextConfirmation={confirmPopupTextConfirmation}
              setConfirmPopupTextConfirmation={setConfirmPopupTextConfirmation}
            />
          ) : null}
          {ticketTextVisible ? (
            <TicketTextPopup
              isVisible={ticketTextVisible}
              setIsVisible={setTicketTextVisible}
              event={event}
            />
          ) : null}
          <ConfirmDeleteModal
            heading="Are you sure you want to delete this event text?"
            subtext="You cannot undo this."
            deleteButtonText="Delete"
            hideModal={!showDeleteMassTextModal}
            setIsVisible={setShowDeleteMassTextModal}
            deleteOnPress={() => deleteEventText(selectedTextType.massText!)}
            theme={theme}
          />
        </>
      }
    />
  );
};

export default EventTexts;
