import {
  MassText,
  MassTextAttachment,
  Event,
  SubSelectionType,
} from "@markit/common.types";
import { getCountFromServer, getDocs, query, where } from "../firebase";
import {
  getMassTextAttachmentsSnap,
  getMassTextsRef,
  getTextRecipientSnap,
  getTextRecipientsRef,
} from "./FirebaseUtils";
import { API } from "../API";
import {
  dayAfterEventText,
  defaultExternalEventText,
  isEventExternalLink,
  massTextPlaceholder,
  massTextUpcomingEventPlaceholder,
  pregameEventText,
} from "@markit/common.utils";
import { generate } from "shortid";
import { getUidsFromEventTicketType } from "./eventUtils/eventUtils";
import { GetLongDate } from "./GetLongDate";

/**
 * Get the mass text attachments from a specified mass text
 */
export const getMassTextAttachments = async (
  userId: string,
  massText: MassText
): Promise<MassTextAttachment[]> => {
  const massTextAttachmentsSnap = await getMassTextAttachmentsSnap(
    userId,
    massText.campaignId,
    massText.id
  );
  const massTextAttachments = massTextAttachmentsSnap.docs.map((doc) =>
    doc.data()
  );
  return massTextAttachments;
};

// get the number of texts by a user was sent to one specified user
export const getNumMassTextsToUser = async (
  userId: string,
  recipientId: string
): Promise<number> => {
  const massTextsRef = getMassTextsRef();
  const massTextsQuery = query(massTextsRef, where("sentBy", "==", userId));
  const massTextsSnap = await getDocs(massTextsQuery);
  const numRecipientsArr: number[] = await Promise.all(
    massTextsSnap.docs.map(async (doc) => {
      const massText = doc.data();
      const recipientsSnap = await getTextRecipientSnap(
        userId,
        massText.campaignId,
        massText.id,
        recipientId
      );
      return recipientsSnap.exists() ? 1 : 0;
    })
  );
  const totalEventTexts = numRecipientsArr.reduce(
    (acc, count) => acc + count,
    0
  );
  return totalEventTexts;
};

/**
 * Get total number of recipients from a specified mass text
 */
export const getNumMassTextRecipients = async (
  userId: string,
  campaignId: string,
  massTextId: string
): Promise<number> => {
  const massTextRecipientsRef = getTextRecipientsRef(
    userId,
    campaignId,
    massTextId
  );
  const snapshot = await getCountFromServer(massTextRecipientsRef);
  return snapshot.data().count;
};

/**
 * Get the recipients from a specified mass text for a specified attachment
 */
export const getMassTextRecipients = async (
  userId: string,
  campaignId: string,
  massTextId: string
): Promise<string[]> => {
  const { textRecipients } = await API.text.getTextRecipients({
    userId: userId,
    campaignId: campaignId,
    massTextId: massTextId,
  });
  return textRecipients;
};

export const getMassTextSendRate = async (
  massText: MassText
): Promise<number> => {
  const response = await API.tracking.getMassTextSendRate({
    massText: massText,
  });

  if (response) {
    return response.sendRate;
  }

  return 100;
};

export const getMassTextDeliveryAttemptedRate = async (
  massText: MassText
): Promise<number> => {
  const response = await API.tracking.getMassTextDeliveryAttemptedRate({
    massText: massText,
  });

  if (response) {
    return response.deliveryAttemptedRate;
  }

  return 100;
};

export const generateDefaultEventTexts = (userId: string, event: Event) => {
  if (isEventExternalLink(event.eventType)) {
    const externalDefaultText: MassText = defaultExternalEventText(
      generate(),
      userId,
      event.id,
      event.start,
      event.externalLink,
      ""
    );
    const defaultEventTexts: MassText[] = [externalDefaultText];
    return defaultEventTexts;
  } else {
    const pregameText: MassText = pregameEventText(
      generate(),
      userId,
      event.id,
      event.start,
      event.isVirtual,
      ""
    );

    const dayAfterText: MassText = dayAfterEventText(
      generate(),
      userId,
      event.id,
      event.end,
      ""
    );

    const defaultEventTexts: MassText[] = [pregameText, dayAfterText];
    return defaultEventTexts;
  }
};

// fetch the attendees per ticket type and store the recipients that belong within each ticket type in a map
// Used for mass text selecting by event ticket types
export const fetchAttendeesPerTypeMap = async (selectedEvent: Event) => {
  if (selectedEvent) {
    const attendeesPerTicketTypeArray = await Promise.all(
      selectedEvent.customTickets.map(async (ticket) => {
        const ticketAttendees = await getUidsFromEventTicketType(
          selectedEvent.id,
          ticket.id
        );
        return {
          id: ticket.id,
          attendees: ticketAttendees,
        };
      })
    );
    const attendeesPerTicketType = new Map(
      attendeesPerTicketTypeArray.map((ticketItem) => [
        ticketItem.id,
        ticketItem.attendees,
      ])
    );
    return attendeesPerTicketType;
  }
  return new Map<string, string[]>();
};

// Returns the default message we want to start the message with based on the suggestedActionType
export const massTextDefaultMessage = (
  event: Event | undefined,
  fullName: string,
  suggestedActionType: SubSelectionType | undefined
) => {
  return event && suggestedActionType === SubSelectionType.ALL_FOLLOWERS
    ? massTextUpcomingEventPlaceholder(
        fullName,
        GetLongDate(event.start, false, true, true, false),
        event.id
      )
    : massTextPlaceholder(fullName);
};
