import React from "react";
import { Colors } from "../../utils/colors";
import { AccountData } from "@markit/common.types";
import { Icon } from "@iconify/react";
import { isDesktop } from "react-device-detect";
import FeaturedPeopleCard from "./FeaturedPeopleCard";

const FeaturedPeople = (props: {
  featuredPeople: AccountData[];
  cityName?: String;
  eventCategory?: String;
}) => {
  const { featuredPeople, cityName, eventCategory } = props;

  const styles = {
    peopleContainer: {
      marginTop: 40,
    },

    title: {
      fontSize: isDesktop ? 32 : 24,
      fontWeight: 600,
    },

    subtitle: {
      fontSize: isDesktop ? 16 : 14,
      color: Colors.GRAY1,
      marginTop: isDesktop ? 7 : 4,
    },

    header: {
      display: "flex",
      alignItems: "center",
      gap: 14,
    },

    content: {
      display: "grid",
      gridTemplateColumns: isDesktop ? "repeat(3, 1fr)" : "1fr",
      gap: 14,
    },
  };

  return (
    <div style={styles.peopleContainer}>
      <div
        className="ColumnNormal"
        style={{ gap: isDesktop ? 7 : 4, marginBottom: 24 }}
      >
        <div style={styles.header}>
          <span style={styles.title}>People</span>
          <Icon icon="ion:people" height={32} style={{ color: Colors.BLACK }} />
        </div>
        <span style={styles.subtitle}>
          {cityName || eventCategory
            ? `Follow these popular creators for updates on great events in ${
                cityName || eventCategory
              }.`
            : "Follow these popular creators for updates on great events!"}
        </span>
      </div>
      <hr />
      <div style={styles.content}>
        {featuredPeople.slice(0, isDesktop ? 6 : 3).map((person) => (
          <FeaturedPeopleCard person={person} key={person.uid} />
        ))}
      </div>
      <hr />
    </div>
  );
};

export default FeaturedPeople;
