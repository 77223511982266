import React from "react";
import "../css/OrganizerItem.css";
import { AccountData } from "@markit/common.types";
import { InitialsPic } from "./InitialsPic";

type OrganizerItemProps = {
  user: AccountData;
  following: boolean;
  onProfileClick: (userId: string) => void;
  selectOrganizerItem: () => void;
};

const OrganizerItem = ({
  user,
  following,
  onProfileClick,
  selectOrganizerItem,
}: OrganizerItemProps) => (
  <div className="OrganizerContainer">
    <div className="OrganizerInfo">
      {user.profilePicURL && user.profilePicURL !== "" ? (
        <img
          className="OrganizerProfilePic"
          src={user.profilePicURL}
          alt="host profile pic"
        />
      ) : (
        <div className="OrganizerProfilePic InitialsPic">
          <InitialsPic fullName={user.fullName} />
        </div>
      )}
      <div className="NameContainer">
        <div className="Hosts">
          <span className="OrganizerFullName" style={{ width: 180 }}>
            {user.fullName}
          </span>
        </div>
        <span
          onClick={() => onProfileClick && onProfileClick(user.username)}
          style={{
            fontSize: 13,
            fontWeight: "600",
            color: "#11B8FF",
          }}
        >
          View Profile
        </span>
      </div>
    </div>
    <div
      style={{
        backgroundColor: following ? "#B9B9B9" : "#11b8ff",
        paddingLeft: 15,
        paddingRight: 15,
        pointerEvents: following ? "none" : "all",
      }}
      className="OrganizerFollowBtn"
      onClick={selectOrganizerItem}
    >
      <span style={{ color: "#FFF", fontSize: 14, fontWeight: "500" }}>
        {following ? "Following" : "Follow"}
      </span>
    </div>
  </div>
);

export default OrganizerItem;
