import { Icon } from "@iconify/react";
import { Dropdown, MenuProps } from "antd";
import { ThemeStyle } from "../../../../hooks/useTheme";
import { AttachmentSourceType, Event } from "@markit/common.types";
import React, { useCallback, useMemo, useState } from "react";
import DropdownMenuLabel from "../../../DropdownMenuLabel";
import { isEventExternalLink } from "@markit/common.utils";
import {
  MEDIA_UPLOAD_CODES,
  errorAlertText,
  errorSubAlertText,
  uploadMedia,
} from "../../../../utils/photoUtils";
import AlertContainer from "../../../Containers/AlertContainer";

type AttachmentsActionBarHoldItemProps = {
  onClick: MenuProps["onClick"];
  attachmentSourceType: AttachmentSourceType;
  theme?: ThemeStyle;
  invertedColor?: boolean;
  disabledEventLinks?: boolean;
  event?: Event;
  setImage?: (image: string) => void;
  fileInputRef?: React.MutableRefObject<HTMLInputElement | null>;
};

export const AttachmentsActionBarHoldItem = (
  props: AttachmentsActionBarHoldItemProps
) => {
  const {
    onClick,
    attachmentSourceType,
    theme,
    invertedColor,
    disabledEventLinks,
    event,
    setImage,
    fileInputRef,
  } = props;

  const [alertText, setAlertText] = useState("");
  const [alertSubText, setAlertSubText] = useState("");

  /*  image selecting functions */
  const uploadImage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      return new Promise<void>((resolve, reject) => {
        (async () => {
          if (setImage) {
            const uploadData = await uploadMedia(event, false, true);
            if (uploadData.uploadCode !== MEDIA_UPLOAD_CODES.SUCCESS) {
              setAlertText(errorAlertText(uploadData.uploadCode, true));
              setAlertSubText(errorSubAlertText(uploadData.uploadCode));
              reject();
            } else {
              setImage(uploadData.blobUrl);
              resolve();
            }
          } else {
            // no set image function
            reject();
          }
        })().catch(reject); // Handle any async errors
      });
    },
    [setAlertText, setImage]
  );
  /*  END image selecting functions */

  const items: MenuProps["items"] = useMemo(() => {
    const menuItems = [
      {
        label: (
          <DropdownMenuLabel
            label="Event Link"
            icon="ion:calendar"
            width={200}
          />
        ),
        key: "event_link",
      },
      {
        label: (
          <DropdownMenuLabel
            label="Discount Ticket"
            icon="ion:pricetag"
            width={200}
          />
        ),
        key: "discount_ticket",
      },
      {
        label: (
          <DropdownMenuLabel label="Free Ticket" icon="ion:gift" width={200} />
        ),
        key: "free_ticket",
      },
    ];
    if (attachmentSourceType !== AttachmentSourceType.MASS_TEXT) {
      menuItems.push({
        label: (
          <DropdownMenuLabel
            label="Link to Ticket"
            icon={disabledEventLinks ? "ion:alert-circle" : "ion:ticket"}
            width={200}
          />
        ),
        key: "ticket_link",
      });
      menuItems.push({
        label: (
          <DropdownMenuLabel
            label="Calendar Invite"
            icon={
              disabledEventLinks ? "ion:alert-circle" : "mdi:calendar-account"
            }
            width={200}
          />
        ),
        key: "calendar_invite",
      });
    }

    if (attachmentSourceType === AttachmentSourceType.CONVERSATION) {
      menuItems.push({
        label: (
          <>
            <DropdownMenuLabel label="Media" icon={"ion:image"} width={200} />
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              accept="image/*"
              onChange={uploadImage}
            />
          </>
        ),
        key: "media",
      });
    }

    return menuItems;
  }, [attachmentSourceType, disabledEventLinks, fileInputRef, uploadImage]);

  const externalItems: MenuProps["items"] = useMemo(() => {
    const menuItems = [
      {
        label: (
          <DropdownMenuLabel
            label="Markit Event Link"
            icon="ion:calendar"
            width={200}
          />
        ),
        key: "markit_event_link",
      },
      {
        label: (
          <DropdownMenuLabel
            label="External Event Link"
            icon="ion:link"
            width={200}
          />
        ),
        key: "external_event_link",
      },
    ];

    if (attachmentSourceType === AttachmentSourceType.CONVERSATION) {
      menuItems.push({
        label: (
          <DropdownMenuLabel label="Media" icon={"ion:image"} width={200} />
        ),
        key: "media",
      });
    }

    return menuItems;
  }, [attachmentSourceType]);

  return (
    <>
      <AlertContainer
        headerComp={
          <div className="ColumnNormal" style={{ gap: 4 }}>
            <Icon icon="mdi-image-remove" height={40} />
            <span style={{ fontSize: 16, fontWeight: "500" }}>{alertText}</span>
          </div>
        }
        subHeaderComp={alertSubText}
        theme={theme}
        closeModal={() => {
          setAlertText("");
          if (fileInputRef && fileInputRef.current) {
            fileInputRef.current.value = "";
          }
        }}
        hideModal={alertText === ""}
      />
      <Dropdown
        menu={{
          items:
            event && isEventExternalLink(event.eventType)
              ? externalItems
              : items,
          onClick,
        }}
        trigger={["click"]}
        placement="topLeft"
        overlayStyle={{ paddingBottom: 10 }}
      >
        <div
          className={
            attachmentSourceType === AttachmentSourceType.CONVERSATION
              ? "CreatorChatAttachmentButton"
              : "MassTextsAttachmentButton"
          }
          style={{
            alignSelf: "flex-end",
            backgroundColor: invertedColor
              ? theme?.ButtonSecondaryBG.backgroundColor
              : theme?.TertiaryBG.backgroundColor,
          }}
        >
          <Icon
            icon="feather:plus"
            height={24}
            style={{
              color: invertedColor
                ? theme?.ButtonText.color
                : theme?.PrimaryText.color,
            }}
          />
        </div>
      </Dropdown>
    </>
  );
};
