import { CSSProperties, memo } from "react";
import { Colors } from "../../utils/colors";
import { useTheme } from "../../hooks/useTheme";

/* flexSections and sectionTitles must be same length */
type GridTableHeaderProps = {
  flexSections: number[];
  sectionTitles: string[];
  containerStyles?: CSSProperties;
};

const GridTableHeader = memo(function GridTableHeaderFn(
  props: GridTableHeaderProps
) {
  const { flexSections, sectionTitles, containerStyles } = props;

  const { theme } = useTheme();

  const styles = {
    ticketHeaders: {
      backgroundColor: Colors.WHITE1,
    },

    ticketHeaderText: {
      color: theme.SubText.color,
      fontSize: 14,
      fontWeight: 500,
    },
  };

  if (flexSections.length !== sectionTitles.length) {
    return null;
  }

  return (
    <div
      className="AlignedRow"
      style={{ ...styles.ticketHeaders, ...containerStyles }}
    >
      {sectionTitles.map((title, index) => (
        <div
          key={index}
          className="AlignedRow"
          style={{ flex: flexSections[index], height: 37 }}
        >
          <div
            style={{
              ...styles.ticketHeaderText,
              marginLeft: 14,
            }}
          >
            {title}
          </div>
        </div>
      ))}
    </div>
  );
});

export default GridTableHeader;
