import { useCallback, useMemo, useRef, useState } from "react";
import { Campaign, MassText } from "@markit/common.types";
import { onSnapshot } from "../../firebase";
import {
  getCampaignMassTextsSnap,
  getUserCampaignRef,
} from "../../utils/FirebaseUtils";
import { useDispatch } from "react-redux";
import {
  campaignActions,
  CampaignDetails,
  MassTextDetails,
} from "../../redux/slices/campaignSlice";
import useAsyncOnMount from "../useAsyncEffectOnMount";
import useOnUnmount from "../useOnUnmount";
import { getMassTextAttachments } from "../../utils/textingUtils";
import { uniqueVals } from "@markit/common.utils";
import { fetchCampaignDetails } from "../../utils/campaignUtils";

type useLiveUpdatingCampaignProps = {
  initialCampaignId: string;
  userId: string;
};

export const useLiveUpdatingCampaign = (
  props: useLiveUpdatingCampaignProps
) => {
  const { initialCampaignId, userId } = props;
  const dispatch = useDispatch();
  const [fetchedCampaign, setFetchedCampaign] = useState<Campaign>();
  const [fetchedCampaignDetail, setFetchedCampaignDetail] =
    useState<CampaignDetails>();
  const [isError, setIsError] = useState(false);
  const unsubscribeArrayRef = useRef<(() => void)[]>([]);

  const loaded = useMemo(
    () => fetchedCampaign !== undefined && fetchedCampaignDetail !== undefined,
    [fetchedCampaign, fetchedCampaignDetail]
  );

  useOnUnmount(
    (latestDeps) => {
      if (latestDeps[0]) {
        dispatch(campaignActions.modifyCampaign(latestDeps[0] as Campaign));
        dispatch(
          campaignActions.modifyCampaignDetails(
            latestDeps[1] as CampaignDetails
          )
        );
      }
      unsubscribeArrayRef.current.forEach(
        (unsubscribe) => unsubscribe && unsubscribe()
      );
      unsubscribeArrayRef.current = []; // Clear the array after cleanup
    },
    [fetchedCampaign, fetchedCampaignDetail]
  );

  const addCampaignDetail = useCallback((campaignDetail: CampaignDetails) => {
    setFetchedCampaignDetail(campaignDetail);
  }, []);

  const addMassText = useCallback(
    async (massTextDetails: MassTextDetails[]) => {
      setFetchedCampaignDetail((fetchedCampaignDetail) => {
        if (!fetchedCampaignDetail) {
          return undefined;
        }
        const updatedMassTextDetails = uniqueVals(
          [...massTextDetails, ...fetchedCampaignDetail.massTextDetails],
          (text: MassTextDetails) => text.massText.id
        );
        return {
          ...fetchedCampaignDetail,
          massTextDetails: updatedMassTextDetails,
        };
      });
    },
    []
  );

  const removeMassText = useCallback((massTextId: string) => {
    setFetchedCampaignDetail((fetchedCampaignDetail) => {
      if (!fetchedCampaignDetail) {
        return undefined;
      }
      const newMassTextDetails = fetchedCampaignDetail?.massTextDetails.filter(
        (text) => text.massText.id !== massTextId
      );
      return {
        ...fetchedCampaignDetail,
        massTextDetails: newMassTextDetails,
      };
    });
  }, []);

  const fetchMassTextDetails = useCallback(
    async (massText: MassText) => {
      const attachments = await getMassTextAttachments(userId, massText);
      const newMassTextDetail: MassTextDetails = {
        massText: massText,
        attachments: attachments,
      };
      return newMassTextDetail;
    },
    [userId]
  );

  useAsyncOnMount(async () => {
    if (initialCampaignId) {
      const unsubscribeCampaign = onSnapshot(
        getUserCampaignRef(userId, initialCampaignId),
        async (doc) => {
          const campaignData = doc.data();
          if (!campaignData) {
            setIsError(true);
          } else {
            setIsError(false);
            setFetchedCampaign(campaignData);
            const campaignDetails = await fetchCampaignDetails(
              userId,
              campaignData
            );
            addCampaignDetail(campaignDetails);
          }
        }
      );
      unsubscribeArrayRef.current.push(unsubscribeCampaign);

      const massTextsSnap = await getCampaignMassTextsSnap(
        userId,
        initialCampaignId
      );
      const unsubscribeTexts = onSnapshot(massTextsSnap.query, (snapshot) => {
        snapshot.docChanges().forEach(async (message) => {
          const massText = message.doc.data();
          // TODO: massTextDetails gets fetched twice here and in fetchCampaignDetails
          const massTextDetail = await fetchMassTextDetails(massText);
          if (message.type === "added" || message.type === "modified") {
            addMassText([massTextDetail]);
          } else if (message.type === "removed") {
            removeMassText(massText.id);
          }
        });
      });
      unsubscribeArrayRef.current.push(unsubscribeTexts);
    }
  });

  return {
    liveCampaign: fetchedCampaign,
    liveCampaignDetail: fetchedCampaignDetail,
    isLiveCampaignError: isError,
    loaded: loaded,
  };
};
