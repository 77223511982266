import React from "react";
import { AccountData } from "@markit/common.types";
import { truncateString } from "@markit/common.utils";
import { Link } from "react-router-dom";
import { Colors } from "../../utils/colors";
import { Icon } from "@iconify/react";
import { InitialsPic } from "../InitialsPic";

type RenderedUserProps = {
  user: AccountData;
};

export const RenderedUser = (props: RenderedUserProps) => {
  const { user } = props;

  return (
    <Link
      reloadDocument
      style={{ textDecoration: "none", color: "black", cursor: "pointer" }}
      to={`/u/${user.username}`}
      key={user.uid}
    >
      <div className="AlignedRowSpaced">
        <div className="AlignedRow">
          {user.profilePicURL !== "" ? (
            <img
              alt="RenderedUser"
              src={user.profilePicURL}
              width="50px"
              height="50px"
              style={{
                borderRadius: 100,
                marginRight: "14px",
                cursor: "pointer",
              }}
            />
          ) : (
            <div
              style={{
                width: "50px",
                height: "50px",
                borderRadius: 100,
                marginRight: "14px",
                cursor: "pointer",
                backgroundColor: Colors.BLUE2,
                display: "grid",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span style={{ fontWeight: 500 }}>
                <InitialsPic fullName={user.fullName} />
              </span>
            </div>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "7px",
              cursor: "pointer",
            }}
          >
            <span style={{ fontSize: "16px", fontWeight: 600 }}>
              {truncateString(user.fullName, 27, "...")}
            </span>
            <span style={{ fontSize: "14px", color: Colors.GRAY1 }}>
              @{truncateString(user.username, 27, "...")}
            </span>
          </div>
        </div>
        <Icon
          icon={"mdi:chevron-right"}
          height={24}
          color={Colors.GRAY1}
          style={{ marginRight: "14px", cursor: "pointer" }}
        />
      </div>
    </Link>
  );
};
