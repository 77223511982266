import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { getAccountState } from "../../redux/slices/accountSlice";
import StandardBorderedContainer from "../Containers/StandardBorderedContainer";
import { HorizontalDivider } from "../Dividers/HorizontalDivider";
import { EventContentMobile } from "../LandingPage/RenderedEvents";
import { hostingLiveEvents } from "@markit/common.utils";
import { Colors } from "../../utils/colors";
import { getEventState } from "../../redux/slices/eventSlice";
import { Icon } from "@iconify/react";
import EmptyStateButton from "../Buttons/EmptyStateButton";
import { DataGraphContainer } from "./DataGraphContainer";
import EventDashboardAction from "../EventDashboard/EventDashboardAction";
import { useNavigate } from "../../hooks/useNavigate";
import MainActionsDropdownMenu from "../DropdownsAndTabs/MainActionsDropdownMenu";
import { NavigationId } from "../../navigation/AppParamList";
import TextingPanelUsage from "../CreatorDashboard/TextingPanelUsage";

type PeoplePanelProps = {
  shareProfileOnPress: () => void;
  importOnPress: () => void;
};

export const PeoplePanel = (props: PeoplePanelProps) => {
  const { shareProfileOnPress, importOnPress } = props;
  const { accountData } = useSelector(getAccountState).account;
  const { events } = useSelector(getEventState).events;
  const navigate = useNavigate();

  const styles = {
    headerTitle: {
      fontSize: 14,
      fontWeight: 500,
    },
    overallContainerView: {
      width: "100%",
      borderRadius: 20,
      paddingBlock: 14,
      backgroundColor: Colors.WHITE,
      height: 225,
    },
    sectionHeaderText: {
      marginBottom: 14,
      fontSize: 16,
      fontWeight: 600,
      whiteSpace: "nowrap",
      color: Colors.GRAY1,
    },
  };

  const upcomingEvents = useMemo(
    () => hostingLiveEvents(events, accountData.uid, true).slice(0, 3),
    [accountData.uid, events]
  );

  const navigateEventOnPress = useCallback(
    (eventId: string) => {
      navigate(`/e/${eventId}/dashboard`, {
        state: { eventId: eventId },
      });
    },
    [navigate]
  );

  const navigateUpcomingEvents = useCallback(() => {
    navigate("/home/events");
  }, [navigate]);

  return (
    <div className="ColumnNormal" style={{ gap: 40 }}>
      <div className="AlignedRow" style={{ gap: 30, flex: 1 }}>
        <div style={{ flex: 1 }}>
          <MainActionsDropdownMenu
            sourceLocation="home_panel"
            overlayStyles={{ paddingInline: 80 }}
          >
            <EventDashboardAction
              title={"New"}
              iconName={"ion:add-circle"}
              iconSize={31}
              onPress={() => {}}
              visible={true}
              containerWidth={"100%"}
            />
          </MainActionsDropdownMenu>
        </div>
        <div style={{ flex: 1 }}>
          <EventDashboardAction
            title={"Share Profile"}
            iconName={"ion:share"}
            iconSize={31}
            onPress={shareProfileOnPress}
            visible={true}
            containerWidth={"100%"}
          />
        </div>
        <div style={{ flex: 1 }}>
          <EventDashboardAction
            title={"Import Contacts"}
            iconName={"ion:cloud-upload"}
            iconSize={31}
            onPress={importOnPress}
            visible={true}
            containerWidth={"100%"}
          />
        </div>
      </div>
      <TextingPanelUsage />
      <div className="ColumnNormal">
        <div style={styles.sectionHeaderText}>Shortcuts</div>
        <div className="RowNormal" style={{ gap: 30 }}>
          <StandardBorderedContainer
            containerStyles={styles.overallContainerView}
          >
            <div className="ColumnNormalSelect">
              <div
                className="AlignedRowSelect"
                style={{ gap: 7, paddingInline: 14 }}
                onClick={() => navigate(NavigationId.HOME_CONTACTS)}
              >
                <span style={styles.headerTitle}>Your Audience</span>
                <Icon
                  icon="ion:chevron-right"
                  height={12}
                  color={Colors.BLACK}
                />
              </div>
              <HorizontalDivider altMargin={14} />
              <DataGraphContainer />
            </div>
          </StandardBorderedContainer>
          <StandardBorderedContainer
            containerStyles={styles.overallContainerView}
          >
            <div className="ColumnNormal" style={{ position: "relative" }}>
              <div
                onClick={navigateUpcomingEvents}
                className="AlignedRowSelect"
                style={{ gap: 7, paddingInline: 14 }}
              >
                <span style={styles.headerTitle}>Upcoming Events</span>
                <Icon
                  icon="ion:chevron-right"
                  height={12}
                  color={Colors.BLACK}
                />
              </div>
              <HorizontalDivider altMargin={14} />
              {upcomingEvents.length > 0 ? (
                <div
                  className="ColumnNormal"
                  style={{ paddingInline: 14, gap: 10 }}
                >
                  {upcomingEvents.map((event) => (
                    <div
                      onClick={() => navigateEventOnPress(event.id)}
                      key={event.id}
                    >
                      <EventContentMobile
                        event={event}
                        altSize="48px"
                        altFontSize={12}
                      />
                    </div>
                  ))}
                </div>
              ) : (
                <div
                  onClick={navigateUpcomingEvents}
                  className="Centering"
                  style={{ cursor: "pointer" }}
                >
                  <EmptyStateButton
                    title="No Events"
                    icon={
                      <Icon
                        icon="ion:calendar"
                        height={42}
                        color={Colors.GRAY1}
                      />
                    }
                    iconBox={64}
                    containerStyles={{ paddingBlock: 24, cursor: "pointer" }}
                  />
                </div>
              )}
            </div>
          </StandardBorderedContainer>
        </div>
      </div>
    </div>
  );
};
