import { useCallback, useState } from "react";
import {
  getUserAudienceListMembersRef,
  getUserAudienceListMembersSnap,
} from "../../utils/FirebaseUtils";
import { getCountFromServer } from "../../firebase";
import { useDispatch, useSelector } from "react-redux";
import { getAccountState } from "../../redux/slices/accountSlice";
import useAsyncEffect from "../useAsyncEffect";

type useLiveUpdatingListAnalyticsProps = {
  listId: string;
  full: boolean;
};

export const useLiveUpdatingListAnalytics = (
  props: useLiveUpdatingListAnalyticsProps
) => {
  const { listId, full } = props;
  const dispatch = useDispatch();
  const { accountData } = useSelector(getAccountState).account;
  const [liveTotalListContacts, setLiveTotalListContacts] = useState<number>(0);
  const [liveListContactUids, setLiveListContactUids] = useState<string[]>([]);

  const [loaded, setLoaded] = useState(false);

  const reloadCount = useCallback(() => {
    setLoaded(false);
  }, []);

  useAsyncEffect(
    // eslint-disable-next-line react-hooks/exhaustive-deps
    async (_deps, isLatest) => {
      if (!loaded && listId) {
        // total list contacts
        const audienceListMembersRef = getUserAudienceListMembersRef(
          accountData.uid,
          listId
        );

        const snapshot = await getCountFromServer(audienceListMembersRef);
        if (isLatest()) {
          setLiveTotalListContacts(snapshot.data().count);
        }

        if (full) {
          const snap = await getUserAudienceListMembersSnap(
            accountData.uid,
            listId
          );
          if (isLatest()) {
            setLiveListContactUids(snap.docs.map((doc) => doc.data().uid));
          }
        }
        setLoaded(true);
      }
    },
    [accountData.uid, dispatch, full, listId, loaded]
  );

  return {
    liveTotalListContacts,
    liveListContactUids,
    loaded,
    reloadCount,
  };
};
