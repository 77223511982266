import React, { useCallback, useMemo, useState } from "react";
import { getAccountState } from "../../redux/slices/accountSlice";
import { Colors } from "../../utils/colors";
import { Icon } from "@iconify/react";
import { useSelector } from "react-redux";
import { LazyMotion, domAnimation, m } from "framer-motion";
import { useLocation, useSearchParams } from "react-router-dom";
import { NavigationId } from "../../navigation/AppParamList";
import MorePanel from "../../screens/Profile/CreatorPanels/MorePanel";
import { DarkTheme, useTheme } from "../../hooks/useTheme";
import { useNavigate } from "../../hooks/useNavigate";
import MarkitPlusNumberModal from "../Subscription/MarkitPlusNumberModal";
import ShareProfilePopupPanel from "./CreatorProfile/ShareProfilePopupPanel";
import { hasSubscription, isExternalGenericLink } from "@markit/common.utils";
import { getEventState } from "../../redux/slices/eventSlice";
import { getUnreadConversationsQuery } from "../../redux/slices/conversationSlice";
import { onSnapshot } from "../../firebase";
import { VerticalDivider } from "../Dividers/VerticalDivider";
import { ThemeType } from "@markit/common.types";
import useAsyncOnMount from "../../hooks/useAsyncEffectOnMount";

export enum MenuItemLabel {
  HOME = "Home",
  CAMPAIGNS = "Campaigns",
  CONVERSATIONS = "Conversations",
  AUDIENCE = "Audience",
  CONTACTS = "Contacts",
  LISTS = "Lists",
  IMPORT = "Import",
  INTEGRATIONS = "Integrations",
  GROW = "Grow",
  EVENTS = "Events",
  LINKS = "Links",
  SHARE_PROFILE = "Share Profile",
  SHARE_NUMBER = "Share Number",
  MORE = "More",
  BILLING = "Billing",
  PLANS = "Plans",
  TRANSACTIONS = "Transactions",
  TERMSOFUSE = "Terms of Use",
  PRIVACYPOLICY = "Privacy Policy",
}

const SubGrowMenuItems = [
  MenuItemLabel.EVENTS,
  MenuItemLabel.LINKS,
  MenuItemLabel.SHARE_PROFILE,
  MenuItemLabel.SHARE_NUMBER,
];

const SubAudienceMenuItems = [
  MenuItemLabel.CONTACTS,
  MenuItemLabel.LISTS,
  MenuItemLabel.IMPORT,
  MenuItemLabel.INTEGRATIONS,
];

export const homePathTab = (pathname: string) => {
  switch (pathname) {
    case "/home":
    case NavigationId.IMPORT:
    case NavigationId.INTEGRATIONS:
      return MenuItemLabel.HOME;
    case NavigationId.HOME_EVENTS:
    case NavigationId.CREATE:
      return MenuItemLabel.EVENTS;
    case NavigationId.HOME_LINKS:
    case NavigationId.CREATE_LINK:
      return MenuItemLabel.LINKS;
    case NavigationId.HOME_CAMPAIGNS:
      return MenuItemLabel.CAMPAIGNS;
    case NavigationId.HOME_CONVERSATIONS:
      return MenuItemLabel.CONVERSATIONS;
    case NavigationId.HOME_CONTACTS:
      return MenuItemLabel.CONTACTS;
    case NavigationId.HOME_LISTS:
      return MenuItemLabel.LISTS;
    case NavigationId.HOME_IMPORT:
      return MenuItemLabel.IMPORT;
    case NavigationId.HOME_INTEGRATIONS:
      return MenuItemLabel.INTEGRATIONS;
    case NavigationId.HOME_TRANSACTIONS:
      return MenuItemLabel.TRANSACTIONS;
    case NavigationId.HOME_BILLING:
      return MenuItemLabel.BILLING;
    default:
      return MenuItemLabel.EVENTS;
  }
};

interface MenuItem {
  label: MenuItemLabel;
  deselectedIcon: string;
  selectedIcon: string;
  subItems?: MenuItemLabel[];
}

type CreatorModeSidebarProps = {
  morePanelVisible: boolean;
  setMorePanelVisible: (morePanelVisible: boolean) => void;
  eventTheme?: ThemeType;
};

const CreatorModeSidebar = (props: CreatorModeSidebarProps) => {
  const { morePanelVisible, setMorePanelVisible, eventTheme } = props;
  const navigate = useNavigate();
  const { account } = useSelector(getAccountState);
  const { accountData } = account;
  const { events: eventList } = useSelector(getEventState).events;
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const [showGrowItems, setShowGrowItems] = useState(true);
  const [showAudienceItems, setShowAudienceItems] = useState(true);
  const [showShareNumber, setShowShareNumber] = useState(false);
  const [shareProfileOpen, setShareProfileOpen] = useState(false);
  const [unreadMessages, setUnreadMessages] = useState(0);
  const { theme } = useTheme(eventTheme);
  const isDarkTheme = useMemo(() => theme && theme === DarkTheme, [theme]);

  // get the number of unread conversation messages
  useAsyncOnMount(async () => {
    const unreadQuery = await getUnreadConversationsQuery(accountData.uid);
    const unsubscribe = onSnapshot(unreadQuery, (snapshot) => {
      setUnreadMessages(snapshot.size);
    });
    return () => unsubscribe();
  });

  const menuItems: MenuItem[] = useMemo(() => {
    const items = [
      {
        label: MenuItemLabel.HOME,
        deselectedIcon: "mdi:home-variant-outline",
        selectedIcon: "mdi:home-variant",
      },
      {
        label: MenuItemLabel.CAMPAIGNS,
        deselectedIcon: "ion:paper-plane-outline",
        selectedIcon: "ion:paper-plane",
      },
      {
        label: MenuItemLabel.CONVERSATIONS,
        deselectedIcon: "ion:chatbubbles-outline",
        selectedIcon: "ion:chatbubbles",
      },
      {
        label: MenuItemLabel.AUDIENCE,
        deselectedIcon: "ion:people-outline",
        selectedIcon: "ion:people",
        subItems: showAudienceItems ? SubAudienceMenuItems : undefined,
      },
      {
        label: MenuItemLabel.GROW,
        deselectedIcon: "ion:trending-up",
        selectedIcon: "ion:trending-up",
        subItems: showGrowItems ? SubGrowMenuItems : undefined,
      },
      {
        label: MenuItemLabel.MORE,
        deselectedIcon: "mdi:dots-horizontal",
        selectedIcon: "mdi:dots-horizontal",
      },
    ];

    return items;
  }, [showAudienceItems, showGrowItems]);

  const foundHomePathTab = useMemo(() => {
    // check searchParam to check if the createLink path is event
    if (
      pathname.includes(NavigationId.CREATE_LINK) &&
      searchParams.has("event")
    ) {
      return MenuItemLabel.EVENTS;
    }
    // check if on create and if it's a generic link
    if (pathname.includes(NavigationId.CREATE) && searchParams.has("link")) {
      return MenuItemLabel.LINKS;
    }
    // check if on dashboard if it's a generic link or event
    if (pathname.includes("/e/")) {
      const eventId = pathname.split("/")[2];
      const foundEvent = eventList.find((event) => event.id === eventId);
      if (foundEvent) {
        return isExternalGenericLink(foundEvent.eventType)
          ? MenuItemLabel.LINKS
          : MenuItemLabel.EVENTS;
      }
    }
    // check if on import page
    if (pathname.includes("/import")) {
      return MenuItemLabel.IMPORT;
    }
    // check if on integrations page
    if (pathname.includes("/integrations")) {
      return MenuItemLabel.INTEGRATIONS;
    }
    const foundTab = homePathTab(pathname);
    return foundTab;
  }, [eventList, pathname, searchParams]);

  const isSelectedMenuItem = useCallback(
    (item: MenuItemLabel) => {
      return (
        (foundHomePathTab === item && !morePanelVisible) ||
        (morePanelVisible && item === MenuItemLabel.MORE) ||
        (item === MenuItemLabel.MORE &&
          (foundHomePathTab === MenuItemLabel.BILLING ||
            foundHomePathTab === MenuItemLabel.TRANSACTIONS))
      );
    },
    [foundHomePathTab, morePanelVisible]
  );

  const menuItemOnPress = useCallback(
    (item: MenuItemLabel) => {
      if (item === MenuItemLabel.MORE) {
        setMorePanelVisible(!morePanelVisible);
        return;
      }

      switch (item) {
        case MenuItemLabel.HOME:
          navigate("/home");
          break;
        case MenuItemLabel.CAMPAIGNS:
          navigate(NavigationId.HOME_CAMPAIGNS);
          break;
        case MenuItemLabel.CONVERSATIONS:
          navigate(NavigationId.HOME_CONVERSATIONS);
          break;
        case MenuItemLabel.AUDIENCE:
          setShowAudienceItems(!showAudienceItems);
          break;
        case MenuItemLabel.CONTACTS:
          navigate(NavigationId.HOME_CONTACTS);
          break;
        case MenuItemLabel.LISTS:
          navigate(NavigationId.HOME_LISTS);
          break;
        case MenuItemLabel.IMPORT:
          navigate(NavigationId.HOME_IMPORT);
          break;
        case MenuItemLabel.INTEGRATIONS:
          navigate(NavigationId.HOME_INTEGRATIONS);
          break;
        case MenuItemLabel.GROW:
          setShowGrowItems(!showGrowItems);
          break;
        case MenuItemLabel.EVENTS:
          navigate(NavigationId.HOME_EVENTS);
          break;
        case MenuItemLabel.LINKS:
          navigate(NavigationId.HOME_LINKS);
          break;
        case MenuItemLabel.SHARE_PROFILE:
          setShareProfileOpen(true);
          break;
        case MenuItemLabel.SHARE_NUMBER:
          setShowShareNumber(true);
          break;
        case MenuItemLabel.TRANSACTIONS:
          navigate(NavigationId.HOME_TRANSACTIONS);
          break;
        case MenuItemLabel.BILLING:
          navigate(NavigationId.HOME_BILLING);
          break;
        default:
          navigate("/home");
      }
      setMorePanelVisible(false);
    },
    [
      morePanelVisible,
      navigate,
      setMorePanelVisible,
      showAudienceItems,
      showGrowItems,
    ]
  );

  const SidebarItem = useCallback(
    (label: MenuItemLabel, icon?: string) => (
      <m.div
        className="AlignedRowSpaced CreatorModeMenuItemContainer"
        onClick={() => menuItemOnPress(label)}
        style={{
          backgroundColor: isSelectedMenuItem(label)
            ? isDarkTheme
              ? Colors.GRAY7
              : Colors.GRAY6
            : isDarkTheme
            ? Colors.GRAY10
            : Colors.WHITE,
        }}
        whileHover={{
          backgroundColor: isDarkTheme ? Colors.GRAY7 : Colors.GRAY6,
        }}
        transition={{ duration: 0.1 }}
      >
        <div className="AlignedRowSelect" style={{ gap: 14 }}>
          {icon ? (
            <div style={{ cursor: "pointer", paddingTop: 1 }}>
              <Icon
                icon={icon}
                height={20}
                style={{ color: theme?.PrimaryText.color }}
              />
            </div>
          ) : (
            <div style={{ paddingInline: 10 }}>
              <VerticalDivider
                height={41}
                color={
                  isSelectedMenuItem(label)
                    ? theme.PrimaryText.color
                    : isDarkTheme
                    ? theme.DividerColor.borderColor
                    : undefined
                }
              />
            </div>
          )}
          <div className="ColumnNormalSelect" style={{ gap: 4 }}>
            <span className="bodyMedium" style={theme?.PrimaryText}>
              {label}
            </span>
            {label === MenuItemLabel.SHARE_NUMBER &&
            !hasSubscription(accountData) ? (
              <span style={{ fontSize: 12, color: Colors.GRAY2 }}>
                Available with Markit
                <span style={{ color: Colors.BLUE5 }}>+</span>
              </span>
            ) : null}
          </div>
        </div>
        {label === MenuItemLabel.GROW || label === MenuItemLabel.AUDIENCE ? (
          <Icon
            icon={
              (label === MenuItemLabel.GROW && showGrowItems) ||
              (label === MenuItemLabel.AUDIENCE && showAudienceItems)
                ? "mdi-chevron-up"
                : "mdi:chevron-down"
            }
            height={22}
            color={theme?.PrimaryText.color}
          />
        ) : null}
        {label === MenuItemLabel.CONVERSATIONS && unreadMessages > 0 ? (
          <div
            className="Centering"
            style={{
              paddingInline: 6,
              paddingBlock: 4,
              minWidth: 10,
              borderRadius: 100,
              backgroundColor: Colors.RED3,
            }}
          >
            <span style={{ fontSize: 11, color: Colors.WHITE }}>
              {unreadMessages}
            </span>
          </div>
        ) : null}
      </m.div>
    ),
    [
      accountData,
      isDarkTheme,
      isSelectedMenuItem,
      menuItemOnPress,
      showAudienceItems,
      showGrowItems,
      theme.DividerColor.borderColor,
      theme.PrimaryText,
      unreadMessages,
    ]
  );

  const SidebarMenuItems = () => {
    return (
      <LazyMotion features={domAnimation}>
        <div
          className="ColumnNormal HideScrollbar"
          style={{ gap: 7, width: 194 }}
        >
          {menuItems.map((item) => {
            return (
              <div className="ColumnNormal" key={item.label}>
                {SidebarItem(
                  item.label,
                  isSelectedMenuItem(item.label)
                    ? item.selectedIcon
                    : item.deselectedIcon
                )}
                {item.subItems ? (
                  <div className="ColumnNormal">
                    {item.subItems.map((subItem) => SidebarItem(subItem))}
                  </div>
                ) : null}
              </div>
            );
          })}
        </div>
      </LazyMotion>
    );
  };

  return (
    <>
      <div
        className="CreatorModeLeftPanel AlignedRow"
        style={{
          backgroundColor: isDarkTheme ? Colors.GRAY10 : Colors.WHITE,
          borderRight: isDarkTheme
            ? "0.5px solid #3d3d3d"
            : `0.5px solid ${Colors.GRAY11}`,
          paddingBlock: 24,
          paddingInline: 14,
        }}
      >
        <SidebarMenuItems />
        <MorePanel
          morePanelVisible={morePanelVisible}
          setMorePanelVisible={setMorePanelVisible}
          theme={theme}
        />
      </div>
      <MarkitPlusNumberModal
        isVisible={showShareNumber}
        setIsVisible={setShowShareNumber}
      />
      {shareProfileOpen ? (
        <ShareProfilePopupPanel
          isVisible={shareProfileOpen}
          setIsVisible={setShareProfileOpen}
        />
      ) : null}
    </>
  );
};

export default CreatorModeSidebar;
