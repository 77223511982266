import { AccountData, Event, VerificationState } from "@markit/common.types";
import { ThemeProvider } from "@mui/material";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  useContext,
} from "react";
import { API } from "../../API";
import { themeMui } from "../FullEvent/FullEventInputForm";
import { MixpanelContext } from "../../context/AnalyticsService";
import { getUserData, getPhoneNumberSnap } from "../../utils/FirebaseUtils";
import { Colors } from "../../utils/colors";
import { isEventExternalLink, isEventTicketsPaid } from "@markit/common.utils";
import { useTheme } from "../../hooks/useTheme";
import {
  checkIfPhoneNumberIsUnsubscribed,
  checkIfUserIsSubscribed,
  checkIfUserIsUnsubscribedMarkit,
} from "../../utils/userUtils";
import RectangleButton from "../Buttons/RectangleButton";
import VerificationCodeTextField from "../TextFields/VerificationCodeTextField";
import { firebaseAuth } from "../../firebase";
import {
  RecaptchaVerifier,
  signInWithPhoneNumber,
  PhoneAuthProvider,
  signInWithCredential,
} from "firebase/auth";
import { useLogin } from "../../hooks/useLogin";
import { Icon } from "@iconify/react";
import OptedOutBanner from "./OptedOutBanner";
import CompliancePhoneOptIn, {
  displaySubaccountComplianceOptIn,
} from "../Compliance/CompliancePhoneOptIn";

type VerificationCodeButtonProp = {
  host?: AccountData;
  event?: Event;
  signIn: boolean;
  buttonText: string;
  tempFullName?: string;
  tempPhoneNumber: string;
  setNewUid?: (newUid: string) => void;
  verificationState: VerificationState;
  setVerificationState: (verificationState: VerificationState) => void;
  optedOut: boolean;
  setOptedOut: (optedOut: boolean) => void;
  optedOutCreator?: boolean;
  setOptedOutCreator?: (optedOutCreator: boolean) => void;
  showCodeInputError: boolean;
  setShowCodeInputError: (showCodeInputError: boolean) => void;
  showError: () => boolean;
  afterVerifiedAction?: () => Promise<void>;
  callbackOnVerify?: () => void;
  processing?: boolean;
  isFollowForm?: boolean;
  setAlreadyFollowing?: (alreadyFollowing: boolean) => void;
  alreadyFollowingUid?: string;
  disableVerifyNumber?: boolean;
};

// IMPORTANT (jonathan): Anywhere new this component is used, need to add RecaptchaVerifierWrapper top level for the recatcha verifier
// Put it in a place where the verifier won't show up on the screen, so ideally as high level as possible. But also needs to be something
// that will remount before every attempt to verify because you can't reuse the same verifier div more than once
// Currently exists in:
// 1) CheckoutContainer
// 2) ProfilePageFollowForm
// 3) FullEventExternal
// 4) LoginWelcome
const VerificationCodeButton = (props: VerificationCodeButtonProp) => {
  const {
    host,
    event,
    signIn,
    buttonText,
    tempFullName,
    tempPhoneNumber,
    setNewUid,
    verificationState,
    setVerificationState,
    optedOut,
    setOptedOut,
    optedOutCreator,
    setOptedOutCreator,
    showCodeInputError,
    setShowCodeInputError,
    showError,
    afterVerifiedAction,
    callbackOnVerify,
    processing,
    setAlreadyFollowing,
    alreadyFollowingUid,
    disableVerifyNumber,
  } = props;
  const mixpanel = useContext(MixpanelContext);
  const { theme } = useTheme();
  const { loginWithCredential } = useLogin();

  const [loading, setLoading] = useState(false);
  const [verificationCode, setVerificationCode] = useState<string[]>(
    Array(6).fill("")
  );
  const [sendingVerificationCode, setSendingVerificationCode] = useState(false);
  const [defaultToMarkitOTP, setDefaultToMarkitOTP] = useState(false);
  const [verificationId, setVerificationId] = useState("");
  const [optInChecked, setOptInChecked] = useState(true);

  const ref0 = useRef<HTMLElement>();
  const ref1 = useRef<HTMLElement>();
  const ref2 = useRef<HTMLElement>();
  const ref3 = useRef<HTMLElement>();
  const ref4 = useRef<HTMLElement>();
  const ref5 = useRef<HTMLElement>();
  const refs = useMemo(() => [ref0, ref1, ref2, ref3, ref4, ref5], []);

  const disableVerifyNumberButton = useMemo(
    () => sendingVerificationCode || disableVerifyNumber || !optInChecked,
    [disableVerifyNumber, optInChecked, sendingVerificationCode]
  );

  const sendMarkitOTP = useCallback(async () => {
    API.text
      .sendVerificationCode({ toPhoneNumber: tempPhoneNumber })
      .then(() => {
        setVerificationState(VerificationState.VERIFYING);
        setSendingVerificationCode(false);
      })
      .catch((err) => {
        alert("Error: " + err.message);
        console.log(err.message);
        setSendingVerificationCode(false);
      });
  }, [setVerificationState, tempPhoneNumber]);

  const sendVerificationCode = useCallback(async () => {
    if (showError()) {
      return;
    }

    let returnEarly = false;
    // Check if phone number is unsubscribed from markit number or creator
    // Even if using firebase OTP, we need them opted in so they can get confirmation texts
    const isUnsubscribedMarkit = await checkIfUserIsUnsubscribedMarkit(
      tempPhoneNumber
    );
    if (isUnsubscribedMarkit) {
      setOptedOut(isUnsubscribedMarkit);
      setVerificationState(VerificationState.VERIFYING);
      setSendingVerificationCode(false);
      returnEarly = true;
    }

    if (host && setOptedOutCreator) {
      const isUnsubscribed = await checkIfPhoneNumberIsUnsubscribed(
        tempPhoneNumber,
        host.uid
      );
      if (isUnsubscribed) {
        setOptedOutCreator(isUnsubscribed);
        setVerificationState(VerificationState.VERIFYING);
        returnEarly = true;
      }
    }
    if (returnEarly) {
      return;
    }

    setSendingVerificationCode(true);

    // this will only happen when we're resending the verification code
    if (defaultToMarkitOTP) {
      sendMarkitOTP();
      return;
    }

    // initialize recaptcha verifier
    window.recaptchaVerifier = new RecaptchaVerifier(
      "sign-in-button",
      {
        size: "invisible",
        callback: (response: any) => {
          console.log("Successfully sent OTP");
        },
      },
      firebaseAuth
    );
    await window.recaptchaVerifier.verify();

    // mixpanel logs and checks
    if (mixpanel) {
      if (signIn) {
        mixpanel.track("Webapp: Send Verification Code For Login", {
          customer_phone: tempPhoneNumber,
        });
      } else {
        if (event) {
          if (event.locationBasedRSVP) {
            alert("This event requires you to download the app");
            setSendingVerificationCode(false);
            return;
          }
          mixpanel.track("Webapp: Send Verification Code", {
            event_id: event.id,
            event_type: isEventTicketsPaid(event) ? "paid" : "free",
            customer_phone: tempPhoneNumber,
            link_type: event.eventType,
          });
        } else {
          mixpanel.track("Webapp: Send Verification Code For Follow", {
            customer_phone: tempPhoneNumber,
          });
        }
      }
    }

    // First attempt to send OTP through Firebase
    signInWithPhoneNumber(
      firebaseAuth,
      tempPhoneNumber,
      window.recaptchaVerifier
    )
      .then(async (confirmationResult) => {
        const phoneNumberSnap = await getPhoneNumberSnap(tempPhoneNumber);
        // mixpanel track if for signing up, not for login verification code
        if (signIn && !phoneNumberSnap.exists()) {
          mixpanel.track("Webapp: Start User Sign Up", {
            customer_phone: tempPhoneNumber,
          });
        }
        setVerificationState(VerificationState.VERIFYING);
        setSendingVerificationCode(false);
        setVerificationId(confirmationResult.verificationId);
      })
      .catch(async (err) => {
        // if sign in, this should fail and show too many requests
        if (signIn) {
          console.log(err.message);
          alert("Too many attempts, please try again in one hour.");
          setSendingVerificationCode(false);
          window.location.reload();
          return;
        }

        // if Firebase OTP fails, try sending OTP through Markit
        setDefaultToMarkitOTP(true);

        sendMarkitOTP();
      });
  }, [
    showError,
    host,
    setOptedOutCreator,
    tempPhoneNumber,
    setVerificationState,
    defaultToMarkitOTP,
    mixpanel,
    setOptedOut,
    sendMarkitOTP,
    signIn,
    event,
  ]);

  const verifyPhoneNumber = useCallback(async () => {
    setLoading(true);
    let error = false;
    let uid = "";

    if (verificationCode.filter((el) => el !== undefined).length !== 6) {
      setShowCodeInputError(true);
      setLoading(false);
      alert("Verification Code is Incorrect");
      return;
    }

    const finalCode = verificationCode.join("");
    // verify with Markit OTP
    if (defaultToMarkitOTP) {
      const response = await API.text
        .verifyCode({
          phoneNumber: tempPhoneNumber,
          code: finalCode,
        })
        .catch((err: any) => {
          setShowCodeInputError(true);
          setLoading(false);
          alert("Verification Code is Incorrect");
          console.log(err.message);
          error = true;
        });
      uid = response?.uid ?? "";
    } else {
      // Verify with Firebase OTP
      if (verificationId) {
        const credential = PhoneAuthProvider.credential(
          verificationId,
          finalCode
        );
        await API.user
          .createPhoneAuthUser({ phoneNumber: tempPhoneNumber })
          .then(async () => {
            await signInWithCredential(firebaseAuth, credential)
              .then(async (result) => {
                uid = result.user.uid;
                await loginWithCredential(result, signIn, tempFullName);
              })
              .catch((err) => {
                alert("Verification Code is Incorrect");
                console.error("Verification Code Error: " + err.message);
                setShowCodeInputError(true);
                setLoading(false);
                error = true;
              });
          });
      } else {
        setShowCodeInputError(true);
        setLoading(false);
        alert("Error: No Verification Code");
        return;
      }
    }

    // Can't return out of catch errors, so made a error flag that we set to true if an error occurred in one of the catches
    if (error) {
      return;
    }
    if (uid !== undefined && setNewUid) {
      setNewUid(uid);
      mixpanel.identify(uid);
    }

    // for profile page follow flow: check if user is already following user
    if (uid && setAlreadyFollowing) {
      const userData = await getUserData(uid);
      if (userData && alreadyFollowingUid) {
        const checkFollowing = await checkIfUserIsSubscribed(
          uid,
          alreadyFollowingUid
        );
        setAlreadyFollowing(checkFollowing);
      }
    }

    if (mixpanel) {
      if (event) {
        mixpanel.track("Webapp: Successful Verify", {
          event_id: event.id,
          event_type: isEventTicketsPaid(event) ? "paid" : "free",
          customer_phone: tempPhoneNumber,
          link_type: event.eventType,
        });
      } else if (!signIn) {
        mixpanel.track("Webapp: Successful Verify For Follow", {
          customer_phone: tempPhoneNumber,
        });
      }
      mixpanel.track("Webapp: Agreed to Terms", {
        event_id: event ? event.id : "",
        signIn: signIn,
        customer_phone: tempPhoneNumber,
      });
    }

    setVerificationState(VerificationState.VERIFIED);
    setLoading(false);

    // If defined, apply extra callback on verify press
    if (callbackOnVerify) {
      callbackOnVerify();
    }
  }, [
    alreadyFollowingUid,
    callbackOnVerify,
    defaultToMarkitOTP,
    event,
    loginWithCredential,
    mixpanel,
    setAlreadyFollowing,
    setNewUid,
    setShowCodeInputError,
    setVerificationState,
    signIn,
    tempFullName,
    tempPhoneNumber,
    verificationCode,
    verificationId,
  ]);

  const reload = useCallback(async () => {
    window.location.reload();
  }, []);

  useEffect(() => {
    if (afterVerifiedAction) {
      if (verificationState === VerificationState.VERIFIED) {
        afterVerifiedAction();
      }
    }
  }, [afterVerifiedAction, verificationCode, verificationState]);

  if (verificationState === VerificationState.UNVERIFIED) {
    return (
      <ThemeProvider theme={themeMui}>
        {/** For when an acount is being converted to subaccount and phone number gets moved */}
        {/* {event?.createdBy !== "WSXfSxoVsUWf4KWHDBVCB1UEi3t2" &&
        hostFullName !== "LA ROB" ? ( */}
        <RectangleButton
          buttonLabel={<span>{buttonText}</span>}
          onPress={sendVerificationCode}
          theme={theme}
          altPaddingVert={12}
          disabled={disableVerifyNumberButton}
          loading={sendingVerificationCode}
        />
        {/* ) : null} */}
        {/* <p
          style={{{sendingVerificationCode ? "Sending" : "Send Verification Code"}
            fontSize: 12,
            textAlign: "center",
            marginInline: 20,
            color: "#929292",
          }}
        >
          {"We will send a verification code to this number."}
          <br />
          {"Phone number secured through Markit. "}
          <TermsLink href={MARKIT_ATTENDEE_FAQ}>Learn More</TermsLink>
          {isFollowForm && (
            <>
              , <br /> and our{" "}
              <TermsLink href={MARKIT_TERMS_OF_USE}>Terms of Use</TermsLink>,{" "}
              <TermsLink href={MARKIT_PRIVACY_POLICY}>Privacy Policy</TermsLink>
            </>
          )}
        </p> */}
        {/* {!signIn ? (
          <p
            style={{
              fontSize: 12,
              fontWeight: "500",
              textAlign: "center",
              marginInline: 20,
              color: "#929292",
            }}
          >
            <span
              style={{ color: Colors.RED1, fontWeight: "600", fontSize: 14 }}
            >
              T-Mobile users!
            </span>{" "}
            Due to an outage, sign in at the top right of the screen using your
            phone number then return to{" "}
            {event ? "the event page to get tickets" : "this page to subscribe"}
          </p>
        ) : null} */}
      </ThemeProvider>
    );
  } else if (verificationState === VerificationState.VERIFYING) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {optedOut || optedOutCreator ? (
          <OptedOutBanner
            host={host}
            optedOut={optedOut}
            optedOutCreator={optedOutCreator}
          />
        ) : (
          <>
            {signIn ? (
              <div
                className="LargePopupPanelClose"
                onClick={reload}
                style={{ marginBottom: 14 }}
              >
                <Icon
                  icon="ion:chevron-back"
                  height={24}
                  style={{
                    color: Colors.GRAY3,
                  }}
                />
              </div>
            ) : null}
            <span
              className="LoginHeaderText"
              style={{
                ...theme.PrimaryText,
              }}
            >
              Enter Code
            </span>
            <div style={{ paddingTop: 4, paddingBottom: 14 }}>
              <span className="LoginHeaderTextDescription">
                {`We sent a code to ${tempPhoneNumber}. Enter below to continue.`}
              </span>
            </div>
            <div className="textFieldDiv" style={{ alignSelf: "center" }}>
              {Array.from(Array(6).keys()).map((elem, index) => {
                return (
                  <div key={index}>
                    <VerificationCodeTextField
                      index={index}
                      elem={elem}
                      showCodeInputError={showCodeInputError}
                      setShowCodeInputError={setShowCodeInputError}
                      verificationCode={verificationCode}
                      setVerificationCode={setVerificationCode}
                      refs={refs}
                    />
                  </div>
                );
              })}
            </div>
            <div className="ColumnNormal" style={{ gap: 14 }}>
              <div style={{ paddingTop: 10 }}>
                <span style={{ color: Colors.GRAY2, fontSize: 12 }}>
                  {"Didn't receive a code? "}
                </span>
                <span
                  style={{
                    ...theme.LinkColor,
                    fontSize: 12,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (!defaultToMarkitOTP) {
                      reload();
                    } else {
                      sendVerificationCode();
                      alert("Resent!");
                    }
                  }}
                >
                  Resend code
                </span>
              </div>
              {host && !displaySubaccountComplianceOptIn(host.uid) ? (
                <CompliancePhoneOptIn
                  isChecked={optInChecked}
                  setIsChecked={setOptInChecked}
                  hostUid={host.uid}
                  hostName={host.fullName}
                />
              ) : null}
              <RectangleButton
                buttonLabel={<span>I Agree</span>}
                onPress={verifyPhoneNumber}
                theme={theme}
                altPaddingVert={12}
                disabled={loading}
                loading={loading}
              />
            </div>
          </>
        )}
      </div>
    );
  } else {
    // once you're verified, this is what else can show
    if (optedOut || optedOutCreator) {
      return (
        <OptedOutBanner
          host={host}
          optedOut={optedOut}
          optedOutCreator={optedOutCreator}
        />
      );
    } else if (
      event &&
      isEventExternalLink(event.eventType) &&
      callbackOnVerify
    ) {
      return (
        <RectangleButton
          buttonLabel={<span>Continue</span>}
          onPress={callbackOnVerify}
          theme={theme}
          altPaddingVert={12}
          disabled={processing}
          loading={processing}
        />
      );
    } else {
      return <></>;
    }
  }
};

export default VerificationCodeButton;
