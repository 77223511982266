import { useCallback, useMemo, useState } from "react";
import { Campaign, SubSelectionType } from "@markit/common.types";
import {
  GetDateWithTime,
  handlePluralString,
  isEventExternalLink,
  massTextMessageConverter,
} from "@markit/common.utils";
import { useTheme } from "../../../hooks/useTheme";
import { Colors } from "../../../utils/colors";
import StandardBorderedContainer from "../../Containers/StandardBorderedContainer";
import ButtonWithDropdown from "../../Buttons/ButtonWithDropdown";
import { DropdownMenuItem } from "../../DropdownMenu";
import { useNavigate } from "react-router-dom";
import CampaignTypeItem from "../../CreatorDashboard/Campaigns/Items/CampaignTypeItem";
import CampaignRenameModal from "../../CreatorDashboard/Campaigns/Items/CampaignRenameModal";
import { HorizontalDivider } from "../../Dividers/HorizontalDivider";
import { ModifiedTextMessageDisplay } from "../ModifiedTextMessageDisplay";
import { useSelector } from "react-redux";
import { getEventState } from "../../../redux/slices/eventSlice";
import { CampaignDetails } from "../../../redux/slices/campaignSlice";
import { getCampaignLinkAnalytics } from "../../../utils/trackingUtils";
import useAsyncOnMount from "../../../hooks/useAsyncEffectOnMount";
import CampaignStatusLabel from "../../CreatorDashboard/Campaigns/Items/CampaignStatusLabel";
import { displayTextAnalytics } from "../../../utils/campaignUtils";

export const CAMPAIGN_ITEM_FLEX_SECTIONS = [4, 2, 2, 3, 8];

type CampaignItemPreviewProps = {
  campaignItem: Campaign;
  campaignDetail: CampaignDetails | undefined;
  newMassTextOnPress: (campaignId?: string) => void;
  setShowDeleteCampaign: (showDeleteCampaign: Campaign) => void;
};

export const CampaignItemPreview = ({
  campaignItem,
  campaignDetail,
  newMassTextOnPress,
  setShowDeleteCampaign,
}: CampaignItemPreviewProps) => {
  const { theme } = useTheme();
  const navigate = useNavigate();
  const { events } = useSelector(getEventState).events;
  const [showDropdown, setShowDropdown] = useState(false);
  const [showRenameModal, setShowRenameModal] = useState(false);
  const [numTotalClicks, setNumTotalClicks] = useState(0);
  const [numTotalConversions, setNumTotalConversions] = useState(0);
  const [loadingAnalytics, setLoadingAnalytics] = useState(true);

  const styles = {
    itemBorder: { border: `1px solid ${theme.SubText.color}` },
  };

  useAsyncOnMount(async () => {
    if (campaignDetail) {
      const { totalOpens, totalConversions } = await getCampaignLinkAnalytics(
        campaignDetail.massTextDetails,
        campaignItem
      );
      setNumTotalClicks(totalOpens);
      setNumTotalConversions(totalConversions);
    }
    setLoadingAnalytics(false);
  });

  const massTexts = useMemo(
    () =>
      campaignDetail
        ? campaignDetail.massTextDetails.map((detail) => detail.massText)
        : [],
    [campaignDetail]
  );

  const initialMassTextDetail = useMemo(
    () =>
      campaignDetail?.massTextDetails.find(
        (text) => text.massText.id === campaignItem.initialTextId
      ),
    [campaignDetail?.massTextDetails, campaignItem.initialTextId]
  );

  // Scheduled campaign status if there is a text in the campaign that is not yet queued and is scheduled
  const scheduledCampaign = useMemo(
    () => massTexts && massTexts.some((text) => !text.queued && text.scheduled),
    [massTexts]
  );

  const nonSingleSentCampaign = useMemo(
    () => massTexts.length > 1 || scheduledCampaign,
    [massTexts.length, scheduledCampaign]
  );

  const navigateCampaignItem = useCallback(
    () =>
      navigate(`/c/${campaignItem.id}`, {
        state: { campaignId: campaignItem.id },
      }),
    [campaignItem.id, navigate]
  );

  const dropdownItems: DropdownMenuItem[] = useMemo(
    () => [
      {
        title: "View",
        icon: "ion:arrow-forward",
        key: "view",
        onPress: navigateCampaignItem,
      },
      {
        title: "Rename",
        icon: "ion:pencil",
        key: "rename",
        onPress: () => {
          setShowRenameModal(true);
          setShowDropdown(false);
        },
      },
      {
        title: "Delete",
        icon: "ion:trash",
        key: "delete",
        onPress: () => {
          setShowDeleteCampaign(campaignItem);
          setShowDropdown(false);
        },
        isDestructive: true,
      },
    ],
    [campaignItem, navigateCampaignItem, setShowDeleteCampaign]
  );

  const textNumRecipients = useMemo(() => {
    const subSelections = initialMassTextDetail
      ? initialMassTextDetail.massText.subSelectionIds
      : [];
    return subSelections.length > 0
      ? subSelections.some(
          (category) => category.type === SubSelectionType.ALL_FOLLOWERS
        )
        ? "All Followers"
        : `${subSelections.length} ${
            subSelections.length !== 1 ? "Categories" : "Category"
          }`
      : campaignDetail && campaignDetail.numRecipients > 0
      ? campaignDetail.numRecipients
      : "--";
  }, [campaignDetail, initialMassTextDetail]);

  const showTextAnalytics = useMemo(() => {
    const initialAttachments = initialMassTextDetail?.attachments ?? [];
    return displayTextAnalytics(initialAttachments);
  }, [initialMassTextDetail?.attachments]);

  // TODO (Peter): Will remove this once we start tracking conversions on external events
  const showTextConversions = useMemo(
    () =>
      campaignDetail?.massTextDetails.some((detail) =>
        detail.attachments.some((attachment) => {
          const foundEvent = events.find(
            (event) => event.id === attachment.eventId
          );
          return foundEvent && !isEventExternalLink(foundEvent.eventType);
        })
      ),
    [campaignDetail?.massTextDetails, events]
  );

  if (!campaignDetail || !initialMassTextDetail) {
    return null;
  }

  return (
    <>
      <div className="AlignedRow" style={{ height: 64 }}>
        <div
          className="ColumnNormalSelect"
          onClick={navigateCampaignItem}
          style={{ flex: CAMPAIGN_ITEM_FLEX_SECTIONS[0], gap: 7 }}
        >
          <span className="AboutSubtitle" style={{ fontSize: 14 }}>
            {campaignItem.title}
          </span>
          <CampaignTypeItem numMassTexts={massTexts.length} />
          <span className="smallBodySubtext">
            {!nonSingleSentCampaign ? "Sent:" : "Updated:"}{" "}
            {GetDateWithTime(campaignItem.updatedAt, true)}
          </span>
        </div>
        <div style={{ flex: CAMPAIGN_ITEM_FLEX_SECTIONS[1] }}>
          <CampaignStatusLabel
            massTexts={massTexts}
            initialMassTextDetail={initialMassTextDetail}
          />
        </div>
        <div style={{ flex: CAMPAIGN_ITEM_FLEX_SECTIONS[2] }}>
          <span
            style={{
              fontSize: 14,
              color: theme.PrimaryText.color,
              paddingLeft: 14,
            }}
          >
            {textNumRecipients}
          </span>
        </div>
        <div
          className="ColumnNormal"
          style={{ flex: CAMPAIGN_ITEM_FLEX_SECTIONS[3] }}
        >
          <StandardBorderedContainer
            containerStyles={{
              borderRadius: 8,
              paddingInline: 9,
              paddingBlock: 7,
              backgroundColor: Colors.WHITE1,
              width: "fit-content",
              marginLeft: 14,
              ...styles.itemBorder,
            }}
          >
            {showTextAnalytics ? (
              <div className="ColumnNormal" style={{ gap: 2 }}>
                <span style={{ fontSize: 14, color: theme.PrimaryText.color }}>
                  {loadingAnalytics ? "--" : numTotalClicks}{" "}
                  <span style={theme.SubText}>
                    {handlePluralString("Click", numTotalClicks)}
                  </span>
                </span>
                {showTextConversions ? (
                  <span
                    style={{ fontSize: 14, color: theme.PrimaryText.color }}
                  >
                    {loadingAnalytics ? "--" : numTotalConversions}{" "}
                    <span style={theme.SubText}>
                      {handlePluralString("Conversion", numTotalConversions)}
                    </span>
                  </span>
                ) : null}
              </div>
            ) : (
              <div className="ColumnNormal">
                <span className="bodySubtext">No Links</span>
              </div>
            )}
          </StandardBorderedContainer>
        </div>
        <div
          className="AlignedRowSpaced"
          style={{ flex: CAMPAIGN_ITEM_FLEX_SECTIONS[4], gap: 24 }}
        >
          <span
            className="TwoLineText"
            style={{
              fontSize: 14,
              color: theme.SubText.color,
              overflowWrap: "anywhere",
              paddingLeft: 14,
            }}
          >
            <ModifiedTextMessageDisplay
              message={
                massTexts.length > 1
                  ? massTexts.length + " Texts"
                  : massTextMessageConverter(
                      initialMassTextDetail.massText.message,
                      initialMassTextDetail.attachments
                    )
              }
            />
          </span>
          <ButtonWithDropdown
            buttonLabel={nonSingleSentCampaign ? "View" : "Follow Up"}
            textColor={nonSingleSentCampaign ? Colors.BLACK : Colors.BLUE5}
            isOpenDropdown={showDropdown}
            setIsOpenDropdown={setShowDropdown}
            onPress={() =>
              nonSingleSentCampaign
                ? navigateCampaignItem()
                : newMassTextOnPress(campaignItem.id)
            }
            dropdownItems={dropdownItems}
            containerStyles={styles.itemBorder}
          />
        </div>
      </div>
      <HorizontalDivider altMargin={14} />
      {showRenameModal ? (
        <CampaignRenameModal
          campaign={campaignItem}
          showModal={showRenameModal}
          setShowModal={setShowRenameModal}
        />
      ) : null}
    </>
  );
};
