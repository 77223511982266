import React, { memo, useCallback } from "react";
import "../css/DefaultLandingPage.css";
import "../css/GlobalStyles.css";
import TopHeader from "../components/TopHeader";
import Footer from "../components/Footer";
import { useOnMount } from "../hooks/useOnMount";
import { useSelector, useDispatch } from "react-redux";
import { LoginState, getAccountState } from "../redux/slices/accountSlice";
import landingPageCover from "../assets/LandingPageCover.png";
import BlackHoverButton from "../components/Buttons/BlackHoverButton";
import { Colors } from "../utils/colors";
import MarkitHeaderLogo from "../components/MarkitHeaderLogo";
import { isDesktop } from "react-device-detect";
import { MARKIT_WEBSITE } from "@markit/common.utils";
import { useNavigate } from "../hooks/useNavigate";
import { Icon } from "@iconify/react";
import { DataLoaders } from "../redux/slices/dataSlice";

const DefaultLandingPage = memo(function DefaultLandingPageFn() {
  const { account } = useSelector(getAccountState);
  const { accountData, loggedIn } = account;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const styles = {
    landingPageContainer: {
      alignItems: "flex-start",
      gap: 20,
      marginLeft: 270,
      marginTop: 100,
      marginBottom: 150,
    },
  };

  useOnMount(() => {
    if (loggedIn === LoginState.LOGGED_IN && accountData.inCreatorMode) {
      navigate("/home");
    }
  });

  const getStartedOnPress = useCallback(() => {
    if (loggedIn === LoginState.LOGGED_IN) {
      dispatch(DataLoaders.user(accountData.uid));
      navigate(`/u/${accountData.username}`, {
        state: { username: accountData.username },
      });
    } else {
      navigate("/welcome");
    }
  }, [accountData.uid, accountData.username, dispatch, loggedIn, navigate]);

  const LearnMoreButton = ({ padding }: { padding: number }) => (
    <a
      href={MARKIT_WEBSITE}
      target="_blank"
      rel="noreferrer"
      style={{
        paddingInline: 14,
        paddingBlock: padding,
        borderColor: Colors.GRAY2,
        border: "1px solid #b9b9b9",
        borderRadius: 100,
        color: Colors.BLACK,
        fontSize: 14,
        whiteSpace: "nowrap",
      }}
    >
      Learn More
    </a>
  );

  return (
    <div style={{ backgroundColor: Colors.WHITE1, overflowX: "hidden" }}>
      <TopHeader hideCreate />
      {isDesktop ? (
        // Desktop view
        <div
          className="landingPageContainer"
          style={{
            ...styles.landingPageContainer,
            flexWrap: "wrap",
            position: "relative",
            zIndex: 1,
          }}
        >
          <div
            className="ColumnNormal"
            style={{ gap: 60, flex: 1, minWidth: 555 }}
          >
            <div className="ColumnNormal" style={{ gap: 30 }}>
              <MarkitHeaderLogo forceColor="gray" />
              <span className="landingPageTitle">
                Reach your
                <br />
                audience
                <br />
                with <span className="gradient-text">texting.</span>
              </span>
              <div className="subtitleText">
                Join hundreds of brands and businesses,
                <br />
                and start texting your audience with Markit.
              </div>
            </div>
            <div className="ColumnNormal" style={{ gap: 20 }}>
              <div className="AlignedRow" style={{ gap: 14 }}>
                <div>
                  <BlackHoverButton
                    title="Get Started"
                    onPress={getStartedOnPress}
                    altTextColor={Colors.WHITE}
                    altBackgroundColor={Colors.BLACK}
                    containerStyles={{
                      whiteSpace: "nowrap",
                      padding: 14,
                      borderRadius: 100,
                    }}
                    iconName={"mdi:chevron-right"}
                    iconSize={18}
                  />
                </div>
                <LearnMoreButton padding={14} />
              </div>
              <div className="AlignedRow" style={{ gap: 10 }}>
                <span className="bodySubtext">Looking for someone?</span>
                <div
                  className="AlignedRowSelect"
                  style={{ gap: 3 }}
                  onClick={() => navigate("/explore")}
                >
                  <span className="bodyMedium">Try Explore</span>
                  <Icon
                    icon={"ion:compass"}
                    height={14}
                    style={{ paddingTop: 2 }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="hero-image">
            <img
              src={landingPageCover}
              alt="Landing page cover"
              width={1000}
              style={{ minWidth: 700 }}
            />
          </div>
        </div>
      ) : (
        // Mobile View
        <div
          className="ColumnNormal"
          style={{ overflowX: "hidden", paddingTop: 40, position: "relative" }}
        >
          <div className="ColumnNormal" style={{ paddingInline: 32, gap: 24 }}>
            <span style={{ fontSize: 48, fontWeight: 600, lineHeight: 1 }}>
              Reach your
              <br />
              audience
              <br />
              with <span className="gradient-text">texting.</span>
            </span>
            <div className="bodySubtext">
              Join hundreds of brands and businesses,
              <br />
              and start texting your audience with Markit.
            </div>
            <div className="AlignedRow" style={{ gap: 10 }}>
              <div>
                <BlackHoverButton
                  title="Explore"
                  onPress={() => navigate("/explore")}
                  altTextColor={Colors.WHITE}
                  altBackgroundColor={Colors.BLACK}
                  containerStyles={{
                    whiteSpace: "nowrap",
                    padding: 12,
                    borderRadius: 100,
                  }}
                  iconName={"ion:compass"}
                  iconSize={16}
                />
              </div>
              <LearnMoreButton padding={10} />
            </div>
          </div>
          <div style={{ paddingTop: 30 }}>
            <img
              src={landingPageCover}
              alt="Landing page cover"
              width={573}
              height="auto"
              style={{ marginLeft: 32 }}
            />
          </div>
        </div>
      )}
      <div style={{ position: "relative", zIndex: 10 }}>
        <Footer />
      </div>
    </div>
  );
});

export default DefaultLandingPage;
