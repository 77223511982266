import { Icon } from "@iconify/react";
import { Colors } from "../../utils/colors";
import RectangleButton from "../Buttons/RectangleButton";
import React, { useCallback } from "react";
import { useNavigate } from "../../hooks/useNavigate";

type LoginOnboardingAccountTypeProps = { individualOnPress: () => void };

const LoginOnboardingAccountType = (props: LoginOnboardingAccountTypeProps) => {
  const { individualOnPress } = props;
  const navigate = useNavigate();

  const organizationOnPress = useCallback(() => {
    navigate("/welcome", { state: { defaultEmail: true } });
  }, [navigate]);

  return (
    <div className="ColumnNormal" style={{ gap: 24 }}>
      <div className="ColumnNormal">
        <span className="LoginHeaderText" style={{ color: Colors.BLACK }}>
          Are you an individual or organization?
        </span>
        <div style={{ paddingTop: 7 }}>
          <span className="LoginHeaderTextDescription">
            This helps us tailor your Markit experience to fit you.
          </span>
        </div>
      </div>
      <div className="ColumnNormal" style={{ gap: 10 }}>
        <RectangleButton
          buttonLabel={<span>Organization</span>}
          onPress={organizationOnPress}
          altPaddingVert={12}
          altColor={Colors.BLACK}
          altTextColor={Colors.WHITE}
          iconRight={
            <Icon
              icon="ion:people"
              height={17}
              style={{ color: Colors.WHITE }}
            />
          }
        />
        <RectangleButton
          buttonLabel={<span>Individual</span>}
          onPress={individualOnPress}
          altColor={Colors.GRAY6}
          altTextColor={Colors.BLACK}
          altPaddingVert={12}
          iconRight={
            <Icon
              icon="ion:person"
              height={17}
              style={{ color: Colors.BLACK }}
            />
          }
        />
      </div>
    </div>
  );
};

export default LoginOnboardingAccountType;
