import React, { useCallback, useMemo, useState } from "react";
import "../../../css/GlobalStyles.css";
import "../../../css/GlobalTextStyles.css";
import { useSelector } from "react-redux";
import { getAccountState } from "../../../redux/slices/accountSlice";
import LargePopupModalContainer from "../../Containers/LargePopupModalContainer";
import BlackHoverButton from "../../Buttons/BlackHoverButton";
import CustomCheckbox from "../../CustomCheckbox";
import { Colors } from "../../../utils/colors";
import { makeDuplicateEvent } from "../../../utils/makeEvent";
import { useNavigate } from "react-router-dom";
import { Event } from "@markit/common.types";
import LargePopupModalHeader from "../../Containers/LargePopupModalHeader";
import {
  eventHasRequestTickets,
  isEventTicketsPaid,
} from "@markit/common.utils";
import { getTotalNumFreePromoTickets } from "../../../utils/eventUtils/eventUtils";
import { getTotalNumUserEventLinkTrackers } from "../../../utils/trackingUtils";
import merge from "lodash.merge";
import { CircularProgress } from "@mui/material";
import useAsyncOnMount from "../../../hooks/useAsyncEffectOnMount";

interface CloneEventModalProps {
  event: Event;
  isModalVisible: boolean;
  setIsModalVisible: (isModalVisible: boolean) => void;
}

const CloneEventModal = (props: CloneEventModalProps) => {
  const { event, isModalVisible, setIsModalVisible } = props;
  const { account } = useSelector(getAccountState);
  const { accountData } = account;
  const navigate = useNavigate();
  const [numTrackingLinks, setNumTrackingLinks] = useState(0);
  const [numFreeTickets, setNumFreeTickets] = useState(0);
  const [eventDetailsChecked, setEventDetailsChecked] = useState(true);
  const [trackingLinksChecked, setTrackingLinksChecked] = useState(false);
  const [promoChecked, setPromoChecked] = useState(false);
  const [loading, setLoading] = useState(true);

  const styles = {
    buttonView: {
      whiteSpace: "nowrap",
      borderRadius: 100,
      paddingBlock: 10,
      paddingInline: 12,
    },
  };

  useAsyncOnMount(async () => {
    const [numTrackingLinks, numFreePromoTickets] = await Promise.all([
      getTotalNumUserEventLinkTrackers(accountData.uid, event.id),
      getTotalNumFreePromoTickets(event.id),
    ]);
    setNumTrackingLinks(numTrackingLinks);
    setNumFreeTickets(numFreePromoTickets);
    setLoading(false);
  });

  const disableClone = useMemo(
    () => !eventDetailsChecked && !trackingLinksChecked && !promoChecked,
    [eventDetailsChecked, promoChecked, trackingLinksChecked]
  );

  const cloneEvent = useCallback(() => {
    const duplicatedEvent = makeDuplicateEvent(
      accountData,
      event,
      eventDetailsChecked
    );
    const newEvent: Event = merge({
      ...duplicatedEvent,
      promoCodes: promoChecked ? event.promoCodes : [],
    });
    navigate("/create", {
      state: {
        duplicatedEvent: newEvent,
        cloneDetails: {
          clonedEventId: event.id,
          trackingLinks: trackingLinksChecked,
          promoCodes: promoChecked,
        },
      },
    });
  }, [
    accountData,
    event,
    eventDetailsChecked,
    navigate,
    promoChecked,
    trackingLinksChecked,
  ]);

  const onEventDetailsChecked = useCallback(() => {
    if (eventDetailsChecked) {
      setPromoChecked(false);
    }
    setEventDetailsChecked(!eventDetailsChecked);
  }, [eventDetailsChecked]);

  const renderItemRow = useCallback(
    (
      title: string,
      subtext: string,
      isChecked: boolean,
      onPress: () => void,
      disabled?: boolean
    ) => (
      <div
        onClick={onPress}
        className="AlignedRowSpacedSelect"
        style={{
          gap: 24,
          opacity: disabled ? 0.5 : 1,
          pointerEvents: disabled ? "none" : "all",
        }}
      >
        <div className="ColumnNormalSelect" style={{ gap: 7 }}>
          <span className="sectionTitle">{title}</span>
          <span className="bodySubtext">{subtext}</span>
        </div>
        <CustomCheckbox
          checked={isChecked}
          sx={{ padding: 0 }}
          altColor={Colors.BLUE5}
        />
      </div>
    ),
    []
  );

  return (
    <>
      <LargePopupModalContainer
        showModal={isModalVisible}
        headerComp={
          <LargePopupModalHeader
            title={"Clone Event"}
            backOnPress={() => setIsModalVisible(false)}
            headerRight={
              <BlackHoverButton
                title="Clone"
                onPress={cloneEvent}
                containerStyles={styles.buttonView}
                permanentHover
                disabled={disableClone}
              />
            }
          />
        }
        valueComp={
          loading ? (
            <div className="Centering">
              <CircularProgress style={{ color: Colors.GRAY1 }} size={20} />
            </div>
          ) : (
            <div className="ColumnNormal" style={{ gap: 24 }}>
              <span style={{ fontSize: 20, fontWeight: 500 }}>
                Choose What to Clone:
              </span>
              {renderItemRow(
                "Event Details",
                "Includes: Title, date/time, location, description, cover image, tickets, form questions, guest list + visibility settings, theme, & refund policy",
                eventDetailsChecked,
                onEventDetailsChecked
              )}
              {numTrackingLinks > 0
                ? renderItemRow(
                    "Tracking Links",
                    "Includes: Tracking link names",
                    trackingLinksChecked,
                    () => setTrackingLinksChecked(!trackingLinksChecked)
                  )
                : null}
              {(isEventTicketsPaid(event) || eventHasRequestTickets(event)) &&
              (event.promoCodes.length > 0 || numFreeTickets > 0)
                ? renderItemRow(
                    "Promo Codes",
                    "Includes: Promo codes created",
                    promoChecked,
                    () => setPromoChecked(!promoChecked),
                    !eventDetailsChecked
                  )
                : null}
              {renderItemRow(
                "Event Texts",
                "Includes: Coming Soon...",
                false,
                () => {},
                true
              )}
            </div>
          )
        }
      />
    </>
  );
};

export default CloneEventModal;
