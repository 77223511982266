import { isDesktop } from "react-device-detect";
import React, { useEffect, useState, useContext } from "react";
import { useSelector } from "react-redux";
import { LoginState, getAccountState } from "../../redux/slices/accountSlice";
import { Outlet, useLocation } from "react-router-dom";
import { useOnMount } from "../../hooks/useOnMount";
import LoadingScreen from "../LoadingScreen";
import WeeklyCommunityUpdatesModal from "../../components/DisplayModal/WeeklyCommunityUpdatesModal";
import { MixpanelContext } from "../../context/AnalyticsService";
import HomePageMobile from "./HomePageMobile";
import HelpModal from "../../components/Containers/HelpModal";
import { isSubscriptionPaymentFailed } from "@markit/common.utils";
import PaymentFailedButtonModal from "../../components/DisplayModal/UpdatePaymentInfoModal";
import { useNavigate } from "../../hooks/useNavigate";
import { NavigationId } from "../../navigation/AppParamList";
import CreatorModeWrapper from "../../components/CreatorDashboard/CreatorModeWrapper";

const HomePage = () => {
  const navigate = useNavigate();
  const { account } = useSelector(getAccountState);
  const { accountData, loggedIn, appInitialized, followersData } = account;
  const { pathname } = useLocation();
  const mixpanel = useContext(MixpanelContext);

  const [loading, setLoading] = useState(true);

  const [showHelpModal, setShowHelpModal] = useState<boolean>(false);
  const [communityUpdatesVisible, setCommunityUpdatesVisible] = useState(false);
  const [paymentFailedVisible, setPaymentFailedVisible] = useState(false);

  useOnMount(() => {
    if (loggedIn !== LoginState.LOGGED_IN) {
      navigate("/welcome");
    } else if (!accountData.inCreatorMode) {
      navigate("/");
    } else {
      setLoading(false);
    }
  });

  // Logic whether to show PaymentFailedSubscription
  useEffect(() => {
    if (
      pathname === NavigationId.HOME_CONVERSATIONS &&
      isSubscriptionPaymentFailed(accountData.customer.state)
    ) {
      setPaymentFailedVisible(true);
    }
  }, [accountData.customer.state, pathname]);

  // Logic whether to show WeeklyCommunityUpdates
  useEffect(() => {
    if (
      followersData.length > 0 &&
      accountData.showedWeeklyCommunityUpdates === false &&
      !paymentFailedVisible &&
      appInitialized
    ) {
      setCommunityUpdatesVisible(true);
      mixpanel.track("Webapp: Triggered Weekly Community Updates Modal", {
        desktop: true,
        phoneAccount: accountData.phoneNumber !== "",
        uid: accountData.uid,
      });
    }
  }, [
    accountData.phoneNumber,
    accountData.showedWeeklyCommunityUpdates,
    accountData.uid,
    appInitialized,
    mixpanel,
    followersData.length,
    paymentFailedVisible,
    setCommunityUpdatesVisible,
  ]);

  if (loading || !account.appInitialized) {
    return <LoadingScreen />;
  }

  return !isDesktop ? (
    <HomePageMobile />
  ) : (
    <CreatorModeWrapper>
      {communityUpdatesVisible ? (
        <WeeklyCommunityUpdatesModal
          setModalVisible={setCommunityUpdatesVisible}
        />
      ) : null}
      {paymentFailedVisible ? (
        <PaymentFailedButtonModal
          location="Initial Home Page"
          isVisible={paymentFailedVisible}
          setIsVisible={setPaymentFailedVisible}
          addOnClose={() => navigate("/home")}
        />
      ) : null}
      <Outlet />
      {/* Floating action button */}
      {/* {homePathTab(pathname) !== MenuItemLabel.CAMPAIGNS ? (
        <div
          className="FloatingActionButton"
          onClick={() => setShowHelpModal(true)}
        >
          <Icon icon="ion:help" height={27} />
        </div>
      ) : null} */}
      <HelpModal showModal={showHelpModal} setShowModal={setShowHelpModal} />
    </CreatorModeWrapper>
  );
};

export default HomePage;
