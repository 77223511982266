import {
  GuestInfo,
  ImportData,
  Spreadsheet,
  SpreadsheetStatus,
  FollowerStatus,
} from "@markit/common.types";
import { getCountFromServer, getDocs, limit, query, where } from "../firebase";
import {
  getSpreadsheetsRef,
  getSpreadsheetsSnap,
  getUserFollowersRef,
} from "./FirebaseUtils";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import { filterUndefinedValues } from "@markit/common.utils";

export const getUserSpreadsheets = async (
  userId: string
): Promise<Spreadsheet[]> => {
  const spreadsheetsSnap = await getSpreadsheetsSnap(userId);
  if (spreadsheetsSnap.docs.length > 0) {
    const spreadsheetsData = spreadsheetsSnap.docs.map((doc) => doc.data());
    return spreadsheetsData.sort(
      (a, b) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    );
  }
  return [];
};

// Get the user's spreadsheets and the number of valid accepted and subscribed contacts (followers)
export const getUserSpreadsheetsAndTextableContacts = async (
  userId: string
): Promise<ImportData[]> => {
  const followersRef = getUserFollowersRef(userId);
  const spreadsheetsSnaps = await getSpreadsheetsSnap(userId);
  if (spreadsheetsSnaps.docs.length > 0) {
    const spreadsheetsData = await Promise.all(
      spreadsheetsSnaps.docs.map(async (doc) => {
        // Get the actual number of accepted valid contacts that are currently followers?
        const query_ = query(
          followersRef,
          where("spreadsheetData.fileId", "==", doc.data().fid),
          where("spreadsheetData.status", "==", SpreadsheetStatus.ACCEPTED),
          where("status", "==", FollowerStatus.SUBSCRIBED)
        );
        const snapshot = await getCountFromServer(query_);
        const numContacts = snapshot.data().count;
        return { spreadsheet: doc.data(), textableContacts: numContacts };
      })
    );
    return spreadsheetsData.sort(
      (a, b) =>
        new Date(b.spreadsheet.createdAt).getTime() -
        new Date(a.spreadsheet.createdAt).getTime()
    );
  }
  return [];
};

// Get the user's contacts from the specified spreadsheet
export const getUserSpreadsheetContacts = async (
  userId: string,
  fileId: string
): Promise<string[]> => {
  const followersRef = getUserFollowersRef(userId);
  const query_ = query(
    followersRef,
    where("spreadsheetData.fileId", "==", fileId)
  );
  const snapshot = await getDocs(query_);
  return snapshot.docs.map((doc) => doc.data().uid);
};

/**
 * Get total number of spreadsheet followers uploaded
 */
export const getTotalSpreadsheetFollowersUploaded = async (
  uid: string
): Promise<number> => {
  const followersRef = getUserFollowersRef(uid);
  const followersQuery = query(
    followersRef,
    where("spreadsheetData.fileId", "!=", "")
  );

  const snapshot = await getCountFromServer(followersQuery);
  return snapshot.data().count;
};

// Parses the csv or xlsx file into a string with the valid info
export const parseSpreadsheetFile = (
  fileData: string | ArrayBuffer | null | undefined,
  file: any
): string => {
  let csv: string = "";
  if (file.name.endsWith(".xlsx")) {
    const workbook = XLSX.read(fileData, { type: "binary" });
    const sheetName = workbook.SheetNames[0];
    const sheet = workbook.Sheets[sheetName];
    const tempCsv = XLSX.utils.sheet_to_csv(sheet);
    // Filter out empty rows
    csv = tempCsv
      .split("\n")
      .filter((row) => row.split(",").some((value) => value.trim() !== ""))
      .join("\n");
  } else if (file.name.endsWith(".csv")) {
    Papa.parse(fileData as any, {
      complete: (result) => {
        const cleanedData = result.data.filter((row: any) =>
          row.some((value: string) => value.trim() !== "")
        );
        csv = Papa.unparse(cleanedData);
      },
    });
  }
  return csv;
};

// Returns the cleaned data columns for the spreadsheet rows
export const getCleanedDataValidColumns = async (rows: GuestInfo[]) => {
  const rowDataColumns = rows.map((row) => row.dataColumns);

  // find the row with the longest dataColumns
  const longestDataColumnsLength = rows.reduce((max, row) => {
    return Math.max(max, row.dataColumns ? row.dataColumns.length : 0);
  }, 0);

  // Find the columns that have all empty data and get the index it's located at
  const emptyColumnIndices = [];
  for (let i = 0; i < longestDataColumnsLength; i++) {
    const isEmptyColumn = rowDataColumns.every(
      (row) => row && /^\s*$/.test(row[i])
    );
    if (isEmptyColumn) {
      emptyColumnIndices.push(i);
    }
  }

  // Modify the rows with the filtered out empty columns
  const indexSet = new Set(emptyColumnIndices);
  const finalRows: GuestInfo[] = [];
  for (let i = 0; i < rows.length; i++) {
    const newDataColumns = rows[i].dataColumns?.filter(
      (_, index) => !indexSet.has(index)
    );
    finalRows.push({ ...rows[i], dataColumns: newDataColumns });
  }

  return finalRows;
};

export const foundExistingSpreadsheetName = async (
  fileName: string,
  userId: string
) => {
  const spreadsheetsRef = getSpreadsheetsRef(userId);
  const spreadsheetsQuery = query(
    spreadsheetsRef,
    where("fileName", "==", fileName)
  );
  const snapshot = await getDocs(spreadsheetsQuery);
  return !snapshot.empty;
};

// Gets the existing contacts out of the validRows ƒwhile parsing spreadsheet
export const foundExistingSpreadsheetContacts = async (
  userId: string,
  validRows: GuestInfo[]
): Promise<GuestInfo[]> => {
  const followersRef = getUserFollowersRef(userId);
  const existingContacts = await Promise.all(
    validRows.map(async (validRow) => {
      const query_ = query(
        followersRef,
        where("phoneNumber", "==", validRow.phoneNumber),
        limit(1)
      );
      const snapshot = await getDocs(query_);
      if (!snapshot.empty) {
        const followerUserId = snapshot.docs.map((doc) => doc.data().uid)[0];
        const existingRow: GuestInfo = {
          ...validRow,
          uid: followerUserId,
        };
        return existingRow;
      }
      return undefined;
    })
  );
  const definedExistingContacts: GuestInfo[] =
    filterUndefinedValues(existingContacts);
  return definedExistingContacts;
};
