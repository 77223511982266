import { m } from "framer-motion";
import { useSelector } from "react-redux";
import { getAccountState } from "../../../redux/slices/accountSlice";
import ProfilePic from "../../ProfilePic";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { Icon } from "@iconify/react";
import { Colors } from "../../../utils/colors";
import { AccountData } from "@markit/common.types";
import {
  MEDIA_UPLOAD_CODES,
  errorAlertText,
  errorSubAlertText,
  uploadMedia,
} from "../../../utils/photoUtils";
import EditProfileConvertModal from "./EditProfileConvertModal";
import RectangleButton from "../../Buttons/RectangleButton";
import AlertContainer from "../../Containers/AlertContainer";
import {
  INSTAGRAM_DEFAULT,
  LINKEDIN_DEFAULT,
  SPOTIFY_DEFAULT,
  TIKTOK_DEFAULT,
  X_DEFAULT,
} from "@markit/common.utils";

export type EditProfilePanelProps = {
  accountSettings: AccountData;
  updateAccountSettings: (accountSettings: Partial<AccountData>) => void;
};

const EditProfilePanel = (props: EditProfilePanelProps) => {
  const { accountSettings, updateAccountSettings } = props;
  const { accountData } = useSelector(getAccountState).account;
  const [convertModal, setConvertModal] = useState(false);
  const [changeModal, setChangeModal] = useState(false);
  const [passwordModal, setPasswordModal] = useState(false);

  const [alertText, setAlertText] = useState("");
  const [alertSubText, setAlertSubText] = useState("");

  const styles = {
    title: { fontSize: 14, fontWeight: "500" },
    smallTitle: { fontSize: 12, fontWeight: "500" },
    errorMessage: { fontSize: 12, color: Colors.RED1 },
  };

  const isPhoneBasedAccount = useMemo(
    () => accountData.phoneNumber !== "",
    [accountData.phoneNumber]
  );

  // States for image picker
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const uploadImage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      return new Promise<void>((resolve, reject) => {
        (async () => {
          const uploadData = await uploadMedia(event, true, true);
          if (uploadData.uploadCode !== MEDIA_UPLOAD_CODES.SUCCESS) {
            setAlertText(errorAlertText(uploadData.uploadCode, true));
            setAlertSubText(errorSubAlertText(uploadData.uploadCode));
            reject();
          } else {
            updateAccountSettings({ profilePicURL: uploadData.blobUrl });
            resolve();
          }
        })().catch(reject); // Handle any async errors
      });
    },
    [updateAccountSettings]
  );

  const renderItemRow = useCallback(
    (
      title: string,
      input: string,
      placeholder: string,
      onChange: (value: string) => void,
      maxLength: number
    ) => (
      <div
        className="AlignedRowSpaced"
        style={{ borderBottom: "1px solid #F4F5F6", paddingBottom: 3 }}
      >
        <div className="AlignedRow">
          <span style={{ ...styles.smallTitle, width: 100 }}>{title}</span>
          {maxLength === 0 ? (
            <span style={{ fontSize: 12 }}>{input}</span>
          ) : (
            <input
              type="text"
              defaultValue={input}
              placeholder={placeholder}
              style={{
                border: 0,
                width: "clamp(314px, 18vw, 414px)",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
              onChange={(e) => onChange(e.target.value)}
            />
          )}
        </div>
        {maxLength === 0 ? (
          <Icon
            icon={"feather:edit"}
            height={14}
            style={{ cursor: "pointer" }}
            onClick={() => setChangeModal(true)}
          />
        ) : (
          <span style={{ fontSize: 11, color: Colors.GRAY1 }}>
            {input.length}/{maxLength}
          </span>
        )}
      </div>
    ),
    [styles.smallTitle]
  );

  return (
    <>
      <m.div
        key="masstextstextsummary"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.2, type: "tween" }}
        className="ColumnNormal HideScrollbar"
        style={{ height: "calc(100vh - 120px)", overflow: "scroll", gap: 10 }}
      >
        <span className="AboutSubtitle" style={{ fontSize: 18 }}>
          Edit Profile
        </span>
        <div style={{ position: "relative" }}>
          <label>
            <input
              type="file"
              ref={fileInputRef}
              accept="image/*"
              onChange={uploadImage}
              style={{ color: "transparent" }}
            />
            <>
              <ProfilePic user={accountSettings} size={90} />
              <div
                style={{
                  position: "absolute",
                  left: 65,
                  bottom: 0,
                  paddingInline: 5,
                  paddingTop: 3,
                  paddingBottom: 2,
                  borderRadius: 5,
                  backgroundColor: Colors.GRAY6,
                  cursor: "pointer",
                }}
              >
                <Icon icon={"feather:edit"} height={16} />
              </div>
            </>
          </label>
        </div>
        <div className="ColumnNormal" style={{ gap: 20 }}>
          <span style={styles.title}>Basic Info</span>
          {renderItemRow(
            "Name",
            accountSettings.fullName,
            "Enter full name",
            (val) => updateAccountSettings({ fullName: val }),
            42
          )}
          {renderItemRow(
            "Username",
            accountSettings.username,
            "Enter username",
            (val) => updateAccountSettings({ username: val }),
            30
          )}
          {isPhoneBasedAccount
            ? renderItemRow(
                "Phone Number",
                accountData.phoneNumber,
                "Enter phone number",
                () => {},
                0
              )
            : renderItemRow(
                "Email",
                accountData.email,
                "Enter email",
                () => {},
                0
              )}
          <RectangleButton
            buttonLabel={`Convert to ${
              isPhoneBasedAccount ? "Email" : "Phone"
            } Account`}
            onPress={() => setConvertModal(true)}
            altColor={Colors.GRAY6}
            altTextColor={Colors.BLACK}
            altPaddingVert={14}
          />
          {renderItemRow(
            "Bio",
            accountSettings.bio,
            "Enter bio",
            (val) => updateAccountSettings({ bio: val }),
            250
          )}
          {renderItemRow(
            "Link",
            accountSettings.profileLink,
            "Enter website",
            (val) => updateAccountSettings({ profileLink: val }),
            150
          )}
          <span style={styles.title}>Social Handles</span>
          {renderItemRow(
            "Instagram",
            accountSettings.instagram,
            INSTAGRAM_DEFAULT,
            (val) => updateAccountSettings({ instagram: val }),
            200
          )}
          {renderItemRow(
            "LinkedIn",
            accountSettings.linkedin,
            LINKEDIN_DEFAULT,
            (val) => updateAccountSettings({ linkedin: val }),
            200
          )}
          {renderItemRow(
            "X",
            accountSettings.twitter,
            X_DEFAULT,
            (val) => updateAccountSettings({ twitter: val }),
            200
          )}
          {renderItemRow(
            "Tiktok",
            accountSettings.tiktok,
            TIKTOK_DEFAULT,
            (val) => updateAccountSettings({ tiktok: val }),
            200
          )}
          {renderItemRow(
            "Spotify",
            accountSettings.spotify,
            SPOTIFY_DEFAULT,
            (val) => updateAccountSettings({ spotify: val }),
            200
          )}
          {!isPhoneBasedAccount ? (
            <>
              <span style={styles.title}>Passwords and Security</span>
              <RectangleButton
                buttonLabel="Change Password"
                onPress={() => setPasswordModal(true)}
                altColor={Colors.GRAY6}
                altTextColor={Colors.BLACK}
                altPaddingVert={14}
              />
            </>
          ) : null}
        </div>
      </m.div>
      <EditProfileConvertModal
        convertModal={convertModal}
        setConvertModal={setConvertModal}
        changeModal={changeModal}
        setChangeModal={setChangeModal}
        passwordModal={passwordModal}
        setPasswordModal={setPasswordModal}
      />
      <AlertContainer
        headerComp={
          <div className="ColumnNormal" style={{ gap: 4 }}>
            <Icon icon="mdi-image-remove" height={40} />
            <span style={{ fontSize: 16, fontWeight: "500" }}>{alertText}</span>
          </div>
        }
        subHeaderComp={alertSubText}
        closeModal={() => {
          setAlertText("");
          if (fileInputRef.current) {
            fileInputRef.current.value = "";
          }
        }}
        hideModal={alertText === ""}
      />
    </>
  );
};

export default EditProfilePanel;
