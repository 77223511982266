import { Icon } from "@iconify/react";
import { useTheme } from "../../../../hooks/useTheme";

type CampaignTypeItemProps = {
  numMassTexts: number;
  preventPointer?: boolean;
};

// Used to display the type of campaign (ie. Single or Multi)
const CampaignTypeItem = (props: CampaignTypeItemProps) => {
  const { numMassTexts, preventPointer } = props;
  const { theme } = useTheme();

  return (
    <div
      className="AlignedRow"
      style={{ gap: 7, cursor: preventPointer ? "default" : "pointer" }}
    >
      <Icon
        icon={numMassTexts > 1 ? "ion:git-network-sharp" : "ion:git-commit"}
        height={15}
        style={theme.SubText}
      />
      <span className="bodySubtext">
        {numMassTexts > 1 ? "Multi" : "Single"} Text
      </span>
    </div>
  );
};

export default CampaignTypeItem;
