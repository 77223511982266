import { useCallback, useEffect, useMemo, useState } from "react";
import { Campaign, MassText } from "@markit/common.types";
import { Colors } from "../../../utils/colors";
import MassTextPanel, {
  TextPopupConfirmActions,
} from "../MassTexts/MassTextPanel";
import RectangleButton from "../../../components/Buttons/RectangleButton";
import { Icon } from "@iconify/react";
import { HorizontalDivider } from "../../../components/Dividers/HorizontalDivider";
import { CampaignMassTextItemPreview } from "../../DisplayItem/CampaignItem/CampaignMassTextItemPreview";
import { VerticalDivider } from "../../../components/Dividers/VerticalDivider";
import { TabPanel } from "../../FullEventSubComponents/TabPanel";
import { handlePluralString } from "@markit/common.utils";
import { useSelector } from "react-redux";
import { getEventState } from "../../../redux/slices/eventSlice";
import CampaignTypeItem from "./Items/CampaignTypeItem";
import { useTheme } from "../../../hooks/useTheme";
import {
  MassTextSelectedType,
  MassTextsWrapperType,
} from "../MassTexts/MassTextWrapperManager";
import { MassTextDetails } from "../../../redux/slices/campaignSlice";
import StandardBorderedContainer from "../../Containers/StandardBorderedContainer";

type CampaignFlowPanelProps = {
  tabValue: number;
  campaign: Campaign;
  massTextDetails: MassTextDetails[];
  totalRecipients: number;
  editingCampaign: boolean;
  setEditingCampaign: (editingCampaign: boolean) => void;
};

const CampaignFlowPanel = (props: CampaignFlowPanelProps) => {
  const {
    tabValue,
    campaign,
    massTextDetails,
    totalRecipients,
    editingCampaign,
    setEditingCampaign,
  } = props;
  const { events } = useSelector(getEventState).events;
  const { theme } = useTheme();
  const [confirmPopupTextConfirmation, setConfirmPopupTextConfirmation] =
    useState<TextPopupConfirmActions>(TextPopupConfirmActions.NONE);
  const [selectedTextType, setSelectedTextType] =
    useState<MassTextSelectedType>({
      massText: undefined,
      isVisible: true,
      isDuplicateText: false,
    });
  const [duplicateTextType, setDuplicateTextType] =
    useState<MassTextSelectedType>({
      massText: undefined,
      isVisible: false,
      isDuplicateText: true,
    });

  const renderVerticalLine = (
    <div style={{ paddingBlock: 4 }}>
      <VerticalDivider height={30} color={Colors.GRAY2} />
    </div>
  );

  const sortedMassTextDetails = useMemo(
    () =>
      [...massTextDetails].sort(
        (a, b) =>
          new Date(a.massText.sentAt).getTime() -
          new Date(b.massText.sentAt).getTime()
      ),
    [massTextDetails]
  );

  const initialMassText = useMemo(
    () => sortedMassTextDetails[0].massText,
    [sortedMassTextDetails]
  );

  const isSelectedText = useCallback(
    (massTextId: string) =>
      selectedTextType.massText && selectedTextType.massText.id === massTextId,
    [selectedTextType.massText]
  );

  // Handles if there is no mass text selected, default back to the initial mass text
  useEffect(() => {
    if (!editingCampaign && !selectedTextType.massText) {
      setSelectedTextType({ massText: initialMassText, isVisible: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editingCampaign]);

  // Handles if the selected text no longer exists and we need to select new text, ie. deleted
  useEffect(() => {
    if (
      selectedTextType.massText &&
      !sortedMassTextDetails.some(
        (text) => text.massText.id === selectedTextType.massText?.id
      )
    ) {
      setSelectedTextType({
        massText:
          sortedMassTextDetails[sortedMassTextDetails.length - 1].massText,
        isVisible: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedMassTextDetails.length]);

  const duplicateTextOnPress = useCallback((massText: MassText) => {
    setDuplicateTextType({ massText, isVisible: true, isDuplicateText: true });
  }, []);

  return (
    <TabPanel value={tabValue} index={0}>
      <div className="RowNormal">
        <div
          className="ColumnNormal"
          style={{
            flex: 1,
            borderRight: `1.5px solid ${theme.DividerColor.borderColor}`,
          }}
        >
          {!editingCampaign ? (
            <>
              <div
                className="AlignedRowSpaced"
                style={{
                  paddingInline: 30,
                  height: 58,
                  backgroundColor: Colors.WHITE,
                }}
              >
                <span className="sectionTitle">Campaign Flow</span>
                <CampaignTypeItem
                  numMassTexts={massTextDetails.length}
                  preventPointer
                />
              </div>
              <HorizontalDivider altMargin={0} />
            </>
          ) : null}
          <div
            className="ColumnNormal"
            style={{
              paddingTop: 30,
              alignItems: "center",
              overflow: "scroll",
              height: editingCampaign ? "78vh" : "64vh",
            }}
          >
            <div
              className="ColumnNormal"
              style={{ gap: 7, textAlign: "center", paddingBlock: 7 }}
            >
              <span className="bodySubtext" style={{ color: Colors.GRAY2 }}>
                Campaign Begins
              </span>
              <div
                className="AlignedRow"
                style={{ gap: 2, justifyContent: "center" }}
              >
                <span style={{ fontSize: 12, color: Colors.BLACK }}>
                  {totalRecipients !== 0 ? totalRecipients : "--"}{" "}
                  {handlePluralString("recipient", totalRecipients)}
                </span>
                {/* <Icon
                icon={"ion:chevron-right"}
                height={9}
                style={{ color: Colors.BLUE5, marginBottom: -1 }}
              /> */}
              </div>
            </div>
            {renderVerticalLine}
            {sortedMassTextDetails.map((massTextDetail, index) => {
              return (
                <div
                  className="ColumnCentering"
                  key={massTextDetail.massText.id}
                >
                  <div
                    style={{
                      border: isSelectedText(massTextDetail.massText.id)
                        ? "2px solid black"
                        : "",
                      borderRadius: 18,
                      padding: isSelectedText(massTextDetail.massText.id)
                        ? 4
                        : 0,
                    }}
                  >
                    <CampaignMassTextItemPreview
                      massTextDetail={massTextDetail}
                      campaign={campaign}
                      viewOnPress={() => {
                        setSelectedTextType({
                          massText: massTextDetail.massText,
                          isVisible: true,
                        });
                      }}
                      duplicateOnPress={duplicateTextOnPress}
                      numCampaignTexts={massTextDetails.length}
                      campaignInitialText={
                        sortedMassTextDetails.find(
                          (text) => text.massText.id === campaign.initialTextId
                        )?.massText
                      }
                      events={events}
                    />
                  </div>
                  {massTextDetails.length - 1 !== index
                    ? renderVerticalLine
                    : null}
                </div>
              );
            })}
            {renderVerticalLine}
            {editingCampaign &&
            !selectedTextType.massText &&
            selectedTextType.isVisible ? (
              <>
                <div
                  style={{
                    border: "2px solid black",
                    borderRadius: 18,
                    padding: 4,
                  }}
                >
                  <StandardBorderedContainer
                    containerStyles={{
                      backgroundColor: Colors.WHITE,
                      padding: 14,
                      borderRadius: 14,
                      width: 300,
                    }}
                  >
                    <div className="ColumnNormal" style={{ gap: 7 }}>
                      <span className="bodyMedium">New Text...</span>
                      <span className="smallBodySubtext">
                        Send or schedule your text to add to campaign
                      </span>
                    </div>
                  </StandardBorderedContainer>
                </div>
                {renderVerticalLine}
              </>
            ) : null}
            <div>
              <RectangleButton
                buttonLabel={
                  <span
                    style={{
                      fontSize: 13,
                      fontWeight: 500,
                      color: Colors.BLUE5,
                    }}
                  >
                    New Text
                  </span>
                }
                onPress={() => {
                  setEditingCampaign(true);
                  setSelectedTextType({ massText: undefined, isVisible: true });
                }}
                altPaddingHorz={10}
                altPaddingVert={10}
                altBorderRadius={100}
                containerStyles={{
                  gap: 4,
                  border: `1px solid ${Colors.BLUE5}`,
                }}
                altColor={"rgba(0,122,255,0.1)"}
                altTextColor={Colors.BLUE5}
                iconLeft={
                  <Icon
                    icon={"ic:round-plus"}
                    height={16}
                    style={{ color: Colors.BLUE5 }}
                  />
                }
              />
            </div>
            {renderVerticalLine}
            <div
              className="AlignedRow"
              style={{ gap: 7, paddingTop: 7, paddingBottom: 100 }}
            >
              <span className="bodySubtext" style={{ color: Colors.GRAY2 }}>
                Campaign Ends
              </span>
              <Icon
                icon={"ion:exit-outline"}
                height={17}
                style={{ color: Colors.GRAY2, marginBottom: -1 }}
              />
            </div>
          </div>
        </div>
        {selectedTextType.isVisible ? (
          <div
            key={selectedTextType.massText?.id}
            style={editingCampaign ? { width: 557 } : { flex: 1 }}
          >
            <MassTextPanel
              wrapperType={
                editingCampaign
                  ? MassTextsWrapperType.SIDE_PANEL
                  : MassTextsWrapperType.SIDE_PANEL_PREVIEW
              }
              selectedTextType={selectedTextType}
              setSelectedTextType={setSelectedTextType}
              duplicateOnPress={duplicateTextOnPress}
              setEditingCampaign={setEditingCampaign}
              campaign={campaign}
              confirmPopupTextConfirmation={confirmPopupTextConfirmation}
              setConfirmPopupTextConfirmation={setConfirmPopupTextConfirmation}
            />
          </div>
        ) : null}
      </div>
      {duplicateTextType.isVisible ? (
        <MassTextPanel
          wrapperType={MassTextsWrapperType.MODAL}
          selectedTextType={duplicateTextType}
          setSelectedTextType={setDuplicateTextType}
          setEditingCampaign={setEditingCampaign}
          campaign={campaign}
          confirmPopupTextConfirmation={confirmPopupTextConfirmation}
          setConfirmPopupTextConfirmation={setConfirmPopupTextConfirmation}
        />
      ) : null}
    </TabPanel>
  );
};

export default CampaignFlowPanel;
