import "../css/App.css";
import "../css/FullEvent/FullEvent.css";
import React, { memo } from "react";
import { AccountData } from "@markit/common.types";
import { Icon } from "@iconify/react";
import { useTheme } from "../hooks/useTheme";
import { Colors } from "../utils/colors";
import { hasSubscription } from "@markit/common.utils";

type CreatorTextManualProp = {
  host: AccountData;
};

const CreatorTextManual = memo(function FullEventInfoFn(
  props: CreatorTextManualProp
) {
  const { host } = props;

  const { theme } = useTheme();

  return (
    <>
      <div className="PageDesktop" style={theme.SecondaryBG}>
        <div className="Container" style={{ paddingInline: 0 }}>
          <div
            style={{
              ...theme.PrimaryText,
              fontSize: 16,
              fontWeight: "600",
              marginInline: 14,
            }}
          >
            {hasSubscription(host)
              ? `Get Texts from ${host.fullName}`
              : "How Markit Works"}
          </div>
          <hr style={{ ...theme.DividerColor, marginTop: 14 }} />
          <div style={{ marginInline: 14 }}>
            <div className="AlignedRow" style={{ marginBottom: 12 }}>
              <Icon
                icon="ion:people"
                height={26}
                style={{ color: Colors.GRAY1, marginRight: 10 }}
              />

              <p className="CreatorTextManualSubtext">
                By getting a ticket or subscribing to {host.fullName}, you will
                receive text updates!
              </p>
            </div>
            <div className="AlignedRow" style={{ marginBottom: 12 }}>
              <Icon
                icon="ion:chatbubble-ellipses"
                height={26}
                style={{ color: Colors.GRAY1, marginRight: 10 }}
              />

              <p className="CreatorTextManualSubtext">
                Chat with {host.fullName} directly from your phone&apos;s
                messaging app.
              </p>
            </div>
            <div className="AlignedRow">
              <Icon
                icon="ion:stop-circle"
                height={26}
                style={{ color: Colors.GRAY1, marginRight: 10 }}
              />
              <p className="CreatorTextManualSubtext">
                Reply STOP at any time to unsubscribe.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default CreatorTextManual;
