import "../../css/Buttons/RectangleButtonStyles.css";
import React, { useCallback } from "react";
import { Colors } from "../../utils/colors";
import { VerticalDivider } from "../Dividers/VerticalDivider";
import { Icon } from "@iconify/react";
import { DropdownMenu, DropdownMenuItem } from "../DropdownMenu";

type ButtonWithDropdownProps = {
  buttonLabel: React.ReactNode;
  onPress: () => void;
  isOpenDropdown: boolean;
  setIsOpenDropdown: (isOpenDropdown: boolean) => void;
  dropdownItems: DropdownMenuItem[];
  textColor?: string;
  containerStyles?: React.CSSProperties;
};

/** A standard button with a dropdown with specified items accessible by a dropdown icon */
const ButtonWithDropdown = (props: ButtonWithDropdownProps) => {
  const {
    buttonLabel,
    onPress,
    isOpenDropdown,
    setIsOpenDropdown,
    dropdownItems,
    textColor,
    containerStyles,
  } = props;

  const handleOpenChange = useCallback(
    (nextOpen: boolean, info?: { source: "trigger" | "menu" }) => {
      if (info?.source === "trigger" || nextOpen) {
        setIsOpenDropdown(nextOpen);
      }
    },
    [setIsOpenDropdown]
  );

  return (
    <div
      className="AlignedRow"
      style={{
        backgroundColor: Colors.WHITE1,
        cursor: "pointer",
        borderRadius: 20,
        paddingInline: 14,
        height: 39,
        whiteSpace: "nowrap",
        ...containerStyles,
      }}
    >
      <span
        onClick={onPress}
        style={{
          color: textColor ?? Colors.BLACK,
          fontWeight: "600",
          fontSize: 14,
          paddingRight: 10,
          paddingBlock: 10,
        }}
      >
        {buttonLabel}
      </span>
      <VerticalDivider height={39} color={Colors.GRAY2} thickness={0.5} />
      <DropdownMenu
        dropdownItems={dropdownItems}
        trigger={["click"]}
        placement="bottomLeft"
        altWidth={212}
        overlayStyle={{ paddingTop: 20 }}
        isOpen={isOpenDropdown}
        onOpenChange={handleOpenChange}
      >
        <Icon
          icon={isOpenDropdown ? "ion:chevron-up" : "ion:chevron-down"}
          height={14}
          style={{ marginBottom: -5, paddingLeft: 10, paddingBlock: 10 }}
        />
      </DropdownMenu>
    </div>
  );
};

export default ButtonWithDropdown;
