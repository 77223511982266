import { useMemo, useState } from "react";
import {
  FollowerStatus,
  MassTextAttachment,
  MassTextAttachmentType,
} from "@markit/common.types";
import { Colors } from "../../utils/colors";
import CreatorModePanelWrapper from "../../components/CreatorDashboard/CreatorModePanelWrapper";
import { useCampaignSelector } from "../../hooks/useParametricSelector";
import { useSelector } from "react-redux";
import { getAccountState } from "../../redux/slices/accountSlice";
import { useNavigate } from "react-router-dom";
import CreatorModeWrapper from "../../components/CreatorDashboard/CreatorModeWrapper";
import LoadingScreen from "../LoadingScreen";
import RectangleButton from "../../components/Buttons/RectangleButton";
import { NavigationId } from "../../navigation/AppParamList";
import { Icon } from "@iconify/react";
import { useTheme } from "../../hooks/useTheme";
import CampaignFlowPanel from "../../components/CreatorDashboard/Campaigns/CampaignFlowPanel";
import CampaignSettingsPanel from "../../components/CreatorDashboard/Campaigns/CampaignSettingsPanel";
import { handlePluralString, isEventExternalLink } from "@markit/common.utils";
import CampaignRenameModal from "../../components/CreatorDashboard/Campaigns/Items/CampaignRenameModal";
import { getCampaignLinkAnalytics } from "../../utils/trackingUtils";
import { useLiveUpdatingCampaign } from "../../hooks/useLiveHooks/useLiveUpdatingCampaign";
import useAsyncEffect from "../../hooks/useAsyncEffect";
import { getEventState } from "../../redux/slices/eventSlice";

enum CampaignDashboardTabs {
  OVERVIEW = 0,
  SETTINGS = 1,
}

const CampaignDashboard = () => {
  const { accountData, appInitialized, followersData } =
    useSelector(getAccountState).account;
  const navigate = useNavigate();
  const { theme } = useTheme();
  const events = useSelector(getEventState).events.events;
  const campaignId = useCampaignSelector();
  const [totalClicks, setTotalClicks] = useState(0);
  const [totalConversions, setTotalConversions] = useState(0);
  const [loadingCampaign, setLoadingCampaign] = useState(true);
  const [tabValue, setTabValue] = useState(CampaignDashboardTabs.OVERVIEW);
  const [showRenameModal, setShowRenameModal] = useState(false);
  const [editingCampaign, setEditingCampaign] = useState(false);

  const {
    liveCampaign: campaign,
    liveCampaignDetail,
    isLiveCampaignError,
    loaded,
  } = useLiveUpdatingCampaign({
    initialCampaignId: campaignId,
    userId: accountData.uid,
  });

  const styles = {
    headerRightText: {
      fontSize: 32,
    },
  };

  const subscribedFollowers = useMemo(
    () =>
      followersData.filter(
        (follower) => follower.status === FollowerStatus.SUBSCRIBED
      ),
    [followersData]
  );

  const numTotalRecipients = useMemo(() => {
    const initialText = liveCampaignDetail?.massTextDetails.find(
      (text) => text.massText.id === campaign?.initialTextId
    )?.massText;
    if (initialText && initialText.subSelectionIds.length === 0) {
      return liveCampaignDetail.numRecipients;
    } else {
      return subscribedFollowers.length;
    }
  }, [campaign?.initialTextId, liveCampaignDetail, subscribedFollowers.length]);

  const campaignAttachments = useMemo(
    () =>
      liveCampaignDetail
        ? liveCampaignDetail.massTextDetails.reduce(
            (prev, curr) => prev.concat(curr.attachments),
            [] as MassTextAttachment[]
          )
        : [],
    [liveCampaignDetail]
  );

  const campaignHasAttachments = useMemo(() => {
    return (
      campaignAttachments.length > 1 ||
      (campaignAttachments.length === 1 &&
        campaignAttachments[0].massTextType !==
          MassTextAttachmentType.TICKET_LINK &&
        campaignAttachments[0].massTextType !==
          MassTextAttachmentType.CALENDAR_INVITE)
    );
  }, [campaignAttachments]);

  const showCampaignConversions = useMemo(
    () =>
      campaignAttachments.some((attachment) => {
        const foundEvent = events.find(
          (event) => event.id === attachment.eventId
        );
        return foundEvent && !isEventExternalLink(foundEvent.eventType);
      }),
    [campaignAttachments, events]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useAsyncEffect(async () => {
    if (
      !appInitialized ||
      !loadingCampaign ||
      !campaign ||
      !liveCampaignDetail
    ) {
      return;
    }

    const { totalOpens, totalConversions } = await getCampaignLinkAnalytics(
      liveCampaignDetail.massTextDetails,
      campaign
    );
    setTotalClicks(totalOpens);
    setTotalConversions(totalConversions);
    setLoadingCampaign(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountData.uid, appInitialized, loaded]);

  if (!loaded || loadingCampaign || !campaign || !liveCampaignDetail) {
    return (
      <LoadingScreen
        isLoadingPage={loadingCampaign || !loaded}
        error={isLiveCampaignError}
      />
    );
  }

  return (
    <CreatorModeWrapper hideSidebar preventScroll>
      <CreatorModePanelWrapper
        title={
          <div className="ColumnNormal" style={{ gap: 7 }}>
            <div className="AlignedRow" style={{ gap: 20 }}>
              <RectangleButton
                buttonLabel={
                  <span style={{ fontWeight: 500 }}>
                    {editingCampaign ? "Exit" : "Back"}
                  </span>
                }
                onPress={() =>
                  editingCampaign
                    ? setEditingCampaign(false)
                    : navigate(NavigationId.HOME_CAMPAIGNS)
                }
                altBorderRadius={100}
                altPaddingHorz={12}
                altPaddingVert={10}
                width={65}
                containerStyles={{
                  border: `1px solid ${Colors.GRAY1}`,
                }}
                altColor={Colors.WHITE1}
                altTextColor={Colors.BLACK}
                iconLeft={
                  <div>
                    <Icon icon={"ion:chevron-left"} height={10} />
                  </div>
                }
              />
              <div className="AlignedRow" style={{ gap: 7 }}>
                <span>
                  {editingCampaign ? "Edit Campaign" : campaign.title}
                </span>
                {!editingCampaign ? (
                  <Icon
                    icon={"ion:create"}
                    height={22}
                    style={{ ...theme.LabelText, cursor: "pointer" }}
                    onClick={() => setShowRenameModal(true)}
                  />
                ) : null}
              </div>
            </div>
          </div>
        }
        headerContainerStyles={{ alignItems: "center" }}
        headerRight={
          !editingCampaign ? (
            <div className="AlignedRow" style={{ gap: 30 }}>
              <div className="ColumnCentering">
                <span style={styles.headerRightText}>
                  {numTotalRecipients !== 0 ? numTotalRecipients : "--"}
                </span>
                <span className="smallBodySubtext">
                  Total {handlePluralString("Recipient", numTotalRecipients)}
                </span>
              </div>
              {campaignHasAttachments ? (
                <>
                  <div className="ColumnCentering">
                    <span style={styles.headerRightText}>{totalClicks}</span>
                    <span className="smallBodySubtext">
                      Total {handlePluralString("Click", totalClicks)}
                    </span>
                  </div>
                  {showCampaignConversions ? (
                    <div className="ColumnCentering">
                      <span style={styles.headerRightText}>
                        {totalConversions}
                      </span>
                      <span className="smallBodySubtext">
                        Total{" "}
                        {handlePluralString("Conversion", totalConversions)}
                      </span>
                    </div>
                  ) : null}
                </>
              ) : null}
            </div>
          ) : undefined
        }
        headerTabs={
          !editingCampaign
            ? {
                tabNames: ["Overview", "Settings"],
                tabValue: tabValue,
                setTabValue: setTabValue,
              }
            : undefined
        }
      >
        <CampaignFlowPanel
          tabValue={tabValue}
          campaign={campaign}
          massTextDetails={liveCampaignDetail.massTextDetails}
          totalRecipients={numTotalRecipients}
          editingCampaign={editingCampaign}
          setEditingCampaign={setEditingCampaign}
        />
        <CampaignSettingsPanel
          tabValue={tabValue}
          campaign={campaign}
          massTexts={liveCampaignDetail.massTextDetails.map(
            (text) => text.massText
          )}
        />
      </CreatorModePanelWrapper>
      {showRenameModal ? (
        <CampaignRenameModal
          campaign={campaign}
          showModal={showRenameModal}
          setShowModal={setShowRenameModal}
        />
      ) : null}
    </CreatorModeWrapper>
  );
};

export default CampaignDashboard;
