import { useCallback, useMemo, useRef, useState } from "react";
import {
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  startAfter,
} from "../firebase";
import { uniqueVals } from "@markit/common.utils";
import { Campaign } from "@markit/common.types";
import { getUserCampaignsRef } from "../utils/FirebaseUtils";
import { useSelector, useDispatch, batch } from "react-redux";
import {
  campaignActions,
  CampaignDetails,
  getCampaignState,
} from "../redux/slices/campaignSlice";
import useOnUnmount from "./useOnUnmount";
import { useOnMount } from "./useOnMount";
import { fetchCampaignDetails } from "../utils/campaignUtils";

type useLoadCampaignItemsProps = {
  userId: string;
  windowSize: number;
};

export const useLoadCampaignItems = (props: useLoadCampaignItemsProps) => {
  const { userId, windowSize } = props;
  const dispatch = useDispatch();
  const { campaigns, campaignDetails } = useSelector(getCampaignState);
  const [fetchedCampaigns, setFetchedCampaigns] = useState<Campaign[]>([]);
  const [fetchedCampaignDetails, setFetchedCampaignDetails] = useState<
    CampaignDetails[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loadedAllCampaigns, setLoadedAllCampaigns] = useState(false);
  const [lastCampaignVisibleTimestamp, setLastCampaignVisibleTimestamp] =
    useState("");
  const unsubscribeArrayRef = useRef<(() => void)[]>([]);

  useOnMount(() => {
    setFetchedCampaigns(campaigns);
    setFetchedCampaignDetails(campaignDetails);
  });

  useOnUnmount(
    (latestDeps) => {
      batch(() => {
        dispatch(
          campaignActions.addMultipleCampaigns(latestDeps[0] as Campaign[])
        );
        dispatch(
          campaignActions.addMultipleCampaignDetails(
            latestDeps[1] as CampaignDetails[]
          )
        );
      });
      unsubscribeArrayRef.current.forEach(
        (unsubscribe) => unsubscribe && unsubscribe()
      );
      unsubscribeArrayRef.current = []; // Clear the array after cleanup
    },
    [fetchedCampaigns, fetchedCampaignDetails]
  );

  const addCampaignData = useCallback((newCampaignData: Campaign[]) => {
    setFetchedCampaigns((fetchedCampaigns) =>
      uniqueVals(
        newCampaignData.concat(fetchedCampaigns),
        (campaign) => campaign.id
      ).sort(
        (a, b) =>
          new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
      )
    );
  }, []);

  const removeCampaignData = useCallback((campaign: Campaign) => {
    setFetchedCampaigns((fetchedCampaigns) => {
      const index = fetchedCampaigns.findIndex(
        (fetched) => fetched.id === campaign.id
      );
      if (index !== -1) {
        fetchedCampaigns.splice(index, 1);
      }
      return [...fetchedCampaigns];
    });
  }, []);

  const addCampaignDetailsData = useCallback(
    (newCampaignDetails: CampaignDetails[]) => {
      setFetchedCampaignDetails((fetchedCampaignDetails) =>
        uniqueVals(
          newCampaignDetails.concat(fetchedCampaignDetails),
          (detail) => detail.campaignId
        )
      );
    },
    []
  );

  const removeCampaignDetailsData = useCallback((campaignId: string) => {
    setFetchedCampaignDetails((fetchedCampaignDetails) => {
      const index = fetchedCampaignDetails.findIndex(
        (fetched) => fetched.campaignId === campaignId
      );
      if (index !== -1) {
        fetchedCampaignDetails.splice(index, 1);
      }
      return [...fetchedCampaignDetails];
    });
  }, []);

  const isFinished = useMemo(() => loadedAllCampaigns, [loadedAllCampaigns]);

  const updateData = useCallback(
    async (foundCampaignData: Campaign[]) => {
      addCampaignData(foundCampaignData);
      const campaignDetails = await Promise.all(
        foundCampaignData.map(async (campaign) => {
          return await fetchCampaignDetails(userId, campaign);
        })
      );
      addCampaignDetailsData(campaignDetails);

      batch(() => {
        dispatch(campaignActions.addMultipleCampaigns(foundCampaignData));
        dispatch(campaignActions.addMultipleCampaignDetails(campaignDetails));
      });
    },
    [addCampaignData, addCampaignDetailsData, dispatch, userId]
  );

  const initialUpdateData = useCallback(
    async (foundCampaignData: Campaign[]) => {
      // Only add any new campaigns or campaigns that have been updated since initial load
      const newCampaigns = foundCampaignData.filter(
        (loadedCampaign) =>
          !campaigns.some(
            (campaign) =>
              campaign.id === loadedCampaign.id &&
              campaign.updatedAt === loadedCampaign.updatedAt
          )
      );

      if (foundCampaignData.length > 0) {
        await updateData(newCampaigns);
      }
      setIsLoading(false);

      if (foundCampaignData.length) {
        setLastCampaignVisibleTimestamp(
          foundCampaignData[foundCampaignData.length - 1].updatedAt
        );
      }
      if (foundCampaignData.length < windowSize) {
        setLoadedAllCampaigns(true);
      }
    },
    [campaigns, updateData, windowSize]
  );

  const liveUpdateCampaigns = useCallback(
    async (
      type: "added" | "modified" | "removed",
      campaign: Campaign,
      foundCampaigns: Campaign[]
    ) => {
      if (
        (type === "added" &&
          !foundCampaigns.some(
            (campaignData) => campaignData.id === campaign.id
          )) ||
        type === "modified"
      ) {
        updateData([campaign]);
      } else if (type === "removed") {
        removeCampaignData(campaign);
        removeCampaignDetailsData(campaign.id);
      }
    },
    [removeCampaignData, removeCampaignDetailsData, updateData]
  );

  const loadCampaigns = async () => {
    setIsLoading(true);
    const campaignsRef = getUserCampaignsRef(userId);
    const campaignsQuery = query(
      campaignsRef,
      orderBy("updatedAt", "desc"),
      limit(windowSize)
    );

    const foundCampaigns = (await getDocs(campaignsQuery)).docs.map((doc) =>
      doc.data()
    );
    await initialUpdateData(foundCampaigns);

    const unsubscribe = onSnapshot(campaignsQuery, (snapshot) => {
      snapshot.docChanges().forEach(async (message) => {
        const campaign = message.doc.data();
        await liveUpdateCampaigns(message.type, campaign, foundCampaigns);
      });
    });
    unsubscribeArrayRef.current.push(unsubscribe);
  };

  const loadMoreCampaigns = async () => {
    if (!isFinished && !isLoading) {
      setIsLoading(true);
      const campaignsRef = getUserCampaignsRef(userId);
      const campaignsQuery = query(
        campaignsRef,
        orderBy("updatedAt", "desc"),
        startAfter(lastCampaignVisibleTimestamp),
        limit(windowSize)
      );

      const foundCampaigns = (await getDocs(campaignsQuery)).docs.map((doc) =>
        doc.data()
      );
      await initialUpdateData(foundCampaigns);

      const unsubscribe = onSnapshot(campaignsQuery, (snapshot) => {
        snapshot.docChanges().forEach(async (message) => {
          const campaign = message.doc.data();
          await liveUpdateCampaigns(message.type, campaign, foundCampaigns);
        });
      });
      unsubscribeArrayRef.current.push(unsubscribe);
    }
  };

  return {
    fetchedCampaigns,
    fetchedCampaignDetails,
    isLoading,
    isFinished,
    lastCampaignVisibleTimestamp,
    loadCampaigns,
    loadMoreCampaigns,
  };
};
