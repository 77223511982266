import React from "react";
import { Colors } from "../../utils/colors";
import Linkify from "react-linkify";

type CustomLinkifyProps = {
  children: React.ReactNode;
  altColor?: string;
  noUnderline?: boolean;
};

const CustomLinkify = (props: CustomLinkifyProps) => {
  const { children, altColor, noUnderline } = props;

  return (
    <Linkify
      componentDecorator={(text: string) => (
        <a
          href={text}
          target="_blank"
          rel="noreferrer"
          style={{
            color: altColor ?? Colors.BLUE5,
            textDecoration: !noUnderline ? "underline" : undefined,
          }}
        >
          {text.replace("mailto:", "")}
        </a>
      )}
    >
      {children}
    </Linkify>
  );
};

export default CustomLinkify;
