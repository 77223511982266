import {
  AccountData,
  FollowType,
  Follower,
  FollowerStatus,
  SavedFormQuestion,
  SavedFormResponse,
} from "@markit/common.types";
import React, { useCallback, useMemo, useState } from "react";
import ProfileItem from "../../ProfileItem";
import { Colors } from "../../../utils/colors";
import { HorizontalDivider } from "../../Dividers/HorizontalDivider";
import { useSelector } from "react-redux";
import { getAccountState } from "../../../redux/slices/accountSlice";
import { fetchUserEssentialFormResponse } from "../../../utils/userUtils";
import EssentialDataResponse from "../../FollowerProfile/EssentialDataResponse";
import LabelButton from "../../Buttons/LabelButton";
import ThreeDotsButton from "../../Buttons/ThreeDotsButton";
import { DropdownMenu, DropdownMenuItem } from "../../DropdownMenu";
import { readdFollower, unfollowUser } from "../../../utils/FollowUser";
import useAsyncOnMount from "../../../hooks/useAsyncEffectOnMount";

export const FOLLOWER_USER_FLEX_SECTIONS = [3, 1, 2, 4];
export const FOLLOWER_SHORT_USER_FLEX_SECTIONS = [5, 2, 2];

type ProfileFollowerItemProps = {
  item: AccountData;
  setProfilePreviewSelected: (
    profilePreviewSelected: AccountData | undefined
  ) => void;
  essentialQuestion: SavedFormQuestion | undefined;
  fetchedFollowerData: Follower[];
};

const ProfileFollowerItem = ({
  item,
  setProfilePreviewSelected,
  essentialQuestion,
  fetchedFollowerData,
}: ProfileFollowerItemProps) => {
  const { accountData } = useSelector(getAccountState).account;
  const [essentialData, setEssentialData] = useState<SavedFormResponse>();

  const styles = {
    subtext: { fontSize: 14, color: Colors.GRAY1 },
  };

  const flexSections = useMemo(
    () =>
      essentialQuestion
        ? FOLLOWER_USER_FLEX_SECTIONS
        : FOLLOWER_SHORT_USER_FLEX_SECTIONS,
    [essentialQuestion]
  );

  const viewProfilePreview = useCallback(() => {
    setProfilePreviewSelected(item);
  }, [setProfilePreviewSelected, item]);

  useAsyncOnMount(async () => {
    const essentialFormResponse = await fetchUserEssentialFormResponse(
      accountData.uid,
      item.uid,
      essentialQuestion ? essentialQuestion.id : ""
    );

    setEssentialData(essentialFormResponse);
  });

  const followerData = useMemo(
    () => fetchedFollowerData.find((follower) => follower.uid === item.uid),
    [fetchedFollowerData, item.uid]
  );

  const followSourceToShow = useMemo(
    () =>
      followerData
        ? followerData.followType === FollowType.UPLOAD
          ? followerData.spreadsheetData.fileName !== "Imported from Contacts"
            ? `Imported from ${followerData.spreadsheetData.fileName}`
            : followerData.spreadsheetData.fileName
          : followerData.followType
        : "---",
    [followerData]
  );

  const removeUserOnPress = useCallback(async () => {
    await unfollowUser(item.uid, accountData.uid, true);
  }, [accountData.uid, item.uid]);

  const readdUserOnPress = useCallback(async () => {
    await readdFollower(item.uid, accountData.uid);
  }, [accountData.uid, item.uid]);

  const dropdownItems: DropdownMenuItem[] = useMemo(() => {
    let dropdownItems: DropdownMenuItem[] = [
      {
        title: "View Follower",
        icon: "ion:eye",
        key: "view",
        onPress: viewProfilePreview,
      },
    ];
    if (followerData && followerData.status === FollowerStatus.SUBSCRIBED) {
      dropdownItems = dropdownItems.concat([
        {
          title: "Remove",
          icon: "ion:archive",
          key: "remove",
          onPress: removeUserOnPress,
          isDestructive: true,
        },
      ]);
    } else if (followerData && followerData.status === FollowerStatus.REMOVED) {
      dropdownItems = dropdownItems.concat([
        {
          title: "Re-Add",
          icon: "ion:person-add",
          key: "re-add",
          onPress: readdUserOnPress,
        },
      ]);
    }
    return dropdownItems;
  }, [followerData, readdUserOnPress, removeUserOnPress, viewProfilePreview]);

  const threeDots = useCallback(
    () => (
      <div style={{ marginRight: 20 }}>
        <DropdownMenu
          trigger={["click"]}
          placement="bottomRight"
          altWidth={200}
          dropdownItems={dropdownItems}
        >
          <ThreeDotsButton onPress={() => {}} />
        </DropdownMenu>
      </div>
    ),
    [dropdownItems]
  );

  return (
    <div>
      <div className="AlignedRow" style={{ paddingBlock: 10 }}>
        <div style={{ marginLeft: 14, flex: flexSections[0] }}>
          <ProfileItem profileItem={item} onItemPress={viewProfilePreview} />
        </div>
        <div className="AlignedRowSpaced" style={{ flex: flexSections[1] }}>
          <LabelButton
            color={
              followerData && followerData.status === FollowerStatus.SUBSCRIBED
                ? Colors.GREEN2
                : Colors.RED3
            }
            backgroundColor={Colors.WHITE1}
            text={followerData ? followerData.status : ""}
            containerStyles={{ marginLeft: 14 }}
          />
        </div>
        <div
          className="AlignedRowSpaced"
          style={{
            flex: flexSections[2],
            marginLeft: 14,
          }}
        >
          <span
            className="TextOverflowEllipsis"
            style={{ marginLeft: 14, maxWidth: "12vw", ...styles.subtext }}
          >
            {followSourceToShow}
          </span>
          {essentialQuestion ? null : threeDots()}
        </div>
        {essentialQuestion ? (
          <div
            className="AlignedRowSpaced"
            style={{
              flex: flexSections[3],
              marginLeft: 28,
            }}
          >
            <div style={{ ...styles.subtext }}>
              <EssentialDataResponse
                savedQuestion={essentialQuestion}
                formResponse={essentialData}
                userData={item}
                followerData={followerData}
              />
            </div>
            {threeDots()}
          </div>
        ) : null}
      </div>
      <HorizontalDivider />
    </div>
  );
};

export default ProfileFollowerItem;
