import "../../css/Containers/ContainerStyles.css";
import React, { ForwardedRef, forwardRef } from "react";
import { Icon } from "@iconify/react";
import { Colors } from "../../utils/colors";

interface SearchBoxContainerProps
  extends React.ComponentPropsWithoutRef<"input"> {
  placeholder: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: () => void;
  autoFocus?: boolean;
  value?: string;
  containerStyles?: React.CSSProperties;
  inputStyles?: React.CSSProperties;
}

const SearchBoxContainer = forwardRef<
  HTMLInputElement,
  SearchBoxContainerProps
>((props: SearchBoxContainerProps, ref: ForwardedRef<HTMLInputElement>) => {
  const {
    placeholder,
    onChange,
    onFocus,
    autoFocus,
    value,
    containerStyles,
    inputStyles,
  } = props;

  return (
    <div
      className="SearchBarContainer"
      style={{ marginTop: 14, ...containerStyles }}
    >
      <div className="SearchBarIconAndInputContainer">
        <Icon icon="ion:search" height={18} style={{ color: Colors.GRAY1 }} />
        <input
          id="searchboxcontainer"
          ref={ref}
          className="SearchBarInput"
          placeholder={placeholder}
          onChange={(e) => onChange(e)}
          onFocus={onFocus}
          autoFocus={autoFocus}
          value={value}
          style={inputStyles}
        />
      </div>
    </div>
  );
});

// Add a display name for the component
SearchBoxContainer.displayName = "SearchBoxContainer";

export default SearchBoxContainer;
