import "../../css/App.css";
import "../../css/FullEvent/FullEvent.css";
import "../../css/FullEvent/FullEventInputForm.css";
import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Event,
  CustomTicketV2,
  TicketV2,
  TicketType,
  FollowSourceType,
  SavedFormQuestion,
  FormAnswersV2,
  PromoCode,
  VerificationState,
  AccountData,
} from "@markit/common.types";
import { Theme, createTheme } from "@mui/material/styles";
import {
  MARKIT_ATTENDEE_FAQ,
  createEmptyFormAnswersV2,
  isCustomTickets,
  isEventTicketsPaid,
  getVisibleCustomTickets,
} from "@markit/common.utils";
import { Elements } from "@stripe/react-stripe-js";
import { Stripe, StripeElements, loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "../FullEventSubComponents/CheckoutForm";
import { useFullEventPrices } from "../../utils/useFullEventPrices";
import { API } from "../../API";
import { FormQuestionsInput } from "../UserInfoContainers/FormQuestionsInput";
import { FullEventTicketOptions } from "./FullEventTicketOptions";
import { userNumberOfTicketsInGroup } from "../../utils/eventUtils/userTicketUtils";
import {
  getTicketsRemainingInGroup,
  isUserOrganizer,
} from "../../utils/eventUtils/eventUtils";
import { MixpanelContext } from "../../context/AnalyticsService";
import { isDesktop } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { LoginState, getAccountState } from "../../redux/slices/accountSlice";
import { DataLoaders } from "../../redux/slices/dataSlice";
import { Colors } from "../../utils/colors";
import { paymentTimeoutHandler } from "../../utils/FullEventUtils/inputFormUtils";
import { useTheme } from "../../hooks/useTheme";
import {
  getUsersFormResponses,
  validateEventForm,
} from "../../utils/eventUtils/formUtils";
import { NameAndPhoneNumberInput } from "../UserInfoContainers/NameAndPhoneNumberInput";
import RectangleButton from "../../components/Buttons/RectangleButton";
import { getDocs, onSnapshot, query, where } from "../../firebase";
import { getTicketsRef } from "../../utils/FirebaseUtils";
import { useNavigate } from "../../hooks/useNavigate";
import { FullEventExistingTicketInfo } from "./FullEventExistingTicketInfo";
import {
  checkIfUserIsUnsubscribed,
  checkIfUserIsUnsubscribedMarkit,
} from "../../utils/userUtils";
import useOnUnmount from "../../hooks/useOnUnmount";
import useAsyncEffect from "../../hooks/useAsyncEffect";

export const themeMui: Theme = createTheme({
  palette: {
    primary: { main: Colors.BLUE1, contrastText: "#fff" },
    secondary: {
      main: Colors.BLUE5,
    },
  },
  typography: {
    button: {
      textTransform: "none",
    },
  },
});

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

export const testStripe = false;
export const stripePromise = loadStripe(
  (testStripe as true | false) === false
    ? "pk_live_51JmlHUHyH26KfMnYu2u6DinG6nsqa66oi8TwQTCZMlPndYL2WaiZ5kvHBs8gTGNx3pr2dYnH8W41sY2mydQZqqRJ00bJd7rzJZ"
    : "pk_test_51JmlHUHyH26KfMnY1Z7bGeXwpGWjEzoFWuTN8XPF318lLridIcPm6OzhvXUX4dWCKowFNad5AlXZF1jsdqP3xadP00dDAQztBS"
);

type FullEventInputFormProps = {
  event: Event;
  host: AccountData;
  setSubmitted: (submitted: boolean) => void;
  tixId: string;
  ticketData?: TicketV2;
  ticketToRedeem: CustomTicketV2 | undefined;
  promoCode?: PromoCode;
  promoterId: string;
  userReferrer: string;
  submitted: boolean;
  selectedCustomTicket: CustomTicketV2;
  setSelectedCustomTicket: (selectedTicket: CustomTicketV2 | undefined) => void;
  isEventAtCapacity: boolean;
  eventFormQuestions: SavedFormQuestion[];
};

/** Modal Form that is showed on the browser when the user wants to view the tickets **/
export const FullEventInputForm = memo(function FullEventInputFormFn(
  props: FullEventInputFormProps
) {
  const {
    event,
    host,
    setSubmitted,
    tixId,
    ticketData,
    ticketToRedeem,
    promoCode,
    promoterId,
    userReferrer,
    selectedCustomTicket,
    submitted,
    setSelectedCustomTicket,
    isEventAtCapacity,
    eventFormQuestions,
  } = props;
  const { account } = useSelector(getAccountState);
  const { linkTrackerId, loggedIn, accountData } = account;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [answers, setAnswers] = useState<FormAnswersV2>(
    createEmptyFormAnswersV2(eventFormQuestions, [])
  );
  const answersRef = useRef(answers);
  const unsubscribeRef = useRef<(() => void) | void>();
  const mixpanel = useContext(MixpanelContext);

  const [formErrorIds, setFormErrorIds] = useState<string[]>([]);
  const [uid, setUid] = useState("");
  const [chargeId, setChargeId] = useState<string>("");
  const [customerId, setCustomerId] = useState<string>("");
  const [options, setOptions] = useState<{
    clientSecret: string;
    appearance: {};
  }>();
  const [processing, setProcessing] = useState(false);
  const [paymentSheetLoading, setPaymentSheetLoading] = useState(false);
  const [verificationState, setVerificationState] = useState(
    VerificationState.UNVERIFIED
  );
  const [optedOut, setOptedOut] = useState(false);
  const [optedOutCreator, setOptedOutCreator] = useState(false);

  const [tempPhoneNumber, setTempPhoneNumber] = useState("");
  const [tempFullName, setTempFullName] = useState("");
  const [ticketQuantity, setTicketQuantity] = useState<number>(1);
  const [numAllTicketsUserInGroups, setNumAllTicketsUserInGroups] = useState<
    number[]
  >([]);
  const [numVisibleTicketsUserInGroups, setNumVisibleTicketsUserInGroups] =
    useState<number[]>([]);
  const [numTicketsRemainingInGroups, setNumTicketsRemainingInGroups] =
    useState<number[]>([]);
  const [customTicketIndex, setCustomTicketIndex] = useState(0);
  const [firstAvailableTicketReady, setFirstAvailableTicketReady] =
    useState(false);
  const [ticketForRedemption, setTicketForRedemption] = useState<
    CustomTicketV2 | undefined
  >(ticketToRedeem);
  const [isOrganizer, setIsOrganizer] = useState(false);
  const [isRedeemFreePromoCode, setIsRedeemFreePromoCode] = useState(false);
  const [isBlacklistedUser, setIsBlacklistedUser] = useState(false);

  const { theme } = useTheme();

  const isEmailBasedAccount = useMemo(
    () => accountData.email !== "",
    [accountData.email]
  );

  const showPaymentAndForm = useMemo(
    () => verificationState === VerificationState.VERIFIED,
    [verificationState]
  );

  const ticketsPaid = useMemo(() => isEventTicketsPaid(event), [event]);

  const visibleTickets = useMemo(
    () => getVisibleCustomTickets(event.customTickets, ticketForRedemption?.id),
    [event.customTickets, ticketForRedemption?.id]
  );

  useOnUnmount((_latestDeps) => {
    if (unsubscribeRef.current) {
      unsubscribeRef.current();
      unsubscribeRef.current = undefined;
    }
  }, []);

  // Needs to be called again once verification state changes to update values and also check if the user has tickets
  // if it's an existing user that verified their number
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useAsyncEffect(async () => {
    setTicketQuantity(visibleTickets[0].minQuantity);
    // Get the number of tickets user owns for all ticket types
    const getAllNumberOfTicketsInGroup = async () => {
      const ticketsInGroup = await Promise.all(
        event.customTickets.map(
          async (customTicket) =>
            await userNumberOfTicketsInGroup(
              event.id,
              uid || accountData.uid || "",
              customTicket.id
            )
        )
      );
      return ticketsInGroup;
    };
    // Get the number of tickets a user owns for only visible ticket types
    const getVisibleNumberOfTicketsInGroup = async () => {
      // waits for user to be verified before setting the userNumTicketsInGroup to anything other than an empty array
      // important to do this to make sure we don't accidentally set it before the user is even set, which can mess up first ticket to set
      if (verificationState === VerificationState.VERIFIED) {
        const ticketsInGroup = await Promise.all(
          visibleTickets.map(
            async (customTicket) =>
              await userNumberOfTicketsInGroup(
                event.id,
                uid || accountData.uid || "",
                customTicket.id
              )
          )
        );
        return ticketsInGroup;
      } else {
        return [];
      }
    };
    // Get the number of tickets remaining for only visible ticket types
    const getVisibleTicketsRemaining = async () => {
      // Gets the number of tickets the user has for each ticket group
      const remainingInGroup = await Promise.all(
        visibleTickets.map(
          async (customTicket) =>
            await getTicketsRemainingInGroup(customTicket, event)
        )
      );
      return remainingInGroup;
    };
    const [allTicketsInGroup, ticketsInGroup, remainingInGroup] =
      await Promise.all([
        getAllNumberOfTicketsInGroup(),
        getVisibleNumberOfTicketsInGroup(),
        getVisibleTicketsRemaining(),
      ]);
    setNumAllTicketsUserInGroups(allTicketsInGroup);
    setNumVisibleTicketsUserInGroups(ticketsInGroup);
    setNumTicketsRemainingInGroups(remainingInGroup);
  }, [accountData.uid, event, uid, verificationState, visibleTickets]);

  // fetch ticket info and refetch if you switch your selected ticket type
  useEffect(() => {
    const index = visibleTickets.findIndex(
      (customTicket) => customTicket.id === selectedCustomTicket.id
    );
    setCustomTicketIndex(index);
  }, [accountData.uid, event, selectedCustomTicket.id, uid, visibleTickets]);

  // set existing user and follower data if you're logged in
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useAsyncEffect(async () => {
    if (loggedIn === LoginState.LOGGED_IN) {
      setTempFullName(accountData.fullName);
      setUid(accountData.uid);
      setVerificationState(VerificationState.VERIFIED);

      // Check if logged in phone number is unsubscribed from markit number or creator
      // Even if using firebase OTP, we need them opted in so they can get confirmation texts
      const isUnsubscribedMarkit = await checkIfUserIsUnsubscribedMarkit(
        accountData.phoneNumber
      );
      setOptedOut(isUnsubscribedMarkit);

      const isUnsubscribed = await checkIfUserIsUnsubscribed(
        accountData.uid,
        event.createdBy
      );
      setOptedOutCreator(isUnsubscribed);

      if (accountData.phoneNumber !== "") {
        setTempPhoneNumber(accountData.phoneNumber);
      }
      const userOrganizer = await isUserOrganizer(event, accountData.uid);
      setIsOrganizer(userOrganizer);
      const userFormResponses = await getUsersFormResponses(
        event.createdBy,
        event.formQuestions,
        accountData.uid
      );
      setAnswers(
        createEmptyFormAnswersV2(eventFormQuestions, userFormResponses)
      );
    }
  }, [
    accountData.fullName,
    accountData.phoneNumber,
    accountData.uid,
    event,
    eventFormQuestions,
    loggedIn,
    tempPhoneNumber,
  ]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useAsyncEffect(async () => {
    if (
      tempPhoneNumber !== "" &&
      verificationState === VerificationState.VERIFIED
    ) {
      // check if blacklistUser
      const response = await API.user.fetchBlacklistUser({
        phoneNumber: tempPhoneNumber,
        uid: uid,
      });
      if (response.isBlacklisted) {
        mixpanel.track("Webapp: Blacklist User Attempting RSVP", {
          distinct_id: uid,
          phoneNumber: tempPhoneNumber,
          event_id: event.id,
        });
        setIsBlacklistedUser(true);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uid, verificationState]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useAsyncEffect(async () => {
    // Autofill any form inputs if user is not logged in and has verified their number
    // The logged in case is handled by the useEffect above
    if (
      loggedIn !== LoginState.LOGGED_IN &&
      verificationState === VerificationState.VERIFIED
    ) {
      const userFormResponses = await getUsersFormResponses(
        event.createdBy,
        event.formQuestions,
        uid
      );
      setAnswers(
        createEmptyFormAnswersV2(eventFormQuestions, userFormResponses)
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verificationState]);

  const {
    discountedTicketPriceCents,
    ticketFeesCents,
    totalAmountCents,
    currPromoCode,
    updatePromoCode,
    currTixId,
    updateTixId,
  } = useFullEventPrices({
    tixId,
    promoCode,
    selectedCustomTicket,
    ticketQuantity,
    eventHostId: host.uid,
  });

  const isDisabledInputSection = useMemo(
    () =>
      verificationState !== VerificationState.VERIFIED ||
      processing ||
      (totalAmountCents !== 0 && options === undefined) ||
      isOrganizer ||
      isEmailBasedAccount ||
      optedOut ||
      optedOutCreator,
    [
      isEmailBasedAccount,
      isOrganizer,
      optedOut,
      optedOutCreator,
      options,
      processing,
      totalAmountCents,
      verificationState,
    ]
  );

  const foundCustomTicketId = useMemo(
    () =>
      ticketForRedemption ? ticketForRedemption.id : selectedCustomTicket.id,
    [selectedCustomTicket, ticketForRedemption]
  );

  const openFAQ = () => {
    window.open(MARKIT_ATTENDEE_FAQ);
  };

  const redirectToConfirmation = useCallback(
    (uid?: string) => {
      if (uid) {
        dispatch(DataLoaders.user(uid));
        if (mixpanel) {
          mixpanel.track("Webapp: Successful RSVP To Event", {
            event_id: event.id,
            event_type: ticketsPaid ? "paid" : "free",
            customTicketId: foundCustomTicketId,
            numberOfTickets: ticketQuantity,
            customer_phone: tempPhoneNumber,
            amount: totalAmountCents,
          });
        }
      }
      navigate(`/e/${event.id}/i/` + (uid || ""), {
        replace: true,
        state: {
          eventId: event.id,
          userId: uid ?? "",
          isTicketBoughtSuccessfully: uid !== undefined,
        },
      });
      window.scrollTo(0, 0);
    },
    [
      dispatch,
      event.id,
      foundCustomTicketId,
      mixpanel,
      navigate,
      tempPhoneNumber,
      ticketQuantity,
      ticketsPaid,
      totalAmountCents,
    ]
  );

  const fetchPaymentSheetParams = useCallback(
    async (amount: number, isLatest: () => boolean) => {
      // if user has already paid or was invited to the event, show them their QR Code
      if (event) {
        if (isLatest()) {
          setPaymentSheetLoading(true);
          // console.log(
          //   "fetchPaymentSheetParams: amount:  " +
          //     amount +
          //     " fullName: " +
          //     tempFullName +
          //     " phoneNumber: " +
          //     tempPhoneNumber +
          //     " numTickets: " +
          //     ticketQuantity +
          //     " amount: " +
          //     amount
          // );
          setOptions(undefined);
        }
        // MUST MANUALLY CHANGE STRIPE TEST VS ACTUAL
        (testStripe ? API.testPayment : API.payment)
          .payWithStripe({
            ...(uid !== undefined && uid !== "" && { uid: uid }),
            eventId: event.id,
            fullName: tempFullName,
            phoneNumber: tempPhoneNumber,
            amount: amount,
            selectedTicketIndex: -1,
            numTickets: ticketQuantity,
            promoterId: promoterId,
          })
          .then((response) => {
            const { paymentIntent, chargeId, customer } = response;
            if (isLatest()) {
              setChargeId(chargeId);
              setCustomerId(customer);

              // get stripe info
              const stripeOptions = {
                // passing the client secret obtained in step 2
                clientSecret: paymentIntent,
                // Fully customizable with appearance API.
                appearance: {
                  theme: theme.StripeTheme.theme,
                  variables: {
                    colorPrimary: Colors.BLUE5,
                    colorBackground: theme.TertiaryBG.backgroundColor,
                  },
                },
              };
              setOptions(stripeOptions);
            }
          })
          .catch((e: any) => {
            alert(
              "An error occurred. Please try again. This page will be refreshed."
            );
            window.location.reload();
            if (mixpanel) {
              mixpanel.track(
                "Webapp: Failed PayWithStripe Initializing Payment",
                {
                  event_id: event.id,
                  event_type: ticketsPaid ? "paid" : "free",
                  customTicketId: foundCustomTicketId,
                  numberOfTickets: ticketQuantity,
                  customer_phone: tempPhoneNumber,
                  amount: totalAmountCents,
                  error_message: e.message,
                }
              );
            }
          })
          .finally(() => {
            setPaymentSheetLoading(false);
          });
      }
    },
    [
      event,
      foundCustomTicketId,
      mixpanel,
      promoterId,
      tempFullName,
      tempPhoneNumber,
      theme.StripeTheme.theme,
      theme.TertiaryBG.backgroundColor,
      ticketQuantity,
      ticketsPaid,
      totalAmountCents,
      uid,
    ]
  );

  // needed so that the answers state is always updated to the most recent by storing as a ref.
  // This was needed because of async issues with the useEffect for apple pay when there were form questions and the state was getting cleared.
  useEffect(() => {
    answersRef.current = answers;
  }, [answers]);

  useAsyncEffect(
    // eslint-disable-next-line react-hooks/exhaustive-deps
    async (_latestDeps, isLatest) => {
      if (
        discountedTicketPriceCents !== 0 &&
        verificationState === VerificationState.VERIFIED &&
        firstAvailableTicketReady
      ) {
        fetchPaymentSheetParams(discountedTicketPriceCents, isLatest);
      }
    },
    [
      firstAvailableTicketReady,
      discountedTicketPriceCents,
      fetchPaymentSheetParams,
      verificationState,
    ]
  );

  const rsvpToEvent = useCallback(async () => {
    const currentAnswers = answersRef.current;
    if (mixpanel) {
      mixpanel.track("Webapp: Attempting RSVP To Event", {
        event_id: event.id,
        event_type: ticketsPaid ? "paid" : "free",
        customTicketId: foundCustomTicketId,
        numberOfTickets: ticketQuantity,
        customer_phone: tempPhoneNumber,
        amount: totalAmountCents,
        form_responses: currentAnswers,
      });
    }

    // prevent host from getting ticket
    if (event.createdBy === uid || event.createdBy === accountData.uid) {
      alert("You are the host of this event and cannot get a ticket.");
      return;
    }

    // check if no ticket is selected, if so, then send alert
    if (isCustomTickets(event) && !selectedCustomTicket) {
      setSubmitted(false);
      alert("Please select a ticket option");
      if (mixpanel) {
        mixpanel.track("Webapp: Attempting RSVP Failed, No Ticket Selected", {
          event_id: event.id,
          event_type: ticketsPaid ? "paid" : "free",
          customTicketId: foundCustomTicketId,
          numberOfTickets: ticketQuantity,
          customer_phone: tempPhoneNumber,
        });
      }
      return;
    }

    // check if ticket is redeem, promoter, or promo code, and has already been redeemed
    if (
      ticketData &&
      (ticketData.redeemedBy !== "" ||
        ticketData.scanned ||
        (ticketData.redeemedBy === "" &&
          ticketData.uid !== "" &&
          ticketData.uid === uid))
    ) {
      setSubmitted(false);
      alert("This ticket has already been redeemed");
      if (mixpanel) {
        mixpanel.track(
          "Webapp: Attempting RSVP Failed, Ticket Already Redeemed",
          {
            event_id: event.id,
            event_type: ticketsPaid ? "paid" : "free",
            customTicketId: foundCustomTicketId,
            numberOfTickets: ticketQuantity,
            customer_phone: tempPhoneNumber,
            form_responses: currentAnswers,
          }
        );
      }
      return;
    }

    // only pass the chargeId if the user paid for the ticket (and did not use free ticket code)
    const finalChargeId = discountedTicketPriceCents !== 0 ? chargeId : "";

    if (mixpanel) {
      mixpanel.track("Webapp: Calling Enqueue RSVP To Event", {
        event_id: event.id,
        event_type: ticketsPaid ? "paid" : "free",
        customTicketId: foundCustomTicketId,
        numberOfTickets: ticketQuantity,
        customer_phone: tempPhoneNumber,
        amount: totalAmountCents,
        form_responses: currentAnswers,
      });
    }

    await API.rsvp
      .enqueueRSVPToEvent({
        toPhoneNumber: tempPhoneNumber,
        fullName: tempFullName,
        eventId: event.id,
        followSourceType: isDesktop
          ? FollowSourceType.DESKTOP_WEB
          : FollowSourceType.MOBILE_WEB,
        answers: eventFormQuestions.length !== 0 ? currentAnswers : undefined,
        customTicketId: foundCustomTicketId,
        numberOfTickets: ticketQuantity,
        redeemTicketId: currTixId,
        promoCodeId: currPromoCode ? currPromoCode.id : "",
        promoterId: promoterId ?? "",
        linkId: linkTrackerId,
        userReferrer: userReferrer,
        amount: discountedTicketPriceCents,
        chargeId: finalChargeId,
        customerId: customerId,
        uid: "",
      })
      .then(async (response) => {
        const { success, firstTicketIdTracker } = response;
        if (!success) {
          if (mixpanel) {
            mixpanel.track("Webapp: Failed RSVP To Event", {
              event_id: event.id,
              event_type: ticketsPaid ? "paid" : "free",
              customTicketId: foundCustomTicketId,
              numberOfTickets: ticketQuantity,
              customer_phone: tempPhoneNumber,
              error_message: "success returned false",
              form_responses: currentAnswers,
              failed_from_catch: false,
            });
          }

          // Redirect to confirmation page instead to show error
          redirectToConfirmation();
        } else {
          // listen for ticket creation before redirecting to confirmation page
          const ticketsRef = getTicketsRef(event.id);
          const ticketIdTrackerQuery = query(
            ticketsRef,
            where("id", "==", firstTicketIdTracker)
          );

          // redirect with an error if not redirected within 20 seconds
          const timeout = setTimeout(async () => {
            // check one more time if the ticket exists
            const ticketsRef = getTicketsRef(event.id);
            const ticketIdTrackerQuery = query(
              ticketsRef,
              where("id", "==", firstTicketIdTracker)
            );
            const ticketIdTrackerSnapshot = await getDocs(ticketIdTrackerQuery);
            if (!ticketIdTrackerSnapshot.empty) {
              const newTicket = ticketIdTrackerSnapshot.docs[0].data();

              // only redirect if the ticket is owned by the current user
              // TODO (jonathan): weird case exists where if the user isn't logged in, it could take them to the original ticket owner's confirmation page
              // This would only happen if the got defaulted to Markit verification code and not firebase
              if (
                newTicket.uid &&
                (accountData.uid === "" || newTicket.uid === accountData.uid)
              ) {
                redirectToConfirmation(newTicket.uid);
              } else {
                redirectToConfirmation();
              }
            } else {
              if (mixpanel) {
                mixpanel.track("Webapp: RSVP Processing Timeout", {
                  event_id: event.id,
                  event_type: ticketsPaid ? "paid" : "free",
                  customTicketId: foundCustomTicketId,
                  numberOfTickets: ticketQuantity,
                  customer_phone: tempPhoneNumber,
                });
              }

              redirectToConfirmation();
            }
          }, 20000);

          const unsubscribe = onSnapshot(ticketIdTrackerQuery, (snapshot) => {
            snapshot.docChanges().forEach(async (change) => {
              const newTicket = change.doc.data();

              // Only redirect when the ticket is owned by the current user
              // TODO (jonathan): weird case exists where if the user isn't logged in, it could take them to the original ticket owner's confirmation page
              // This would only happen if the got defaulted to Markit verification code and not firebase
              if (
                newTicket.uid &&
                (accountData.uid === "" || newTicket.uid === accountData.uid)
              ) {
                // make sure we don't redirect again since it found the ticket
                clearTimeout(timeout);

                redirectToConfirmation(newTicket.uid);
              }
            });
          });
          unsubscribeRef.current = unsubscribe;
        }
      })
      .catch((e: any) => {
        if (mixpanel) {
          mixpanel.track("Webapp: Failed RSVP To Event", {
            event_id: event.id,
            event_type: ticketsPaid ? "paid" : "free",
            customTicketId: foundCustomTicketId,
            numberOfTickets: ticketQuantity,
            fullName: tempFullName,
            customer_phone: tempPhoneNumber,
            error_message: e.message,
            form_responses: currentAnswers,
            failed_from_catch: true,
            toPhoneNumber: tempPhoneNumber,
            followSourceType: isDesktop
              ? FollowSourceType.DESKTOP_WEB
              : FollowSourceType.MOBILE_WEB,
            answers:
              eventFormQuestions.length !== 0 ? currentAnswers : undefined,
            redeemTicketId: currTixId,
            promoCodeId: currPromoCode ? currPromoCode.id : "",
            promoterId: promoterId ?? "",
            linkId: linkTrackerId,
            userReferrer: userReferrer,
            amount: discountedTicketPriceCents,
            chargeId: finalChargeId,
            customerId: customerId,
            uid: "",
          });
        }

        // Redirect to confirmation page instead to show error
        redirectToConfirmation();
      });
  }, [
    mixpanel,
    event,
    uid,
    accountData.uid,
    selectedCustomTicket,
    ticketData,
    chargeId,
    tempPhoneNumber,
    tempFullName,
    eventFormQuestions.length,
    foundCustomTicketId,
    ticketQuantity,
    currTixId,
    currPromoCode,
    promoterId,
    linkTrackerId,
    userReferrer,
    discountedTicketPriceCents,
    customerId,
    ticketsPaid,
    totalAmountCents,
    setSubmitted,
    redirectToConfirmation,
  ]);

  const notYetRedeemed = useMemo(
    () => ticketData && ticketData.redeemedBy === "" && !ticketData.scanned,
    [ticketData]
  );

  // Determines if the number of tickets that the user is going to get exceeds the event capacity
  // false if you are redeeming a valid ticket too and also if you are redeeming a free ticket promo code
  const willExceedCapacity = useMemo(
    () =>
      selectedCustomTicket.quantityAvailable !== 0 &&
      ticketQuantity > numTicketsRemainingInGroups[customTicketIndex] &&
      !notYetRedeemed &&
      !isRedeemFreePromoCode,
    [
      customTicketIndex,
      isRedeemFreePromoCode,
      notYetRedeemed,
      numTicketsRemainingInGroups,
      selectedCustomTicket.quantityAvailable,
      ticketQuantity,
    ]
  );

  // Determines if the number of tickets that the user is going to get exceeds the maximum quantity a user
  // can get of a specific ticket type
  const willExceedUserCapacity = useMemo(
    () =>
      numVisibleTicketsUserInGroups[customTicketIndex] >=
      selectedCustomTicket.maxQuantity,
    [
      customTicketIndex,
      numVisibleTicketsUserInGroups,
      selectedCustomTicket.maxQuantity,
    ]
  );

  // Determines if the number of tickets that the user is going to get exceeds the maximum use count for
  // a specific promo
  const willExceedPromoCapacity = useMemo(
    () =>
      currPromoCode &&
      currPromoCode.usedCount + ticketQuantity > currPromoCode.maxUseCount,
    [currPromoCode, ticketQuantity]
  );

  const inputFormErrorPrompt = useMemo(
    () =>
      !paymentSheetLoading && firstAvailableTicketReady
        ? isOrganizer
          ? "You are an organizer for the event and cannot get a ticket."
          : willExceedCapacity && !isEventAtCapacity
          ? "The number of tickets you are trying to purchase exceeds the event's capacity."
          : willExceedUserCapacity && !isEventAtCapacity
          ? `You already have the max ticket quantity allowed for ${
              visibleTickets.length > 1 ? "each ticket type for" : ""
            } this event.`
          : willExceedPromoCapacity && !isEventAtCapacity
          ? "The number of tickets you are trying to purchase exceeds the max use count for this promo code."
          : undefined
        : undefined,
    [
      paymentSheetLoading,
      firstAvailableTicketReady,
      isOrganizer,
      willExceedCapacity,
      isEventAtCapacity,
      willExceedUserCapacity,
      visibleTickets.length,
      willExceedPromoCapacity,
    ]
  );

  // Determines if the checkout button should be disabled
  const checkoutBtnDisabled = useMemo(
    () =>
      submitted ||
      (!isRedeemFreePromoCode &&
        !currTixId &&
        (willExceedUserCapacity ||
          willExceedCapacity ||
          willExceedPromoCapacity)) ||
      isDisabledInputSection,
    [
      currTixId,
      isDisabledInputSection,
      isRedeemFreePromoCode,
      submitted,
      willExceedCapacity,
      willExceedPromoCapacity,
      willExceedUserCapacity,
    ]
  );

  const totalUserTickets = useMemo(() => {
    return numAllTicketsUserInGroups.reduce((sum, element) => sum + element, 0);
  }, [numAllTicketsUserInGroups]);

  const showPurchaseError = useCallback(() => {
    if (willExceedUserCapacity) {
      alert(
        "You have reached the maximum number of tickets for this selected ticket option. "
      );
      return true;
    }

    if (willExceedPromoCapacity) {
      alert(
        "The number of tickets you are trying to purchase exceeds the max use count for this promo code "
      );
      return true;
    }

    const errorIds = validateEventForm(answers, eventFormQuestions);
    if (errorIds.length > 0) {
      setFormErrorIds(errorIds);
      alert("Please answer the required questions.");
      return true;
    }
    return false;
  }, [
    answers,
    eventFormQuestions,
    willExceedPromoCapacity,
    willExceedUserCapacity,
  ]);

  // For Credit Card Payments
  const paymentConfirmedOnPress = useCallback(
    async (stripe: Stripe, elements: StripeElements) => {
      if (showPurchaseError()) {
        return;
      }

      if (mixpanel) {
        mixpanel.track("Webapp: Attempting Ticket Purchase", {
          event_id: event.id,
          event_type: ticketsPaid ? "paid" : "free",
          customTicketId: foundCustomTicketId,
          numberOfTickets: ticketQuantity,
          customer_phone: tempPhoneNumber,
          apple_pay: false,
          amount: totalAmountCents,
          form_answers: answers,
        });
      }

      setProcessing(true);

      // Wrap Stripe's confirmPayment in a Promise for use in Promise.race
      const paymentPromise = new Promise((resolve, reject) => {
        stripe
          .confirmPayment({
            // `Elements` instance that was used to create the Payment Element
            elements,
            confirmParams: {
              return_url: "https://markitsocial.net/events/?id=" + event.id,
            },
            redirect: "if_required",
          })
          .then((result) => {
            if (result.error) {
              resolve(new Error(result.error.message));
            } else {
              resolve(result);
            }
          })
          .catch((error) => {
            resolve(new Error(error));
          });
      });

      // Set payment timeout of 30 seconds
      const paymentTimeout = 30000;
      const timeoutPromise = new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(new Error("Payment processing timed out. Please try again."));
        }, paymentTimeout);
      });

      const result = await Promise.race([paymentPromise, timeoutPromise]);

      if (result instanceof Error) {
        if (
          result.message === "Payment processing timed out. Please try again."
        ) {
          await paymentTimeoutHandler(
            event.id,
            tempPhoneNumber,
            "",
            totalAmountCents,
            ticketQuantity,
            ticketsPaid ? "paid" : "free",
            foundCustomTicketId,
            tempPhoneNumber,
            mixpanel
          );

          alert(
            "Your payment has timed out. Please try again. This page will be refreshed."
          );
          window.location.reload();
        } else {
          // This point will only be reached if there is an immediate error when
          // confirming the payment. Show error to your customer (for example, payment
          // details incomplete)
          alert(
            "An error occurred with your payment method: " + result.message
          );
          mixpanel.track("Webapp: Error Buying a Ticket", {
            event_id: event.id,
            event_type: ticketsPaid ? "paid" : "free",
            error_message: result.message,
            customTicketId: foundCustomTicketId,
            numberOfTickets: ticketQuantity,
            customer_phone: tempPhoneNumber,
          });
          setProcessing(false);
        }
      } else {
        if (mixpanel) {
          mixpanel.track("Webapp: Bought a Ticket", {
            event_id: event.id,
            event_type: ticketsPaid ? "paid" : "free",
            customTicketId: foundCustomTicketId,
            numberOfTickets: ticketQuantity,
            amount: totalAmountCents,
            customer_phone: tempPhoneNumber,
            apple_pay: false,
          });
        }
        await rsvpToEvent();
      }
    },
    [
      answers,
      event.id,
      foundCustomTicketId,
      mixpanel,
      rsvpToEvent,
      showPurchaseError,
      tempPhoneNumber,
      ticketQuantity,
      ticketsPaid,
      totalAmountCents,
    ]
  );

  // Displays the checkout button for the user to get the
  const paymentOrRsvpElement = useMemo(() => {
    if (currTixId === "" && selectedCustomTicket.type === TicketType.PAID) {
      if (options !== undefined) {
        return !inputFormErrorPrompt ? (
          <div
            style={{
              pointerEvents:
                willExceedCapacity ||
                willExceedPromoCapacity ||
                willExceedUserCapacity
                  ? "none"
                  : "all",
              opacity:
                willExceedCapacity ||
                willExceedPromoCapacity ||
                willExceedUserCapacity
                  ? 0.3
                  : 1,
            }}
            className="checkoutElement"
          >
            <Elements stripe={stripePromise} options={options}>
              <CheckoutForm
                customerPhone={tempPhoneNumber}
                totalAmount={totalAmountCents}
                customTicketId={foundCustomTicketId}
                processing={processing}
                setProcessing={setProcessing}
                isReadyForPayment={
                  verificationState === VerificationState.VERIFIED
                }
                showPurchaseError={showPurchaseError}
                clientSecret={options.clientSecret}
                event={event}
                onPress={(stripe: Stripe, elements: StripeElements) =>
                  // For handling card payments
                  paymentConfirmedOnPress(stripe, elements)
                }
                rsvpToEvent={rsvpToEvent}
                ticketQuantity={ticketQuantity}
                foundCustomTicketId={foundCustomTicketId}
                disabled={
                  willExceedCapacity ||
                  willExceedPromoCapacity ||
                  willExceedUserCapacity
                }
              />
            </Elements>
          </div>
        ) : null;
      } else {
        return (
          <h3 className="LoadingText" style={theme.PrimaryText}>
            ...
          </h3>
        );
      }
    } else {
      return inputFormErrorPrompt ? null : (
        <div style={{ marginTop: 14 }}>
          <RectangleButton
            buttonLabel={
              <span>
                {currTixId !== "" || isRedeemFreePromoCode
                  ? "Redeem Ticket"
                  : event && selectedCustomTicket.type === TicketType.REQUEST
                  ? "Request"
                  : "RSVP"}
              </span>
            }
            onPress={async () => {
              if (!showPurchaseError()) {
                setSubmitted(true);
                await rsvpToEvent();
              }
            }}
            theme={theme}
            altPaddingVert={12}
            disabled={checkoutBtnDisabled || submitted}
            loading={submitted}
          />
        </div>
      );
    }
  }, [
    currTixId,
    selectedCustomTicket.type,
    options,
    inputFormErrorPrompt,
    willExceedCapacity,
    willExceedPromoCapacity,
    willExceedUserCapacity,
    tempPhoneNumber,
    totalAmountCents,
    foundCustomTicketId,
    processing,
    verificationState,
    showPurchaseError,
    event,
    rsvpToEvent,
    ticketQuantity,
    paymentConfirmedOnPress,
    theme,
    isRedeemFreePromoCode,
    checkoutBtnDisabled,
    submitted,
    setSubmitted,
  ]);

  return (
    <div
      className={isDesktop ? "InputFormContainerDesktop" : ""}
      style={{ pointerEvents: processing ? "none" : "all" }}
    >
      <NameAndPhoneNumberInput
        host={host}
        verificationState={verificationState}
        setVerificationState={setVerificationState}
        tempFullName={tempFullName}
        setTempFullName={setTempFullName}
        tempPhoneNumber={tempPhoneNumber}
        setTempPhoneNumber={setTempPhoneNumber}
        setUid={setUid}
        processing={processing}
        setIsOrganizer={setIsOrganizer}
        event={event}
        optedOut={optedOut}
        setOptedOut={setOptedOut}
        optedOutCreator={optedOutCreator}
        setOptedOutCreator={setOptedOutCreator}
      />
      {/** For when an acount is being converted to subaccount and phone number gets moved */}
      {/* {event.createdBy === "qB9dhzxgPYf62LHiMQh7yhSifaX2" ||
      host.fullName === "Glamhospitality" ? (
        <></>
      ) : */}
      {isBlacklistedUser ? (
        <div
          className={
            isDesktop ? "TicketIssueContainerDesktop" : "TicketIssueContainer"
          }
          style={{ ...theme.SecondaryBG, gap: 5 }}
        >
          <div
            style={{ ...theme.PrimaryText, fontSize: 16, fontWeight: "600" }}
          >
            Attention!
          </div>
          <div style={{ paddingTop: 5 }}>
            <span style={{ ...theme.PrimaryText, fontSize: 14 }}>
              You are blacklisted from purchasing any tickets because you have
              disputed a previous charge. Please contact the hotline by visiting
              our FAQ below.
            </span>
          </div>
        </div>
      ) : (
        <>
          {totalUserTickets > 0 ? (
            <FullEventExistingTicketInfo
              event={event}
              userId={uid}
              totalUserTickets={totalUserTickets}
              numTicketsUserInGroups={numAllTicketsUserInGroups}
            />
          ) : null}
          {eventFormQuestions.length > 0 ? (
            <div
              className={
                isDesktop
                  ? "HostQuestionsContainerDesktop"
                  : "HostQuestionsContainer"
              }
              style={theme.SecondaryBG}
            >
              <FormQuestionsInput
                formQuestions={eventFormQuestions}
                answers={answers}
                setAnswers={setAnswers}
                formErrorIds={formErrorIds}
                setFormErrorIds={setFormErrorIds}
                disabled={isDisabledInputSection}
              />
            </div>
          ) : null}
          <div
            className={isDesktop ? "TicketContainerDesktop" : "TicketContainer"}
            style={theme.SecondaryBG}
          >
            <FullEventTicketOptions
              event={event}
              isRedeemTicket={currTixId !== ""}
              ticketForRedemption={ticketForRedemption}
              setTicketForRedemption={setTicketForRedemption}
              promoCode={currPromoCode}
              tixId={currTixId}
              pCodeId={promoCode?.id ?? ""}
              selectedCustomTicket={selectedCustomTicket}
              selectedCustomTicketIndex={customTicketIndex}
              setSelectedCustomTicket={setSelectedCustomTicket}
              firstAvailableTicketReady={firstAvailableTicketReady}
              setFirstAvailableTicketReady={setFirstAvailableTicketReady}
              ticketQuantity={ticketQuantity}
              setTicketQuantity={setTicketQuantity}
              ticketFeesCents={ticketFeesCents}
              totalAmountCents={totalAmountCents}
              discountedTicketPriceCents={discountedTicketPriceCents}
              numTicketsUserInGroups={numVisibleTicketsUserInGroups}
              disabled={isDisabledInputSection}
              verifiedDetails={verificationState === VerificationState.VERIFIED}
              updatePromoCode={updatePromoCode}
              updateTixId={updateTixId}
              setIsRedeemFreePromoCode={setIsRedeemFreePromoCode}
              isRedeemFreePromoCode={isRedeemFreePromoCode}
              inputFormErrorPrompt={inputFormErrorPrompt}
            />
            {showPaymentAndForm ? (
              <div style={{ position: "relative" }}>{paymentOrRsvpElement}</div>
            ) : null}
          </div>
        </>
      )}
      {ticketsPaid ? (
        <div
          className={
            isDesktop ? "TicketIssueContainerDesktop" : "TicketIssueContainer"
          }
          style={theme.SecondaryBG}
        >
          <div
            style={{ ...theme.PrimaryText, fontSize: 16, fontWeight: "600" }}
          >
            Ticket Issue?
          </div>
          <div
            className="Banner"
            style={{
              ...theme.TertiaryBG,
              marginTop: "0.5em",
              justifyContent: "center",
              padding: 12,
            }}
            onClick={openFAQ}
          >
            <div className="LeftContainer" style={{ width: "34%" }}>
              <div className="BannerFullName" style={{ color: "#000" }}>
                <p
                  style={{
                    ...theme.PrimaryText,
                    padding: 0,
                    margin: 0,
                    fontSize: 14,
                    fontWeight: "600",
                  }}
                >
                  Visit Our FAQ
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
});
