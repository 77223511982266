import React, { memo } from "react";
import { Colors } from "../utils/colors";

type InitialsPicProps = {
  fullName?: string;
  isLink?: boolean;
  size?: number;
  pointer?: boolean;
};

export const InitialsPic = memo(function InitialsPicFn(
  props: InitialsPicProps
) {
  const { fullName, isLink, size, pointer } = props;
  const getInitials = (fullName: string) => {
    const parts = fullName.split(" ");
    let initials = "";
    for (let i = 0; i < parts.length && i < 2; i++) {
      if (parts[i].length > 0 && parts[i] !== "") {
        initials += parts[i][0];
      }
    }
    return initials.toUpperCase();
  };
  return fullName ? (
    <span
      style={{
        fontSize: size,
        cursor: isLink || pointer ? "pointer" : "auto",
        color: Colors.WHITE,
        fontWeight: 500,
        fontFamily: "system-ui",
      }}
    >
      {getInitials(fullName)}
    </span>
  ) : null;
});
