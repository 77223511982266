import React, { useCallback, useState } from "react";
import "../../css/GlobalStyles.css";
import PopupModalContainer from "../Containers/PopupModalContainer";
import {
  AccountData,
  Event,
  Follower,
  SavedFormQuestion,
  SavedFormResponse,
} from "@markit/common.types";
import { getEventData } from "../../utils/FirebaseUtils";
import { useTheme } from "../../hooks/useTheme";
import SingleCustomDataItem from "./SingleCustomDataItem";
import { GetDateWithTime, isExternalGenericLink } from "@markit/common.utils";
import { Icon } from "@iconify/react";
import { HorizontalDivider } from "../Dividers/HorizontalDivider";
import useAsyncOnMount from "../../hooks/useAsyncEffectOnMount";

interface SingleCustomDataProps {
  savedQuestion: SavedFormQuestion;
  formResponse: SavedFormResponse | undefined;
  userData: AccountData;
  followerData: Follower | undefined;
  closeSingleCustomData: () => void;
}

const SingleCustomData = (props: SingleCustomDataProps) => {
  const {
    savedQuestion,
    formResponse,
    userData,
    followerData,
    closeSingleCustomData,
  } = props;
  const { theme } = useTheme();

  const [sourceEvent, setSourceEvent] = useState<Event>();

  const editOnPress = useCallback(async () => {
    alert("Edit coming soon...");
  }, []);

  const styles = {
    bodyText: {
      color: formResponse ? theme.PrimaryText.color : theme.SubText.color,
      fontSize: 14,
      fontWeight: 500,
    },

    sectionContainer: {
      marginTop: 4,
      paddingBlock: 14,
      borderRadius: 12,
    },

    subSectionContainer: {
      paddingInline: 14,
    },
  };

  useAsyncOnMount(async () => {
    if (formResponse && formResponse.eventId) {
      const event = await getEventData(formResponse.eventId);
      setSourceEvent(event);
    }
  });

  return (
    <PopupModalContainer
      customHeader={
        <div className="AlignedRowSpaced">
          <span
            style={{
              color: theme.PrimaryText,
              fontSize: 16,
              fontWeight: "600",
              position: "absolute", // Absolute positioning for centering
              left: "50%",
              transform: "translateX(-50%)", // Center text horizontally
            }}
          >
            Details
          </span>
          <div
            className="LargePopupPanelGrayButton"
            onClick={editOnPress}
            style={{ marginLeft: "auto" }} // Pushes the button to the far right
          >
            <span
              style={{
                color: theme.PrimaryText,
                fontSize: 16,
                fontWeight: "600",
              }}
            >
              Edit
            </span>
          </div>
        </div>
      }
      valueComp={
        <div
          style={{
            ...styles.sectionContainer,
            backgroundColor: theme.NewPrimaryBG.backgroundColor,
          }}
        >
          {savedQuestion && userData ? (
            <SingleCustomDataItem
              savedQuestion={savedQuestion}
              formResponse={formResponse}
              userData={userData}
              followerData={followerData}
              detailView
            />
          ) : null}
          <div style={styles.subSectionContainer}>
            <div style={{ marginBottom: 4, fontSize: 14 }}>
              <span className="bodySubtext">Collected On</span>
            </div>
            <div>
              <span style={styles.bodyText}>
                {formResponse
                  ? GetDateWithTime(formResponse.lastResponded, true)
                  : "N/A"}
              </span>
            </div>
          </div>
          <HorizontalDivider altMargin={14} />
          <div style={styles.subSectionContainer}>
            <div style={{ marginBottom: 4 }}>
              <span className="bodySubtext">Source</span>
            </div>
            <div>
              <span style={styles.bodyText}>
                {formResponse
                  ? sourceEvent
                    ? `${
                        isExternalGenericLink(sourceEvent.eventType)
                          ? "Submitted to Link:"
                          : "Went to Event:"
                      } ${sourceEvent.title}`
                    : "Followed Profile"
                  : "N/A"}
              </span>
            </div>
          </div>
        </div>
      }
      noExit
      closeOnOutsidePress
      closeModal={closeSingleCustomData}
      positionOnTop
    />
  );
};

export default SingleCustomData;
