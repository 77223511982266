import "../../css/Containers/ContainerStyles.css";
import React, { ReactNode } from "react";
import { useTheme } from "../../hooks/useTheme";
import StandardBorderedContainer from "./StandardBorderedContainer";
import { HorizontalDivider } from "../Dividers/HorizontalDivider";
import { CircularProgress } from "@mui/material";
import { Colors } from "../../utils/colors";

type StandardListContainerProps = {
  searchComp: ReactNode;
  headerRightComp?: ReactNode;
  subHeaderComp?: ReactNode;
  gridHeaderComp: ReactNode;
  listComp: ReactNode;
  subListComp?: ReactNode;
  isLoading: boolean;
};

/* TODO: (Peter): This will be refactored to StandardListContainerNew when we change the Contacts, Lists, etc. screens */
/* Used for a standard list container for displaying items in a StandardBorderContainer, ie. EventDashboard. Standardize to ensure Flatlist scrolling behavior works */
const StandardListContainer = (props: StandardListContainerProps) => {
  const {
    searchComp,
    headerRightComp,
    subHeaderComp,
    gridHeaderComp,
    isLoading,
    listComp,
    subListComp,
  } = props;
  const { theme } = useTheme();

  const styles = {
    headerContainer: {
      paddingInline: 14,
      paddingBottom: 12,
      gap: 14,
    },

    guestListView: {
      paddingBlock: 14,
    },

    listView: {
      height: 560,
      paddingBottom: 50,
    },
  };

  return (
    <StandardBorderedContainer
      containerStyles={{
        width: "100%",
        backgroundColor: theme.SecondaryBG.backgroundColor,
        borderRadius: 20,
      }}
    >
      <div className="ColumnNormal" style={styles.guestListView}>
        <div className="RowNormal" style={{ ...styles.headerContainer }}>
          {searchComp}
          {headerRightComp}
        </div>
        {subHeaderComp}
        <HorizontalDivider />
        {gridHeaderComp}
        <HorizontalDivider />
        {!isLoading ? (
          <div
            className="HideScrollbar"
            style={{ ...styles.listView, overflowY: "scroll" }}
          >
            {listComp}
            {subListComp}
          </div>
        ) : (
          <CircularProgress
            style={{
              color: Colors.GRAY1,
              alignSelf: "center",
              marginTop: "24px",
              marginLeft: "14px",
            }}
            size={30}
          />
        )}
      </div>
    </StandardBorderedContainer>
  );
};

export default StandardListContainer;
