import "../../css/GlobalStyles.css";
import { CSSProperties, ReactNode, memo } from "react";
import { Colors } from "../../utils/colors";
import { CreatorPanelTabs } from "../Tabs/CreatorPanelTabs";

export type CreatorModeHeaderTab = {
  tabNames: string[];
  tabValue: number;
  setTabValue: (tabValue: number) => void;
};

type CreatorModePanelWrapperProps = {
  title: string | ReactNode;
  children: ReactNode;
  headerRight: ReactNode;
  headerTabs?: CreatorModeHeaderTab;
  headerContainerStyles?: CSSProperties;
};

const CreatorModePanelWrapper = (props: CreatorModePanelWrapperProps) => {
  const { title, children, headerRight, headerTabs, headerContainerStyles } =
    props;

  return (
    <div className="ColumnNormal" style={{ overflow: "auto", height: "100%" }}>
      <div
        className="AlignedRowSpaced"
        style={{
          alignItems: "flex-start",
          padding: 30,
          borderBottom: `1px solid ${Colors.GRAY11}`,
          ...headerContainerStyles,
        }}
      >
        <div className="ColumnNormal" style={{ gap: 20 }}>
          <span style={{ fontSize: 32, fontWeight: 600 }}>{title}</span>
          {headerTabs ? (
            <CreatorPanelTabs
              tabValue={headerTabs.tabValue}
              setTabValue={headerTabs.setTabValue}
              tabNames={headerTabs.tabNames}
            />
          ) : null}
        </div>
        {headerRight}
      </div>
      <div style={{ backgroundColor: Colors.WHITE1, height: "100%" }}>
        {children}
      </div>
    </div>
  );
};

export default memo(CreatorModePanelWrapper);
