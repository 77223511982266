import "../css/FullEvent/FullEvent.css";
import "../css/GlobalStyles.css";
import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { isDesktop } from "react-device-detect";
import powered from "../assets/WhiteLabel/Powered.png";
import RCA from "../assets/WhiteLabel/RCA.png";
import { useDispatch, useSelector } from "react-redux";
import {
  LoginState,
  accountActions,
  getAccountState,
} from "../redux/slices/accountSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { Colors } from "../utils/colors";
import { MixpanelContext } from "../context/AnalyticsService";
import { useTheme } from "../hooks/useTheme";
import { ThemeType } from "@markit/common.types";
import { useOnMount } from "../hooks/useOnMount";
import HeaderProfilePic from "./HeaderProfilePic";
import BlackHoverButton from "./Buttons/BlackHoverButton";
import MarkitHeaderLogo from "./MarkitHeaderLogo";
import CreateDropdownMenu from "./DropdownsAndTabs/CreateDropdownMenu";
import { Icon } from "@iconify/react";
import { NavigationId } from "../navigation/AppParamList";
import { MARKITAI } from "@markit/common.utils";

type TopHeaderProps = {
  hideLogin?: boolean;
  showRCA?: boolean;
  eventTheme?: ThemeType;
  isOnProfilePage?: boolean;
  hideCreate?: boolean;
  whiteLogo?: boolean;
};

const TopHeader = memo(function TopHeaderFn(props: TopHeaderProps) {
  const {
    hideLogin,
    showRCA,
    eventTheme,
    isOnProfilePage,
    hideCreate,
    whiteLogo = false,
  } = props;
  const mixpanel = useContext(MixpanelContext);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { account } = useSelector(getAccountState);
  const { isRCA, loggedIn, accountData } = account;
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [exploreHover, setExploreHover] = useState(false);
  const [onExplore, setOnExplore] = useState(false);
  const [onExploreSubPage, setOnExploreSubPage] = useState(false);
  const [onCreate, setOnCreate] = useState(false);

  const { theme } = useTheme(eventTheme, hideLogin);

  useOnMount(() => {
    const pathname = location.pathname;
    if (pathname === "/explore") {
      setOnExplore(true);
    } else if (pathname.includes("/explore")) {
      setOnExploreSubPage(true);
    }
    if (pathname === "/create" || pathname.includes("/createLink")) {
      setOnCreate(true);
    }
  });

  const onMarkitClick = useCallback(() => {
    if (mixpanel) {
      mixpanel.track("Webapp: Pressed Header Markit Button", {
        pathname: location.pathname,
      });
    }
    if (loggedIn === LoginState.LOGGED_IN && accountData.inCreatorMode) {
      navigate("/home");
    } else {
      navigate("/");
    }
  }, [
    accountData.inCreatorMode,
    location.pathname,
    loggedIn,
    mixpanel,
    navigate,
  ]);

  const loginUser = useCallback(() => {
    dispatch(accountActions.setRedirectPath(location.pathname));
    navigate("/welcome");
  }, [dispatch, location.pathname, navigate]);

  const navigateCreateOnPress = useCallback(() => {
    if (
      location.pathname === "/" ||
      location.pathname === `/u/${accountData.username}`
    ) {
      navigate("/create");
    } else {
      window.open("https://markitai.com/create", "_blank", "noreferrer");
    }
  }, [accountData.username, location.pathname, navigate]);

  const navigateCreateLinkOnPress = useCallback(() => {
    if (
      location.pathname === "/" ||
      location.pathname === `/u/${accountData.username}`
    ) {
      navigate(NavigationId.CREATE_LINK_EVENT);
    } else {
      window.open(
        MARKITAI + NavigationId.CREATE_LINK_EVENT,
        "_blank",
        "noreferrer"
      );
    }
  }, [accountData.username, location.pathname, navigate]);

  const ref = useRef<any>(null);

  useEffect(() => {
    const handler = (event: any) => {
      if (
        dropdownVisible &&
        ref.current &&
        !ref.current.contains(event.target)
      ) {
        setDropdownVisible(false);
      }
    };
    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    };
  }, [dropdownVisible]);

  return (
    <div
      className={isDesktop ? "FinalHeaderDesktop" : "AlignedTopRow"}
      style={
        isRCA && showRCA
          ? {
              minHeight: isDesktop ? "100px" : "80px",
              marginRight: "20px",
            }
          : {}
      }
    >
      {isRCA && showRCA ? (
        <div className="AlignedRow">
          <div
            style={{
              height: isDesktop ? "90px" : "60px",
            }}
          >
            <img
              src={RCA}
              className="referralProgramCircle"
              alt="ReferralProgramImage"
            />
          </div>
          <div
            onClick={onMarkitClick}
            style={{
              marginLeft: 10,
              height: isDesktop ? "40px" : "30px",
            }}
          >
            <img
              src={powered}
              className="referralProgramCircle"
              alt="ReferralProgramImage"
            />
          </div>
        </div>
      ) : (
        <MarkitHeaderLogo
          theme={theme}
          forceColor={whiteLogo ? "white" : undefined}
        />
      )}
      <div className="AlignedRow" style={{ gap: "15px" }}>
        {!onExplore && !accountData.inCreatorMode ? (
          <div
            className="AlignedRowSelect ExploreLink"
            style={{
              gap: 5,
              color: exploreHover
                ? onExploreSubPage
                  ? Colors.BLACK
                  : Colors.GRAY3
                : onExploreSubPage
                ? Colors.WHITE
                : theme.PrimaryText.color,
            }}
            onMouseEnter={() => setExploreHover(true)}
            onMouseLeave={() => setExploreHover(false)}
            onClick={() => {
              navigate("/explore");
              mixpanel.track("Webapp: Pressed Header Explore Button", {
                pathname: location.pathname,
              });
            }}
          >
            <span style={{ fontWeight: 500, fontSize: "15px" }}>Explore</span>
            <Icon icon={"ion:compass"} height={15} style={{ marginTop: 3 }} />
          </div>
        ) : null}
        {isDesktop && !hideCreate ? (
          <CreateDropdownMenu
            navCreateOnPress={navigateCreateOnPress}
            navCreateLinkOnPress={navigateCreateLinkOnPress}
            sourceLocation="top_header"
          >
            <BlackHoverButton
              title="Create"
              iconName="ion:add-circle-outline"
              iconSize={15}
              onPress={() => {}}
              disabled={onCreate}
              altTextColor={theme.PrimaryText.color}
              altBackgroundColor={
                onExplore ? Colors.WHITE : theme.SignInBG.backgroundColor
              }
              containerStyles={{
                whiteSpace: "nowrap",
                paddingBlock: 7,
                paddingInline: 12,
                borderRadius: 100,
              }}
            />
          </CreateDropdownMenu>
        ) : null}
        {accountData.isAdmin && !hideLogin ? (
          <span
            style={{
              color: Colors.RED2,
              fontSize: "15px",
              fontWeight: "500",
              cursor: "pointer",
            }}
            onClick={() => navigate("/adminDashboard")}
          >
            Admin Dashboard
          </span>
        ) : null}
        {!hideLogin ? (
          loggedIn === LoginState.LOGGED_IN ? (
            <HeaderProfilePic
              accountData={accountData}
              isOnProfilePage={isOnProfilePage}
            />
          ) : (
            <>
              <BlackHoverButton
                title="Sign In"
                onPress={loginUser}
                altTextColor={theme.PrimaryText.color}
                altBackgroundColor={
                  onExplore ? Colors.WHITE : theme.SignInBG.backgroundColor
                }
                containerStyles={{
                  whiteSpace: "nowrap",
                  paddingBlock: 7,
                  paddingInline: 12,
                  borderRadius: 100,
                }}
              />
            </>
          )
        ) : null}
      </div>
    </div>
  );
});

export default TopHeader;
