import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppState } from "../store";

/**
 * Stores the parsed web URL (both pathname and query param)
 */
export interface UrlParamsState {
  e: string; // eventId
  u: string; // username
  i: string; // userId
  c: string; // campaignId
  id: string; // old logic
  username: string;
  qrCodeId: string;
  ticketId: string;
  promoCodeId: string;
  promoter: string;
  userReferrer: string; // might be deprecated
  referral: string;
  communityId: string;
  l: string; // linkId
  linkId: string; // old linkId (backsupport, deprecate on 1/1/25)
  openPass: boolean;
  showNextEvent: boolean; // showEvent
}

export const initialUrlParamState: UrlParamsState = {
  e: "",
  u: "",
  i: "",
  c: "",
  id: "",
  username: "",
  qrCodeId: "", // old logic
  ticketId: "",
  promoCodeId: "",
  promoter: "",
  userReferrer: "",
  referral: "",
  communityId: "",
  l: "",
  linkId: "",
  openPass: false,
  showNextEvent: false, // deprecated?
};

export const UrlParamsSlice = createSlice({
  name: "UrlParams",
  initialState: initialUrlParamState,
  reducers: {
    setUrlState: (state, action: PayloadAction<UrlParamsState>) => {
      return action.payload;
    },
    clearUrlState: (state) => {
      return initialUrlParamState;
    },
  },
});

export const urlParamsReducer = UrlParamsSlice.reducer;
export const urlParamsActions = UrlParamsSlice.actions;

export const getUrlParamState = (state: AppState) => state.urlParams;
