import "../../css/GlobalStyles.css";
import React, { memo, useCallback, useMemo, useState } from "react";
import { Dropdown, MenuProps } from "antd";
import DropdownMenuLabel from "../DropdownMenuLabel";
import { Icon } from "@iconify/react";
import { Colors } from "../../utils/colors";
import Skeleton from "react-loading-skeleton";
import { useSearchParams } from "react-router-dom";

export enum ProfileTimelineState {
  LINKS = "Links",
  UPCOMING = "Upcoming Events",
  PAST = "Past Events",
  ATTENDING = "Attending",
  ATTENDED = "Attended",
}

export enum ProfileDisplayView {
  CARD_VIEW = "Card View",
  GRID_VIEW = "Grid View",
}

type ProfilePageTimelineHeaderProps = {
  timelineState: ProfileTimelineState;
  setTimelineState: (timelineState: ProfileTimelineState) => void;
  displayView: ProfileDisplayView;
  setDisplayView: (displayView: ProfileDisplayView) => void;
  loading: boolean;
  hasLinks: boolean;
};

export const ProfilePageTimelineHeader = memo(
  function ProfilePageTimelineHeaderFn(props: ProfilePageTimelineHeaderProps) {
    const {
      timelineState,
      setTimelineState,
      displayView,
      setDisplayView,
      loading,
      hasLinks,
    } = props;
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [searchParams] = useSearchParams();

    const styles = {
      buttonContainer: {
        backgroundColor: Colors.GRAY6,
        minHeight: 36,
        borderRadius: 12,
      },
      toggleContainer: {
        zIndex: 2,
        cursor: "pointer",
      },
    };

    const items: MenuProps["items"] = useMemo(() => {
      const menuItems = [];
      if (hasLinks) {
        menuItems.push({
          label: (
            <DropdownMenuLabel
              label="Links"
              icon="mdi:link-variant"
              width={200}
            />
          ),
          key: "links",
        });
      }
      menuItems.push(
        {
          label: (
            <DropdownMenuLabel
              label="Upcoming Events"
              icon="ion:alarm"
              width={200}
            />
          ),
          key: "upcoming_events",
        },
        {
          label: <DropdownMenuLabel label="Past Events" icon="ion:play-back" />,
          key: "past_events",
        },
        {
          label: <DropdownMenuLabel label="Attending" icon="ion:ticket" />,
          key: "attending_events",
        },
        {
          label: <DropdownMenuLabel label="Attended" icon="ion:ticket" />,
          key: "attended_events",
        }
      );
      return menuItems;
    }, [hasLinks]);

    const onClick: MenuProps["onClick"] = ({ key }) => {
      switch (key) {
        case "links":
          setTimelineState(ProfileTimelineState.LINKS);
          return;
        case "upcoming_events":
          setTimelineState(ProfileTimelineState.UPCOMING);
          return;
        case "past_events":
          setTimelineState(ProfileTimelineState.PAST);
          return;
        case "attending_events":
          setTimelineState(ProfileTimelineState.ATTENDING);
          return;
        case "attended_events":
          setTimelineState(ProfileTimelineState.ATTENDED);
      }
    };

    const toggleDisplayView = useCallback(
      (displayView: ProfileDisplayView) => {
        if (displayView === ProfileDisplayView.CARD_VIEW) {
          // Reset the url without condensed param
          const newUrl = [window.location.pathname, searchParams.toString()]
            .filter(Boolean)
            .join("?");
          window.history.replaceState(null, "", newUrl);
        } else {
          // Add to the url the condensed param
          window.history.replaceState(null, "", "?condensed=true");
        }
        setDisplayView(displayView);
      },
      [searchParams, setDisplayView]
    );

    return (
      <div className="AlignedRow" style={{ gap: 8 }}>
        {loading ? (
          <Skeleton
            width={100}
            height={34}
            baseColor={Colors.WHITE}
            borderRadius={12}
          />
        ) : (
          <Dropdown
            menu={{ items, onClick }}
            trigger={["click"]}
            placement="bottomLeft"
            overlayStyle={{ paddingTop: 7 }}
            onOpenChange={(isOpen: boolean) => setDropdownVisible(isOpen)}
          >
            <div
              className="AlignedRowSelect"
              style={{ ...styles.buttonContainer, gap: 4, paddingInline: 10 }}
            >
              <span style={{ fontWeight: "500", fontSize: 12 }}>
                {timelineState}
              </span>
              <Icon
                icon={dropdownVisible ? "mdi-chevron-up" : "mdi:chevron-down"}
                height={18}
                color={Colors.BLACK}
              />
            </div>
          </Dropdown>
        )}
        {/** Can make this a component in the future if we want another sliding toggle button somewhere else */}
        <div
          className="AlignedRowSelect"
          style={{
            ...styles.buttonContainer,
            position: "relative",
            zIndex: 1,
          }}
        >
          <div
            style={{ ...styles.toggleContainer, padding: "5px 5px 5px 10px" }}
            onClick={() => toggleDisplayView(ProfileDisplayView.CARD_VIEW)}
          >
            <Icon
              icon={"ion:reader-outline"}
              height={17}
              color={Colors.BLACK}
            />
          </div>
          <div
            style={{ ...styles.toggleContainer, padding: "5px 10px 5px 5px" }}
            onClick={() => toggleDisplayView(ProfileDisplayView.GRID_VIEW)}
          >
            <Icon icon={"ion:list"} height={17} color={Colors.BLACK} />
          </div>
          <div
            style={{
              zIndex: 1,
              backgroundColor: Colors.WHITE,
              borderRadius: 10,
              boxSizing: "border-box",
              boxShadow: "0px 1px 1px 0px #b9b9b9",
              position: "absolute",
              padding: 15,
              display: "block",
              left: `calc(${
                displayView === ProfileDisplayView.CARD_VIEW ? "5%" : "50%"
              })`,
              transitionDuration: "0.3s",
              transitionTimingFunction: "cubic-bezier(0.4, 0, 0.2, 1)",
              transitionBehavior: "normal",
            }}
          ></div>
        </div>
      </div>
    );
  }
);
