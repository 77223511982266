import React, { memo } from "react";
import { useLoadUserList } from "../../../../../hooks/useLoadUserList";
import { useOnMount } from "../../../../../hooks/useOnMount";
import { CircularProgress } from "@mui/material";
import { Colors } from "../../../../../utils/colors";
import FlatList from "flatlist-react/lib";
import { HorizontalDivider } from "../../../../Dividers/HorizontalDivider";
import { MassTextsPersonItem } from "../../Items/MassTextsPersonItem";
import { AccountData } from "@markit/common.types";

type SelectRecipientsDataItemResponsesProps = {
  responseUserIds: string[];
  setProfileSelected: (profileSelected: AccountData) => void;
  excludingMode: boolean;
  unsavedRecipients: string[];
  setUnsavedRecipients: (unsavedRecipients: string[]) => void;
};

export const SelectRecipientsDataItemResponses = memo(
  function SelectRecipientsDataItemResponsesFn(
    props: SelectRecipientsDataItemResponsesProps
  ) {
    const {
      responseUserIds,
      setProfileSelected,
      unsavedRecipients,
      excludingMode,
      setUnsavedRecipients,
    } = props;

    const { isFinished, isLoading, fetchedUserData, loadMoreUsers } =
      useLoadUserList({
        userIdList: responseUserIds,
        windowSize: 30,
      });

    useOnMount(() => {
      loadMoreUsers();
    });

    return (
      <div className="ColumnNormal">
        <FlatList
          list={fetchedUserData}
          renderWhenEmpty={() => <></>}
          renderItem={(item) => {
            const lastItem =
              fetchedUserData[fetchedUserData.length - 1].uid === item.uid;
            return (
              <div className="ColumnNormal" style={{ gap: 10 }}>
                <MassTextsPersonItem
                  user={item}
                  setProfileSelected={setProfileSelected}
                  unsavedPeople={unsavedRecipients}
                  setUnsavedPeople={setUnsavedRecipients}
                  excludingMode={excludingMode}
                  removeDivider={lastItem && !isFinished}
                />
                {lastItem && !isFinished ? (
                  <div
                    className="ColumnNormal"
                    style={{ gap: 10, paddingBottom: 14 }}
                  >
                    <span
                      onClick={loadMoreUsers}
                      className="bodySubtext"
                      style={{ cursor: "pointer" }}
                    >
                      Load More...
                    </span>
                    <HorizontalDivider />
                  </div>
                ) : null}
              </div>
            );
          }}
          paginationLoadingIndicator={
            isLoading && !isFinished ? (
              <CircularProgress
                size={18}
                style={{ color: Colors.BLACK, paddingBlock: 14 }}
              />
            ) : null
          }
        />
      </div>
    );
  }
);
