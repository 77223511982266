import React from "react";
import "../../../css/Containers/ContainerStyles.css";
import { Colors } from "../../../utils/colors";
import PopupModalContainer, {
  PopupModalContainerWidth,
} from "../PopupModalContainer";
import RectangleButton from "../../Buttons/RectangleButton";
import { ThemeStyle } from "../../../hooks/useTheme";

type ConfirmDeleteModalProps = {
  heading: string;
  subtext?: string;
  deleteButtonText: string;
  hideModal: boolean;
  setIsVisible: (isVisible: boolean) => void;
  deleteOnPress: () => void;
  icon?: React.ReactNode;
  theme?: ThemeStyle;
};

// Used as a replacement to browser window.confirm to confirm an deletion action or cancel
const ConfirmDeleteModal = (props: ConfirmDeleteModalProps) => {
  const {
    heading,
    subtext,
    deleteButtonText,
    hideModal,
    setIsVisible,
    deleteOnPress,
    icon,
    theme,
  } = props;

  return (
    <PopupModalContainer
      headerComp={
        <div className="ColumnNormal" style={{ gap: 4 }}>
          {icon}
          <h1 style={{ fontSize: 17, fontWeight: "500" }}>{heading}</h1>
        </div>
      }
      subHeaderComp={
        <div style={{ marginTop: 7 }}>
          <h1 style={{ fontSize: 14, fontWeight: 400, color: Colors.GRAY1 }}>
            {subtext}
          </h1>
        </div>
      }
      valueComp={
        <div className="RowNormal" style={{ gap: 14 }}>
          <RectangleButton
            buttonLabel="Cancel"
            onPress={() => setIsVisible(false)}
            altTextColor={Colors.BLACK}
            altColor={Colors.GRAY6}
            altPaddingVert={14}
          />
          <RectangleButton
            buttonLabel={deleteButtonText}
            onPress={() => {
              deleteOnPress();
              setIsVisible(false);
            }}
            altTextColor={Colors.WHITE}
            altColor={Colors.RED3}
            altPaddingVert={14}
          />
        </div>
      }
      noExit
      closeModal={() => {}}
      hideModal={hideModal}
      theme={theme}
      modalContentWidth={PopupModalContainerWidth.SMALL}
    />
  );
};

export default ConfirmDeleteModal;
