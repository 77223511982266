import "../css/ProfileItem.css";
import "../css/GlobalStyles.css";
import { AccountData } from "@markit/common.types";
import ProfilePic from "./ProfilePic";
import { Colors } from "../utils/colors";
import { Icon } from "@iconify/react";

type ProfileItemProps = {
  profileItem: AccountData | undefined;
  onItemPress: () => void;
  subtext?: JSX.Element;
  rightIcon?: JSX.Element;
  hideViewIcon?: boolean;
  disabled?: boolean;
  organizer?: boolean;
};

const ProfileItem = ({
  profileItem,
  onItemPress,
  subtext,
  rightIcon,
  hideViewIcon,
  disabled,
  organizer,
}: ProfileItemProps) => {
  return (
    <div className="AlignedRowSpaced" style={{ flexGrow: 1 }}>
      <div
        onClick={onItemPress}
        className="AlignedRowSelect"
        style={{
          gap: 14,
          pointerEvents: disabled ? "none" : "all",
          flexGrow: 1,
        }}
      >
        <div className="AlignedRowSelect" style={{ position: "relative" }}>
          {profileItem ? (
            <ProfilePic user={profileItem} size={48} forcePointer />
          ) : (
            <div
              className="Centering"
              style={{
                width: 48,
                height: 48,
                borderRadius: 48 / 2,
                backgroundColor: Colors.GRAY6,
              }}
            >
              <Icon icon="ion:person" height={30} color={Colors.GRAY1} />
            </div>
          )}
          {!hideViewIcon && !disabled ? (
            <Icon
              icon="ion:eye"
              height={16}
              style={{
                backgroundColor: Colors.GRAY6,
                borderRadius: 100,
                padding: 1,
                position: "absolute",
                right: -4,
                bottom: 0,
              }}
              color={Colors.BLACK}
            />
          ) : null}
        </div>
        <div className="ColumnNormalSelect">
          <span
            style={{ color: Colors.BLACK, fontSize: 14, fontWeight: "500" }}
          >
            {!profileItem && organizer
              ? "Unassigned Role"
              : !profileItem
              ? ""
              : profileItem.fullName}
          </span>
          {subtext}
        </div>
      </div>
      <div style={{ height: "100%", justifyContent: "center" }}>
        {rightIcon}
      </div>
    </div>
  );
};

export default ProfileItem;
