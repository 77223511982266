import "../../css/AdminDashboard.css";
import "../../css/GlobalStyles.css";
import React, { memo, useCallback, useState } from "react";
import { CircularProgress, Box } from "@mui/material";
import { firebaseAuth, signOut } from "../../firebase";
import { EventInfo } from "@markit/common.types";
import { GetNumericDate, GetTime } from "../../utils/GetTime";
import {
  CustomTab,
  CustomTabs,
  a11yProps,
} from "../../components/FullEventSubComponents/CustomTabs";
import { TabPanel } from "../../components/FullEventSubComponents/TabPanel";
import FlatList from "flatlist-react/lib";
import { API } from "../../API";
import DatePicker from "react-datepicker";
import { isDesktop } from "react-device-detect";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import {
  accountActions,
  getAccountState,
} from "../../redux/slices/accountSlice";
import useAsyncEffect from "../../hooks/useAsyncEffect";

type AdminDashboardEventsProps = {};

const AdminDashboardEvents = memo(function AdminDashboardEventsFn(
  props: AdminDashboardEventsProps
) {
  const { account } = useSelector(getAccountState);
  const { accountData } = account;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const oneWeekAgoDate = new Date();
  oneWeekAgoDate.setDate(oneWeekAgoDate.getDate() - 7);
  const oneWeekAgoDateString = oneWeekAgoDate.toISOString();
  const [startDate, setStartDate] = useState<string>(oneWeekAgoDateString);
  const [endDate, setEndDate] = useState<string>(new Date().toISOString());
  const [minOneEvents, setMinOneEvents] = useState<EventInfo[]>([]);
  const [minTenEvents, setMinTenEvents] = useState<EventInfo[]>([]);
  const [limit] = useState(30);

  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useAsyncEffect(async () => {
    setLoading(true);
    const { minOneEvents, minTenEvents } =
      await API.adminDashboard.fetchDashboardEvents({
        startDate: startDate,
        endDate: endDate,
        limit: limit,
      });

    setMinOneEvents(minOneEvents);
    setMinTenEvents(minTenEvents);
    setLoading(false);
  }, [endDate, limit, startDate]);

  const logoutUser = useCallback(() => {
    dispatch(accountActions.logout());
    signOut(firebaseAuth);
  }, [dispatch]);

  const navigateToEventLink = useCallback((id: string) => {
    window.open("https://markitai.com/e/" + id);
  }, []);

  const renderItem = (eventInfo: EventInfo) => {
    return (
      <div className="AdminDashboardListItemEvent">
        <div className="AlignedRowSpaced">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <span className="AdminDashboardSubtext">
              {GetNumericDate(eventInfo.event.start)}
            </span>
            <span className="AdminDashboardSubtext">
              {GetTime(eventInfo.event.start)
                .replace(" am", "")
                .replace(" pm", "")}
              -{GetTime(eventInfo.event.end)}
            </span>
          </div>
          <span
            className="AdminDashboardSubtext"
            style={{ width: 120, color: "#1BB8FF" }}
            onClick={() => navigateToEventLink(eventInfo.event.id)}
          >
            {eventInfo.event.title}
          </span>
          <span className="AdminDashboardSubtext">{eventInfo.event.id}</span>
          <span className="AdminDashboardSubtext">
            {eventInfo.numAttendees}
          </span>
        </div>
      </div>
    );
  };

  return (
    <div className="AdminDashboardEventsContainer">
      <div className="AdminDashboardBody">
        <div className="AlignedRowSpaced">
          <p className="AdminDashboardHeader">
            Welcome, Admin Master {accountData.fullName}!
          </p>
          <div style={{ marginTop: 5 }} onClick={logoutUser}>
            <p
              style={{
                color: "#11B8FF",
                fontSize: 14,
              }}
            >
              Sign Out
            </p>
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span className="AdminDashboardSectionTitle">Events</span>
          <span className="AdminDashboardSubtext" style={{ marginTop: 10 }}>
            Time Range Filter
          </span>
          <div
            className={isDesktop ? "AlignedRow" : "AlignedRowSpaced"}
            style={{ marginTop: 10 }}
          >
            <DatePicker
              selected={new Date(startDate)}
              onChange={(date) => date && setStartDate(date.toISOString())}
              showTimeSelect
            />
            <DatePicker
              selected={new Date(endDate)}
              onChange={(date) => date && setEndDate(date.toISOString())}
              showTimeSelect
            />
          </div>
          <Box>
            <CustomTabs
              value={value}
              onChange={handleChange}
              sx={{ borderBottom: 0, marginTop: 1 }}
            >
              <CustomTab
                label={
                  <span
                    className={value === 0 ? "TabNameActive" : "TabName"}
                    style={{ color: "#FFF" }}
                  >
                    Min. 1 Attendee
                  </span>
                }
                {...a11yProps(0)}
              />
              <CustomTab
                label={
                  <span
                    className={value === 1 ? "TabNameActive" : "TabName"}
                    style={{ color: "#FFF" }}
                  >
                    Min. 10 Attendee
                  </span>
                }
                {...a11yProps(1)}
              />
            </CustomTabs>
          </Box>
          <div className="AlignedRowSpaced">
            <span className="AdminDashboardSubtext">Time</span>
            <span className="AdminDashboardSubtext">Title</span>
            <span className="AdminDashboardSubtext">Id</span>
            <span className="AdminDashboardSubtext"># Attendees</span>
          </div>
          {!loading ? (
            <>
              <TabPanel value={value} index={0}>
                <div className="AdminDashboardEventsList">
                  <FlatList
                    list={minOneEvents}
                    renderItem={(eventInfo) => renderItem(eventInfo)}
                    renderWhenEmpty={() => (
                      <div>
                        <span className="AdminDashboardSubtext">
                          No successful payments to show
                        </span>
                      </div>
                    )}
                  />
                </div>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <div className="AdminDashboardEventsList">
                  <FlatList
                    list={minTenEvents}
                    renderItem={(eventInfo) => renderItem(eventInfo)}
                    renderWhenEmpty={() => (
                      <div>
                        <span className="AdminDashboardSubtext">
                          No failed payments to show
                        </span>
                      </div>
                    )}
                  />
                </div>
              </TabPanel>
            </>
          ) : (
            <div className="AdminDashboardContainer" style={{ marginTop: 50 }}>
              <CircularProgress
                style={{ color: "#929292", alignSelf: "center" }}
                size={25}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
});

export default AdminDashboardEvents;
