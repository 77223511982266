import {
  Campaign,
  Event,
  MassTextAttachment,
  MassTextAttachmentType,
} from "@markit/common.types";
import {
  CampaignDetails,
  MassTextDetails,
} from "../redux/slices/campaignSlice";
import { getCampaignMassTextsSnap } from "./FirebaseUtils";
import {
  getMassTextAttachments,
  getNumMassTextRecipients,
} from "./textingUtils";
import { isEventExternalLink } from "@markit/common.utils";

export const fetchCampaignDetails = async (
  userId: string,
  campaign: Campaign
): Promise<CampaignDetails> => {
  const massTextsSnap = await getCampaignMassTextsSnap(userId, campaign.id);
  const numRecipients = await getNumMassTextRecipients(
    userId,
    campaign.id,
    campaign.initialTextId
  );
  const massTextDetails: MassTextDetails[] = await Promise.all(
    massTextsSnap.docs.map(async (doc) => {
      const massText = doc.data();
      const attachments = await getMassTextAttachments(userId, massText);
      return {
        massText,
        attachments,
      };
    })
  );
  return { campaignId: campaign.id, numRecipients, massTextDetails };
};

// Determines whether to show the link clicks and conversions
export const displayTextAnalytics = (attachments: MassTextAttachment[]) => {
  return (
    attachments.length > 1 ||
    (attachments.length === 1 &&
      attachments[0].massTextType !== MassTextAttachmentType.TICKET_LINK &&
      attachments[0].massTextType !== MassTextAttachmentType.CALENDAR_INVITE)
  );
};

// Determines whether to show the link conversions when displaying the analytics
// Use this logic if the event link being shared on the message is an external link, then hide conversions
export const displayTextConversions = (
  attachments: MassTextAttachment[],
  events: Event[]
) => {
  if (attachments.length === 1) {
    const foundEvent = events.find(
      (event) => event.id === attachments[0].eventId
    );
    if (foundEvent && isEventExternalLink(foundEvent.eventType)) {
      return false;
    }
  }
  return true;
};
