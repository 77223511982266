import { useRef } from "react";
import { useOnMount } from "./useOnMount";

/*
 * How to use this:
 * Use if you ever need to run an async function (IIFE) within a useOnMount.
 *
 * Often times you will need to run cleanup logic if you are opening a snapshot, calling a firebase
 * API function, external api function, window listeners or setTimeout. This hook handles the cleanup
 */
type AsyncOnMount = () => Promise<(() => void) | void>;

function useAsyncOnMount(effect: AsyncOnMount): void {
  // Reference to store the cleanup function
  const cleanupRef = useRef<(() => void) | void>();

  useOnMount(() => {
    // Immediately invoke the async effect in an IIFE
    (async () => {
      // Execute the async effect and capture the cleanup function
      cleanupRef.current = await effect();
    })();

    // Cleanup function to run on component unmount or dependencies change
    return () => {
      if (cleanupRef.current) {
        cleanupRef.current();
        cleanupRef.current = undefined;
      }
    };
  });
}

export default useAsyncOnMount;
