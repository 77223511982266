import { useCallback, useState } from "react";
import {
  AttachmentLinkType,
  MassText,
  MassTextAttachment,
  MassTextAttachmentType,
  MassTextType,
} from "@markit/common.types";
import { useSelector } from "react-redux";
import { getAccountState } from "../redux/slices/accountSlice";
import { generate } from "shortid";
import {
  deepCopy,
  massTextMessageConverter,
  massTextPlaceholder,
} from "@markit/common.utils";
import { getMassTextAttachmentsSnap } from "../utils/FirebaseUtils";
import useAsyncOnMount from "./useAsyncEffectOnMount";

export type AttachmentMode = {
  icon: string;
  label: string;
};

export const attachmentModes: AttachmentMode[] = [
  {
    icon: "ion:calendar",
    label: MassTextAttachmentType.LINK,
  },
  {
    icon: "ion:gift",
    label: MassTextAttachmentType.FREE_TICKET_LINK,
  },
  {
    icon: "ion:pricetag",
    label: MassTextAttachmentType.DISCOUNTED_TICKET_LINK,
  },
  {
    icon: "ion:ticket-outline",
    label: MassTextAttachmentType.TICKET_LINK,
  },
  {
    icon: "mdi:calendar-account",
    label: MassTextAttachmentType.CALENDAR_INVITE,
  },
  {
    icon: "mdi:ticket-confirmation-outline",
    label: MassTextAttachmentType.GENERATE_TICKET_BUNDLE,
  },
];

export const makeEmptyMassText = (
  userId: string,
  fullName: string,
  eventRefId?: string, // for event text ref
  campaignId?: string // not defined if mass text not part of existing campaign, so create new campaign
) => {
  const id = generate();

  const newMassText: MassText = {
    id: id,
    campaignId: campaignId ?? "",
    createdAt: new Date().toISOString(),
    message: massTextPlaceholder(fullName),
    queued: false,
    scheduled: false,
    sentAt: "",
    sentBy: userId,
    type: MassTextType.MASSTEXT,
    eventRefId: eventRefId ?? "",
    catchUpEnabled: false,
    subSelectionIds: [],
    excludedSubSelectionIds: [],
    mediaUrl: "",
    isDefault: false,
  };

  return newMassText;
};

export const makeEmptyTextAttachment = () => {
  const id = generate();

  const newMassTextAttachment: MassTextAttachment = {
    id: id,
    linkType: AttachmentLinkType.MARKIT_LINK,
    massTextType: MassTextAttachmentType.LINK,
    eventId: "",
    massTextId: "",
    numTickets: 1,
    discount: 20,
    promoCodeQuantity: 1,
    promoCodeId: "",
    customTicketLabel: "",
    numOpens: 0,
    numConversions: 0,
  };

  return newMassTextAttachment;
};

type useMassTextManagementProps = {
  massText?: MassText;
  campaignId?: string;
  eventId?: string;
  isDuplicateText?: boolean;
};

export const useMassTextManagement = (props: useMassTextManagementProps) => {
  const { massText, campaignId, eventId, isDuplicateText } = props;
  const { accountData } = useSelector(getAccountState).account;
  const [massTextSettings, setMassTextSettings] = useState<MassText>(
    makeEmptyMassText(
      accountData.uid,
      accountData.fullName,
      eventId,
      campaignId
    )
  );
  const [attachmentSettings, setAttachmentSettings] =
    useState<MassTextAttachment>(makeEmptyTextAttachment());
  const [loaded, setLoaded] = useState(false);

  const updateMassTextSettings = useCallback(
    (newMassTextSettings: Partial<MassText>) => {
      setMassTextSettings((prevMassTextSettings) => {
        return { ...prevMassTextSettings, ...newMassTextSettings };
      });
    },
    []
  );

  const updateAttachmentSettings = useCallback(
    (newAttachmentSettings: Partial<MassTextAttachment>) => {
      setAttachmentSettings((prevAttachmentSettings) => {
        return { ...prevAttachmentSettings, ...newAttachmentSettings };
      });
    },
    []
  );

  const resetMassText = useCallback(() => {
    setMassTextSettings(
      makeEmptyMassText(accountData.uid, accountData.fullName, eventId)
    );
  }, [accountData.fullName, accountData.uid, eventId]);

  const resetMassTextAttachment = useCallback(() => {
    setAttachmentSettings(makeEmptyTextAttachment());
  }, []);

  useAsyncOnMount(async () => {
    const emptyMassText = makeEmptyMassText(
      accountData.uid,
      accountData.fullName,
      eventId,
      isDuplicateText ? "" : campaignId
    );
    if (isDuplicateText && massText) {
      const oldAttachmentsSnap = await getMassTextAttachmentsSnap(
        accountData.uid,
        massText.campaignId,
        massText.id
      );
      const attachments = oldAttachmentsSnap.docs.map((doc) => doc.data());
      const duplicateText: MassText = {
        ...emptyMassText,
        scheduled: massText.scheduled,
        sentAt: massText.scheduled ? massText.sentAt : new Date().toISOString(),
        message: massTextMessageConverter(massText.message, attachments),
        catchUpEnabled: massText.catchUpEnabled,
        mediaUrl: massText.mediaUrl,
      };
      setMassTextSettings(duplicateText);
    } else {
      setMassTextSettings(massText ? deepCopy(massText) : emptyMassText);
    }
    setLoaded(true);
  });

  return {
    updateMassTextSettings,
    massTextSettings,
    makeEmptyMassText,
    attachmentSettings,
    updateAttachmentSettings,
    resetMassText,
    resetMassTextAttachment,
    loaded,
  };
};
