/* eslint-disable camelcase */
import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { atcb_action } from "add-to-calendar-button";
import { Event } from "@markit/common.types";

import "add-to-calendar-button/assets/css/atcb.css";
import "../css/Calendar.css";

import { toISO8601, formatHourMinute } from "../utils/GetTime";
import { useSelector } from "react-redux";
import { getAccountState } from "../redux/slices/accountSlice";
import { Icon } from "@iconify/react";
import { LightTheme, useTheme } from "../hooks/useTheme";
import { useSearchParams } from "react-router-dom";
import { MixpanelContext } from "../context/AnalyticsService";
import { Colors } from "../utils/colors";
import RectangleButton from "./Buttons/RectangleButton";

type CalendarOptions = Parameters<typeof atcb_action>[0];

interface Props {
  event: Event;
  loading: boolean;
  full?: boolean;
  numOfTickets: number;
  hasNoTickets?: boolean;
  hideLocation?: boolean;
  pending?: boolean;
}

/**
 * Dependencies: https://github.com/add2cal/add-to-calendar-button
 */
const Calendar = (props: Props) => {
  const {
    event,
    loading,
    full,
    numOfTickets,
    hasNoTickets,
    hideLocation,
    pending,
  } = props;
  const mixpanel = useContext(MixpanelContext);
  const { account } = useSelector(getAccountState);
  const { isRCA } = account;
  const [searchParams] = useSearchParams();

  const calendarOptions: CalendarOptions = useMemo(() => {
    return {
      name: (pending ? "[PENDING] " : "") + event.title,
      description:
        (pending ? "Pending " : "") +
        `Ticket: [url]${window.location}[/url]\n\n${
          !hideLocation
            ? event.isVirtual
              ? "Virtual Event: " + event.locationDetails + "\n"
              : `Location: ${
                  event.formattedAddress !== ""
                    ? event.formattedAddress
                    : event.googleDescription
                }\n${
                  event.locationDetails !== ""
                    ? event.locationDetails + "\n"
                    : ""
                }`
            : ""
        }\nDescription: ${event.description}`,
      options: [
        "Apple",
        "Google",
        "Microsoft365",
        "MicrosoftTeams",
        "Outlook.com",
      ],
      startDate: toISO8601(event.start),
      startTime: formatHourMinute(event.start),
      endDate: toISO8601(event.end),
      endTime: formatHourMinute(event.end),
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      location: !hideLocation
        ? event.isVirtual
          ? ""
          : event.formattedAddress !== ""
          ? event.formattedAddress
          : event.googleDescription
        : "",
    };
  }, [
    event.description,
    event.end,
    event.formattedAddress,
    event.googleDescription,
    event.isVirtual,
    event.locationDetails,
    event.start,
    event.title,
    hideLocation,
    pending,
  ]);

  const { theme } = useTheme();

  const onCalendarPress = useCallback(() => {
    if (hasNoTickets) {
      alert("You must have a ticket to add to calendar.");
    } else {
      atcb_action(calendarOptions);
    }
  }, [calendarOptions, hasNoTickets]);

  useEffect(() => {
    const modalOpen = searchParams.get("openCal");
    if (!loading && modalOpen === "true" && !hasNoTickets) {
      onCalendarPress();
      mixpanel.track("Webapp: Opened add to calendar link", {
        eventId: event.id,
        ticketUrl: window.location,
      });
    }
  }, [
    event.id,
    hasNoTickets,
    loading,
    mixpanel,
    onCalendarPress,
    searchParams,
  ]);

  return full ? (
    <div style={{ flex: 1 }}>
      <RectangleButton
        buttonLabel={"Add to Calendar"}
        onPress={onCalendarPress}
        altColor={
          pending ? theme.PrimaryText.color : theme.TertiaryBG.backgroundColor
        }
        altTextColor={
          pending
            ? theme === LightTheme
              ? Colors.WHITE
              : Colors.BLACK
            : numOfTickets > 0
            ? theme.PrimaryText.color
            : Colors.GRAY2
        }
        altPaddingVert={14}
        altBorderRadius={12}
        iconLeft={
          <Icon
            icon="ion:calendar"
            height={16}
            style={{
              color: pending
                ? theme === LightTheme
                  ? Colors.WHITE
                  : Colors.BLACK
                : numOfTickets > 0
                ? theme.PrimaryText.color
                : Colors.GRAY2,
            }}
          />
        }
        disabled={hasNoTickets}
      />
    </div>
  ) : (
    <div className="CalendarContainer">
      <button
        className={"CalendarButton"}
        onClick={() => atcb_action(calendarOptions)}
        style={{ color: isRCA ? "#FE296C" : "" }}
      >
        Add to Calendar
      </button>
    </div>
  );
};

export default Calendar;
