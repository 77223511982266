import { CSSProperties, ReactNode } from "react";

type LabelButtonProps = {
  color: string;
  backgroundColor: string;
  text: string;
  icon?: ReactNode;
  containerStyles?: CSSProperties;
};

// Used for displaying labels within ticket items and profile items (subscribed status, ticket status)
const LabelButton = (props: LabelButtonProps) => {
  const { color, backgroundColor, text, icon, containerStyles } = props;

  return (
    <div
      className="AlignedRow"
      style={{
        color: color,
        backgroundColor: backgroundColor,
        borderRadius: 100,
        fontSize: 12,
        fontWeight: 500,
        paddingBlock: 7,
        paddingInline: 10,
        gap: 5,
        display: "inline-flex",
        ...containerStyles,
      }}
    >
      {icon}
      {text}
    </div>
  );
};

export default LabelButton;
