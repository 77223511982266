import React, { useCallback, useEffect, useMemo, useState } from "react";
import "../../css/CreateEvent.css";
import {
  FormCheckBoxField,
  FormInput,
  FormInputType,
  FormRadioField,
  SavedFormQuestion,
} from "@markit/common.types";
import { LightTheme, ThemeStyle } from "../../hooks/useTheme";
import { Colors } from "../../utils/colors";
import {
  IoAddCircleOutline,
  IoCheckboxOutline,
  IoTextOutline,
} from "react-icons/io5";
import { Icon } from "@iconify/react";
import SwitchToggleItem from "../SwitchToggleItem";
import {
  Button,
  Checkbox,
  FormControl,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  ThemeProvider,
} from "@mui/material";
import { generate } from "shortid";
import { IoIosRadioButtonOn } from "react-icons/io";
import { themeMui } from "../FullEvent/FullEventInputForm";
import checkbox from "@iconify/icons-mdi/checkbox-outline";
import PopupModalContainer from "../Containers/PopupModalContainer";
import { generateNewSavedFormQuestion } from "@markit/common.utils";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "../../redux/store";
import RectangleButton from "../Buttons/RectangleButton";
import { questionInputName } from "../../utils/eventUtils/formUtils";
import {
  LoginState,
  accountActions,
  editSingleProfileQuestion,
} from "../../redux/slices/accountSlice";
import { MdLockOutline } from "react-icons/md";
import { Batch, getUserSavedFormQuestionRef } from "../../utils/FirebaseUtils";
import AlertContainer from "../Containers/AlertContainer";
import ConfirmDeleteModal from "../Containers/ConfirmPopups/ConfirmDeleteModal";
import ConfirmActionModal from "../Containers/ConfirmPopups/ConfirmActionModal";
import { FormSavedQuestionsType } from "./FormSavedQuestions";

interface FormBuilderProps {
  type: FormSavedQuestionsType;
  selectedQuestions: SavedFormQuestion[];
  setSelectedQuestions: (eventFormQuestions: SavedFormQuestion[]) => void;
  setIsFormsModalOpen: (formsModalOpen: boolean) => void;
  formQuestionItem?: SavedFormQuestion | undefined;
  setFormQuestionItem?: (
    newFormQuestion: SavedFormQuestion | undefined
  ) => void;
  newQuestionOptions?: string[];
  theme?: ThemeStyle;
}

const FormBuilder = (props: FormBuilderProps) => {
  const {
    type,
    selectedQuestions,
    setSelectedQuestions,
    setIsFormsModalOpen,
    formQuestionItem,
    setFormQuestionItem,
    newQuestionOptions,
    theme,
  } = props;
  const dispatch = useDispatch();
  const { savedQuestions, loggedIn, currentEventFormQuestions, accountData } =
    useSelector((state: AppState) => state.account);
  const [newFormQuestion, setNewFormQuestion] = useState<SavedFormQuestion>(
    generateNewSavedFormQuestion(generate(), FormInputType.TEXT_INPUT)
  );
  const [essentialModalVisible, setEssentialModalVisible] = useState(false);

  const [alertText, setAlertText] = useState("");
  const [deleteOptionConfirm, setDeleteOptionConfirm] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(-1);
  const [changeLabelConfirm, setChangeLabelConfirm] = useState(false);

  const styles = {
    textLabel: {
      fontSize: 14,
      fontWeight: 600,
    },
  };

  const essentialQuestion = useMemo(
    () => savedQuestions.find((question) => question.isEssential),
    [savedQuestions]
  );

  const currentFormQuestions = useMemo(
    () =>
      type === FormSavedQuestionsType.PROFILE
        ? savedQuestions.filter((question) => question.onProfileFollow)
        : currentEventFormQuestions,
    [currentEventFormQuestions, savedQuestions, type]
  );

  const inputDisabled = useMemo(
    () =>
      formQuestionItem !== undefined &&
      (formQuestionItem.eventIds.length > 0 ||
        formQuestionItem.formInput.inputType === FormInputType.EMAIL ||
        formQuestionItem.formInput.inputType === FormInputType.PHONE),
    [formQuestionItem]
  );

  const changeIsEssential = useCallback(
    (isEssential: boolean) => {
      const formQuestion: SavedFormQuestion = {
        ...newFormQuestion,
        isEssential: isEssential,
      };
      setNewFormQuestion(formQuestion);
    },
    [newFormQuestion]
  );

  const changeIsRequired = useCallback(() => {
    const updatedFormQuestion: SavedFormQuestion = {
      ...newFormQuestion,
      formInput: {
        ...newFormQuestion.formInput,
        isRequired: !newFormQuestion.formInput.isRequired,
      },
    };
    setNewFormQuestion(updatedFormQuestion);
  }, [newFormQuestion]);

  useEffect(() => {
    // default isEssential to true if the user does not have an essential question yet, and logged in
    if (
      loggedIn === LoginState.LOGGED_IN &&
      !essentialQuestion &&
      !formQuestionItem &&
      !selectedQuestions.some((question) => question.isEssential)
    ) {
      const formQuestion: SavedFormQuestion = {
        ...newFormQuestion,
        isEssential: true,
      };
      setNewFormQuestion(formQuestion);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (formQuestionItem) {
      setNewFormQuestion(formQuestionItem);
    }
  }, [formQuestionItem]);

  const changeInput = useCallback(
    (newInput: Partial<FormInput>) => {
      const updatedFormQuestion: SavedFormQuestion = {
        ...newFormQuestion,
        formInput: { ...newFormQuestion.formInput, ...newInput } as FormInput,
      };
      setNewFormQuestion(updatedFormQuestion);
    },
    [newFormQuestion, setNewFormQuestion]
  );

  const addInput = useCallback(
    (type: FormInputType) => {
      // Maintain the same label on changing input types
      const newQuestion = generateNewSavedFormQuestion(generate(), type);
      const formInput = newQuestionOptions
        ? {
            ...newQuestion.formInput,
            label: newFormQuestion.formInput.label ?? "",
            options:
              type === FormInputType.CHECK_BOX
                ? newQuestionOptions.flatMap((option) => option.split(", "))
                : newQuestionOptions,
          }
        : {
            ...newQuestion.formInput,
            label: newFormQuestion.formInput.label ?? "",
          };
      const newSavedFormQuestion: SavedFormQuestion = {
        ...newQuestion,
        formInput: formInput,
      };
      setNewFormQuestion(newSavedFormQuestion);
    },
    [newFormQuestion.formInput.label, newQuestionOptions]
  );

  const deleteInputOption = useCallback((index: number) => {
    setDeleteOptionConfirm(true);
    setDeleteIndex(index);
  }, []);

  const resetOnPress = useCallback(() => {
    setNewFormQuestion(
      generateNewSavedFormQuestion(generate(), FormInputType.TEXT_INPUT)
    );
    setIsFormsModalOpen(false);
    if (setFormQuestionItem) {
      setFormQuestionItem(undefined);
    }
  }, [setFormQuestionItem, setIsFormsModalOpen]);

  const finalizeFormQuestion = useCallback(() => {
    const finalNewFormQuestion: SavedFormQuestion = {
      ...newFormQuestion,
      formInput: {
        ...newFormQuestion.formInput,
        label: newFormQuestion.formInput.label?.trim() ?? "",
      },
    };
    let updatedFormQuestions: SavedFormQuestion[] = [...selectedQuestions];
    const index = selectedQuestions.findIndex(
      (question) => question.id === finalNewFormQuestion.id
    );
    if (index !== -1) {
      updatedFormQuestions.splice(index, 1, finalNewFormQuestion);
    } else {
      updatedFormQuestions = selectedQuestions.concat(finalNewFormQuestion);
    }

    // remove essential from any selectedQuestions that has it marked as true (local)
    // if the newFormQuestion is also marked as essential
    const foundOldSelectedEssential = selectedQuestions.find(
      (question) => question.isEssential
    );
    if (foundOldSelectedEssential && finalNewFormQuestion.isEssential) {
      const newQuestion: SavedFormQuestion = {
        ...foundOldSelectedEssential,
        isEssential: false,
      };
      const index = selectedQuestions.findIndex(
        (question) => question.isEssential
      );
      if (index !== -1) {
        updatedFormQuestions.splice(index, 1, newQuestion);
      }
    }
    setSelectedQuestions(updatedFormQuestions);

    // remove essential from any currEventFormQuestions that has it marked as true (redux)
    const foundOldCurrEssential = currentFormQuestions.find(
      (question) => question.isEssential
    );
    if (
      type === FormSavedQuestionsType.EVENT &&
      foundOldCurrEssential &&
      finalNewFormQuestion.isEssential
    ) {
      const newQuestion: SavedFormQuestion = {
        ...foundOldCurrEssential,
        isEssential: false,
      };
      const index = currentFormQuestions.findIndex(
        (question) => question.isEssential
      );
      if (index !== -1) {
        dispatch(accountActions.updateCurrentEventFormQuestions([newQuestion]));
      }
    }

    // update any found currEventFormQuestion that exists (editing) in redux
    if (
      currentFormQuestions.some(
        (question) => question.id === finalNewFormQuestion.id
      )
    ) {
      dispatch(
        accountActions.updateCurrentEventFormQuestions([finalNewFormQuestion])
      );
    }

    // update the savedQuestion in redux after editing a profile question
    if (type !== FormSavedQuestionsType.EVENT) {
      dispatch(
        editSingleProfileQuestion(accountData.uid, finalNewFormQuestion)
      );
    }

    resetOnPress();
  }, [
    accountData.uid,
    currentFormQuestions,
    dispatch,
    newFormQuestion,
    resetOnPress,
    selectedQuestions,
    setSelectedQuestions,
    type,
  ]);

  const saveNewFormInput = useCallback(() => {
    const formInput = newFormQuestion.formInput;
    const finalLabel = newFormQuestion.formInput.label?.trim();
    const formIsValid = () => {
      // check if label name already exists as another form question
      if (
        savedQuestions.some(
          (question) => question.formInput.label === finalLabel
        ) &&
        !formQuestionItem
      ) {
        setAlertText(
          "You already have a saved form question with the same name."
        );
        return false;
      }
      switch (formInput.inputType) {
        case FormInputType.TEXT_INPUT:
          if (!finalLabel) {
            setAlertText("Short answer questions must have a label.");
            return false;
          }
          return true;
        case FormInputType.RADIO:
        case FormInputType.CHECK_BOX: {
          if (
            formInput.options.some((option) => option === "") ||
            !finalLabel
          ) {
            setAlertText("All answer options and the label must be non-empty.");
            return false;
          } else if (formInput.options.length === 0) {
            setAlertText(
              "Question must have at least one answer option and label."
            );
            return false;
          }
          return true;
        }
        default:
          return true;
      }
    };

    if (formIsValid()) {
      if (formQuestionItem && finalLabel !== formQuestionItem.formInput.label) {
        setChangeLabelConfirm(true);
      } else {
        finalizeFormQuestion();
      }
    }
  }, [
    newFormQuestion.formInput,
    savedQuestions,
    formQuestionItem,
    finalizeFormQuestion,
  ]);

  const essentialCheckmarkOnPress = useCallback(() => {
    if (essentialQuestion) {
      setEssentialModalVisible(true);
    } else if (!newFormQuestion.isEssential) {
      changeIsEssential(true);
    } else {
      changeIsEssential(false);
    }
  }, [changeIsEssential, essentialQuestion, newFormQuestion.isEssential]);

  // if changing essential question when you already have an essential question:
  // Change the old saved question to not essential locally and in firebase
  // Change the redux state for the essential saved question
  const changeEssentialQuestionOnPress = useCallback(
    async (newEssentialState: boolean) => {
      if (essentialQuestion) {
        const batch = new Batch("Error updating essential question");
        // update old essential question
        const oldFormQuestion: SavedFormQuestion = {
          ...essentialQuestion,
          isEssential: false,
        };
        if (
          currentFormQuestions.some(
            (question) => question.id === essentialQuestion.id
          )
        ) {
          dispatch(
            accountActions.updateCurrentEventFormQuestions([oldFormQuestion])
          );
        }
        const oldEssentialQuestionRef = getUserSavedFormQuestionRef(
          accountData.uid,
          essentialQuestion.id
        );
        batch.update(oldEssentialQuestionRef, { isEssential: false });

        try {
          await batch.commit();
          // change redux state for essential question
          dispatch(accountActions.addSavedFormQuestions([oldFormQuestion]));
          changeIsEssential(newEssentialState);
        } catch (err: any) {
          setAlertText(
            "Sorry, there was an error switching your essential question at this time."
          );
        }
        setEssentialModalVisible(false);
      }
    },
    [
      accountData.uid,
      changeIsEssential,
      currentFormQuestions,
      dispatch,
      essentialQuestion,
    ]
  );

  return (
    <>
      <PopupModalContainer
        headerComp={
          formQuestionItem !== undefined ? "Edit Question" : "New Question"
        }
        subHeaderComp="We will save your question for future use."
        valueComp={
          <div>
            <div style={{ marginBottom: 15 }}>
              <div className="AlignedRowSpaced" style={{ marginBottom: 15 }}>
                <h4 style={{ ...theme?.PrimaryText, fontWeight: "500" }}>
                  Question Type
                </h4>
                <FormControl
                  sx={{
                    ...theme?.SecondaryBG,
                    width: 200,
                    borderRadius: 2,
                    border: "0.5px solid #929292",
                  }}
                  size="small"
                >
                  <Select
                    id="demo-select-small"
                    displayEmpty
                    defaultValue={newFormQuestion.formInput.inputType || ""}
                    value={newFormQuestion.formInput.inputType || ""}
                    inputProps={{
                      "aria-label": "Without label",
                    }}
                    onChange={(e: any) => addInput(e.target.value)}
                    renderValue={(value: any) =>
                      value === FormInputType.EMAIL
                        ? "Collect Emails"
                        : value === FormInputType.PHONE
                        ? "Collect Phone #'s"
                        : questionInputName(value)
                    }
                    style={{
                      borderRadius: 8,
                      ...theme?.PrimaryText,
                      opacity: inputDisabled ? 0.5 : 1,
                      pointerEvents: inputDisabled ? "none" : "all",
                    }}
                  >
                    <MenuItem value={FormInputType.TEXT_INPUT}>
                      <div
                        className="AlignedRowSpaced"
                        style={{ width: "100%" }}
                      >
                        <span>Short Answer</span>
                        <IoTextOutline size={18} />
                      </div>
                    </MenuItem>
                    <MenuItem value={FormInputType.RADIO}>
                      <div
                        className="AlignedRowSpaced"
                        style={{ width: "100%" }}
                      >
                        <span>Multiple Choice</span>
                        <IoIosRadioButtonOn size={18} />
                      </div>
                    </MenuItem>
                    <MenuItem value={FormInputType.CHECK_BOX}>
                      <div
                        className="AlignedRowSpaced"
                        style={{ width: "100%" }}
                      >
                        <span>Checkbox</span>
                        <IoCheckboxOutline size={18} />
                      </div>
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
              {newFormQuestion.formInput.inputType === FormInputType.RADIO ||
              newFormQuestion.formInput.inputType ===
                FormInputType.CHECK_BOX ? (
                <div
                  className="ColumnNormal"
                  style={{ gap: 10, borderRadius: 8, ...theme?.SecondaryBG }}
                >
                  <div className="inputTextAreaContainer AlignedRowSpaced">
                    <TextField
                      size="small"
                      type="text"
                      placeholder={
                        newFormQuestion.formInput.inputType ===
                        FormInputType.RADIO
                          ? "Write question here..."
                          : "Checkbox Label..."
                      }
                      inputProps={{
                        style: {
                          ...theme?.PrimaryText,
                        },
                      }}
                      style={{
                        width: "100%",
                        borderRadius: 5,
                        ...theme?.SecondaryBG,
                      }}
                      value={newFormQuestion.formInput.label}
                      onChange={(e) => {
                        changeInput({ label: e.target.value });
                      }}
                    />
                  </div>
                  {newFormQuestion.formInput.options
                    ? newFormQuestion.formInput.options.map((option, index) => {
                        const optionDisabled =
                          inputDisabled &&
                          (
                            formQuestionItem &&
                            (formQuestionItem.formInput as FormCheckBoxField)
                          )?.options.some((opt) => opt === option);
                        return (
                          <div
                            key={index}
                            className="AlignedRow"
                            style={{ gap: 10 }}
                          >
                            <span style={{ color: Colors.GRAY1 }}>
                              #{index + 1}
                            </span>
                            <div className="inputTextAreaContainer">
                              <TextField
                                size="small"
                                type="text"
                                placeholder={"Edit option..."}
                                inputProps={{
                                  style: { ...theme?.PrimaryText },
                                }}
                                style={{
                                  width: "100%",
                                  borderRadius: 5,
                                  ...theme?.SecondaryBG,
                                  opacity: optionDisabled ? 0.5 : 1,
                                  pointerEvents: inputDisabled ? "none" : "all",
                                }}
                                value={option}
                                onChange={(e) => {
                                  changeInput({
                                    options: (
                                      newFormQuestion.formInput as
                                        | FormCheckBoxField
                                        | FormRadioField
                                    ).options.map((o, i) =>
                                      i === index ? e.target.value : o
                                    ),
                                  });
                                }}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      {optionDisabled ? (
                                        <Icon
                                          icon="uil:lock"
                                          height={20}
                                          color={Colors.GRAY1}
                                        />
                                      ) : (
                                        <Icon
                                          icon={"feather:trash"}
                                          height={18}
                                          style={{
                                            color: Colors.RED1,
                                            cursor: "pointer",
                                          }}
                                          onClick={() =>
                                            deleteInputOption(index)
                                          }
                                        />
                                      )}
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </div>
                          </div>
                        );
                      })
                    : null}
                  <div
                    onClick={() => {
                      changeInput({
                        options: (
                          newFormQuestion.formInput as
                            | FormCheckBoxField
                            | FormRadioField
                        ).options.concat(""),
                      });
                    }}
                    className="formQuestionAddButton"
                    style={{ ...theme?.SecondaryBG }}
                  >
                    <span style={{ fontSize: 14, ...theme?.PrimaryText }}>
                      {newFormQuestion.formInput.inputType ===
                      FormInputType.RADIO
                        ? "Add multiple choice option"
                        : "Add checkbox option"}
                    </span>
                    <IoAddCircleOutline
                      size={18}
                      color={
                        theme === LightTheme || !theme
                          ? Colors.BLACK
                          : Colors.WHITE
                      }
                      style={{ marginBottom: -1 }}
                    />
                  </div>
                </div>
              ) : newFormQuestion.formInput.inputType ===
                FormInputType.TEXT_INPUT ? (
                <div className="AlignedColumn" style={{ gap: 14 }}>
                  <div
                    className="descriptionTextAreaContainer"
                    style={theme?.SecondaryBG}
                  >
                    <textarea
                      value={newFormQuestion.formInput.label}
                      placeholder={"Write your question here..."}
                      onChange={(e) => {
                        changeInput({ label: e.target.value });
                      }}
                      className="descriptionTextArea"
                      style={{
                        ...theme?.PrimaryText,
                        height: "5vh",
                      }}
                      maxLength={1000}
                    />
                    <div
                      className="AlignedRow"
                      style={{ marginTop: 8, justifyContent: "space-between" }}
                    >
                      <h4
                        style={{
                          fontWeight: "400",
                          fontSize: 12,
                          color: Colors.GRAY1,
                        }}
                      >
                        {newFormQuestion.formInput.label?.length || 0}/1000
                      </h4>
                    </div>
                  </div>
                  <div
                    className="descriptionTextAreaContainer"
                    style={theme?.SecondaryBG}
                  >
                    <textarea
                      value={newFormQuestion.formInput.placeholder}
                      placeholder={"Set placeholder text (optional)..."}
                      onChange={(e) => {
                        changeInput({ placeholder: e.target.value });
                      }}
                      className="descriptionTextArea"
                      style={{
                        ...theme?.PrimaryText,
                        height: "5vh",
                      }}
                      maxLength={1000}
                    />
                    <div
                      className="AlignedRow"
                      style={{ marginTop: 8, justifyContent: "space-between" }}
                    >
                      <h4
                        style={{
                          fontWeight: "400",
                          fontSize: 12,
                          color: Colors.GRAY1,
                        }}
                      >
                        {newFormQuestion.formInput.placeholder?.length || 0}
                        /1000
                      </h4>
                    </div>
                  </div>
                </div>
              ) : newFormQuestion.formInput.inputType === FormInputType.EMAIL ||
                newFormQuestion.formInput.inputType === FormInputType.PHONE ? (
                <div className="ColumnNormal" style={{ gap: 14 }}>
                  <div className="AlignedRow" style={{ gap: 5 }}>
                    <h4 style={{ ...theme?.PrimaryText, fontWeight: "500" }}>
                      Preview
                    </h4>
                    <MdLockOutline
                      size={18}
                      color={
                        theme === LightTheme || !theme
                          ? Colors.BLACK
                          : Colors.WHITE
                      }
                    />
                  </div>
                  <div
                    className="ColumnNormal"
                    style={{
                      padding: 14,
                      borderRadius: 8,
                      gap: 10,
                      backgroundColor: theme
                        ? theme.TertiaryBG.backgroundColor
                        : Colors.GRAY6,
                    }}
                  >
                    <h4
                      style={{
                        ...theme?.PrimaryText,
                        fontWeight: "500",
                        paddingRight: 80,
                        fontSize: 15,
                      }}
                    >
                      {newFormQuestion.formInput.inputType ===
                      FormInputType.EMAIL
                        ? "Enter a valid email*"
                        : "I agree to share my phone number with the host"}
                    </h4>
                    {newFormQuestion.formInput.inputType ===
                    FormInputType.EMAIL ? (
                      <div
                        style={{
                          borderRadius: 8,
                          padding: 10,
                          border: "0.5px solid #B9B9B9",
                        }}
                      >
                        <span style={{ color: Colors.GRAY1 }}>
                          Enter email here...
                        </span>
                      </div>
                    ) : (
                      <div className="AlignedRow">
                        <Checkbox
                          checked={true}
                          inputProps={{
                            color: theme?.PrimaryText.color,
                          }}
                          style={{ paddingInline: 5, paddingBlock: 0 }}
                          color="default"
                          sx={{ color: theme?.PrimaryText.color }}
                          checkedIcon={<Icon icon={checkbox} height={24} />}
                          disabled={true}
                        />
                        <span style={theme?.PrimaryText}>I agree</span>
                      </div>
                    )}
                  </div>
                </div>
              ) : null}
            </div>
            <SwitchToggleItem
              title={"Required Question"}
              description=""
              toggleValue={newFormQuestion.formInput.isRequired}
              onChange={changeIsRequired}
              theme={theme}
            />
            {loggedIn === LoginState.LOGGED_IN ? (
              <div className="AlignedRowSpaced" style={{ marginBlock: 15 }}>
                <div className="ColumnNormal" style={{ gap: 5 }}>
                  <div style={{ ...theme?.PrimaryText, ...styles.textLabel }}>
                    Essential Question
                  </div>
                  <p style={{ fontSize: 12, ...theme?.SubText }}>
                    This data will be pinned with each unique profile.
                    <br />
                    Every event will have this question added by default.
                  </p>
                </div>
                <Checkbox
                  checked={newFormQuestion.isEssential}
                  onChange={essentialCheckmarkOnPress}
                  inputProps={{
                    color: theme?.PrimaryText.color,
                  }}
                  style={{ paddingInline: 5, paddingBlock: 0 }}
                  color="default"
                  sx={{
                    color: Colors.GRAY1,
                    "&.Mui-checked": {
                      color: Colors.BLUE5,
                    },
                  }}
                  checkedIcon={<Icon icon={checkbox} height={24} />}
                />
              </div>
            ) : null}
            <div>
              <hr style={theme?.DividerColor} />
              <ThemeProvider theme={themeMui}>
                <Button
                  style={{
                    backgroundColor: !newFormQuestion
                      ? Colors.GRAY1
                      : theme === LightTheme || !theme
                      ? Colors.BLACK
                      : Colors.WHITE,
                    fontWeight: 600,
                    color:
                      theme === LightTheme || !theme
                        ? Colors.WHITE
                        : Colors.BLACK,
                    width: "100%",
                  }}
                  variant="contained"
                  disableElevation
                  sx={{ borderRadius: "8px" }}
                  onClick={saveNewFormInput}
                >
                  {formQuestionItem !== undefined ? "Save" : "Create Question"}
                </Button>
              </ThemeProvider>
            </div>
          </div>
        }
        theme={theme}
        closeModal={resetOnPress}
      />
      {essentialModalVisible ? (
        <div
          style={{
            position: "fixed",
            top: 220,
            left: 80,
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 1000,
          }}
        >
          <div
            className={"modalContent"}
            style={{
              ...theme?.SecondaryBG,
              paddingInline: 14,
              width: "22vw",
              gap: 5,
            }}
          >
            <span style={{ ...theme?.PrimaryText, fontWeight: 500 }}>
              {!newFormQuestion.isEssential
                ? "Make Essential Question?"
                : "Remove Essential Question?"}
            </span>
            <div style={{ color: "#929292", fontSize: 12 }}>
              {!newFormQuestion.isEssential
                ? "You can only have one essential question. Undo existing and set this one as essential?"
                : "This change will immediately take effect."}
            </div>
            <div className="AlignedRow" style={{ gap: 5, marginTop: 5 }}>
              <RectangleButton
                buttonLabel="Cancel"
                onPress={() => setEssentialModalVisible(false)}
                altColor={Colors.GRAY6}
                altTextColor={Colors.BLACK}
              />
              <RectangleButton
                buttonLabel="Yes"
                onPress={() =>
                  changeEssentialQuestionOnPress(!newFormQuestion.isEssential)
                }
                altColor={
                  theme === LightTheme || !theme ? Colors.BLACK : Colors.WHITE
                }
                altTextColor={
                  theme === LightTheme || !theme ? Colors.WHITE : Colors.BLACK
                }
              />
            </div>
          </div>
        </div>
      ) : null}
      <AlertContainer
        headerComp={alertText}
        theme={theme}
        closeModal={() => setAlertText("")}
        hideModal={alertText === ""}
      />
      <ConfirmDeleteModal
        heading="Are you sure you want to delete this option?"
        subtext="You cannot undo this."
        deleteButtonText="Delete"
        hideModal={!deleteOptionConfirm}
        setIsVisible={setDeleteOptionConfirm}
        deleteOnPress={() => {
          changeInput({
            options: (
              newFormQuestion.formInput as FormRadioField
            ).options.filter((option, i) => i !== deleteIndex),
          });
        }}
        theme={theme}
      />
      <ConfirmActionModal
        heading="You are about to change the label of this saved question."
        subtext="This will rename all past data collected with this question. Are you sure you want to continue?"
        confirmButtonText="Yes"
        hideModal={!changeLabelConfirm}
        setIsVisible={setChangeLabelConfirm}
        confirmOnPress={finalizeFormQuestion}
        theme={theme}
      />
    </>
  );
};

export default FormBuilder;
