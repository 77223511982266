import { useCallback, useMemo, useRef, useState } from "react";
import {
  getDocs,
  onSnapshot,
  limit,
  orderBy,
  query,
  startAfter,
  where,
  Query,
} from "../firebase";
import { uniqueVals } from "@markit/common.utils";
import { AccountData, Conversation } from "@markit/common.types";
import { getUserConversationsRef } from "../utils/FirebaseUtils";
import { fetchSingleUser } from "../utils/FetchSingleData";
import { loadAlgoliaUsersSearchResults } from "../utils/algoliaUtils";
import { useDispatch, useSelector } from "react-redux";
import { accountActions, getAccountState } from "../redux/slices/accountSlice";
import useOnUnmount from "./useOnUnmount";
import { conversationActions } from "../redux/slices/conversationSlice";

type useLoadUserConversationsListProps = {
  userId: string;
  windowSize: number;
};
export const useLoadUserConversationsList = (
  props: useLoadUserConversationsListProps
) => {
  const { userId, windowSize } = props;
  const { followingAccountData } = useSelector(getAccountState).account;
  const dispatch = useDispatch();

  const [fetchedConversations, setFetchedConversations] = useState<
    Conversation[]
  >([]);
  const [fetchedUserData, setFetchedUserData] = useState<AccountData[]>([]);
  const unsubscribeArrayRef = useRef<(() => void)[]>([]);

  const [isLoading, setIsLoading] = useState(false);
  const [timeoutEvent, setTimeoutEvent] = useState<
    NodeJS.Timeout | undefined
  >();

  const [lastVisible, setLastVisible] = useState<string>(
    new Date().toISOString()
  );
  const [loadedAllConversations, setLoadedAllConversations] = useState(false);

  useOnUnmount(
    (latestDeps) => {
      dispatch(
        accountActions.addMultipleFollowingAccountData(
          latestDeps[0] as AccountData[]
        )
      );
      dispatch(
        conversationActions.addMultipleConversations(
          latestDeps[1] as Conversation[]
        )
      );
      unsubscribeArrayRef.current.forEach(
        (unsubscribe) => unsubscribe && unsubscribe()
      );
      unsubscribeArrayRef.current = []; // Clear the array after cleanup
    },
    [fetchedUserData, fetchedConversations]
  );

  const addUserData = useCallback((newUserData: AccountData[]) => {
    setFetchedUserData((fetchedUserData) =>
      uniqueVals(
        fetchedUserData.concat(newUserData),
        (userData) => userData.uid
      )
    );
  }, []);

  const removeUserData = useCallback((newUserDataUid: string) => {
    setFetchedUserData((fetchedUserData) => {
      const index = fetchedUserData.findIndex(
        (fetched) => fetched.uid === newUserDataUid
      );
      if (index !== -1) {
        fetchedUserData.splice(index, 1);
      }
      return [...fetchedUserData];
    });
  }, []);

  const addConversationData = useCallback((conversations: Conversation[]) => {
    setFetchedConversations((fetchedConversations) =>
      uniqueVals(
        conversations.concat(fetchedConversations),
        (conversation) => conversation.conversationSid
      )
    );
  }, []);

  const removeConversationData = useCallback((conversationSid: string) => {
    setFetchedConversations((fetchedConversations) => {
      const index = fetchedConversations.findIndex(
        (fetched) => fetched.conversationSid === conversationSid
      );
      if (index !== -1) {
        fetchedConversations.splice(index, 1);
      }
      return uniqueVals(
        fetchedConversations,
        (conversation) => conversation.conversationSid
      );
    });
  }, []);

  const isFinished = useMemo(
    () => loadedAllConversations,
    [loadedAllConversations]
  );

  const retrieveConversationResults = useCallback(
    async (
      conversationsQuery: Query<Conversation>
    ): Promise<Conversation[]> => {
      const conversationsSnap = await getDocs(conversationsQuery);
      const uploadedTimes = conversationsSnap.docs.map(
        (doc) => doc.data().recentTimestamp
      );
      if (uploadedTimes.length > 0) {
        setLastVisible(uploadedTimes[uploadedTimes.length - 1]);
      }
      if (uploadedTimes.length < windowSize) {
        setLoadedAllConversations(true);
      }

      const conversations = await Promise.all(
        conversationsSnap.docs.map(async (doc) => {
          const conversation = doc.data();
          await fetchSingleUser(
            conversation.participantUids[0],
            followingAccountData,
            (userData) => addUserData([userData])
          );
          return conversation;
        })
      );
      addConversationData(conversations);
      setIsLoading(false);
      return conversations;
    },
    [addConversationData, addUserData, followingAccountData, windowSize]
  );

  const addConversationAndUser = useCallback(
    (conversation: Conversation) => {
      addConversationData([conversation]);
      fetchSingleUser(
        conversation.participantUids[0],
        followingAccountData,
        (userData) => addUserData([userData])
      );
    },
    [addConversationData, addUserData, followingAccountData]
  );

  const fetchConversations = useCallback(async () => {
    if (!isFinished && !isLoading) {
      setIsLoading(true);
      const userConversationsRef = getUserConversationsRef(userId);
      const userConversationsQuery = query(
        userConversationsRef,
        where("creatorUid", "==", userId),
        where("recentTimestamp", "!=", ""),
        orderBy("recentTimestamp", "desc"),
        limit(windowSize)
      );
      const conversations = await retrieveConversationResults(
        userConversationsQuery
      );

      const unsubscribe = onSnapshot(userConversationsQuery, (snapshot) => {
        snapshot.docChanges().forEach(async (message) => {
          const conversation = message.doc.data();
          // Only enter added case if new participant conversation message is created.
          // Does not enter if part of initial retrieveConversationResults
          if (
            message.type === "added" &&
            !conversations.some(
              (convo) => convo.conversationSid === conversation.conversationSid
            )
          ) {
            addConversationAndUser(conversation);
          } else if (message.type === "modified") {
            addConversationAndUser(conversation);
          } else if (message.type === "removed") {
            removeConversationData(conversation.conversationSid);
            removeUserData(conversation.participantUids[0]);
          }
        });
      });

      unsubscribeArrayRef.current.push(unsubscribe);
    }
  }, [
    isFinished,
    isLoading,
    userId,
    windowSize,
    retrieveConversationResults,
    addConversationAndUser,
    removeConversationData,
    removeUserData,
  ]);

  const fetchMoreConversations = useCallback(async () => {
    if (!isFinished && !isLoading) {
      setIsLoading(true);
      const userConversationsRef = getUserConversationsRef(userId);
      const userConversationsQuery = query(
        userConversationsRef,
        where("creatorUid", "==", userId),
        where("recentTimestamp", "!=", ""),
        orderBy("recentTimestamp", "desc"),
        startAfter(lastVisible),
        limit(windowSize)
      );
      const conversations = await retrieveConversationResults(
        userConversationsQuery
      );

      const unsubscribe = onSnapshot(userConversationsQuery, (snapshot) => {
        snapshot.docChanges().forEach(async (message) => {
          const conversation = message.doc.data();
          if (
            message.type === "added" &&
            !conversations.some(
              (convo) => convo.conversationSid === conversation.conversationSid
            )
          ) {
            addConversationAndUser(conversation);
          }
          if (message.type === "modified") {
            addConversationAndUser(conversation);
          } else if (message.type === "removed") {
            removeConversationData(conversation.conversationSid);
            removeUserData(conversation.participantUids[0]);
          }
        });
      });

      unsubscribeArrayRef.current.push(unsubscribe);
    }
  }, [
    isFinished,
    isLoading,
    userId,
    lastVisible,
    windowSize,
    retrieveConversationResults,
    addConversationAndUser,
    removeConversationData,
    removeUserData,
  ]);

  const loadSearchResults = useCallback(
    async (searchTerm: string) => {
      const fetchConversations = async (userIds: string[]) => {
        setIsLoading(true);
        await Promise.all(
          userIds.map(async (uid) => {
            const conversationsRef = getUserConversationsRef(userId);
            const query_ = query(
              conversationsRef,
              where("participantUids", "array-contains", uid),
              where("recentTimestamp", "!=", "")
            );
            const snapshot = await getDocs(query_);
            if (!snapshot.empty) {
              const conversation = snapshot.docs.map((doc) => doc.data())[0];
              addConversationAndUser(conversation);
            }
          })
        );
        setIsLoading(false);
      };
      await loadAlgoliaUsersSearchResults(
        searchTerm,
        `following:${userId}`,
        (userIds: string[]) => fetchConversations(userIds)
      );
    },
    [userId, addConversationAndUser]
  );

  const loadSearchResultsThrottled = useCallback(
    (searchTerm: string) => {
      if (timeoutEvent) {
        clearTimeout(timeoutEvent);
      }
      setTimeoutEvent(
        setTimeout(() => {
          if (searchTerm.length > 1) {
            loadSearchResults(searchTerm);
          }
        }, 500)
      );
    },
    [loadSearchResults, timeoutEvent]
  );

  return {
    isFinished,
    isLoading,
    fetchedUserData,
    fetchedConversations,
    fetchConversations,
    fetchMoreConversations,
    loadSearchResultsThrottled,
  };
};
