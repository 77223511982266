import React, { memo } from "react";
import { useTheme } from "../../hooks/useTheme";

type VerticalDividerProps = {
  height: number;
  color?: string;
  altMargin?: number;
  thickness?: number;
};

export const VerticalDivider = memo(function VerticalDividerFn(
  props: VerticalDividerProps
) {
  const { height, color, altMargin, thickness } = props;
  const { theme } = useTheme();

  return (
    <div
      style={{
        // width: altThickness ?? 1,
        // backgroundColor: theme.DividerColor.borderColor,
        width: 0,
        border: `${thickness ?? 1}px solid ${
          color || theme.DividerColor.borderColor
        }`,
        height: height,
        margin: `${altMargin !== undefined ? altMargin : 0} ${
          altMargin !== undefined ? altMargin : 0
        }`,
      }}
    />
  );
});
