import React from "react";
import { Colors } from "../../../../utils/colors";
import { AudienceList } from "@markit/common.types";
import { MassTextsActionItem } from "../../MassTexts/Items/MassTextsActionItem";
import { Icon } from "@iconify/react";
import { HorizontalDivider } from "../../../Dividers/HorizontalDivider";
import { FAVORITES_LIST_NAME } from "@markit/common.utils";
import { AddNewToListButton } from "../../../Buttons/AddNewToListButton";
import StandardBorderedContainer from "../../../Containers/StandardBorderedContainer";

interface AudienceListItemsContainerProps {
  lists: AudienceList[];
  itemOnPress?: (list: AudienceList) => void;
  newListOnPress?: () => void;
}

const AudienceListItemsContainer = (props: AudienceListItemsContainerProps) => {
  const { lists, newListOnPress, itemOnPress } = props;

  return (
    <StandardBorderedContainer
      containerStyles={{
        width: 547,
        backgroundColor: Colors.WHITE1,
        paddingBlock: 14,
      }}
    >
      <div className="ColumnNormal">
        {lists.map((list, index) => (
          <div className="ColumnNormal" key={index}>
            <MassTextsActionItem
              title={list.name}
              altIconBackgroundColor={Colors.WHITE}
              icon={
                list.name === FAVORITES_LIST_NAME ? (
                  <Icon icon="mdi:star-circle" height={34} />
                ) : (
                  <Icon icon="ion:list" height={40} color={Colors.BLUE5} />
                )
              }
              onPress={itemOnPress ? () => itemOnPress(list) : undefined}
              checkColor={Colors.BLUE5}
              isCheckSelected={true}
              containerStyles={{ paddingInline: 14 }}
            />
            {index !== lists.length - 1 || newListOnPress ? (
              <HorizontalDivider altMargin={14} />
            ) : null}
          </div>
        ))}
        {newListOnPress ? (
          <AddNewToListButton
            label="Add to Lists..."
            onPress={newListOnPress}
            containerStyles={{ paddingInline: 14 }}
          />
        ) : null}
      </div>
    </StandardBorderedContainer>
  );
};

export default AudienceListItemsContainer;
