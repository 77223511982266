import { useCallback, useMemo, useRef, useState } from "react";
import {
  getDocs,
  onSnapshot,
  limit,
  orderBy,
  query,
  startAfter,
  where,
} from "../firebase";
import { filterUndefinedValues, uniqueVals } from "@markit/common.utils";
import {
  AccountData,
  Follower,
  SpreadsheetStatus,
  FollowerStatus,
} from "@markit/common.types";
import { getUserFollowersRef } from "../utils/FirebaseUtils";
import { fetchSingleFollower, fetchSingleUser } from "../utils/FetchSingleData";
import { loadAlgoliaUsersSearchResults } from "../utils/algoliaUtils";
import { batch, useDispatch, useSelector } from "react-redux";
import { accountActions, getAccountState } from "../redux/slices/accountSlice";
import useOnUnmount from "./useOnUnmount";

type useLoadSpreadsheetUserListProps = {
  userId: string;
  followerStatus: FollowerStatus;
  windowSize: number;
  status: SpreadsheetStatus;
  fileId?: string;
};
export const useLoadSpreadsheetUserList = (
  props: useLoadSpreadsheetUserListProps
) => {
  const { userId, followerStatus, windowSize, status, fileId } = props;

  const { followingAccountData, followersData } =
    useSelector(getAccountState).account;
  const dispatch = useDispatch();
  const [fetchedUnorderedUserData, setFetchedUnorderedUserData] = useState<
    AccountData[]
  >([]);
  const [fetchedFollowerData, setFetchedFollowerData] = useState<Follower[]>(
    []
  );
  const unsubscribeArrayRef = useRef<(() => void)[]>([]);

  const [isLoading, setIsLoading] = useState(false);
  const [timeoutEvent, setTimeoutEvent] = useState<
    NodeJS.Timeout | undefined
  >();

  const [lastVisible, setLastVisible] = useState<string>(
    new Date().toISOString()
  );
  const [loadedAllMessages, setLoadedAllMessages] = useState(false);

  const fetchedUserData: AccountData[] = useMemo(() => {
    const followerUidOrder = fetchedFollowerData.map(
      (follower) => follower.uid
    );
    return fetchedUnorderedUserData.sort(
      (a, b) =>
        followerUidOrder.indexOf(a.uid) - followerUidOrder.indexOf(b.uid)
    );
  }, [fetchedFollowerData, fetchedUnorderedUserData]);

  useOnUnmount(
    (latestDeps) => {
      batch(() => {
        dispatch(
          accountActions.addMultipleFollowingAccountData(
            latestDeps[0] as AccountData[]
          )
        );
        dispatch(
          accountActions.addMultipleFollowerData(latestDeps[1] as Follower[])
        );
      });
      unsubscribeArrayRef.current.forEach(
        (unsubscribe) => unsubscribe && unsubscribe()
      );
      unsubscribeArrayRef.current = []; // Clear the array after cleanup
    },
    [fetchedUnorderedUserData, fetchedFollowerData]
  );

  const addUserData = useCallback((newUserData: AccountData[]) => {
    setFetchedUnorderedUserData((fetchedUserData) =>
      uniqueVals(
        newUserData.concat(fetchedUserData),
        (userData) => userData.uid
      )
    );
  }, []);

  const removeUserData = useCallback((userDataUid: string) => {
    setFetchedUnorderedUserData((fetchedUserData) => {
      const index = fetchedUserData.findIndex(
        (fetched) => fetched.uid === userDataUid
      );
      if (index !== -1) {
        fetchedUserData.splice(index, 1);
      }
      return [...fetchedUserData];
    });
  }, []);

  const addFollowerData = useCallback((newFollowers: Follower[]) => {
    setFetchedFollowerData((fetchedFollowerData) =>
      uniqueVals(
        newFollowers.concat(fetchedFollowerData),
        (followerData) => followerData.uid
      ).sort(
        (a, b) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      )
    );
  }, []);

  const removeFollowerData = useCallback((newFollowerUid: string) => {
    setFetchedFollowerData((fetchedFollowerData) => {
      const index = fetchedFollowerData.findIndex(
        (fetched) => fetched.uid === newFollowerUid
      );
      if (index !== -1) {
        fetchedFollowerData.splice(index, 1);
      }
      return [...fetchedFollowerData];
    });
  }, []);

  const isFinished = useMemo(() => loadedAllMessages, [loadedAllMessages]);

  const updateData = useCallback(
    async (foundMemberData: Follower[]) => {
      addFollowerData(foundMemberData as Follower[]);
      dispatch(
        accountActions.addMultipleFollowerData(foundMemberData as Follower[])
      );

      const membersUserData = await Promise.all(
        foundMemberData.map((member) =>
          fetchSingleUser(member.uid, followingAccountData)
        )
      );
      const definedMembersUserData: AccountData[] =
        filterUndefinedValues(membersUserData);
      addUserData(definedMembersUserData);
    },
    [addFollowerData, addUserData, dispatch, followingAccountData]
  );

  const initialUpdateData = useCallback(
    async (foundMemberData: Follower[]) => {
      if (foundMemberData.length) {
        setLastVisible(foundMemberData[foundMemberData.length - 1].uid);
      }
      if (foundMemberData.length < windowSize) {
        setLoadedAllMessages(true);
      }

      if (foundMemberData.length > 0) {
        await updateData(foundMemberData);
      }
    },
    [updateData, windowSize]
  );

  const liveUpdateData = useCallback(
    async (
      type: "added" | "modified" | "removed",
      member: Follower,
      foundMemberData: Follower[]
    ) => {
      if (
        (type === "added" &&
          !foundMemberData.some(
            (memberData) => memberData.uid === member.uid
          )) ||
        type === "modified"
      ) {
        updateData([member]);
      } else if (type === "removed") {
        removeFollowerData(member.uid);
        removeUserData(member.uid);
      }
    },
    [removeFollowerData, removeUserData, updateData]
  );

  const loadUsers = useCallback(async () => {
    if (!isFinished && !isLoading) {
      setIsLoading(true);
      const followMembersRef = getUserFollowersRef(userId);
      const followMembersQuery = fileId
        ? query(
            followMembersRef,
            where("spreadsheetData.fileId", "==", fileId),
            where("spreadsheetData.status", "==", status),
            where("status", "==", followerStatus),
            orderBy("createdAt", "desc"),
            limit(windowSize)
          )
        : query(
            followMembersRef,
            where("spreadsheetData.status", "==", status),
            where("status", "==", followerStatus),
            orderBy("createdAt", "desc"),
            limit(windowSize)
          );

      const foundFollowMembers = (await getDocs(followMembersQuery)).docs.map(
        (doc) => doc.data()
      );
      await initialUpdateData(foundFollowMembers);

      const unsubscribe = onSnapshot(followMembersQuery, (snapshot) => {
        snapshot.docChanges().forEach(async (message, index) => {
          const memberData = message.doc.data();
          liveUpdateData(message.type, memberData, foundFollowMembers);
        });
      });
      unsubscribeArrayRef.current.push(unsubscribe);

      setIsLoading(false);
      return unsubscribe;
    }
  }, [
    isFinished,
    isLoading,
    userId,
    fileId,
    status,
    followerStatus,
    windowSize,
    initialUpdateData,
    liveUpdateData,
  ]);

  const loadMoreUsers = useCallback(async () => {
    if (!isFinished && !isLoading) {
      setIsLoading(true);
      const followMembersRef = getUserFollowersRef(userId);
      const followMembersQuery = fileId
        ? query(
            followMembersRef,
            where("spreadsheetData.fileId", "==", fileId),
            where("spreadsheetData.status", "==", status),
            where("status", "==", followerStatus),
            orderBy("createdAt", "desc"),
            startAfter(lastVisible),
            limit(windowSize)
          )
        : query(
            followMembersRef,
            where("spreadsheetData.status", "==", status),
            where("status", "==", followerStatus),
            orderBy("createdAt", "desc"),
            startAfter(lastVisible),
            limit(windowSize)
          );

      const foundFollowMembers = (await getDocs(followMembersQuery)).docs.map(
        (doc) => doc.data()
      );
      await initialUpdateData(foundFollowMembers);

      const unsubscribe = onSnapshot(followMembersQuery, (snapshot) => {
        snapshot.docChanges().forEach(async (message, index) => {
          const memberData = message.doc.data();
          liveUpdateData(message.type, memberData, foundFollowMembers);
        });
      });
      unsubscribeArrayRef.current.push(unsubscribe);

      setIsLoading(false);
      return unsubscribe;
    }
  }, [
    fileId,
    followerStatus,
    initialUpdateData,
    isFinished,
    isLoading,
    lastVisible,
    liveUpdateData,
    status,
    userId,
    windowSize,
  ]);

  /*
   * Start searching utils
   */
  const checkIfAddUserData = useCallback(
    async (uid: string) => {
      const userData = await fetchSingleUser(uid, followingAccountData);
      if (userData) {
        const followerData = await fetchSingleFollower(
          userId,
          userData.uid,
          followersData
        );
        if (followerData && followerData.status === followerStatus) {
          addFollowerData([followerData]);
          addUserData([userData]);
        }
      }
    },
    [
      addFollowerData,
      addUserData,
      followerStatus,
      followersData,
      followingAccountData,
      userId,
    ]
  );

  const loadSearchResults = useCallback(
    async (searchTerm: string) => {
      const fetchFollowers = async (userIds: string[]) => {
        setIsLoading(true);
        await Promise.all(
          userIds.map(async (uid) => {
            await checkIfAddUserData(uid);
          })
        );
        setIsLoading(false);
      };
      await loadAlgoliaUsersSearchResults(
        searchTerm,
        `following:${userId}`,
        (userIds: string[]) => fetchFollowers(userIds)
      );
    },
    [userId, checkIfAddUserData]
  );

  const loadSearchResultsThrottled = useCallback(
    (searchTerm: string) => {
      if (timeoutEvent) {
        clearTimeout(timeoutEvent);
      }
      setTimeoutEvent(
        setTimeout(() => {
          if (searchTerm.length > 1) {
            loadSearchResults(searchTerm);
          }
        }, 500)
      );
    },
    [loadSearchResults, timeoutEvent]
  );
  /*
   * End searching utils
   */

  return {
    isFinished,
    isLoading,
    fetchedUserData,
    fetchedFollowerData,
    loadUsers,
    loadMoreUsers,
    loadSearchResultsThrottled,
  };
};
