import "../../../css/Containers/ContainerStyles.css";
import { Icon } from "@iconify/react";
import { AnimatePresence, LazyMotion, domAnimation, m } from "framer-motion";
import React, { useEffect } from "react";
import { Colors } from "../../../utils/colors";

export enum BinaryConfirmActions {
  AFFIRMATIVE = "affirmative",
  NEGATIVE = "negative",
  NONE = "",
}

type ConfirmActionPopupProps = {
  negativeTitle?: string;
  isNegative?: boolean;
  onDisappear: () => void;
  title: string;
  altIcon?: string;
};

// Small popup that slides in from the bottom to confirm an action and fades out after a few seconds
const ConfirmActionPopup = (props: ConfirmActionPopupProps) => {
  const { title, altIcon, negativeTitle, isNegative, onDisappear } = props;

  useEffect(() => {
    const timer = setTimeout(() => {
      onDisappear();
    }, 3000);

    return () => clearTimeout(timer);
    // adding onDisappear as dependency causes excessive re-renders, can solve by wrapping every
    // instance of onDisappear with useCallbacks, but easier to use by just not including it

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNegative]);

  return (
    <AnimatePresence>
      <LazyMotion features={domAnimation}>
        <m.div
          className="ConfirmActionContainer"
          initial={{ opacity: 0, y: 40 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 40 }}
          transition={{ duration: 0.4, ease: "easeIn" }}
          style={{
            backgroundColor: isNegative ? Colors.RED3 : Colors.GREEN2,
            x: "-50%",
          }}
        >
          <Icon
            icon={
              altIcon ??
              (isNegative ? "ion:close-circle" : "ion:checkmark-circle")
            }
            height={18}
          />
          <span>{isNegative ? negativeTitle : title}</span>
        </m.div>
      </LazyMotion>
    </AnimatePresence>
  );
};

export default ConfirmActionPopup;
