import { combineReducers } from "redux";
import {
  Action,
  PayloadAction,
  ThunkAction,
  configureStore,
} from "@reduxjs/toolkit";
import { initialUrlParamState, urlParamsReducer } from "./slices/urlParamSlice";
import createSagaMiddleware from "@redux-saga/core";
import { loadDataSaga } from "./sagas/dataSagas";
import { dataReducer, initialDataState } from "./slices/dataSlice";
import { accountReducer, initialAccountState } from "./slices/accountSlice";
import { initialWishMarkState, wishMarkReducer } from "./slices/wishMarkSlice";
import { eventReducer, initialEventState } from "./slices/eventSlice";
import {
  conversationReducer,
  initialConversationState,
} from "./slices/conversationSlice";
import { loadState, saveState } from "./localStorage";
import { debounce } from "debounce";
import { initializationSaga } from "./sagas/initializationSaga";
import { campaignReducer, initialCampaignState } from "./slices/campaignSlice";

export const USER_LOGGED_OUT = "USER_LOGGED_OUT";

const appReducer = combineReducers({
  urlParams: urlParamsReducer,
  wishlist: wishMarkReducer,
  events: eventReducer,
  account: accountReducer,
  campaigns: campaignReducer,
  conversations: conversationReducer,
  data: dataReducer,
});

export type AppState = ReturnType<typeof appReducer>;
export type AppThunk = ThunkAction<void, AppState, unknown, Action<string>>;

const rootReducer = (
  state: AppState | undefined,
  action: PayloadAction<any>
) => {
  // Clear all data in redux store to initial.
  if (action.type === USER_LOGGED_OUT) {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

const sagaMiddleware = createSagaMiddleware();
const loadedState = loadState();

const initialAppState: AppState = {
  account: {
    ...initialAccountState,
    loggedIn:
      loadedState && loadedState.savedState
        ? loadedState.savedState.loggedIn
        : 1,
    accountData: {
      ...initialAccountState.accountData,
      uid:
        loadedState && loadedState.savedState ? loadedState.savedState.uid : "",
      fullName:
        loadedState && loadedState.savedState
          ? loadedState.savedState.fullName
          : "",
      inCreatorMode:
        loadedState && loadedState.savedState
          ? loadedState.savedState.inCreatorMode
          : false,
      profilePicURL:
        loadedState && loadedState.savedState
          ? loadedState.savedState.profilePicURL
          : "",
      isAdmin:
        loadedState && loadedState.savedState
          ? loadedState.savedState.isAdmin
          : false,
    },
  },
  wishlist: initialWishMarkState,
  events: initialEventState,
  urlParams: initialUrlParamState,
  campaigns: initialCampaignState,
  conversations: initialConversationState,
  data: initialDataState,
};

const store = configureStore({
  reducer: rootReducer,
  preloadedState: initialAppState,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {},
    }).concat(sagaMiddleware),
});

// subscribe to the store changes redux
store.subscribe(
  debounce(() => {
    saveState({
      savedState: {
        loggedIn: store.getState().account
          ? store.getState().account.loggedIn
          : 1,
        uid: store.getState().account
          ? store.getState().account.accountData.uid
          : "",
        fullName: store.getState().account
          ? store.getState().account.accountData.fullName
          : "",
        inCreatorMode: store.getState().account
          ? store.getState().account.accountData.inCreatorMode
          : false,
        profilePicURL: store.getState().account
          ? store.getState().account.accountData.profilePicURL
          : "",
        isAdmin: store.getState().account
          ? store.getState().account.accountData.isAdmin
          : false,
      },
    });
  }, 800)
);

sagaMiddleware.run(initializationSaga);
sagaMiddleware.run(loadDataSaga);

export default store;
