import {
  Event,
  PromoCode,
  PromoCodeType,
  TicketV2,
} from "@markit/common.types";
import { Colors } from "../../../utils/colors";
import { API } from "../../../API";
import React, { useMemo, useState } from "react";
import { useTheme } from "../../../hooks/useTheme";
import ThreeDotsButton from "../../Buttons/ThreeDotsButton";
import { HorizontalDivider } from "../../Dividers/HorizontalDivider";
import { DropdownMenu, DropdownMenuItem } from "../../DropdownMenu";
import useAsyncEffect from "../../../hooks/useAsyncEffect";

export const PROMO_FLEX_SECTIONS = [10, 5, 2];

type PromoCodeItemProps = {
  event: Event;
  item: PromoCode | TicketV2;
  index: number;
  totalItems: number;
  setItem: (promoCodeId: PromoCode | TicketV2) => void;
  setFreePromoRedeemed: (freePromoRedeemed: boolean) => void;
  freeTicketsTotalCount: number;
  dropdownItems: DropdownMenuItem[];
};

export const PromoCodeItem = (props: PromoCodeItemProps) => {
  const {
    event,
    item,
    index,
    totalItems,
    setItem,
    setFreePromoRedeemed,
    freeTicketsTotalCount,
    dropdownItems,
  } = props;
  const { theme } = useTheme();
  const [totalFreePromo, setTotalFreePromo] = useState(1);
  const [usedFreePromo, setUsedFreePromo] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const isDiscountCode = useMemo(
    () => (item as PromoCode).type !== undefined,
    [item]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useAsyncEffect(async () => {
    if ((item as TicketV2).alias) {
      await Promise.all([
        API.promoCodes
          .getTotalFreePromo({
            eventId: event.id,
            alias: item.alias,
          })
          .then(async (response) => {
            const { totalFreePromo } = response;
            setTotalFreePromo(totalFreePromo);
          }),
        API.promoCodes
          .getUsedFreePromo({
            eventId: event.id,
            alias: item.alias,
          })
          .then(async (response) => {
            const { usedFreePromo } = response;
            setUsedFreePromo(usedFreePromo);
          }),
      ]);
    }
    setIsLoading(false);
  }, [event.id, item, freeTicketsTotalCount]);

  return (
    <div key={item.id}>
      <div
        style={{
          display: "flex",
          paddingInline: 14,
          paddingBlock: 7,
          gap: 14,
        }}
      >
        <span
          style={{
            color: Colors.BLACK,
            fontSize: 14,
            fontWeight: 600,
            flex: PROMO_FLEX_SECTIONS[0],
          }}
          className="EventDashboardContentDescription"
        >
          {item.alias}
        </span>
        <div style={{ flex: PROMO_FLEX_SECTIONS[1] }}>
          <span
            style={{
              color: theme.SubText.color,
              fontSize: 14,
              marginLeft: 20,
            }}
            className="EventDashboardContentDescription"
          >
            {isDiscountCode
              ? (item as PromoCode).type === PromoCodeType.PERCENT_OFF
                ? `${(item as PromoCode).constants[0]}%`
                : 0
              : "Free"}
          </span>
        </div>
        <div
          className="AlignedRowSpaced"
          style={{ flex: PROMO_FLEX_SECTIONS[2] }}
        >
          <span
            style={{
              paddingLeft: 24,
              color: theme.SubText.color,
            }}
            className="EventDashboardContentDescription"
          >
            {isDiscountCode
              ? (item as PromoCode).maxUseCount !== undefined
                ? `${(item as PromoCode).usedCount ?? 0}/${
                    (item as PromoCode).maxUseCount
                  }`
                : "-/-"
              : !isLoading
              ? usedFreePromo + "/" + totalFreePromo
              : "-/-"}
          </span>
          <div>
            <DropdownMenu
              dropdownItems={dropdownItems}
              trigger={["click"]}
              placement="bottomLeft"
              altWidth={150}
            >
              <ThreeDotsButton
                onPress={() => {
                  if (!isDiscountCode) {
                    setFreePromoRedeemed(usedFreePromo > 0);
                  }
                  console.log(item.id);
                  setItem(item);
                }}
              />
            </DropdownMenu>
          </div>
        </div>
      </div>
      {index !== totalItems - 1 && <HorizontalDivider altMargin={14} />}
    </div>
  );
};
