import "../../css/App.css";
import {
  useStripe,
  useElements,
  PaymentElement,
  PaymentRequestButtonElement,
} from "@stripe/react-stripe-js";
import {
  PaymentRequest,
  Stripe,
  StripeElements,
  StripePaymentRequestButtonElementClickEvent,
} from "@stripe/stripe-js";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Event } from "@markit/common.types";
import { MixpanelContext } from "../../context/AnalyticsService";
import { useSelector } from "react-redux";
import { getAccountState } from "../../redux/slices/accountSlice";
import { Colors } from "../../utils/colors";
import { paymentTimeoutHandler } from "../../utils/FullEventUtils/inputFormUtils";
import { isEventTicketsPaid } from "@markit/common.utils";
import { useTheme } from "../../hooks/useTheme";
import RectangleButton from "../../components/Buttons/RectangleButton";

type CheckoutFormProps = {
  customerPhone: string;
  totalAmount: number;
  customTicketId: string;
  clientSecret: string | undefined;
  isReadyForPayment: boolean;
  processing: boolean;
  setProcessing: (processing: boolean) => void;
  event: Event;
  onPress: (stripe: Stripe, elements: StripeElements) => Promise<void>;
  showPurchaseError: () => boolean;
  rsvpToEvent: () => Promise<void>;
  ticketQuantity: number;
  foundCustomTicketId: string;
  disabled: boolean;
};

const CheckoutForm = ({
  customerPhone,
  totalAmount,
  customTicketId,
  clientSecret,
  isReadyForPayment,
  processing,
  setProcessing,
  event,
  onPress,
  showPurchaseError,
  rsvpToEvent,
  ticketQuantity,
  foundCustomTicketId,
  disabled,
}: CheckoutFormProps) => {
  const stripe = useStripe();
  const elements = useElements();
  const { account } = useSelector(getAccountState);
  const { isRCA, accountData } = account;
  const { theme } = useTheme();

  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest>();
  const [useCredit, setUseCreditCard] = useState(false);
  const mixpanel = useContext(MixpanelContext);

  // Disable the payment button if the user is an email based account, or if the disabled prop is true
  // disabled prop can be true if the purchase exceeds capacity (willExceedCapacity), etc
  const disablePayment = useMemo(
    () => accountData.email !== "" || disabled,
    [accountData.email, disabled]
  );

  // For handling apple pay
  useEffect(() => {
    if (!stripe || !elements) {
      return;
    }

    const pr = stripe.paymentRequest({
      country: "US",
      currency: "usd",
      total: {
        label: "Ticket to " + event.title,
        amount: totalAmount,
      },
      requestPayerName: true,
      requestPayerEmail: true,
    });
    // console.log("payment request", pr !== undefined);

    // Check the availability of the Payment Request API.
    pr.canMakePayment()
      .then((result) => {
        // console.log("can make payment");
        if (result) {
          setPaymentRequest(pr);
        }
      })
      .catch(() => {
        alert("Error showing payment sheet.");
      });

    pr.on("cancel", async () => {
      if (mixpanel) {
        mixpanel.track("Webapp: User Cancelled Apple Pay Payment Sheet", {
          event_id: event.id,
          event_type: isEventTicketsPaid(event) ? "paid" : "free",
          customTicketId: customTicketId,
          customer_phone: customerPhone,
          apple_pay: true,
          amount: totalAmount,
        });
      }
      setProcessing(false);
    });

    pr.on("paymentmethod", async (e) => {
      if (mixpanel) {
        mixpanel.track("Webapp: Attempting Ticket Purchase", {
          event_id: event.id,
          event_type: isEventTicketsPaid(event) ? "paid" : "free",
          customTicketId: customTicketId,
          customer_phone: customerPhone,
          apple_pay: true,
          amount: totalAmount,
        });
      }
      if (!clientSecret) {
        e.complete("fail");
        return;
      }

      // Set payment timeout of 30 seconds
      const paymentTimeout = 30000;
      const timeoutPromise = new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(new Error("Payment processing timed out. Please try again."));
        }, paymentTimeout);
      });

      const paymentPromise = new Promise((resolve, reject) => {
        stripe
          .confirmCardPayment(
            clientSecret,
            {
              payment_method: e.paymentMethod.id,
            },
            { handleActions: false }
          )
          .then((result) => {
            const { error: stripeError } = result;
            resolve(stripeError); // Resolve the Promise with the error object
          })
          .catch((error) => {
            resolve(
              new Error(
                "API call to Stripe confirmCardPayment failed: " + error
              )
            );
          });
      });

      const result = await Promise.race([paymentPromise, timeoutPromise]);

      // result is either instanceof Error or StripeError if there is an error
      // otherwise, result is undefined if payment is successful
      if (
        result instanceof Error &&
        result.message === "Payment processing timed out. Please try again."
      ) {
        e.complete("fail");
        await paymentTimeoutHandler(
          event.id,
          customerPhone,
          "",
          totalAmount,
          ticketQuantity,
          isEventTicketsPaid(event) ? "paid" : "free",
          foundCustomTicketId,
          customerPhone,
          mixpanel
        );

        alert(
          "Your payment has timed out. Please try again. This page will be refreshed."
        );
        window.location.reload();
      } else if (result) {
        // Show error to your customer (e.g., insufficient funds)
        e.complete("fail");
        mixpanel.track("Webapp: Stripe Error", {
          event_id: event.id,
          error_message: result,
          customTicketId: customTicketId,
          customer_phone: customerPhone,
        });
      } else {
        e.complete("success");
        mixpanel.track("Webapp: Bought a Ticket", {
          event_id: event.id,
          event_type: isEventTicketsPaid(event) ? "paid" : "free",
          customTicketId: customTicketId,
          customer_phone: customerPhone,
          apple_pay: true,
          amount: totalAmount,
        });
        await rsvpToEvent();
      }
    });
  }, [
    mixpanel,
    stripe,
    elements,
    totalAmount,
    clientSecret,
    rsvpToEvent,
    event.title,
    event.id,
    customerPhone,
    setProcessing,
    event,
    customTicketId,
    accountData.uid,
    ticketQuantity,
    foundCustomTicketId,
    showPurchaseError,
  ]);

  if (!elements || !stripe) {
    return null;
  }

  return (
    <>
      {paymentRequest && !disablePayment && (
        <div aria-disabled={processing} className="applePayElement">
          <PaymentRequestButtonElement
            options={{
              paymentRequest,
            }}
            onClick={(
              clickEvent: StripePaymentRequestButtonElementClickEvent
            ) => {
              if (showPurchaseError()) {
                clickEvent.preventDefault();
                return false;
              }

              setProcessing(true);
              if (!isReadyForPayment) {
                clickEvent.preventDefault();
                mixpanel.track("Webapp: Ready For Payment Error", {
                  event_id: event.id,
                  customTicketId: customTicketId,
                  customer_phone: customerPhone,
                });
              }
            }}
          />
        </div>
      )}
      {useCredit ? (
        <>
          <PaymentElement
            options={{
              wallets: {
                applePay: "never",
              },
            }}
          />
          <div className="AlignedColumn" style={{ marginTop: "14px" }}>
            <RectangleButton
              buttonLabel={<span>{"Pay with Card"}</span>}
              onPress={() => onPress(stripe, elements)}
              theme={theme}
              altPaddingVert={12}
              disabled={!stripe || processing}
              loading={processing}
            />
            {processing ? (
              <div
                style={{
                  ...theme.PrimaryText,
                  fontSize: 12,
                  fontWeight: "400",
                  textAlign: "center",
                  width: "60%",
                  marginTop: 7,
                }}
              >
                Please do not close this window while your payment is
                processing.
              </div>
            ) : null}
          </div>
        </>
      ) : (
        <p
          className="useCreditCard"
          onClick={() => {
            console.log("credit card amount: " + totalAmount);
            if (showPurchaseError()) {
              return false;
            }
            if (!isReadyForPayment) {
              mixpanel.track("Webapp: Not Ready For Payment Error", {
                event_id: event.id,
                customTicketId: customTicketId,
                customer_phone: customerPhone,
              });
            } else {
              setUseCreditCard(true);
            }
          }}
          style={{
            color: disablePayment
              ? Colors.GRAY1
              : isRCA
              ? "#FE296C"
              : Colors.BLUE5,
            fontWeight: "500",
            cursor: "pointer",
            pointerEvents: disablePayment ? "none" : "all",
          }}
        >
          Use Credit Card
        </p>
      )}
    </>
  );
};

export default CheckoutForm;
