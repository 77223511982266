import "../../css/FullEvent/FullEvent.css";
import "../../css/GlobalStyles.css";
import React, { memo, useCallback, useMemo, useState } from "react";
import {
  Event,
  AccountData,
  DiscussionPost,
  CustomTicketV2,
  TicketType,
} from "@markit/common.types";
import { FormatCurrency } from "../../utils/FormatCurrency";
import { TimeAgo } from "../../utils/TimeAgo";
import ProgressBar from "@ramonak/react-progress-bar";
import { groupByKey, isEventTicketsPaid } from "@markit/common.utils";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { FullEventLocationPin } from "./FullEventLocationPin";
import {
  getTicketLabels,
  getTicketsRemainingInGroup,
} from "../../utils/eventUtils/eventUtils";
import { isDesktop } from "react-device-detect";
import { GuestListSummary } from "../FullEventSubComponents/GuestListSummary";
import { InitialsPic } from "../InitialsPic";
import Banner, { BannerType } from "../Banner";
import Footer from "../Footer";
import { Colors } from "../../utils/colors";
import { useTheme } from "../../hooks/useTheme";
import FullEventHosts from "./FullEventHosts";
import CustomLinkify from "../Links/CustomLinkify";
import useAsyncOnMount from "../../hooks/useAsyncEffectOnMount";

type FullEventInfoProps = {
  event: Event;
  host: AccountData | undefined;
  cohostsList?: AccountData[] | undefined;
  posts: DiscussionPost[];
  address: string;
  visibleTickets: CustomTicketV2[];
};

const FullEventInfo = memo(function FullEventInfoFn(props: FullEventInfoProps) {
  const { event, host, cohostsList, posts, address, visibleTickets } = props;
  const [labels, setLabels] = useState<string[][]>([[]]);
  const [groupTicketsRemaining, setGroupTicketsRemaining] = useState<number[]>(
    []
  );

  const { theme } = useTheme();

  useAsyncOnMount(async () => {
    const ticketsRemainingInGroups = await Promise.all(
      visibleTickets.map(
        async (customTicket: CustomTicketV2) =>
          await getTicketsRemainingInGroup(customTicket, event)
      )
    );
    setGroupTicketsRemaining(ticketsRemainingInGroups);

    const allLabels = await Promise.all(
      visibleTickets.map(
        async (customTicket: CustomTicketV2, index: number) =>
          await getTicketLabels(
            customTicket,
            ticketsRemainingInGroups[index],
            event.hideResponses
          )
      )
    );
    setLabels(allLabels);
  });

  const ticketsPaid = useMemo(() => isEventTicketsPaid(event), [event]);

  const checkCrowdfundingHide = useMemo(
    () => (event?.crowdfundingHide ? event?.crowdfundingHide : false),
    [event?.crowdfundingHide]
  );

  const hostAndCohosts: AccountData[] =
    cohostsList && host ? [host, ...cohostsList] : host ? [host] : [];

  const getTicketPriceElement = useCallback(
    (ticket: CustomTicketV2, index: number) => {
      if (event !== undefined) {
        return (
          <div
            key={index}
            className="TicketPrices"
            style={{
              ...theme.TertiaryBG,
              opacity:
                groupTicketsRemaining[index] !== 0 && !event.isManualSoldOut
                  ? 1
                  : 0.3,
            }}
          >
            <div className="AlignedRowSpaced" style={{ gap: 7 }}>
              <span style={{ ...theme.PrimaryText, flex: 1 }}>
                {ticket.label}
              </span>
              {groupTicketsRemaining[index] === 0 || event.isManualSoldOut ? (
                <span
                  style={{
                    ...theme.SmallSubText,
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}
                >
                  Sold Out
                </span>
              ) : null}
            </div>
            <hr
              style={{
                border: `1px dashed ${theme.TextFieldBorder.borderColor}`,
              }}
            />
            <div className="ColumnNormal" style={{ gap: 3 }}>
              <span
                className="TicketPrice"
                style={{ ...theme.PrimaryText, marginBottom: 4 }}
              >
                {ticket.type === TicketType.REQUEST
                  ? "Approval Required"
                  : ticket.price === 0
                  ? "Free"
                  : FormatCurrency(ticket.price / 100)}
              </span>
              {labels[index] &&
              labels[index].length > 0 &&
              labels.length === visibleTickets.length ? (
                <span
                  className="FullEventHeaderSectionSubtext"
                  style={{ fontWeight: 400 }}
                >
                  {labels[index].join(" • ")}
                </span>
              ) : null}
              {ticket.minQuantity && ticket.maxQuantity ? (
                <div
                  className="FullEventHeaderSectionSubtext"
                  style={{ fontWeight: 400 }}
                >
                  <span>
                    {Number(ticket.minQuantity) === Number(ticket.maxQuantity)
                      ? ticket.minQuantity + " per person"
                      : ticket.minQuantity +
                        " to " +
                        ticket.maxQuantity +
                        " per person"}
                  </span>
                </div>
              ) : null}
            </div>
          </div>
        );
      }
    },
    [
      event,
      theme.TertiaryBG,
      theme.PrimaryText,
      theme.SmallSubText,
      theme.TextFieldBorder.borderColor,
      groupTicketsRemaining,
      labels,
      visibleTickets.length,
    ]
  );

  const ReadMore = ({ children }: any) => {
    const text = children;
    const hasText = !!text;
    const textLength = text ? text.length : 0;
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
      setIsReadMore(!isReadMore);
    };

    return hasText && textLength > 150 ? (
      <div className="AboutContent">
        <CustomLinkify>
          <div className="text" style={theme.SmallSubText}>
            {isReadMore ? text.slice(0, 150) + "..." : text}
          </div>
        </CustomLinkify>
        {!isReadMore && event.link !== "" ? (
          <a className="Link" href={event?.link}>
            {event?.link}
          </a>
        ) : null}
        <div
          onClick={toggleReadMore}
          className={isReadMore ? "ReadOrHideClosed" : "ReadOrHideOpened"}
          style={isReadMore ? theme.ExpandBG : {}}
        >
          <div className="ReadOrHideText" style={theme.PrimaryText}>
            {isReadMore ? "See more" : "See less"}
          </div>
          {isReadMore ? (
            <IoIosArrowDown
              size={18}
              style={{ ...theme.PrimaryText, cursor: "pointer" }}
            />
          ) : (
            <IoIosArrowUp
              size={18}
              style={{ ...theme.PrimaryText, cursor: "pointer" }}
            />
          )}
        </div>
      </div>
    ) : (
      <div className="AboutContent">
        <CustomLinkify>
          <div className="text" style={theme.SmallSubText}>
            {text}
            {event.link !== "" ? (
              <a className="Link" href={event?.link}>
                {event?.link}
              </a>
            ) : null}
          </div>
        </CustomLinkify>
      </div>
    );
  };

  return (
    <div>
      {event.description !== "" || event.link !== "" ? (
        <div
          className={isDesktop ? "PageDesktop" : "Page"}
          style={theme.SecondaryBG}
        >
          <div className="Container">
            <h3 className="AboutSubtitle" style={theme.PrimaryText}>
              Details
            </h3>
            <hr style={theme.DividerColor} />
            <div style={{ whiteSpace: "pre-wrap" }}>
              <ReadMore>{event.description}</ReadMore>
            </div>
          </div>
        </div>
      ) : null}
      <div
        className={isDesktop ? "PageDesktop" : "Page"}
        style={theme.SecondaryBG}
      >
        <div className="Container">
          {ticketsPaid ? (
            <>
              <h3 className="AboutSubtitle" style={theme.PrimaryText}>
                Tickets
              </h3>
              <hr style={theme.DividerColor} />
            </>
          ) : null}
          <div className="TicketPricesContainer">
            {groupByKey(visibleTickets, (elem: any) => elem.id).map(
              (customTicket: any, index: number) =>
                getTicketPriceElement(customTicket[0], index)
            )}
          </div>
        </div>
      </div>
      <GuestListSummary event={event} />
      <div
        className={isDesktop ? "PageDesktop" : "Page"}
        style={theme.SecondaryBG}
      >
        <div className="Container">
          <div style={{ display: "flex", gap: "4px", alignItems: "center" }}>
            <h3 className="AboutSubtitle" style={theme.PrimaryText}>
              Location
            </h3>
          </div>
          <hr style={theme.DividerColor} />
          <FullEventLocationPin event={event} address={address} />
        </div>
      </div>
      {isEventTicketsPaid(event) && (
        <div
          className={isDesktop ? "PageDesktop" : "Page"}
          style={theme.SecondaryBG}
        >
          <div className="Container">
            <div
              style={{
                ...theme.PrimaryText,
                fontWeight: "600",
                paddingBottom: "7px",
              }}
            >
              Refund Policy
            </div>
            <div style={{ fontSize: "14px", color: Colors.GRAY1 }}>
              {event.canRefund
                ? "Contact host to request a refund."
                : "No refunds"}
            </div>
          </div>
        </div>
      )}
      {!isDesktop && (
        <div
          className={isDesktop ? "PageDesktop" : "Page"}
          style={theme.SecondaryBG}
        >
          <div className="Container">
            <h3 className="AboutSubtitle" style={theme.PrimaryText}>
              Contact Host
            </h3>
            <hr style={theme.DividerColor} />
            <FullEventHosts host={host} hostAndCohosts={hostAndCohosts} />
            <div style={{ marginTop: "14px" }}>
              {host && host.customer.phone !== "" ? (
                <Banner
                  type={BannerType.TEXTHOST}
                  hostPhone={host.customer.phone}
                />
              ) : (
                <Banner type={BannerType.TEXTHOST_FREE} />
              )}
            </div>
          </div>
        </div>
      )}
      {event?.crowdfundingGoal &&
      event?.crowdfundingGoal !== 0 &&
      !checkCrowdfundingHide ? (
        <div>
          <div className="ResponseHeader">
            <h3 className="AboutSubtitle">Crowdfunding</h3>
            <p>
              <span className="CrowdfundingText">
                {FormatCurrency(
                  event?.contributions.reduce(
                    (partialSum, a) => partialSum + a.amount,
                    0
                  ) / 100
                )}
              </span>{" "}
              of {FormatCurrency(event?.crowdfundingGoal / 100)}
            </p>
          </div>
          <ProgressBar
            completed={
              event?.contributions.reduce(
                (partialSum, a) => partialSum + a.amount,
                0
              ) / 100
            }
            maxCompleted={event?.crowdfundingGoal / 100}
            isLabelVisible={false}
            bgColor="#11b8ff"
            baseBgColor="rgba(133,134,145,0.25)"
            height="10px"
            margin="0 0 1.5em 0"
          />
          <hr />
        </div>
      ) : null}
      {posts.length !== 0 ? (
        <>
          <div>
            <h3 className="AboutSubtitle">Posts ({posts.length})</h3>
            {posts.length > 0 &&
              posts.map((post, index) => (
                <div key={index} className="PostContainer">
                  {host?.profilePicURL && host?.profilePicURL !== "" ? (
                    <img
                      className="PostProfilePic"
                      src={host?.profilePicURL}
                      alt="profile pic"
                    />
                  ) : (
                    <div className="PostProfilePic InitialsPic">
                      <InitialsPic fullName={host?.fullName} />
                    </div>
                  )}
                  <div className="PostNameContainer">
                    <p className="FullNamePost">{host?.fullName}</p>
                    <p className="TimeAgoText" style={{ marginTop: 5 }}>
                      {TimeAgo(post.postedAt)}
                    </p>
                    <p className="PostText">{post.text}</p>
                  </div>
                </div>
              ))}
          </div>
          <hr style={{ marginBottom: 10, marginTop: 20 }} />
        </>
      ) : undefined}
      <div style={{ paddingTop: isDesktop ? "" : "40px" }}>
        {!isDesktop ? <Footer showRCA host={host} /> : null}
      </div>
    </div>
  );
});

export default FullEventInfo;
