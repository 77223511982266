import { useState } from "react";
import { Event } from "@markit/common.types";
import { getEventRef } from "../utils/FirebaseUtils";
import { useOnMount } from "./useOnMount";
import { onSnapshot } from "../firebase";

type useLiveUpdatingEventProps = {
  onDeletion?: () => void;
  initialEventId: string;
};

export const useLiveUpdatingEvent = (props: useLiveUpdatingEventProps) => {
  const { onDeletion, initialEventId } = props;
  const [liveUpdatingEvent, setLiveUpdatingEvent] = useState<Event>();
  const [isError, setIsError] = useState(false);
  const [loaded, setLoaded] = useState(false);

  useOnMount(() => {
    if (initialEventId) {
      const unsubscribe = onSnapshot(getEventRef(initialEventId), (doc) => {
        const eventData = doc.data();
        if (!eventData) {
          setIsError(true);
          onDeletion && onDeletion();
        } else {
          setIsError(false);
          setLiveUpdatingEvent(eventData);
          setLoaded(true);
        }
      });
      return () => unsubscribe();
    }
  });

  return { liveEvent: liveUpdatingEvent, isLiveEventError: isError, loaded };
};
