import { ReactNode } from "react";
import IntermediaryModalContainer from "../../Containers/IntermediaryModalContainer";
import { MassTextsWrapperType } from "./MassTextWrapperManager";
import { AnimatePresence, domAnimation, LazyMotion, m } from "framer-motion";

type MassTextMessagingWrapperProps = {
  wrapperType: MassTextsWrapperType;
  children: ReactNode;
};

const MassTextMessagingWrapper = (props: MassTextMessagingWrapperProps) => {
  const { wrapperType, children } = props;

  switch (wrapperType) {
    case MassTextsWrapperType.MODAL:
      return (
        <IntermediaryModalContainer
          body={children}
          containerStyles={{ overflow: "hidden" }}
        />
      );
    case MassTextsWrapperType.SIDE_PANEL:
    case MassTextsWrapperType.SIDE_PANEL_PREVIEW:
      return (
        <AnimatePresence>
          <LazyMotion features={domAnimation}>
            <m.div
              key="sidepanelwrapper"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2, ease: "easeIn" }}
            >
              <div
                style={{
                  flex: 1,
                  height:
                    wrapperType === MassTextsWrapperType.SIDE_PANEL
                      ? "75vh"
                      : "67vh",
                  overflow: "scroll",
                }}
              >
                {children}
              </div>
            </m.div>
          </LazyMotion>
        </AnimatePresence>
      );
  }
};

export default MassTextMessagingWrapper;
