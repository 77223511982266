import "../../css/GlobalStyles.css";
import React, { memo, useCallback, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { DropdownMenu, DropdownMenuItem } from "../DropdownMenu";
import {
  deleteAudienceList,
  getAccountState,
} from "../../redux/slices/accountSlice";
import ThreeDotsButton from "../Buttons/ThreeDotsButton";
import AlertContainer from "../Containers/AlertContainer";
import { FAVORITES_LIST_NAME, MARKITAI } from "@markit/common.utils";
import ConfirmDeleteModal from "../Containers/ConfirmPopups/ConfirmDeleteModal";
import { AudienceList } from "@markit/common.types";

type AudienceListDropdownMenuProps = {
  list: AudienceList;
  deleteOnPress: () => void;
};

const AudienceListDropdownMenu = memo(function AudienceListDropdownMenuFn(
  props: AudienceListDropdownMenuProps
) {
  const { list, deleteOnPress } = props;
  const dispatch = useDispatch();
  const { account } = useSelector(getAccountState);
  const { accountData } = account;
  const [alertText, setAlertText] = useState<{
    heading: string;
    subHeading: string;
  }>({ heading: "", subHeading: "" });
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const initialDeleteListOnPress = useCallback(() => {
    setShowDeleteModal(true);
  }, []);

  const deleteListOnPress = useCallback(() => {
    dispatch(deleteAudienceList(accountData.uid, list.id));
    deleteOnPress();
  }, [accountData.uid, deleteOnPress, dispatch, list.id]);

  const shareOnPress = useCallback(() => {
    navigator.clipboard
      .writeText(`${MARKITAI}/u/${accountData.username}?openModal=true`)
      .then(() => {
        setAlertText({
          heading: "Copied shareable link to your list to clipboard.",
          subHeading: "",
        });
      })
      .catch((err) => {
        setAlertText({ heading: err, subHeading: "" });
      });
  }, [accountData.username]);

  const dropdownItems: DropdownMenuItem[] = useMemo(() => {
    const menuItems: DropdownMenuItem[] = [
      {
        title: "Share List",
        icon: "ion:share",
        key: "share_list",
        onPress: shareOnPress,
      },
      {
        title: "Export",
        icon: "ion:download",
        key: "export",
        onPress: () =>
          setAlertText({ heading: "Coming soon...", subHeading: "" }),
      },
    ];
    if (list.name !== FAVORITES_LIST_NAME) {
      menuItems.push({
        title: "Delete List",
        icon: "ion:trash-outline",
        key: "delete_list",
        onPress: initialDeleteListOnPress,
        isDestructive: true,
      });
    }
    return menuItems;
  }, [initialDeleteListOnPress, list.name, shareOnPress]);

  return (
    <>
      <DropdownMenu
        trigger={["click"]}
        placement="bottomRight"
        altWidth={200}
        dropdownItems={dropdownItems}
      >
        <ThreeDotsButton onPress={() => {}} />
      </DropdownMenu>
      <AlertContainer
        headerComp={alertText.heading}
        subHeaderComp={
          alertText.subHeading !== "" ? alertText.subHeading : undefined
        }
        closeModal={() => setAlertText({ heading: "", subHeading: "" })}
        hideModal={alertText.heading === "" && alertText.subHeading === ""}
      />
      <ConfirmDeleteModal
        heading="Delete List?"
        subtext="This will permanently delete this list. This cannot be undone."
        deleteButtonText="Delete"
        hideModal={!showDeleteModal}
        setIsVisible={setShowDeleteModal}
        deleteOnPress={deleteListOnPress}
      />
    </>
  );
});

export default AudienceListDropdownMenu;
