import { useEffect, useRef } from "react";

/*
 * How to use this:
 * Use if you ever need to run an async function (IIFE) within a useEffect.
 *
 * Often times you will need to run cleanup logic if you are opening a snapshot, calling a firebase
 * API function, external api function, window listeners or setTimeout. This hook handles the cleanup
 *
 * Reminder: need to add '// eslint-disable-next-line react-hooks/exhaustive-deps' to silence async warning
 *
 * Optionally: You can pass into the async function in the format (deps[], isLatest) and use those
 * variables within the IIFE if you need to make sure you are on the latest invocation and have the
 * latest dependencies given that it's an async execution that could have a stale closure.
 *
 * Note: The cleanup function will run any time the useEffect re-renders to ensure any previous calls
 * get cleaned up by its cleanup function. We also immediately call it at end of function if it's not the latest invocation
 * (i.e. overlapping re-renders before the previous invocation finishes). This is done to prevent race conditions.
 *
 * Additional note: If an additional run of a useEffect gets called, you can use the isLatest function
 * to check at any time if the async function is the latest run or if a new invocation has
 * been called that should supersede the previous call.
 *
 * Warning: onSnapshot should only be used onMount except rare situations
 */
type AsyncEffect<T> = (
  latestDeps: T[],
  isLatest: () => boolean // This function will return `true` if the effect run is the latest
) => Promise<(() => void) | void>;

function useAsyncEffect<T>(effect: AsyncEffect<T>, dependencies: T[]): void {
  // Reference to store the latest cleanup function
  const cleanupRef = useRef<(() => void) | void>();
  // Track the latest invocation
  const latestInvocationRef = useRef<number>(0);

  // Ref to hold the latest values of dependencies
  const latestDepsRef = useRef<T[]>(dependencies);

  // Update the ref whenever dependencies change
  useEffect(() => {
    latestDepsRef.current = dependencies;
  }, [dependencies]);

  useEffect(() => {
    // Increment the invocation count to represent the current effect run
    const currentInvocation = ++latestInvocationRef.current;

    // Function to check if this is the latest invocation
    const isLatest = () => currentInvocation === latestInvocationRef.current;

    // Invoke the async effect in an IIFE
    (async () => {
      // Call any existing cleanup function before running the new effect
      if (cleanupRef.current) {
        cleanupRef.current();
        cleanupRef.current = undefined;
      }

      // Await the effect function, passing dependencies and `isLatest`
      const cleanup = await effect(latestDepsRef.current, isLatest);

      // Set the new cleanup function, scoped to this invocation
      if (isLatest()) {
        cleanupRef.current = cleanup ?? undefined;
      } else if (cleanup) {
        // Run cleanup immediately if it's not the latest invocation
        cleanup();
      }
    })();

    // Cleanup function to run on component unmount or dependencies change
    return () => {
      if (cleanupRef.current) {
        cleanupRef.current();
        cleanupRef.current = undefined;
      }
    };
    // custom eslint rule was added to check for correct dependencies when this hook is used
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies); // Trigger effect only when dependencies change
}

export default useAsyncEffect;
