import { MassText } from "@markit/common.types";
import { MassTextDetails } from "../../../../redux/slices/campaignSlice";
import { useMemo } from "react";
import LabelButton from "../../../Buttons/LabelButton";
import { Colors } from "../../../../utils/colors";
import { Icon } from "@iconify/react";

type CampaignStatusLabelProps = {
  massTexts: MassText[];
  initialMassTextDetail: MassTextDetails | undefined;
};

// Used to display the status of the campaign (Active, Sent, Scheduled, Queued)
const CampaignStatusLabel = (props: CampaignStatusLabelProps) => {
  const { massTexts, initialMassTextDetail } = props;

  // A text should be queued if the text is scheduled or if it's immediate and queued is true. Otherwise, show the queued status for sending
  const textQueued = useMemo(
    () =>
      initialMassTextDetail &&
      (initialMassTextDetail.massText.scheduled ||
        (!initialMassTextDetail.massText.scheduled &&
          initialMassTextDetail.massText.queued)),
    [initialMassTextDetail]
  );

  // Scheduled campaign status if there is a text in the campaign that is not yet queued and is scheduled
  const scheduledCampaign = useMemo(
    () => massTexts && massTexts.some((text) => !text.queued && text.scheduled),
    [massTexts]
  );

  // Active campaign status if there are multiple texts and there is a text that is scheduled
  const activeCampaign = useMemo(
    () => massTexts.length > 1 && scheduledCampaign,
    [massTexts.length, scheduledCampaign]
  );

  return (
    <LabelButton
      color={Colors.BLACK}
      backgroundColor={
        activeCampaign
          ? "rgba(61,220,132,0.1)"
          : scheduledCampaign
          ? "rgba(0,122,255,0.1)"
          : Colors.WHITE1
      }
      text={
        !textQueued
          ? "Queued"
          : activeCampaign
          ? "Active"
          : scheduledCampaign
          ? "Scheduled"
          : "Sent"
      }
      icon={
        activeCampaign ? (
          <Icon icon={"ion:ellipse"} height={8} color={Colors.GREEN1} />
        ) : undefined
      }
      containerStyles={{
        border: `1px solid ${
          activeCampaign
            ? Colors.GREEN1
            : scheduledCampaign
            ? Colors.BLUE3
            : Colors.GRAY1
        }`,
        marginLeft: 14,
      }}
    />
  );
};

export default CampaignStatusLabel;
