import { AccountData, SubSelectionItem } from "@markit/common.types";
import { CircularProgress } from "@mui/material";
import FlatList from "flatlist-react/lib";
import { Colors } from "../../../../utils/colors";
import { useSelector } from "react-redux";
import { m } from "framer-motion";
import SearchBoxContainer from "../../../Containers/SearchBoxContainer";
import IntermediaryModalContainer from "../../../Containers/IntermediaryModalContainer";
import React, { useCallback, useMemo, useState } from "react";
import filter from "lodash.filter";
import { MassTextsPersonItem } from "../Items/MassTextsPersonItem";
import { getEventState } from "../../../../redux/slices/eventSlice";
import { SelectRecipientsMainSharedProps } from "./SelectRecipientsScreens";
import { addOrFilterCategories } from "@markit/common.utils";
import SelectRecipientCategoryItem from "../Items/SelectRecipientCategoryItem";
import ConfirmDeleteModal from "../../../Containers/ConfirmPopups/ConfirmDeleteModal";
import { useLoadUserList } from "../../../../hooks/useLoadUserList";
import { useSelectRecipientsManagement } from "../../../../hooks/useSelectRecipientsManagement";
import { useOnMount } from "../../../../hooks/useOnMount";

type SelectedRecipientsListProps = SelectRecipientsMainSharedProps & {
  unsavedRecipients: string[];
  setUnsavedRecipients: (unsavedRecipients: string[]) => void;
  unsavedCategories: SubSelectionItem[];
  setUnsavedCategories: (unsavedCategories: SubSelectionItem[]) => void;
  allSelectedPeople: string[];
  allExcludedPeople: string[];
  setProfileSelected: (profileSelected: AccountData) => void;
  resetOnPress: () => void;
};

const SelectedRecipientsList = (props: SelectedRecipientsListProps) => {
  const {
    massText,
    updateMassTextSettings,
    excludingMode,
    unsavedRecipients,
    setUnsavedRecipients,
    unsavedCategories,
    setUnsavedCategories,
    allSelectedPeople,
    allExcludedPeople,
    showCategories,
    setProfileSelected,
    resetOnPress,
  } = props;
  const { events } = useSelector(getEventState).events;
  const [searchTerm, setSearchTerm] = useState("");
  const [resetAllConfirm, setResetAllConfirm] = useState(false);

  const styles = {
    mainText: { fontWeight: "500", fontSize: 14 },
    sectionContainer: {
      padding: 14,
      backgroundColor: Colors.GRAY6,
      borderRadius: 8,
      marginBottom: 14,
    },
  };

  const { iconColor, netRecipients, netCategoriesToShow } =
    useSelectRecipientsManagement({
      excludingMode: excludingMode,
      massText: massText,
      selectedPeople: allSelectedPeople,
      excludedPeople: allExcludedPeople,
    });

  const foundEvent = useMemo(
    () => events.find((event) => massText && event.id === massText.eventRefId),
    [events, massText]
  );

  const netUnsavedCategories = useMemo(
    () =>
      excludingMode
        ? unsavedCategories
        : unsavedCategories.filter(
            (category) =>
              !massText?.excludedSubSelectionIds.some(
                (cat) => cat.id === category.id
              )
          ),
    [excludingMode, massText?.excludedSubSelectionIds, unsavedCategories]
  );

  const netUnsavedRecipients = useMemo(
    () =>
      excludingMode
        ? unsavedRecipients
        : unsavedRecipients.filter(
            (userId) => !allExcludedPeople.includes(userId)
          ),
    [allExcludedPeople, excludingMode, unsavedRecipients]
  );

  const { fetchedUserData, loadMoreUsers, isFinished, isLoading } =
    useLoadUserList({
      userIdList: netRecipients,
      windowSize: 30,
    });

  useOnMount(() => {
    loadMoreUsers();
  });

  const displayCategoriesView = useMemo(
    () => showCategories && netCategoriesToShow.length > 0,
    [netCategoriesToShow.length, showCategories]
  );

  const containsUser = (user: AccountData, query: string) => {
    return user.fullName.toLowerCase().includes(query.toLowerCase());
  };

  const itemsToShow = useMemo(() => {
    if (searchTerm === "") {
      return fetchedUserData;
    }
    const userList = filter(fetchedUserData, (user: AccountData) => {
      return containsUser(user, searchTerm);
    });
    return userList;
  }, [fetchedUserData, searchTerm]);

  const updateSearch = useCallback((text: string) => {
    setSearchTerm(text);
  }, []);

  const renderRecipientItem = useCallback(
    (item: AccountData) => {
      return (
        <MassTextsPersonItem
          user={item}
          unsavedPeople={unsavedRecipients}
          setUnsavedPeople={setUnsavedRecipients}
          excludingMode={excludingMode}
          setProfileSelected={setProfileSelected}
        />
      );
    },
    [unsavedRecipients, setUnsavedRecipients, excludingMode, setProfileSelected]
  );

  return (
    <IntermediaryModalContainer
      body={
        <>
          <SearchBoxContainer
            placeholder="Search Events, People, Lists..."
            value={searchTerm}
            onChange={(e) => updateSearch(e.target.value)}
            containerStyles={{ marginTop: 0, marginBottom: 14 }}
          />
          <m.div
            key="selectrecipientselection"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2, type: "tween" }}
          >
            <>
              <div style={styles.sectionContainer}>
                <div style={{ width: "100%" }}>
                  <div className="AlignedRowSpaced">
                    <div className="ColumnNormal">
                      <span
                        style={{
                          color:
                            displayCategoriesView && !excludingMode
                              ? Colors.PURPLE7
                              : iconColor,
                          ...styles.mainText,
                        }}
                      >
                        {displayCategoriesView ? (
                          <>
                            {netUnsavedCategories.length}{" "}
                            {!excludingMode &&
                            massText?.subSelectionIds.length !==
                              netUnsavedCategories.length
                              ? "Net "
                              : ""}
                            {netUnsavedCategories.length !== 1
                              ? "Categories "
                              : "Category "}
                            {excludingMode ? "Excluded" : ""}
                          </>
                        ) : (
                          <>
                            {netUnsavedRecipients.length}{" "}
                            {excludingMode
                              ? "Excluded"
                              : `${
                                  allExcludedPeople.length > 0 ? "Net " : ""
                                }Recipient${
                                  netRecipients.length !== 1 ? "s" : ""
                                }`}
                          </>
                        )}
                      </span>
                      {displayCategoriesView ? (
                        <span
                          style={{
                            fontSize: 12,
                            color: Colors.GRAY2,
                            marginRight: 40,
                            marginTop: 7,
                          }}
                        >
                          Will {excludingMode ? "exclude" : "send to"} all
                          recipients in{" "}
                          {excludingMode ? "excluded" : "selected"} categories
                          at time of send.
                        </span>
                      ) : null}
                    </div>
                    {(netRecipients.length > 0 ||
                      netCategoriesToShow.length > 0) &&
                    !excludingMode ? (
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => setResetAllConfirm(true)}
                      >
                        <span style={styles.mainText}>Reset</span>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              {showCategories && massText && updateMassTextSettings ? (
                netCategoriesToShow.map((category) => {
                  return (
                    <SelectRecipientCategoryItem
                      item={category}
                      onPress={() =>
                        addOrFilterCategories(
                          unsavedCategories,
                          setUnsavedCategories,
                          category
                        )
                      }
                      excludingMode={excludingMode}
                      isSelected={unsavedCategories.includes(category)}
                      event={foundEvent}
                      key={category.id}
                    />
                  );
                })
              ) : (
                <div
                  className="HideScrollbar"
                  style={{ height: "calc(100vh - 240px)", overflowY: "scroll" }}
                >
                  <FlatList
                    list={itemsToShow}
                    renderItem={(user) => renderRecipientItem(user)}
                    hasMoreItems={!isFinished}
                    loadMoreItems={() => {
                      if (
                        searchTerm.trim() === "" &&
                        !isLoading &&
                        !isFinished
                      ) {
                        loadMoreUsers();
                      }
                    }}
                    paginationLoadingIndicator={() =>
                      isLoading && !isFinished ? (
                        <CircularProgress
                          style={{ color: Colors.GRAY1, alignSelf: "center" }}
                          size={20}
                        />
                      ) : null
                    }
                  />
                  <div style={{ height: 400 }} />
                </div>
              )}
            </>
          </m.div>
          <ConfirmDeleteModal
            heading="Reset All Selection?"
            subtext="You cannot undo this."
            deleteButtonText="Reset"
            hideModal={!resetAllConfirm}
            setIsVisible={setResetAllConfirm}
            deleteOnPress={resetOnPress}
          />
        </>
      }
    />
  );
};

export default SelectedRecipientsList;
