import { AnimatePresence, LazyMotion, domAnimation, m } from "framer-motion";
import { ThemeStyle } from "../../hooks/useTheme";
import { Colors } from "../../utils/colors";
import React, { useEffect, useRef } from "react";
import { HorizontalDivider } from "../Dividers/HorizontalDivider";

type LargePopupModalContainerProps = {
  showModal: boolean;
  headerComp?: string | React.ReactNode;
  subHeaderComp?: string | React.ReactNode;
  valueComp: React.ReactNode;
  fixedBottomComp?: React.ReactNode;
  closeOnOutsidePress?: () => void;
  theme?: ThemeStyle;
  containerStyles?: React.CSSProperties; // for the container styles such as the padding on the edges
};

const LargePopupModalContainer = (props: LargePopupModalContainerProps) => {
  const {
    showModal,
    headerComp,
    subHeaderComp,
    valueComp,
    fixedBottomComp,
    closeOnOutsidePress,
    theme,
    containerStyles,
  } = props;
  const modalRef = useRef<any>(null);

  // prevent background scrolling when modal is open
  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = "hidden";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [showModal]);

  // Handle click outside the modal to close it
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target) &&
        closeOnOutsidePress
      ) {
        closeOnOutsidePress();
      }
    };

    if (showModal) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showModal, closeOnOutsidePress]);

  return (
    <AnimatePresence>
      {showModal ? (
        <LazyMotion features={domAnimation}>
          <m.div
            className="modalBackground HideScrollbar"
            key="dimmedbg"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            style={{ zIndex: 4, overflow: "hidden" }}
          >
            <m.div
              ref={modalRef}
              className="LargePopupPanelContainer RestrictContainerWidth"
              key="largepopuppanel"
              initial={{ right: "-500px", opacity: 0 }}
              animate={{ right: "14px", opacity: 1 }}
              exit={{ right: "-500px", opacity: 0 }}
              transition={{ duration: 0.2, type: "tween" }}
              style={{ zIndex: 6, ...theme?.SecondaryBG }}
            >
              {headerComp ? (
                <>
                  <div style={{ paddingInline: 14, paddingTop: 14 }}>
                    {headerComp}
                  </div>
                  <div
                    style={{
                      color: "#929292",
                      fontSize: 12,
                      paddingInline: "14px",
                    }}
                  >
                    {subHeaderComp}
                  </div>
                  <HorizontalDivider altMargin={14} />
                </>
              ) : null}
              <div
                className="HideScrollbar"
                style={{
                  // maxHeight: "80vh",
                  overflowY: "scroll",
                  paddingInline: 14,
                  ...containerStyles,
                }}
              >
                {valueComp}
              </div>
              {fixedBottomComp ? (
                <div
                  style={{
                    backgroundColor: Colors.WHITE,
                    width: "100%",
                    position: "absolute",
                    bottom: 0,
                    borderBottomLeftRadius: 8,
                    borderBottomRightRadius: 8,
                  }}
                >
                  {fixedBottomComp}
                </div>
              ) : null}
            </m.div>
          </m.div>
        </LazyMotion>
      ) : null}
    </AnimatePresence>
  );
};

export default LargePopupModalContainer;
