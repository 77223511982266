import { Colors } from "../../../../utils/colors";
import { Icon } from "@iconify/react";
import { useEffect, useRef, useState } from "react";

const MassTextRecipientsTag = () => {
  const [showDialog, setShowDialog] = useState(false);

  const containerRef = useRef<any>(null);
  useEffect(() => {
    const handler = (event: any) => {
      if (
        showDialog &&
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setShowDialog(false);
      }
    };
    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    };
  }, [showDialog]);

  return (
    <div
      ref={containerRef}
      style={{ position: "relative", pointerEvents: "all" }}
    >
      <Icon
        icon="mdi:information-outline"
        onClick={(e) => {
          e.stopPropagation();
          setShowDialog(!showDialog);
        }}
        height={14}
        style={{ cursor: "pointer", marginBottom: -2 }}
      />
      {showDialog ? (
        <div
          className="ColumnNormal"
          style={{
            boxShadow: "0px 1px 4px 0px #1E1E371A",
            backgroundColor: Colors.WHITE,
            zIndex: 99,
            gap: 5,
            width: 340,
            padding: 14,
            borderRadius: 8,
            position: "absolute",
            ...{
              left: -46,
              top: 30,
            },
          }}
        >
          <span style={{ fontSize: 14 }}>Limited Recipients</span>
          <span style={{ fontSize: 12, color: Colors.GRAY2 }}>
            When you send or schedule texts in a multi-text campaign, you can
            only send text to recipients that received the first text in the
            chain.
          </span>
        </div>
      ) : null}
    </div>
  );
};

export default MassTextRecipientsTag;
