import React, { useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import TopHeader from "../../components/TopHeader";
import ExploreHero from "../../components/Explore/ExploreHero";
import NewYorkHeroImage from "../../assets/ExplorePageBackgrounds/NewYorkHeroImage.jpeg";
import LosAngelesHeroImage from "../../assets/ExplorePageBackgrounds/LosAngelesHeroImage.jpeg";
import NightlifeHeroImage from "../../assets/ExplorePageBackgrounds/NightLifeHeroImage.jpg";
import TechHeroImage from "../../assets/ExplorePageBackgrounds/TechNavImage.png";
import BostonHeroImage from "../../assets/ExplorePageBackgrounds/BostonHeroImage.jpg";
import { isDesktop } from "react-device-detect";
import Footer from "../../components/Footer";
import {
  fetchFeaturedEvents,
  fetchFeaturedUsers,
} from "../../utils/ExploreUtils";
import {
  AccountData,
  Event,
  ExploreSubpageInfoMap,
  LocationInfo,
  ThemeType,
} from "@markit/common.types";
import FeaturedEvents from "../../components/Explore/FeaturedEvents";
import LoadingScreen from "../LoadingScreen";
import FeaturedPeople from "../../components/Explore/FeaturedPeople";
import { fetchEventOrganizers } from "../../utils/eventUtils/eventUtils";
import { Colors } from "../../utils/colors";
import useAsyncOnMount from "../../hooks/useAsyncEffectOnMount";

export const locationData: Record<string, LocationInfo> = {
  "New York": {
    latitudeRange: [40.4774, 45.01585],
    longitudeRange: [-74.25909, -73.70018],
  },
  Boston: {
    latitudeRange: [42.2279, 42.4154],
    longitudeRange: [-71.1912, -70.746],
  },
  "Los Angeles": {
    latitudeRange: [33.7037, 34.3373],
    longitudeRange: [-118.6682, -118.1553],
  },
};

const CityPage = () => {
  const navigate = useNavigate();
  const { cityname } = useParams();
  const cityInfo = cityname ? ExploreSubpageInfoMap[cityname] : undefined;
  const [featuredEvents, setFeaturedEvents] = useState<Event[]>([]);
  const [featuredEventOrganizers, setFeaturedEventOrganizers] = useState<
    AccountData[]
  >([]);
  const [featuredPeople, setFeaturedPeople] = useState<AccountData[]>([]);
  const [isLoadingPage, setIsLoadingPage] = useState<boolean>(true);
  const [theme, setTheme] = useState<ThemeType>(ThemeType.Light);

  const imageName = useMemo(() => {
    if (cityname === "boston") {
      setTheme(ThemeType.Light);
      return BostonHeroImage;
    } else if (cityname === "newyork") {
      return NewYorkHeroImage;
    } else if (cityname === "losangeles") {
      return LosAngelesHeroImage;
    } else if (cityname === "nightlife") {
      return NightlifeHeroImage;
    } else if (cityname === "tech") {
      setTheme(ThemeType.Light);
      return TechHeroImage;
    }
  }, [cityname]);
  const styles = {
    gradientHeader: {
      backgroundImage: `linear-gradient(to left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6)), url(${imageName})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center 40%",
      height: isDesktop ? 400 : 250,
    },
    heroContainer: {
      padding: isDesktop ? "0px 18vw 60px 18vw" : "0px 0px 40px 14px",
    },
    mainContentContainer: {
      marginTop: 40,
      padding: isDesktop ? "0px 18vw 60px 18vw" : "0px 0px 40px 14px",
    },
  };

  useAsyncOnMount(async () => {
    if (!cityname || !(cityname in ExploreSubpageInfoMap)) {
      navigate("/explore");
    } else {
      const [featuredEvents, featuredPeople] = await Promise.all([
        fetchFeaturedEvents({ isBasePage: false, info: cityInfo }),
        fetchFeaturedUsers({
          city: cityInfo?.cityName,
          category: cityInfo?.eventCategory,
          numDisplay: isDesktop ? 6 : 3,
        }),
      ]);

      setFeaturedEvents(featuredEvents);
      setFeaturedPeople(featuredPeople);

      const featuredEventOrganizers = await fetchEventOrganizers(
        featuredEvents
      );
      setFeaturedEventOrganizers(featuredEventOrganizers);
      setIsLoadingPage(false);
    }
  });

  if (isLoadingPage) {
    return <LoadingScreen />;
  } else {
    return (
      <div style={{ backgroundColor: Colors.WHITE1 }}>
        <div style={styles.gradientHeader}>
          <TopHeader eventTheme={theme} whiteLogo />
          <div style={styles.heroContainer}>
            <ExploreHero
              title={cityInfo?.cityName ?? cityInfo?.eventCategory}
              cityDescription={cityInfo?.cityDescription}
            />
          </div>
        </div>
        <div style={styles.mainContentContainer}>
          <FeaturedPeople
            featuredPeople={featuredPeople}
            cityName={cityInfo?.cityName}
            eventCategory={cityInfo?.eventCategory}
          />
          <FeaturedEvents
            featuredEvents={featuredEvents}
            featuredEventOrganizers={featuredEventOrganizers}
            description={cityInfo?.trendingDescription}
          />
        </div>
        <Footer />
      </div>
    );
  }
};

export default CityPage;
