import { AccountData } from "@markit/common.types";
import CustomCheckbox from "../CustomCheckbox";
import { HorizontalDivider } from "../Dividers/HorizontalDivider";
import { Icon } from "@iconify/react";
import { Colors } from "../../utils/colors";
import ProfilePic from "../ProfilePic";

type SelectRecipientItemProps = {
  item: AccountData;
  isSelected: boolean;
  iconColor: string;
  selectOnPress: () => void;
  selectPreviewOnPress: () => void;
  subtext?: JSX.Element;
  disabled?: boolean;
  removeDivider?: boolean;
};

// When we want only the profile icon to open the profile preview and the rest of the item to select/deselect check
export const SelectRecipientItem = (props: SelectRecipientItemProps) => {
  const {
    item,
    isSelected,
    disabled,
    selectOnPress,
    selectPreviewOnPress,
    subtext,
    iconColor,
    removeDivider,
  } = props;

  return (
    <div>
      <div className="AlignedRowSpaced" style={{ gap: 14, height: "100%" }}>
        <div style={{ position: "relative", cursor: "pointer" }}>
          {item ? (
            <ProfilePic user={item} size={48} onPress={selectPreviewOnPress} />
          ) : (
            <div
              className="Centering"
              style={{
                width: 48,
                height: 48,
                borderRadius: 48 / 2,
                backgroundColor: Colors.GRAY6,
              }}
            >
              <Icon icon="ion:person" height={30} color={Colors.GRAY1} />
            </div>
          )}
          <Icon
            icon="ion:eye"
            height={16}
            style={{
              backgroundColor: Colors.GRAY6,
              borderRadius: 100,
              padding: 1,
              position: "absolute",
              right: -4,
              bottom: 0,
            }}
            color={Colors.BLACK}
          />
        </div>
        <div
          onClick={selectOnPress}
          className="AlignedRowSpacedSelect"
          style={{
            gap: 14,
            pointerEvents: disabled ? "none" : "all",
            flex: 1,
            minHeight: 48,
          }}
        >
          <div className="ColumnNormalSelect">
            <span className="bodyMedium">{item.fullName}</span>
            {subtext}
          </div>
          {!disabled ? (
            <CustomCheckbox
              checked={isSelected}
              onChange={selectOnPress}
              sx={{ padding: 0 }}
              altColor={iconColor}
            />
          ) : null}
        </div>
      </div>
      {!removeDivider ? <HorizontalDivider altMargin={10} /> : null}
    </div>
  );
};
