import { useState } from "react";
import {
  getTicketsRef,
  getUserEventLinkTrackersRef,
} from "../utils/FirebaseUtils";
import { useOnMount } from "./useOnMount";
import { onSnapshot, query, where } from "../firebase";
import { Unsubscribe } from "firebase/firestore";
import {
  DefaultLinkTrackerAttachmentId,
  DefaultLinkTrackerName,
  OrganizerType,
  RequestStatus,
} from "@markit/common.types";

type useLiveUpdatingEventAnalyticsProps = {
  eventId: string;
  userId?: string; // for total link views
};

export const useLiveUpdatingEventAnalytics = (
  props: useLiveUpdatingEventAnalyticsProps
) => {
  const { eventId, userId } = props;
  const [liveTotalGuests, setLiveTotalGuests] = useState<number>(0);
  const [uniqueGuestsUids, setUniqueGuestsUids] = useState<string[]>([]);
  const [liveTicketsScanned, setLiveTicketsScanned] = useState<number>(0);
  const [liveTotalRequested, setLiveTotalRequested] = useState<number>(0);
  const [liveTotalOrganizers, setLiveTotalOrganizers] = useState<number>(0);
  const [liveTotalLinkViews, setLiveTotalLinkViews] = useState(0);

  useOnMount(() => {
    if (!eventId) {
      return;
    }
    // total attendees
    const ticketAttendeesRef = getTicketsRef(eventId);
    const totalAttendeesQuery = query(
      ticketAttendeesRef,
      where("uid", "!=", ""),
      where("requestStatus", "==", RequestStatus.ACCEPTED),
      where("role.type", "==", OrganizerType.ATTENDEE)
    );
    const unsubscribeTotalAttendees = onSnapshot(
      totalAttendeesQuery,
      (snapshot) => {
        const documentCount = snapshot.size;
        setLiveTotalGuests(documentCount);
      }
    );

    // total unique attendees
    const uniqueAttendeesRef = getTicketsRef(eventId);
    const uniqueAttendeesQuery = query(
      uniqueAttendeesRef,
      where("redeemedBy", "!=", ""),
      where("requestStatus", "==", RequestStatus.ACCEPTED),
      where("role.type", "==", OrganizerType.ATTENDEE)
    );

    const unsubscribeUniqueAttendees = onSnapshot(
      uniqueAttendeesQuery,
      (snapshot) => {
        if (snapshot.docs.length === 0) {
          setUniqueGuestsUids([]);
        } else {
          setUniqueGuestsUids(snapshot.docs.map((doc) => doc.data().uid));
        }
      }
    );

    // tickets scanned
    const ticketsRef = getTicketsRef(eventId);
    const ticketsScannedQuery = query(ticketsRef, where("scanned", "==", true));
    const unsubscribeTicketsScanned = onSnapshot(
      ticketsScannedQuery,
      (snapshot) => {
        const documentCount = snapshot.size;
        setLiveTicketsScanned(documentCount);
      }
    );

    // total requested
    const requestedAttendeesRef = getTicketsRef(eventId);
    const totalRequestedQuery = query(
      requestedAttendeesRef,
      where("uid", "!=", ""),
      where("requestStatus", "==", RequestStatus.PENDING),
      where("role.type", "==", OrganizerType.ATTENDEE)
    );
    const unsubscribeTotalRequested = onSnapshot(
      totalRequestedQuery,
      (snapshot) => {
        const documentCount = snapshot.size;
        setLiveTotalRequested(documentCount);
      }
    );

    // total organizers
    const organizersRef = getTicketsRef(eventId);
    const totalOrganizersQuery = query(
      organizersRef,
      where("redeemedBy", "!=", ""),
      where("customTicketId", "==", "")
    );
    const unsubscribeTotalOrganizers = onSnapshot(
      totalOrganizersQuery,
      (snapshot) => {
        const documentCount = snapshot.size;
        setLiveTotalOrganizers(documentCount);
      }
    );

    // total link views
    let unsubscribeTotalLinkViews: Unsubscribe;
    if (userId) {
      const eventLinkTrackersRef = getUserEventLinkTrackersRef(userId, eventId);
      const totalLinkViewsQuery = query(
        eventLinkTrackersRef,
        where("alias", "==", DefaultLinkTrackerName.DEFAULT_EVENT_LINK_TRACKER)
      );
      unsubscribeTotalLinkViews = onSnapshot(
        totalLinkViewsQuery,
        (snapshot) => {
          const eventLinkTracker = snapshot.docs.map((doc) => doc.data())[0];
          if (eventLinkTracker) {
            setLiveTotalLinkViews(
              eventLinkTracker.trackingOverviews[DefaultLinkTrackerAttachmentId]
                .numOpens
            );
          }
        }
      );
    }

    // TODO (jonathan): move revenue into here as live updating too from event dashboard

    return () => {
      if (unsubscribeTotalAttendees) {
        unsubscribeTotalAttendees();
      }
      if (unsubscribeUniqueAttendees) {
        unsubscribeUniqueAttendees();
      }
      if (unsubscribeTicketsScanned) {
        unsubscribeTicketsScanned();
      }
      if (unsubscribeTotalRequested) {
        unsubscribeTotalRequested();
      }
      if (unsubscribeTotalOrganizers) {
        unsubscribeTotalOrganizers();
      }
      if (unsubscribeTotalLinkViews) {
        unsubscribeTotalLinkViews();
      }
    };
  });

  return {
    liveTotalGuests,
    uniqueGuestsUids,
    liveTicketsScanned,
    liveTotalRequested,
    liveTotalOrganizers,
    liveTotalLinkViews,
  };
};
