import "../../css/App.css";
import "../../css/Subscription.css";
import {
  useStripe,
  useElements,
  PaymentElement,
  PaymentRequestButtonElement,
} from "@stripe/react-stripe-js";
import {
  PaymentRequest,
  Stripe,
  StripeElements,
  StripePaymentRequestButtonElementClickEvent,
} from "@stripe/stripe-js";
import { MixpanelContext } from "../../context/AnalyticsService";
import { Theme } from "@mui/material/styles";
import React, { useContext, useEffect, useState } from "react";
import { Colors } from "../../utils/colors";
import { SubscriptionPlanType } from "@markit/common.types";
import RectangleButton from "../Buttons/RectangleButton";
import { SubscriptionStage } from "../../screens/Subscription/SubscriptionPlan";

type SubscriptionCheckoutFormProps = {
  theme: Theme;
  userId: string;
  subscriptionOption: SubscriptionPlanType;
  trialSubscription: boolean;
  clientSecret: string | undefined;
  isReadyForPayment: boolean;
  processing: boolean;
  setProcessing: (processing: boolean) => void;
  onPress: (stripe: Stripe, elements: StripeElements) => Promise<void>;
  setSubscriptionStage: (subscriptionStage: SubscriptionStage) => void;
};

const SubscriptionCheckoutForm = ({
  theme,
  userId,
  subscriptionOption,
  trialSubscription,
  clientSecret,
  isReadyForPayment,
  processing,
  setProcessing,
  onPress,
  setSubscriptionStage,
}: SubscriptionCheckoutFormProps) => {
  const stripe = useStripe();
  const elements = useElements();
  const mixpanel = useContext(MixpanelContext);
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest>();

  useEffect(() => {
    if (!stripe || !elements) {
      return;
    }

    const pr = stripe.paymentRequest({
      country: "US",
      currency: "usd",
      total: {
        label: subscriptionOption.name + " Subscription",
        amount: trialSubscription ? 0 : subscriptionOption.price,
      },
      requestPayerName: true,
      requestPayerEmail: true,
    });
    console.log("payment request", pr !== undefined);

    // Check the availability of the Payment Request API.
    pr.canMakePayment()
      .then((result) => {
        console.log("can make payment");
        if (result) {
          setPaymentRequest(pr);
        }
      })
      .catch(() => {
        alert("Error showing payment sheet.");
      });
  }, [
    elements,
    stripe,
    subscriptionOption.name,
    subscriptionOption.price,
    trialSubscription,
  ]);

  // Moving this out of the useEffect fixed a bug where "options.clientSecret is not a mutable property" was printed infinitely.
  // We have this in CheckoutForm too, but it doesn't do infinite printing like it did here.
  if (paymentRequest && stripe) {
    paymentRequest.on("paymentmethod", async (e) => {
      if (!clientSecret) {
        e.complete("fail");
        return;
      }

      setProcessing(true);
      const { error: stripeError } = trialSubscription
        ? await stripe.confirmCardSetup(
            clientSecret,
            {
              payment_method: e.paymentMethod.id,
            },
            { handleActions: false }
          )
        : await stripe.confirmCardPayment(
            clientSecret,
            {
              payment_method: e.paymentMethod.id,
            },
            { handleActions: false }
          );

      if (stripeError) {
        // Show error to your customer (e.g., insufficient funds)
        e.complete("fail");
        setProcessing(false);
        mixpanel.track("Webapp: Stripe Error", {
          error_message: stripeError,
          distinct_id: userId,
        });
      } else {
        e.complete("success");
        if (mixpanel) {
          mixpanel.track("Webapp: Getting Subscription With Apple Pay", {
            distinct_id: userId,
            subscription_plan: subscriptionOption.name,
            is_trial: trialSubscription,
          });
        }
        setSubscriptionStage(SubscriptionStage.RECEIPT);
      }
    });
  }

  if (!stripe || !elements) {
    return null;
  }
  return (
    <>
      {paymentRequest ? (
        <div
          style={{ paddingTop: 10 }}
          aria-disabled={processing}
          className="applePayElement"
        >
          <PaymentRequestButtonElement
            options={{ paymentRequest }}
            onClick={(
              clickEvent: StripePaymentRequestButtonElementClickEvent
            ) => {
              if (!isReadyForPayment) {
                clickEvent.preventDefault();
                mixpanel.track("Webapp: Ready For Payment Error", {});
              }
            }}
          />
        </div>
      ) : null}
      <PaymentElement options={{ wallets: { applePay: "never" } }} />
      <RectangleButton
        buttonLabel={trialSubscription ? "Set up card" : "Pay with card"}
        onPress={() => {
          if (!stripe) {
            return;
          }
          onPress(stripe, elements);
        }}
        altPaddingVert={10}
        altColor={Colors.BLACK}
        altTextColor={Colors.WHITE}
        disabled={processing}
        loading={processing}
        containerStyles={{ marginTop: 14 }}
      />
    </>
  );
};

export default SubscriptionCheckoutForm;
