import React, { useEffect, useMemo, useState } from "react";
import {
  AccountData,
  Campaign,
  Event,
  FollowerStatus,
  MassText,
  SubSelectionItem,
  SubSelectionType,
} from "@markit/common.types";
import SelectRecipientsScreens from "./SelectRecipientsFlow/SelectRecipientsScreens";
import {
  addHoursToTimestamp,
  filterFollowersByStatus,
  isSubscriptionPaymentFailed,
  LessThanDate,
  roundTimeInterval,
} from "@markit/common.utils";
import "../../../css/CreatorDashboardMassTextsPanel.css";
import { useMassTextManagement } from "../../../hooks/useMassTextManagement";
import MassTextMessagingScreens from "./MassTextMessagingScreens";
import { getTotalAttendeeTickets } from "../../../utils/eventUtils/eventUtils";
import {
  getMassTextRecipients,
  massTextDefaultMessage,
} from "../../../utils/textingUtils";
import PaymentFailedButtonModal from "../../DisplayModal/UpdatePaymentInfoModal";
import { useSelector } from "react-redux";
import { getAccountState } from "../../../redux/slices/accountSlice";
import FullProfilePreviewModal from "../../FollowerProfile/FullProfilePreviewModal";
import ConfirmActionPopup from "../../Containers/ConfirmPopups/ConfirmActionPopup";
import useAsyncEffect from "../../../hooks/useAsyncEffect";
import { getCampaignMassTextData } from "../../../utils/FirebaseUtils";
import MassTextWrapperManager, {
  MassTextSelectedType,
  MassTextsWrapperType,
} from "./MassTextWrapperManager";
import { CircularProgress } from "@mui/material";
import { Colors } from "../../../utils/colors";

export enum TextPopupConfirmActions {
  SENT_SUCCESS = "Text Sent",
  SENT_FAILURE = "Text Failed to Send",
  SCHEDULED_SUCCESS = "Text Scheduled",
  SCHEDULED_FAILURE = "Text Failed to Schedule",
  TEXT_DELETE = "Text Deleted",
  NONE = "",
}

export enum MassTextScreenType {
  SELECT_RECIPIENTS = "Select Recipients",
  OVERVIEW = "Overview",
}

export enum MassTextMessagingScreenType {
  SUMMARY = "Mass Text Summary",
  SEND_SUMMARY = "Mass Text Send Summary",
  SEND_SCHEDULE = "Mass Text Send Schedule",
  RECIPIENTS_SEND_SUMMARY = "Recipients Send Summary",
  LINK_ANALYTICS = "Link Analytics",
}

export type MassTextsSharedProps = {
  massTextSettings: MassText;
  updateMassTextSettings: (massTextSettings: Partial<MassText>) => void;
  setMainScreen: (mainScreen: MassTextScreenType) => void;
  setProfileSelected: (profileSelected: AccountData) => void;
};

type MassTextPanelProps = {
  wrapperType: MassTextsWrapperType;
  campaign: Campaign | undefined; // defined campaign if starting new mass text on existing campaign
  selectedTextType: MassTextSelectedType;
  setSelectedTextType: (selectedTextType: MassTextSelectedType) => void;
  setEditingCampaign?: (editingCampaign: boolean) => void;
  duplicateOnPress?: (massText: MassText) => void;
  event?: Event;
  suggestedAction?: SubSelectionItem;
  defaultScheduled?: boolean; // for event texts scheduled default
  // for text send/schedule success/fail/delete popup
  confirmPopupTextConfirmation: TextPopupConfirmActions;
  setConfirmPopupTextConfirmation: (
    confirmType: TextPopupConfirmActions
  ) => void;
};

const MassTextPanel = (props: MassTextPanelProps) => {
  const {
    wrapperType,
    selectedTextType,
    setSelectedTextType,
    setEditingCampaign,
    duplicateOnPress,
    campaign,
    event,
    suggestedAction,
    defaultScheduled,
    confirmPopupTextConfirmation,
    setConfirmPopupTextConfirmation,
  } = props;
  const { accountData, followersData } = useSelector(getAccountState).account;
  const { massTextSettings, updateMassTextSettings, resetMassText, loaded } =
    useMassTextManagement({
      massText: selectedTextType.massText,
      eventId: event ? event.id : "",
      campaignId: campaign?.id,
      isDuplicateText: selectedTextType.isDuplicateText,
    });

  const [screenType, setScreenType] = useState<MassTextScreenType>(
    MassTextScreenType.OVERVIEW
  );
  const [numEventAttendees, setNumEventAttendees] = useState(0);
  const [textSuggestedAction, setTextSuggestedAction] = useState<
    SubSelectionItem | undefined
  >(!selectedTextType.massText ? suggestedAction : undefined);

  // keep track of currently selected recipients
  const [allSelectedPeople, setAllSelectedPeople] = useState<string[]>([]);
  const [allExcludedPeople, setAllExcludedPeople] = useState<string[]>([]);
  const [loadingInitialRecipients, setLoadingInitialRecipients] =
    useState(true);

  const [profileSelected, setProfileSelected] = useState<AccountData>();
  const [paymentFailedVisible, setPaymentFailedVisible] = useState(false);

  // Editing if the selected text is defined and is not a duplicate text
  const isEditing = useMemo(
    () =>
      selectedTextType.massText !== undefined &&
      !selectedTextType.isDuplicateText,
    [selectedTextType.isDuplicateText, selectedTextType.massText]
  );

  const allSubscribedSelectedPeople = useMemo(
    () =>
      filterFollowersByStatus(
        allSelectedPeople,
        followersData,
        FollowerStatus.SUBSCRIBED
      ),
    [allSelectedPeople, followersData]
  );

  const netRecipients = useMemo(
    () =>
      allSubscribedSelectedPeople.filter(
        (item) => !allExcludedPeople.includes(item)
      ),
    [allSubscribedSelectedPeople, allExcludedPeople]
  );

  useEffect(() => {
    // Whether to display the payment failed popup
    if (
      wrapperType !== MassTextsWrapperType.SIDE_PANEL_PREVIEW &&
      isSubscriptionPaymentFailed(accountData.customer.state)
    ) {
      setPaymentFailedVisible(true);
    }
  }, [accountData.customer.state, wrapperType]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useAsyncEffect(async () => {
    // Set up step for some of the mass text / scheduleTextInfo data (not editing case)
    if (event && !selectedTextType.massText) {
      updateMassTextSettings({
        message: massTextDefaultMessage(
          event,
          accountData.fullName,
          suggestedAction?.type
        ),
      });
    }
    const roundedSendTime = roundTimeInterval(new Date().toISOString(), 5);
    if (defaultScheduled) {
      updateMassTextSettings({
        scheduled: defaultScheduled,
        sentAt: roundedSendTime.toISOString(),
      });
    }

    if (campaign) {
      const [textRecipients, initialMassText] = await Promise.all([
        getMassTextRecipients(
          massTextSettings.sentBy,
          campaign.id,
          campaign.initialTextId
        ),
        getCampaignMassTextData(
          massTextSettings.sentBy,
          campaign.id,
          campaign.initialTextId
        ),
      ]);
      // Need to handle the all followers case to get the user ids for campaign text
      const isAllFollowers = initialMassText?.subSelectionIds.some(
        (category) => category.type === SubSelectionType.ALL_FOLLOWERS
      );
      setAllSelectedPeople(
        isAllFollowers
          ? filterFollowersByStatus(
              followersData.map((fol) => fol.uid),
              followersData,
              FollowerStatus.SUBSCRIBED
            )
          : textRecipients
      );
      // Set the send time if the initial mass text has not sent yet, set it one hour ahead of it
      if (
        initialMassText &&
        initialMassText.scheduled &&
        LessThanDate(new Date().toISOString(), initialMassText.sentAt)
      ) {
        updateMassTextSettings({
          scheduled: true,
          sentAt: addHoursToTimestamp(initialMassText.sentAt, 1).toISOString(),
        });
      }
    } else if (selectedTextType.massText) {
      // TODO: Keeping for event texts, can remove and use the campaign case once changed
      // Fetch and set the recipients for the editing existing mass text case
      const textRecipients = await getMassTextRecipients(
        selectedTextType.massText.sentBy,
        selectedTextType.massText.campaignId,
        selectedTextType.massText.id
      );
      setAllSelectedPeople(textRecipients);
    }

    // Fetch the number of recipients for an event related text to determine whether to show categories
    if (event) {
      const numEventAttendees = await getTotalAttendeeTickets(event.id);
      setNumEventAttendees(numEventAttendees);
    }
    setLoadingInitialRecipients(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTextType.massText]);

  // If newMassText is defined, a new text was created within the campaign dashboard
  const resetState = (newMassText?: MassText) => {
    if (newMassText) {
      setSelectedTextType({
        massText: newMassText,
        isVisible: true,
        isDuplicateText: false,
      });
    } else {
      setSelectedTextType({
        massText: undefined,
        isVisible: false,
        isDuplicateText: false,
      });
      resetMassText();
    }
  };

  const sharedPropsPack = {
    massTextSettings: massTextSettings,
    updateMassTextSettings: updateMassTextSettings,
    setProfileSelected: setProfileSelected,
    setMainScreen: setScreenType,
  };

  return (
    <>
      <MassTextWrapperManager wrapperType={wrapperType}>
        {loaded ? (
          <>
            {screenType === MassTextScreenType.OVERVIEW ? (
              <MassTextMessagingScreens
                {...sharedPropsPack}
                wrapperType={wrapperType}
                setEditingCampaign={setEditingCampaign}
                duplicateOnPress={duplicateOnPress}
                isEditing={isEditing}
                event={event}
                netSelectedPeople={netRecipients}
                suggestedAction={textSuggestedAction}
                editingMassText={selectedTextType.massText}
                resetState={resetState}
                setConfirmPopupTextConfirmation={
                  setConfirmPopupTextConfirmation
                }
                loadingRecipients={loadingInitialRecipients}
              />
            ) : screenType === MassTextScreenType.SELECT_RECIPIENTS ? (
              <SelectRecipientsScreens
                {...sharedPropsPack}
                allSelectedPeople={allSubscribedSelectedPeople}
                setAllSelectedPeople={setAllSelectedPeople}
                allExcludedPeople={allExcludedPeople}
                setAllExcludedPeople={setAllExcludedPeople}
                numEventAttendees={numEventAttendees}
                setSuggestedAction={setTextSuggestedAction}
              />
            ) : null}
          </>
        ) : (
          <div className="Centering" style={{ height: "100%" }}>
            <CircularProgress style={{ color: Colors.GRAY1 }} size={24} />
          </div>
        )}
      </MassTextWrapperManager>
      {profileSelected ? (
        <FullProfilePreviewModal
          profileSelected={profileSelected}
          setProfileSelected={setProfileSelected}
        />
      ) : null}
      {paymentFailedVisible ? (
        <PaymentFailedButtonModal
          isVisible={paymentFailedVisible}
          setIsVisible={() => {
            setPaymentFailedVisible(false);
            setSelectedTextType({ ...selectedTextType, isVisible: false });
          }}
          location="Mass Text"
        />
      ) : null}
      {confirmPopupTextConfirmation ? (
        <ConfirmActionPopup
          title={confirmPopupTextConfirmation}
          negativeTitle={confirmPopupTextConfirmation}
          isNegative={
            confirmPopupTextConfirmation ===
              TextPopupConfirmActions.SCHEDULED_FAILURE ||
            confirmPopupTextConfirmation ===
              TextPopupConfirmActions.SENT_FAILURE ||
            confirmPopupTextConfirmation === TextPopupConfirmActions.TEXT_DELETE
          }
          altIcon={
            confirmPopupTextConfirmation === TextPopupConfirmActions.TEXT_DELETE
              ? "ion:trash-outline"
              : undefined
          }
          onDisappear={() =>
            setConfirmPopupTextConfirmation(TextPopupConfirmActions.NONE)
          }
        />
      ) : null}
    </>
  );
};

export default MassTextPanel;
