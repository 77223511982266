import "../../css/App.css";
import "../../css/FullEvent/FullEvent.css";
import "../../css/GlobalStyles.css";
import React, { memo, useCallback, useState, useMemo } from "react";
import {
  AccountData,
  AudienceList,
  FormAnswersV2,
  SavedFormQuestion,
  VerificationState,
} from "@markit/common.types";
import { useTheme } from "../../hooks/useTheme";
import { followUser, addUserToAudienceLists } from "../../utils/FollowUser";
import { Colors } from "../../utils/colors";
import { isDesktop } from "react-device-detect";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import { LazyMotion, domAnimation, m } from "framer-motion";
import { Icon } from "@iconify/react";
import { ProfilePageFollowAudienceLists } from "./ProfilePageFollowAudienceLists";
import { LoginState, getAccountState } from "../../redux/slices/accountSlice";
import { createEmptyFormAnswersV2 } from "@markit/common.utils";
import {
  getUsersFormResponses,
  validateEventForm,
} from "../../utils/eventUtils/formUtils";
import { FormQuestionsInput } from "../UserInfoContainers/FormQuestionsInput";
import { NameAndPhoneNumberInput } from "../UserInfoContainers/NameAndPhoneNumberInput";
import RectangleButton from "../Buttons/RectangleButton";
import { ProfileFollowingActionsButton } from "../Buttons/ProfileFollowingActionsButton";
import {
  checkIfUserIsSubscribed,
  checkIfUserIsUnsubscribed,
  checkIfUserIsUnsubscribedMarkit,
  fetchUserJoinedAudienceLists,
} from "../../utils/userUtils";
import { BinaryConfirmActions } from "../Containers/ConfirmPopups/ConfirmActionPopup";
import RecaptchaVerifierWrapper from "../Wrappers/RecaptchaVerifierWrapper";
import useAsyncOnMount from "../../hooks/useAsyncEffectOnMount";
import useAsyncEffect from "../../hooks/useAsyncEffect";

type ProfilePageFollowFormProps = {
  creatorData: AccountData; // creator's accountData
  profileFormQuestions: SavedFormQuestion[]; // creator's saved profile form questions
  publicCreatorLists: AudienceList[]; // creator's public audience lists
  alreadyFollowing: boolean;
  setAlreadyFollowing: (alreadyFollowingState: boolean) => void;
  setShowForm: (showForm: boolean) => void;
  setConfirmPopupSubscribeAction: (action: BinaryConfirmActions) => void;
};

export const ProfilePageFollowForm = memo(function ProfilePageFollowFormFn(
  props: ProfilePageFollowFormProps
) {
  const {
    creatorData,
    profileFormQuestions,
    publicCreatorLists,
    alreadyFollowing,
    setAlreadyFollowing,
    setShowForm,
    setConfirmPopupSubscribeAction,
  } = props;
  const { theme } = useTheme();
  const { account } = useSelector(getAccountState);
  const { accountData, loggedIn } = account;

  const [tempFullName, setTempFullName] = useState("");
  const [tempPhoneNumber, setTempPhoneNumber] = useState("");
  const [verificationState, setVerificationState] = useState(
    VerificationState.UNVERIFIED
  );
  const [optedOut, setOptedOut] = useState(false);
  const [optedOutCreator, setOptedOutCreator] = useState(false);

  const [processing, setProcessing] = useState(false);
  const [formErrorIds, setFormErrorIds] = useState<string[]>([]);
  const [uid, setUid] = useState("");
  const [answers, setAnswers] = useState<FormAnswersV2>(
    createEmptyFormAnswersV2(profileFormQuestions, [])
  );
  const [selectedLists, setSelectedLists] = useState<string[]>([]);

  const isEmailBasedAccount = useMemo(
    () => accountData.email !== "",
    [accountData.email]
  );
  const isLoggedIn = useMemo(
    () => loggedIn === LoginState.LOGGED_IN,
    [loggedIn]
  );

  useAsyncOnMount(async () => {
    if (isLoggedIn) {
      setTempFullName(accountData.fullName);
      setTempPhoneNumber(accountData.phoneNumber);
      setUid(accountData.uid);
      setVerificationState(VerificationState.VERIFIED);

      // Check if logged in phone number is unsubscribed from markit number or creator
      // Even if using firebase OTP, we need them opted in so they can get confirmation texts
      const isUnsubscribedMarkit = await checkIfUserIsUnsubscribedMarkit(
        accountData.phoneNumber
      );
      setOptedOut(isUnsubscribedMarkit);

      const isUnsubscribed = await checkIfUserIsUnsubscribed(
        accountData.uid,
        creatorData.uid
      );
      setOptedOutCreator(isUnsubscribed);

      const following = await checkIfUserIsSubscribed(
        accountData.uid,
        creatorData.uid
      );
      setAlreadyFollowing(following);

      // set already answered form questions
      const userFormResponses = await getUsersFormResponses(
        creatorData.uid,
        profileFormQuestions.map((formQuestions) => formQuestions.id),
        accountData.uid
      );
      setAnswers(
        createEmptyFormAnswersV2(profileFormQuestions, userFormResponses)
      );

      // set already joined lists
      const joinedAudienceLists = await fetchUserJoinedAudienceLists(
        creatorData.uid,
        accountData.uid
      );
      setSelectedLists(joinedAudienceLists.map((list) => list.id));
    }
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useAsyncEffect(async () => {
    // Autofill any form inputs if user is not logged in and has verified their number
    // The logged in case is handled by the useAsyncOnMount above
    if (
      loggedIn !== LoginState.LOGGED_IN &&
      verificationState === VerificationState.VERIFIED
    ) {
      const following = await checkIfUserIsSubscribed(
        accountData.uid,
        creatorData.uid
      );
      setAlreadyFollowing(following);

      // set already answered form questions
      const userFormResponses = await getUsersFormResponses(
        creatorData.uid,
        profileFormQuestions.map((formQuestions) => formQuestions.id),
        uid
      );
      setAnswers(
        createEmptyFormAnswersV2(profileFormQuestions, userFormResponses)
      );

      // set already joined lists
      const joinedAudienceLists = await fetchUserJoinedAudienceLists(
        creatorData.uid,
        uid
      );
      setSelectedLists(joinedAudienceLists.map((list) => list.id));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verificationState]);

  const isDisabledInputSection = useMemo(
    () =>
      (verificationState !== VerificationState.VERIFIED && !isLoggedIn) ||
      processing ||
      creatorData.uid === uid ||
      isEmailBasedAccount ||
      alreadyFollowing ||
      optedOut ||
      optedOutCreator,
    [
      alreadyFollowing,
      creatorData.uid,
      isEmailBasedAccount,
      isLoggedIn,
      optedOut,
      optedOutCreator,
      processing,
      uid,
      verificationState,
    ]
  );

  const followUserAccount = useCallback(async () => {
    const errorIds = validateEventForm(answers, profileFormQuestions);
    if (errorIds.length > 0) {
      setFormErrorIds(errorIds);
      alert("Please answer the required questions.");
      return;
    }

    setProcessing(true);
    const uid: string = await followUser(
      tempPhoneNumber,
      tempFullName,
      creatorData,
      "",
      answers
    );

    // follow audience lists
    await addUserToAudienceLists(creatorData.uid, uid, selectedLists);

    setProcessing(false);
    setShowForm(false);
    setAlreadyFollowing(true);
    setConfirmPopupSubscribeAction(BinaryConfirmActions.AFFIRMATIVE);
  }, [
    answers,
    profileFormQuestions,
    tempPhoneNumber,
    tempFullName,
    creatorData,
    selectedLists,
    setShowForm,
    setAlreadyFollowing,
    setConfirmPopupSubscribeAction,
  ]);

  return (
    <RecaptchaVerifierWrapper>
      <div className={isDesktop ? "FollowFormDesktop" : "FollowFormMobile"}>
        <div className={"ExpandedPhotoHeader"}>
          <p className={isDesktop ? "CheckoutTitleDesktop" : "CheckoutTitle"}>
            Subscribe
          </p>
          <Icon
            icon="gridicons:cross-circle"
            height={30}
            color={Colors.GRAY1}
            onClick={() => setShowForm(false)}
            style={{
              cursor: "pointer",
              paddingRight: isDesktop ? "" : "14px",
            }}
          />
        </div>
        <LazyMotion features={domAnimation}>
          <m.div
            className={
              isDesktop
                ? "FollowFormContentDesktop HideScrollbar"
                : "FollowFormContentMobile HideScrollbar"
            }
            initial={{ y: "20px", opacity: 0, filter: "blur(2px)" }}
            animate={{ y: "0px", opacity: 1, filter: "blur(0px)" }}
            exit={{ y: "20px", opacity: 0, filter: "blur(2px)" }}
            transition={{ duration: 0.3, ease: "easeOut" }}
            style={{
              overflowY: "auto",
              marginBottom: "40px",
              paddingBottom: 100,
            }}
          >
            <div
              className={
                isDesktop
                  ? "HideScrollbar InputFormContainerDesktop"
                  : "HideScrollbar"
              }
            >
              <NameAndPhoneNumberInput
                host={creatorData}
                verificationState={verificationState}
                setVerificationState={setVerificationState}
                optedOut={optedOut}
                setOptedOut={setOptedOut}
                optedOutCreator={optedOutCreator}
                setOptedOutCreator={setOptedOutCreator}
                tempFullName={tempFullName}
                setTempFullName={setTempFullName}
                tempPhoneNumber={tempPhoneNumber}
                setTempPhoneNumber={setTempPhoneNumber}
                setUid={setUid}
                processing={processing}
              />
              {profileFormQuestions.length > 0 ? (
                <div
                  className={
                    isDesktop
                      ? "HostQuestionsContainerDesktop"
                      : "HostQuestionsContainer"
                  }
                  style={theme.SecondaryBG}
                >
                  <FormQuestionsInput
                    formQuestions={profileFormQuestions}
                    answers={answers}
                    setAnswers={setAnswers}
                    formErrorIds={formErrorIds}
                    setFormErrorIds={setFormErrorIds}
                    disabled={isDisabledInputSection}
                    creatorData={creatorData}
                  />
                </div>
              ) : null}
              {publicCreatorLists.length > 0 ? (
                <Box
                  sx={{
                    padding: "14px",
                    backgroundColor: Colors.WHITE,
                    borderRadius: "12px",
                    marginTop: "14px",
                  }}
                >
                  <ProfilePageFollowAudienceLists
                    creatorData={creatorData}
                    publicCreatorLists={publicCreatorLists}
                    verified={
                      verificationState === VerificationState.VERIFIED ||
                      isLoggedIn
                    }
                    selectedLists={selectedLists}
                    setSelectedLists={setSelectedLists}
                  />
                </Box>
              ) : null}
              <Box
                sx={{
                  padding: "14px",
                  backgroundColor: Colors.WHITE,
                  borderRadius: "12px",
                  marginTop: "14px",
                }}
              >
                {alreadyFollowing ? (
                  <ProfileFollowingActionsButton
                    userId={uid}
                    creatorData={creatorData}
                    setAlreadyFollowing={setAlreadyFollowing}
                    setShowForm={setShowForm}
                    setProcessing={setProcessing}
                    setConfirmPopupSubscribeAction={
                      setConfirmPopupSubscribeAction
                    }
                  />
                ) : (
                  <RectangleButton
                    buttonLabel={"Subscribe"}
                    onPress={followUserAccount}
                    theme={theme}
                    altPaddingVert={12}
                    disabled={isDisabledInputSection || processing}
                    loading={processing}
                  />
                )}
              </Box>
            </div>
          </m.div>
        </LazyMotion>
      </div>
    </RecaptchaVerifierWrapper>
  );
});
