import React from "react";

type GenericActionItemProps = {
  textComp: JSX.Element;
  leftComp: JSX.Element | undefined;
  rightComp?: JSX.Element;
  onPress?: () => void;
  containerStyles?: React.CSSProperties;
  disabled?: boolean;
};

// Should be the standard skeleton of an item that is part of a list
// icon | title/subtext             rightIcon
export const GenericActionItem = (props: GenericActionItemProps) => {
  const { textComp, leftComp, rightComp, onPress, containerStyles, disabled } =
    props;

  return (
    <div
      onClick={onPress}
      className="AlignedRowSpacedSelect"
      style={{
        ...containerStyles,
        pointerEvents: disabled || !onPress ? "none" : "all",
        opacity: disabled ? 0.5 : 1,
      }}
    >
      <div className="AlignedRowSelect">
        {leftComp ?? null}
        <div style={{ marginLeft: leftComp ? 14 : 0 }}>{textComp}</div>
      </div>
      {rightComp ?? null}
    </div>
  );
};
