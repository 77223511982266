import "../../css/App.css";
import "../../css/FullEvent/FullEvent.css";
import "../../css/GlobalStyles.css";
import React, { memo, useCallback, useMemo } from "react";
import { AccountData, AudienceList } from "@markit/common.types";
import { Colors } from "../../utils/colors";

type ProfilePageFollowAudienceListsProps = {
  creatorData: AccountData; // creator's accountData
  publicCreatorLists: AudienceList[]; // creator's public audience lists
  verified: boolean;
  selectedLists: string[];
  setSelectedLists: (selectedLists: string[]) => void;
};

export const ProfilePageFollowAudienceLists = memo(
  function ProfilePageFollowAudienceListsFn(
    props: ProfilePageFollowAudienceListsProps
  ) {
    const {
      creatorData,
      publicCreatorLists,
      verified,
      selectedLists,
      setSelectedLists,
    } = props;

    const addRemoveSelected = useCallback(
      (id: string) => {
        if (selectedLists.includes(id)) {
          setSelectedLists(selectedLists.filter((list) => list !== id));
        } else {
          setSelectedLists(selectedLists.concat(id));
        }
      },
      [selectedLists, setSelectedLists]
    );

    const renderCreatorLists = useMemo(() => {
      return (
        <div className="FollowOverlayList">
          <div className="AlignedRow" style={{ gap: "7px" }}>
            <span style={{ fontWeight: 600, fontSize: "16px" }}>
              Join {creatorData.fullName}&apos;s Lists
            </span>
          </div>
          <hr />
          {publicCreatorLists.map((list, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                flexDirection: "column",
                marginBottom: 10,
                opacity: verified ? 1 : 0.2,
              }}
              onClick={verified ? () => addRemoveSelected(list.id) : undefined}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    backgroundColor: Colors.WHITE,
                    height: 15,
                    width: 15,
                    borderRadius: 3,
                    alignItems: "center",
                    display: "flex",
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  {selectedLists.includes(list.id) ? (
                    <span style={{ color: Colors.BLACK, marginLeft: "1px" }}>
                      &#x2713;
                    </span>
                  ) : null}
                </div>
                <span style={{ marginLeft: 15 }}>{list.name}</span>
              </div>
              {list.description && (
                <p
                  style={{
                    paddingTop: "5px",
                    paddingLeft: 32,
                    color: "#929292",
                  }}
                >
                  {list.description}
                </p>
              )}
            </div>
          ))}
        </div>
      );
    }, [
      creatorData.fullName,
      verified,
      publicCreatorLists,
      selectedLists,
      addRemoveSelected,
    ]);

    return <div>{renderCreatorLists}</div>;
  }
);
