import { useCallback, useMemo, useRef, useState } from "react";
import { uniqueVals } from "@markit/common.utils";
import {
  DefaultLinkTrackerName,
  EventLinkTracker,
  LinkMapping,
} from "@markit/common.types";
import {
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  Query,
  startAfter,
  where,
} from "../firebase";
import {
  getUserEventLinkMappingsRef,
  getUserEventLinkTrackersRef,
} from "../utils/FirebaseUtils";
import useOnUnmount from "./useOnUnmount";

export type TrackingLinkData = {
  eventLinkTracker: EventLinkTracker;
  linkMapping: LinkMapping;
};

type useLoadTrackingLinksListProps = {
  userId: string;
  eventId: string;
  windowSize: number;
};

export const useLoadTrackingLinksList = (
  props: useLoadTrackingLinksListProps
) => {
  const { userId, eventId, windowSize } = props;

  const [fetchedLinkTrackers, setFetchedLinkTrackers] = useState<
    TrackingLinkData[]
  >([]);
  const unsubscribeArrayRef = useRef<(() => void)[]>([]);

  const [isLoading, setIsLoading] = useState(false);
  const [timeoutEvent, setTimeoutEvent] = useState<
    NodeJS.Timeout | undefined
  >();

  const [lastVisible, setLastVisible] = useState<string>(
    new Date().toISOString()
  );
  const [loadedAllTrackingLinks, setLoadedAllTrackingLinks] = useState(false);

  useOnUnmount((_latestDeps) => {
    unsubscribeArrayRef.current.forEach(
      (unsubscribe) => unsubscribe && unsubscribe()
    );
    unsubscribeArrayRef.current = []; // Clear the array after cleanup
  }, []);

  const addTrackingLinkData = useCallback(
    (linkTrackers: TrackingLinkData[]) => {
      setFetchedLinkTrackers((fetchedLinkTrackers) => {
        return uniqueVals(
          linkTrackers.concat(fetchedLinkTrackers),
          (linkTracker) => linkTracker.eventLinkTracker.id
        ).sort((a, b) =>
          a.eventLinkTracker.alias.localeCompare(b.eventLinkTracker.alias)
        );
      });
    },
    []
  );

  const removeTrackingLinkData = useCallback((trackingLinkId: string) => {
    setFetchedLinkTrackers((fetchedLinkTrackers) => {
      const index = fetchedLinkTrackers.findIndex(
        (fetched) => fetched.eventLinkTracker.id === trackingLinkId
      );
      if (index !== -1) {
        fetchedLinkTrackers.splice(index, 1);
      }
      return [...fetchedLinkTrackers];
    });
  }, []);

  const isFinished = useMemo(
    () => loadedAllTrackingLinks,
    [loadedAllTrackingLinks]
  );

  const retrieveTrackingLinkDataResults = useCallback(
    async (
      trackingLinksQuery: Query<EventLinkTracker>
    ): Promise<TrackingLinkData[]> => {
      const trackingLinksSnap = await getDocs(trackingLinksQuery);
      const uploadedTimes = trackingLinksSnap.docs.map(
        (doc) => doc.data().alias
      );
      if (uploadedTimes.length > 0) {
        setLastVisible(uploadedTimes[uploadedTimes.length - 1]);
      }
      if (uploadedTimes.length < windowSize) {
        setLoadedAllTrackingLinks(true);
      }

      const trackingLinks: TrackingLinkData[] = await Promise.all(
        trackingLinksSnap.docs.map(async (doc) => {
          const eventLinkTracker = doc.data();
          const linkMappingsRef = getUserEventLinkMappingsRef(userId, eventId);
          const linkMappingsQuery = query(
            linkMappingsRef,
            where("refId", "==", eventLinkTracker.id)
          );
          const linkMapping = (await getDocs(linkMappingsQuery)).docs.map(
            (doc) => doc.data()
          )[0];
          return {
            eventLinkTracker,
            linkMapping: linkMapping,
          };
        })
      );
      addTrackingLinkData(trackingLinks);
      setIsLoading(false);
      return trackingLinks;
    },
    [addTrackingLinkData, eventId, userId, windowSize]
  );

  const createTrackingLinkData = useCallback(
    async (trackingLink: EventLinkTracker) => {
      const linkMappingsRef = getUserEventLinkMappingsRef(userId, eventId);
      const linkMappingsQuery = query(
        linkMappingsRef,
        where("refId", "==", trackingLink.id)
      );
      const linkMapping = (await getDocs(linkMappingsQuery)).docs.map((doc) =>
        doc.data()
      )[0];
      const trackingLinkData: TrackingLinkData = {
        eventLinkTracker: trackingLink,
        linkMapping: linkMapping,
      };
      addTrackingLinkData([trackingLinkData]);
    },
    [addTrackingLinkData, eventId, userId]
  );

  const fetchTrackingLinks = useCallback(async () => {
    if (!isFinished && !isLoading) {
      setIsLoading(true);
      const userTrackingLinksRef = getUserEventLinkTrackersRef(userId, eventId);
      const userTrackingLinksQuery = query(
        userTrackingLinksRef,
        where("alias", "!=", DefaultLinkTrackerName.DEFAULT_EVENT_LINK_TRACKER),
        orderBy("alias", "asc"),
        limit(windowSize)
      );
      const userTrackingLinks = await retrieveTrackingLinkDataResults(
        userTrackingLinksQuery
      );

      const unsubscribe = onSnapshot(userTrackingLinksQuery, (snapshot) => {
        snapshot.docChanges().forEach(async (message) => {
          const trackingLink = message.doc.data();
          // Only enter added case if new tracking link is created.
          // Does not enter if part of initial retrieval
          if (
            message.type === "added" &&
            !userTrackingLinks.some(
              (link) => link.eventLinkTracker.id === trackingLink.id
            )
          ) {
            await createTrackingLinkData(trackingLink);
          } else if (message.type === "modified") {
            await createTrackingLinkData(trackingLink);
          } else if (message.type === "removed") {
            removeTrackingLinkData(trackingLink.id);
          }
        });
      });

      unsubscribeArrayRef.current.push(unsubscribe);
    }
  }, [
    isFinished,
    isLoading,
    userId,
    eventId,
    windowSize,
    retrieveTrackingLinkDataResults,
    createTrackingLinkData,
    removeTrackingLinkData,
  ]);

  const fetchMoreTrackingLinks = useCallback(async () => {
    if (!isFinished && !isLoading) {
      setIsLoading(true);
      const userTrackingLinksRef = getUserEventLinkTrackersRef(userId, eventId);
      const userTrackingLinksQuery = query(
        userTrackingLinksRef,
        where("alias", "!=", DefaultLinkTrackerName.DEFAULT_EVENT_LINK_TRACKER),
        orderBy("alias", "asc"),
        startAfter(lastVisible),
        limit(windowSize)
      );
      const userTrackingLinks = await retrieveTrackingLinkDataResults(
        userTrackingLinksQuery
      );

      const unsubscribe = onSnapshot(userTrackingLinksQuery, (snapshot) => {
        snapshot.docChanges().forEach(async (message) => {
          const trackingLink = message.doc.data();
          if (
            message.type === "added" &&
            !userTrackingLinks.some(
              (link) => link.eventLinkTracker.id === trackingLink.id
            )
          ) {
            await createTrackingLinkData(trackingLink);
          } else if (message.type === "modified") {
            await createTrackingLinkData(trackingLink);
          } else if (message.type === "removed") {
            removeTrackingLinkData(trackingLink.id);
          }
        });
      });

      unsubscribeArrayRef.current.push(unsubscribe);
    }
  }, [
    isFinished,
    isLoading,
    userId,
    eventId,
    lastVisible,
    windowSize,
    retrieveTrackingLinkDataResults,
    createTrackingLinkData,
    removeTrackingLinkData,
  ]);

  const loadSearchResultsThrottled = useCallback(
    (searchTerm: string) => {
      if (timeoutEvent) {
        clearTimeout(timeoutEvent);
      }
      setTimeoutEvent(
        setTimeout(() => {
          if (
            searchTerm.length > 1 &&
            !fetchedLinkTrackers.some((tracker) =>
              tracker.eventLinkTracker.alias.includes(searchTerm)
            )
          ) {
            fetchMoreTrackingLinks();
          }
        }, 500)
      );
    },
    [fetchMoreTrackingLinks, fetchedLinkTrackers, timeoutEvent]
  );

  return {
    isFinished,
    isLoading,
    fetchedLinkTrackers,
    fetchTrackingLinks,
    fetchMoreTrackingLinks,
    loadSearchResultsThrottled,
  };
};
