import { useCallback, useContext } from "react";
import {
  changeBio,
  changeFullName,
  changeInstagram,
  changeLinkedin,
  changeProfilePicURL,
  changeTwitter,
  changeSpotify,
  changeTiktok,
  getAccountState,
  changeHideProfileEvents,
} from "../redux/slices/accountSlice";
import { useSelector, useDispatch } from "react-redux";
import { MixpanelContext } from "../context/AnalyticsService";
import { checkValidSocialMediaLink } from "@markit/common.utils/dist/displayUtils";

export enum SocialMediaType {
  INSTAGRAM = "instagram",
  LINKEDIN = "linkedin",
  TWITTER = "twitter",
  TIKTOK = "tiktok",
  SPOTIFY = "spotify",
}

type useProfileUpdatesProps = {
  profilePic: string;
  fullName: string;
  bio: string;
  instagram: string;
  linkedin: string;
  twitter: string;
  tiktok: string;
  spotify: string;
  hideProfileEvents: boolean;
};

export const useProfileUpdates = (props: useProfileUpdatesProps) => {
  const {
    profilePic,
    fullName,
    bio,
    instagram,
    linkedin,
    twitter,
    tiktok,
    spotify,
    hideProfileEvents,
  } = props;
  const { account } = useSelector(getAccountState);
  const { accountData } = account;
  const dispatch = useDispatch();
  const mixpanel = useContext(MixpanelContext);

  const updateProfilePic = useCallback(async () => {
    if (profilePic === "") {
      alert("Please set a profile picture");
      return;
    }

    if (profilePic !== accountData.profilePicURL) {
      dispatch(changeProfilePicURL(profilePic, accountData.uid));
    }
  }, [profilePic, accountData.profilePicURL, accountData.uid, dispatch]);

  const updateFullName = useCallback(() => {
    if (fullName === "") {
      alert("Full name cannot be empty");
      return;
    }

    dispatch(changeFullName(accountData.uid, fullName.trim()));
  }, [fullName, dispatch, accountData.uid]);

  const updateBio = useCallback(() => {
    dispatch(changeBio(accountData.uid, bio.trim()));
  }, [dispatch, accountData.uid, bio]);

  const updateInstagram = useCallback(() => {
    if (instagram !== accountData.instagram) {
      const valid = checkValidSocialMediaLink("instagram", instagram);
      if (!valid) {
        alert("Invalid instagram url");
      } else if (instagram.length > 200) {
        alert("Instagram is limited to 200 characters");
      } else {
        dispatch(changeInstagram(accountData.uid, instagram.trim()));

        mixpanel.track("Set Instagram Account Details", {
          instagram: instagram,
          type: "edited",
          from: "webapp",
        });
      }
    }
  }, [instagram, accountData.instagram, accountData.uid, dispatch, mixpanel]);

  const updateLinkedin = useCallback(() => {
    if (linkedin !== accountData.linkedin) {
      const valid = checkValidSocialMediaLink("linkedin", linkedin);
      if (!valid) {
        alert("Invalid linkedin url");
      } else if (linkedin.length > 200) {
        alert("Linkedin is limited to 200 characters");
      } else {
        dispatch(changeLinkedin(accountData.uid, linkedin.trim()));

        mixpanel.track("Set LinkedIn Account Details", {
          linkedin: linkedin,
          type: "edited",
          from: "webapp",
        });
      }
    }
  }, [linkedin, accountData.linkedin, accountData.uid, dispatch, mixpanel]);

  const updateTwitter = useCallback(() => {
    if (twitter !== accountData.twitter) {
      const valid = checkValidSocialMediaLink("twitter", twitter);
      if (!valid) {
        alert("Invalid X/twitter url");
      } else if (twitter.length > 200) {
        alert("Twitter is limited to 200 characters");
      } else {
        dispatch(changeTwitter(accountData.uid, twitter.trim()));

        mixpanel.track("Set Twitter Account Details", {
          twitter: twitter,
          type: "edited",
          from: "webapp",
        });
      }
    }
  }, [twitter, accountData.twitter, accountData.uid, dispatch, mixpanel]);

  const updateTiktok = useCallback(() => {
    if (tiktok !== accountData.tiktok) {
      const valid = checkValidSocialMediaLink("tiktok", tiktok);
      if (!valid) {
        alert("Invalid tiktok url");
      } else if (tiktok.length > 200) {
        alert("Tiktok is limited to 200 characters");
      } else {
        dispatch(changeTiktok(accountData.uid, tiktok.trim()));

        mixpanel.track("Set TikTok Account Details", {
          tiktok: tiktok,
          type: "edited",
          from: "webapp",
        });
      }
    }
  }, [tiktok, accountData.tiktok, accountData.uid, dispatch, mixpanel]);

  const updateSpotify = useCallback(() => {
    if (spotify !== accountData.spotify) {
      const valid = checkValidSocialMediaLink("spotify", spotify);
      if (!valid) {
        alert("Invalid spotify url");
      } else if (spotify.length > 200) {
        alert("Spotify is limited to 200 characters");
      } else {
        dispatch(changeSpotify(accountData.uid, spotify.trim()));

        mixpanel.track("Set Spotify Account Details", {
          spotify: spotify,
          type: "edited",
          from: "webapp",
        });
      }
    }
  }, [spotify, accountData.spotify, accountData.uid, dispatch, mixpanel]);

  const updateHideProfileEvents = useCallback(() => {
    dispatch(changeHideProfileEvents(accountData.uid, hideProfileEvents));
    mixpanel.track("Updated HideProfileEvents Toggle", {
      distinct_id: accountData.uid,
      toggled_on: hideProfileEvents,
      from: "webapp",
    });
  }, [accountData.uid, dispatch, hideProfileEvents, mixpanel]);

  return {
    updateProfilePic,
    updateFullName,
    updateBio,
    updateInstagram,
    updateLinkedin,
    updateTwitter,
    updateTiktok,
    updateSpotify,
    updateHideProfileEvents,
  };
};
