import { CustomTicketV2 } from "@markit/common.types";
import React, { useMemo, useState } from "react";
import {
  getRedeemedTicketsDistributedInGroup,
  getTotalTicketsDistributedInGroup,
  getTotalTicketsSoldInGroup,
} from "../../utils/eventUtils/eventUtils";
import { Colors } from "../../utils/colors";
import { abbreviateNumber, truncateString } from "@markit/common.utils";
import useAsyncOnMount from "../../hooks/useAsyncEffectOnMount";

type EventAnalyticsTicketRowProps = {
  eventId: string;
  customTicket: CustomTicketV2;
};

export const EventAnalyticsTicketRow = (
  props: EventAnalyticsTicketRowProps
) => {
  const { eventId, customTicket } = props;

  const [ticketsSold, setTicketsSold] = useState<number | null>(null);
  const [hostTicketsRedeemed, setHostTicketsRedeemed] = useState<number | null>(
    null
  );
  const [hostTicketsDistributed, setHostTicketsDistributed] = useState<
    number | null
  >(null);

  useAsyncOnMount(async () => {
    const [soldInGroup, hostRedeemedInGroup, hostDistributedInGroup] =
      await Promise.all([
        getTotalTicketsSoldInGroup(eventId, customTicket.id, true),
        getRedeemedTicketsDistributedInGroup(eventId, customTicket.id),
        getTotalTicketsDistributedInGroup(eventId, customTicket.id),
      ]);

    // Sold
    setTicketsSold(soldInGroup);
    // Free Tickets Redeemed
    setHostTicketsRedeemed(hostRedeemedInGroup);
    setHostTicketsDistributed(hostDistributedInGroup);
  });

  const displayCapacityAvailable = useMemo(
    () => customTicket.quantityAvailable !== 0,
    [customTicket.quantityAvailable]
  );

  return (
    <div
      style={{
        color: Colors.BLACK,
        display: "flex",
      }}
    >
      <div style={{ fontSize: "24px", marginRight: "14px", minWidth: 45 }}>
        {ticketsSold === null || hostTicketsRedeemed === null
          ? "--"
          : abbreviateNumber(ticketsSold + hostTicketsRedeemed)}
      </div>
      <div>
        <div style={{ fontSize: "12px", marginBottom: "4px" }}>
          {truncateString(customTicket.label, 40, "... ")}
        </div>
        <div
          style={{
            color: Colors.GRAY1,
            fontSize: "12px",
            marginBottom: "4px",
          }}
        >
          {ticketsSold === customTicket.quantityAvailable &&
          ticketsSold !== 0 &&
          displayCapacityAvailable
            ? "Sold Out: " +
              (ticketsSold === null ? "--" : abbreviateNumber(ticketsSold)) +
              "/" +
              abbreviateNumber(customTicket.quantityAvailable)
            : "Sold: " +
              (ticketsSold === null ? "--" : abbreviateNumber(ticketsSold!)) +
              (displayCapacityAvailable
                ? "/" + abbreviateNumber(customTicket.quantityAvailable)
                : "")}
        </div>
        <div style={{ color: Colors.GRAY1, fontSize: "12px" }}>
          Free Tickets Redeemed:{" "}
          {hostTicketsRedeemed === null || hostTicketsDistributed === null
            ? "--"
            : hostTicketsRedeemed + "/" + hostTicketsDistributed}
        </div>
      </div>
    </div>
  );
};
