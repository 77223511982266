import { m } from "framer-motion";
import { Colors } from "../../../utils/colors";
import { useCallback, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  changeHideProfileEvents,
  changeProfilePasswordProtect,
  getAccountState,
} from "../../../redux/slices/accountSlice";
import FormBuilderWrapper from "../../Form/FormBuilderWrapper";
import FollowTextPopup from "../FollowTextPopup";
import PreviewBoxContainer from "../../Containers/PreviewBoxContainer";
import BlackHoverButton from "../../Buttons/BlackHoverButton";
import StandardBorderedContainer from "../../Containers/StandardBorderedContainer";
import { useTheme } from "../../../hooks/useTheme";
import SwitchToggleItem from "../../SwitchToggleItem";
import RectangleButton from "../../Buttons/RectangleButton";
import CustomTextField from "../../CustomTextField";
import { HorizontalDivider } from "../../Dividers/HorizontalDivider";
import ConfirmActionPopup from "../../Containers/ConfirmPopups/ConfirmActionPopup";

export type ShareProfilePanelProps = {};

const ShareProfilePanel = (props: ShareProfilePanelProps) => {
  const { accountData } = useSelector(getAccountState).account;
  const { theme } = useTheme();
  const dispatch = useDispatch();
  const [followTextVisible, setFollowTextVisible] = useState(false);
  const [profilePassword, setProfilePassword] = useState(
    accountData.profilePasswordProtect
  );
  const [confirmPasswordAlert, setConfirmPasswordAlert] = useState(false);

  const styles = {
    headerText: { fontSize: 14, fontWeight: "500" },
    subtext: { fontSize: 12, color: Colors.GRAY1 },
    sectionContainer: {
      padding: 14,
      borderRadius: 12,
      backgroundColor: Colors.GRAY6,
    },
  };

  const disableSetPassword = useMemo(
    () =>
      !profilePassword ||
      profilePassword === accountData.profilePasswordProtect,
    [accountData.profilePasswordProtect, profilePassword]
  );

  const toggleHideProfileEvents = useCallback(() => {
    dispatch(
      changeHideProfileEvents(accountData.uid, !accountData.hideProfileEvents)
    );
  }, [accountData.hideProfileEvents, accountData.uid, dispatch]);

  const changeRequirePassword = useCallback(() => {
    dispatch(changeProfilePasswordProtect(accountData.uid, profilePassword));
    setConfirmPasswordAlert(true);
  }, [accountData.uid, dispatch, profilePassword]);

  const togglePasswordProtectProfile = useCallback(() => {
    const password = !profilePassword ? "Password" : "";
    dispatch(changeProfilePasswordProtect(accountData.uid, password));
    setProfilePassword(password);
  }, [accountData.uid, dispatch, profilePassword]);

  return (
    <m.div
      key="masstextstextsummary"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.2, type: "tween" }}
      className="HideScrollbar ColumnNormal"
      style={{
        height: "calc(100vh - 120px)",
        overflow: "scroll",
        gap: 14,
      }}
    >
      <div className="ColumnNormal" style={{ gap: 7 }}>
        <span className="AboutSubtitle" style={{ fontSize: 18 }}>
          Share Profile
        </span>
        <span style={styles.subtext}>
          Customize the experience of following your profile and collect info
          upon follow.
        </span>
      </div>
      <PreviewBoxContainer
        message={accountData.contactCustomMessage}
        containerStyles={{
          ...styles.sectionContainer,
          border: `1.5px solid ${theme.DividerColor.borderColor}`,
        }}
        headerText={"Follow Text"}
        subHeaderComp={
          <span style={{ fontSize: 12, color: Colors.GRAY1 }}>
            Sent when someone follows you (Required)
          </span>
        }
        headerRightComp={
          <BlackHoverButton
            leftIconName="feather:edit"
            leftIconSize={18}
            onPress={() => setFollowTextVisible(true)}
            containerStyles={{ padding: 8 }}
          />
        }
      />
      <StandardBorderedContainer containerStyles={styles.sectionContainer}>
        <FormBuilderWrapper isProfileQuestions hostId={accountData.uid} />
      </StandardBorderedContainer>
      <StandardBorderedContainer containerStyles={styles.sectionContainer}>
        <SwitchToggleItem
          title="Hide Events Section"
          description="Hide the events section of your profile"
          toggleValue={accountData.hideProfileEvents}
          onChange={toggleHideProfileEvents}
        />
      </StandardBorderedContainer>
      <StandardBorderedContainer
        containerStyles={{ ...styles.sectionContainer, paddingInline: 0 }}
      >
        <div
          className="AlignedRowSpaced"
          style={{ gap: 14, paddingInline: 14 }}
        >
          <SwitchToggleItem
            title="Password Protect Profile"
            description="Require a password to view events and links displayed on your
              profile."
            toggleValue={accountData.profilePasswordProtect !== ""}
            onChange={togglePasswordProtectProfile}
          />
        </div>
        {accountData.profilePasswordProtect !== "" ? (
          <>
            <HorizontalDivider altMargin={18} />
            <div className="ColumnNormal" style={{ gap: 7, paddingInline: 14 }}>
              <span style={{ fontSize: 12, fontWeight: 500 }}>
                Set Password
              </span>
              <div className="AlignedRow" style={{ gap: 10 }}>
                <CustomTextField
                  value={profilePassword}
                  placeholder="password"
                  inputMode="text"
                  borderRadius={12}
                  noAutocomplete
                  backgroundColor={Colors.WHITE}
                  onChange={(change: any) => {
                    setProfilePassword(change.target.value);
                  }}
                  altMarginBottom={0}
                  altPadding="10px 10px"
                />
                <RectangleButton
                  buttonLabel="Set Password"
                  onPress={changeRequirePassword}
                  altColor={Colors.BLACK}
                  altTextColor={Colors.WHITE}
                  altPaddingVert={12}
                  width={220}
                  containerStyles={{
                    pointerEvents: disableSetPassword ? "none" : "all",
                    opacity: disableSetPassword ? 0.3 : 1,
                  }}
                />
              </div>
            </div>
          </>
        ) : null}
      </StandardBorderedContainer>
      {followTextVisible ? (
        <FollowTextPopup
          isVisible={followTextVisible}
          setIsVisible={setFollowTextVisible}
        />
      ) : null}
      {confirmPasswordAlert ? (
        <ConfirmActionPopup
          title="Password Changed!"
          onDisappear={() => setConfirmPasswordAlert(false)}
        />
      ) : null}
    </m.div>
  );
};

export default ShareProfilePanel;
