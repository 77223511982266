import React from "react";
import { isDesktop } from "react-device-detect";
import { Icon } from "@iconify/react";
import { FeaturedCities, FeaturedEventCategories } from "@markit/common.types";
import { Colors } from "../../utils/colors";

/* --- Header Component for explore page and each subpage --- */
/*
 * Takes in a title and description. If neither are defined, defaults to
 * explore page title/description.
 */

const ExploreHero = (props: {
  title?: FeaturedCities | FeaturedEventCategories;
  cityDescription?: string;
}) => {
  const { title, cityDescription } = props;

  const styles = {
    heroContainer: {
      marginTop: isDesktop ? 80 : 50,
      marginBottom: isDesktop ? 30 : 20,
    },

    heroText: {
      fontSize: isDesktop ? 40 : 24,
      color: Colors.WHITE,
      fontWeight: 600,
    },

    heroSubtext: {
      fontSize: isDesktop ? 16 : 14,
      color: Colors.GRAY2,
      fontWeight: 400,
    },
  };

  return (
    <div style={styles.heroContainer}>
      <div
        className="AlignedRow"
        style={{ alignItems: "center", gap: 7, marginBottom: 7 }}
      >
        <span style={styles.heroText}>{title ?? "Explore"}</span>
        {title === undefined ? (
          <Icon
            icon="ion:compass"
            height={isDesktop ? 25 : 17}
            color={Colors.WHITE}
            style={{ marginTop: isDesktop ? 8 : 4 }}
          />
        ) : null}
      </div>
      <h3 style={styles.heroSubtext}>
        {cityDescription ??
          "Discover the best brands and creators in nightlife, tech, and more in your city"}
      </h3>
    </div>
  );
};

export default ExploreHero;
