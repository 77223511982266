import React from "react";
import { MARKIT_TERMS_OF_USE } from "@markit/common.utils";
import { Colors } from "../utils/colors";

const MessageDataRates = () => {
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <span
        style={{
          fontSize: 12,
          color: Colors.GRAY1,
        }}
      >
        By signing up, you agree to the{" "}
        <a
          href={MARKIT_TERMS_OF_USE}
          rel="noreferrer"
          style={{ color: Colors.BLACK }}
        >
          Terms & Privacy Policy.
        </a>
      </span>
    </div>
  );
};

export default MessageDataRates;
