import React from "react";
import "../../css/Buttons/RectangleButtonStyles.css";
import { Colors } from "../../utils/colors";
import { Icon } from "@iconify/react";

type SelectItemRowProps = {
  title: string;
  description: string;
  selected: boolean;
  subComp?: React.ReactNode;
  disabled?: boolean;
  onPress: () => void;
};

const SelectItemRow = (props: SelectItemRowProps) => {
  const { title, description, selected, subComp, disabled, onPress } = props;

  return (
    <div>
      <div
        className="AlignedRow"
        style={{
          cursor: "pointer",
          gap: "12px",
          marginTop: "0px",
          pointerEvents: disabled ? "none" : "all",
        }}
        onClick={onPress}
      >
        <Icon
          icon={selected ? "mdi:radiobox-marked" : "mdi:radiobox-blank"}
          height={28}
          style={{
            color: Colors.BLACK,
            opacity: disabled ? 0.4 : 1,
            flexShrink: 0,
          }}
        />
        <div style={{ cursor: "pointer" }}>
          <div
            style={{
              fontSize: 14,
              fontWeight: 500,
              color: Colors.BLACK,
              opacity: disabled ? 0.4 : 1,
              cursor: "pointer",
            }}
          >
            {title}
          </div>
          <div
            style={{
              marginTop: 4,
              color: Colors.GRAY1,
              opacity: disabled ? 0.4 : 1,
              fontSize: 12,
              fontWeight: "400",
              cursor: "pointer",
            }}
          >
            {description}
          </div>
        </div>
      </div>
      {selected && subComp ? (
        <div style={{ marginLeft: 40 }}>{subComp}</div>
      ) : null}
    </div>
  );
};

export default SelectItemRow;
