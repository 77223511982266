import React, { useState, useMemo, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  createSingleSavedQuestion,
  getAccountState,
} from "../../../../redux/slices/accountSlice";
import {
  GuestInfo,
  SavedFormQuestion,
  SpreadsheetInfo,
} from "@markit/common.types";
import { Colors } from "../../../../utils/colors";
import { Icon } from "@iconify/react";
import { useOnMount } from "../../../../hooks/useOnMount";
import FormSavedQuestions, {
  FormSavedQuestionsType,
} from "../../../Form/FormSavedQuestions";
import "../../../../css/Table.css";
import { deepCopy, detectedCensored, uniqueVals } from "@markit/common.utils";
import { DropdownMenu, DropdownMenuItem } from "../../../DropdownMenu";
import { FullNameColumnLabel } from "../../../../screens/Import/ImportContacts";

type AssignImportedContactsProps = {
  spreadsheet: SpreadsheetInfo;
  setSpreadsheet: (spreadsheet: SpreadsheetInfo) => void;
  includeExistingContacts: boolean;
  assignedColumns: string[];
  setAssignedColumns: (assignedColumns: string[]) => void;
  setFullNameAlert: (fullNameAlert: {
    header: string;
    subHeader: string;
  }) => void;
};

const AssignImportedContacts = (props: AssignImportedContactsProps) => {
  const {
    spreadsheet,
    setSpreadsheet,
    includeExistingContacts,
    assignedColumns,
    setAssignedColumns,
    setFullNameAlert,
  } = props;
  const dispatch = useDispatch();
  const { accountData, savedQuestions } = useSelector(getAccountState).account;
  const [questionsVisible, setQuestionsVisible] = useState(false);
  const [formQuestionItem, setFormQuestionItem] = useState<SavedFormQuestion>();
  const [columnIndex, setColumnIndex] = useState(-1);
  const [newQuestionOptions, setNewQuestionOptions] = useState<string[]>([]);

  const styles = {
    headerText: { fontSize: 20, fontWeight: 500 },
    subtext: { fontSize: 14, color: Colors.GRAY1 },
    leftCellContainer: {
      paddingInline: 24,
      paddingBlock: 20,
      whiteSpace: "nowrap",
    },
    cellContainer: {
      paddingInline: 40,
      paddingBlock: 20,
      whiteSpace: "nowrap",
    },
  };

  useOnMount(() => {
    if (spreadsheetRows[0].dataColumns && assignedColumns.length === 0) {
      // Add full name column if there is only one dataColumn
      if (spreadsheetRows[0].dataColumns.length === 1) {
        setAssignedColumns([FullNameColumnLabel]);
      } else {
        setAssignedColumns(
          new Array(spreadsheetRows[0].dataColumns.length).fill("")
        );
      }
    }
  });

  const spreadsheetRows = useMemo(
    () =>
      spreadsheet
        ? includeExistingContacts
          ? spreadsheet.validRows.concat(spreadsheet.existingContactRows)
          : spreadsheet.validRows
        : [],
    [includeExistingContacts, spreadsheet]
  );

  const foundFullNameColumn = useMemo(
    () => assignedColumns.some((column) => column === FullNameColumnLabel),
    [assignedColumns]
  );

  const modifySpreadsheetItemNames = useCallback(
    (isAssigning: boolean, index: number) => {
      // Check if this column has any empty items (full name must all be populated)
      const hasEmptyFullName = spreadsheetRows.some(
        (row) => row.dataColumns && /^\s*$/.test(row.dataColumns[index])
      );
      if (hasEmptyFullName) {
        setFullNameAlert({
          header: "This column cannot be assigned as Full Name.",
          subHeader:
            "There cannot be any empty items in the column that is assigned as Full Name. Please select a column with all items populated.",
        });
        return undefined;
      }
      // Check if column items contains any censored terms
      const censoredTermsFound = spreadsheetRows
        .filter(
          (row) => row.dataColumns && detectedCensored(row.dataColumns[index])
        )
        .map((item) => (item.dataColumns ? item.dataColumns[index] : ""));
      if (censoredTermsFound.length > 0) {
        setFullNameAlert({
          header: "This column cannot be assigned as Full Name.",
          subHeader: `The following censored terms were detected: ${censoredTermsFound.join(
            ", "
          )}. Please remove these terms and then try again.`,
        });
        return undefined;
      }

      const newValidRows: GuestInfo[] = spreadsheet.validRows.map((row) => {
        return {
          ...row,
          fullName:
            isAssigning && row.dataColumns ? row.dataColumns[index] : "",
        };
      });
      const newAlreadyExistingRows: GuestInfo[] =
        spreadsheet.existingContactRows.map((row) => {
          return {
            ...row,
            fullName:
              isAssigning && row.dataColumns ? row.dataColumns[index] : "",
          };
        });
      const newSpreadsheet: SpreadsheetInfo = {
        ...spreadsheet,
        validRows: newValidRows,
        existingContactRows: newAlreadyExistingRows,
      };
      return newSpreadsheet;
    },
    [setFullNameAlert, spreadsheet, spreadsheetRows]
  );

  const assignFullName = useCallback(() => {
    const newSpreadsheet = modifySpreadsheetItemNames(true, columnIndex);
    if (newSpreadsheet) {
      setSpreadsheet(newSpreadsheet);
      const assignedColumnsCopy = deepCopy(assignedColumns);
      assignedColumnsCopy.splice(columnIndex, 1, FullNameColumnLabel);
      setAssignedColumns(assignedColumnsCopy);
    }
    setColumnIndex(-1);
  }, [
    assignedColumns,
    columnIndex,
    modifySpreadsheetItemNames,
    setAssignedColumns,
    setSpreadsheet,
  ]);

  const unassignFullName = useCallback(
    (index: number) => {
      const newSpreadsheet = modifySpreadsheetItemNames(false, index);
      if (newSpreadsheet) {
        setSpreadsheet(newSpreadsheet);
        const assignedColumnsCopy = deepCopy(assignedColumns);
        assignedColumnsCopy.splice(index, 1, "");
        setAssignedColumns(assignedColumnsCopy);
      }
      setColumnIndex(-1);
    },
    [
      assignedColumns,
      modifySpreadsheetItemNames,
      setAssignedColumns,
      setSpreadsheet,
    ]
  );

  const assignOnPress = useCallback(
    (selectedQuestion: SavedFormQuestion) => {
      // Another column has the selected question assigned already
      if (
        selectedQuestion &&
        assignedColumns.some((column) => column === selectedQuestion.id) &&
        formQuestionItem?.id !== selectedQuestion.id
      ) {
        return false;
      }
      // Assigning/Unassigning column to question
      const assignedColumnsCopy = deepCopy(assignedColumns);
      assignedColumnsCopy.splice(
        columnIndex,
        1,
        selectedQuestion ? selectedQuestion.id : ""
      );
      setAssignedColumns(assignedColumnsCopy);
      if (selectedQuestion) {
        dispatch(createSingleSavedQuestion(accountData.uid, selectedQuestion));
      }
      setColumnIndex(-1);
      setNewQuestionOptions([]);
      setFormQuestionItem(undefined);
      return true;
    },
    [
      accountData.uid,
      assignedColumns,
      columnIndex,
      dispatch,
      formQuestionItem?.id,
      setAssignedColumns,
    ]
  );

  const columnOnPress = useCallback(
    (questionId: string, index: number) => {
      if (questionId !== "") {
        const formQuestion = savedQuestions.find(
          (question) => question.id === questionId
        );
        setFormQuestionItem(formQuestion);
      }
      // Auto generate the options for the question
      const questionOptions = spreadsheetRows
        .map((row) => (row.dataColumns ? row.dataColumns[index] : ""))
        .filter((option) => option !== "");
      setNewQuestionOptions(uniqueVals(questionOptions));
      setQuestionsVisible(true);
    },
    [savedQuestions, spreadsheetRows]
  );

  const dropdownItems: DropdownMenuItem[] = useMemo(() => {
    const dropdownItems: DropdownMenuItem[] = [
      {
        title: "Assign as Full Name",
        key: "full_name",
        onPress: assignFullName,
      },
      {
        title: "Custom Data",
        icon: "ion:arrow-forward",
        key: "custom_data",
        onPress: () => columnOnPress("", columnIndex),
      },
    ];

    return dropdownItems;
  }, [assignFullName, columnIndex, columnOnPress]);

  const hasDataColumns = useMemo(
    () =>
      spreadsheetRows.length > 0
        ? spreadsheetRows[0].dataColumns &&
          spreadsheetRows[0].dataColumns.length > 0
        : false,
    [spreadsheetRows]
  );

  const renderColumnTitle = useCallback(
    (columnId: string, index: number) => {
      const formQuestion = savedQuestions.find(
        (question) => question.id === columnId
      );
      return (
        <div
          className="AlignedRow"
          style={{
            justifyContent: "center",
            cursor:
              columnId === FullNameColumnLabel || assignedColumns.length === 1
                ? "default"
                : "pointer",
          }}
        >
          <span>
            {formQuestion
              ? formQuestion.formInput.label
              : columnId === FullNameColumnLabel
              ? FullNameColumnLabel
              : "Assign Column"}
          </span>
          {columnId !== FullNameColumnLabel ? (
            <Icon
              icon="mdi:chevron-down-box"
              height={17}
              style={{ marginBottom: -2, marginLeft: 10 }}
            />
          ) : assignedColumns.length > 1 ? (
            <Icon
              icon="mdi:close-box"
              height={17}
              style={{ marginBottom: -2, marginLeft: 10, cursor: "pointer" }}
              onClick={() => unassignFullName(index)}
            />
          ) : null}
        </div>
      );
    },
    [assignedColumns.length, savedQuestions, unassignFullName]
  );

  return (
    <>
      <div
        className="ColumnNormal Centering"
        style={{ gap: 40, paddingBlock: 30 }}
      >
        <div className="ColumnNormal" style={{ gap: 10, textAlign: "center" }}>
          <span style={styles.headerText}>
            Assign imported columns to existing data
          </span>
          <span style={styles.subtext}>
            Assign columns to existing data or create new column types. Review
            before continuing.
          </span>
        </div>
        <div
          className="ColumnNormal"
          style={{
            overflowX: "auto",
            width: hasDataColumns ? "100%" : "auto",
          }}
        >
          <table style={{ marginInline: 40, paddingBottom: 20 }}>
            <thead>
              <tr>
                <th style={styles.leftCellContainer}></th>
                <th style={{ ...styles.cellContainer, textAlign: "center" }}>
                  Phone Number
                </th>
                {assignedColumns.map((columnId, index) => {
                  return (
                    <th
                      key={index}
                      style={{
                        ...styles.cellContainer,
                        color: columnId ? Colors.BLACK : Colors.ORANGE1,
                      }}
                      onClick={() => {
                        if (!columnId) {
                          if (!foundFullNameColumn) {
                            // Column not assigned and no full name column
                          } else {
                            // Column not assigned and full name column found
                            columnOnPress("", index);
                          }
                        } else if (columnId !== FullNameColumnLabel) {
                          // Column is assigned and not the full name column
                          columnOnPress(columnId, index);
                        }
                        setColumnIndex(index);
                      }}
                    >
                      {spreadsheetRows[0].fullName || columnId !== "" ? (
                        renderColumnTitle(columnId, index)
                      ) : (
                        <div
                          style={{
                            width: columnId !== "" ? undefined : 140,
                            margin: "auto",
                          }}
                        >
                          <DropdownMenu
                            dropdownItems={dropdownItems}
                            trigger={["click"]}
                            placement="bottomLeft"
                            altWidth={200}
                          >
                            {renderColumnTitle(columnId, index)}
                          </DropdownMenu>
                        </div>
                      )}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {spreadsheetRows.slice(0, 10).map((row, index) => {
                const rowItems = [row.phoneNumber, ...(row.dataColumns ?? [])];
                return (
                  <tr key={index}>
                    <td
                      style={{
                        ...styles.leftCellContainer,
                        textAlign: "center",
                        backgroundColor: Colors.WHITE1,
                      }}
                    >
                      {index + 1}
                    </td>
                    {rowItems.map((item, index) => (
                      <td
                        style={{
                          ...styles.cellContainer,
                          textAlign: "center",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: 500,
                        }}
                        key={index}
                      >
                        {item}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
          {spreadsheetRows.length > 7 ? (
            <div className="Centering" style={{ paddingTop: 20 }}>
              <span style={{ color: Colors.GRAY1 }}>
                {spreadsheetRows.length - 10} More Rows...
              </span>
            </div>
          ) : null}
        </div>
      </div>
      {questionsVisible ? (
        <FormSavedQuestions
          type={FormSavedQuestionsType.ASSIGN}
          isVisible={questionsVisible}
          setIsVisible={(isVisible: boolean) => {
            setQuestionsVisible(isVisible);
            setFormQuestionItem(undefined);
          }}
          assignOnPress={assignOnPress}
          selectedAssignedQuestion={formQuestionItem}
          newQuestionOptions={newQuestionOptions}
        />
      ) : null}
    </>
  );
};

export default AssignImportedContacts;
