import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  Event,
  MassText,
  MassTextType,
  SubSelectionItem,
  SubSelectionType,
} from "@markit/common.types";
import { getAccountState } from "../../../redux/slices/accountSlice";
import MassTextMessageComposition from "./Summary/MassTextMessageComposition";
import MassTextsSummary from "./Summary/MassTextsSummary";
import MassTextsSendSchedule from "./Summary/MassTextsSendSchedule";
import MassTextsRecipients from "./MassTextsRecipients";
import MassTextsMessagingHeader from "./MassTextsMessagingHeader";
import {
  MassTextMessagingScreenType,
  MassTextsSharedProps,
  TextPopupConfirmActions,
} from "./MassTextPanel";
import isEqual from "lodash.isequal";
import { API } from "../../../API";
import {
  LessThanDate,
  massTextPlaceholder,
  massTextUpcomingEventPlaceholder,
} from "@markit/common.utils";
import { GetLongDate } from "../../../utils/GetLongDate";
import { useTheme } from "../../../hooks/useTheme";
import useAsyncOnMount from "../../../hooks/useAsyncEffectOnMount";
import useAsyncEffect from "../../../hooks/useAsyncEffect";
import MassTextMessagingWrapper from "./MassTextMessagingWrapper";
import { HorizontalDivider } from "../../Dividers/HorizontalDivider";
import { MassTextsWrapperType } from "./MassTextWrapperManager";

export type ScheduleTextInfo = {
  sendTime: Date;
  scheduleToggle: boolean;
  catchUpChecked: boolean;
};

type MassTextMessagingScreensProps = MassTextsSharedProps & {
  wrapperType: MassTextsWrapperType;
  setEditingCampaign?: (editingCampaign: boolean) => void;
  duplicateOnPress?: (massText: MassText) => void;
  isEditing: boolean;
  event: Event | undefined;
  netSelectedPeople: string[];
  editingMassText: MassText | undefined;
  resetState: (newMassText?: MassText) => void;
  suggestedAction: SubSelectionItem | undefined;
  // for text send/schedule success/fail/delete popup
  setConfirmPopupTextConfirmation: (
    confirmType: TextPopupConfirmActions
  ) => void;
  loadingRecipients: boolean;
};

const MassTextMessagingScreens = (props: MassTextMessagingScreensProps) => {
  const { theme } = useTheme();
  const { account } = useSelector(getAccountState);
  const { accountData } = account;
  const {
    wrapperType,
    setEditingCampaign,
    duplicateOnPress,
    isEditing,
    event,
    massTextSettings,
    updateMassTextSettings,
    netSelectedPeople,
    setProfileSelected,
    editingMassText,
    suggestedAction,
    setMainScreen,
    resetState,
    setConfirmPopupTextConfirmation,
    loadingRecipients,
  } = props;

  const [screen, setScreen] = useState<MassTextMessagingScreenType>(
    MassTextMessagingScreenType.SUMMARY
  );
  // Local state for the Sending_Schedule screen
  const [scheduleTextInfo, setScheduleTextInfo] = useState<ScheduleTextInfo>({
    sendTime: new Date(),
    scheduleToggle: false,
    catchUpChecked: false,
  });
  const [totalDailyMessages, setTotalDailyMessages] = useState(0);
  const [loadingDailyMessages, setLoadingDailyMessages] = useState(true);
  const [categoryRecipients, setCategoryRecipients] = useState<string[]>([]);
  const [loadingCategoryRecipients, setLoadingCategoryRecipients] =
    useState(true);

  useEffect(() => {
    if (editingMassText && isEditing) {
      setScreen(MassTextMessagingScreenType.SEND_SUMMARY);
    } else {
      setScreen(MassTextMessagingScreenType.SUMMARY);
    }
  }, [editingMassText, isEditing]);

  const isEditingScheduledText = useMemo(
    () =>
      LessThanDate(new Date().toISOString(), massTextSettings.sentAt) &&
      isEditing,
    [isEditing, massTextSettings.sentAt]
  );

  const defaultMessage = useMemo(
    () =>
      event && suggestedAction?.type === SubSelectionType.ALL_FOLLOWERS
        ? massTextUpcomingEventPlaceholder(
            accountData.fullName,
            GetLongDate(event.start, false, true, true, false),
            event.id
          )
        : massTextPlaceholder(accountData.fullName),
    [accountData.fullName, event, suggestedAction]
  );

  // Returns the net categories from the selected and excluded categories
  const finalSubSelectionIds = useMemo(
    () =>
      suggestedAction
        ? [suggestedAction]
        : massTextSettings.subSelectionIds || [],
    [massTextSettings.subSelectionIds, suggestedAction]
  );

  // Displays the FINAL recipients whether it's for categories or for individuals
  const finalRecipients = useMemo(
    () =>
      finalSubSelectionIds.length > 0 ? categoryRecipients : netSelectedPeople,
    [netSelectedPeople, finalSubSelectionIds, categoryRecipients]
  );

  const sharedSummaryPropsPack = {
    finalRecipients: finalRecipients,
    netIndividualRecipients: netSelectedPeople,
    massText: massTextSettings,
    updateMassTextSettings: updateMassTextSettings,
    screen: screen,
    setScreen: setScreen,
    setMainScreen: setMainScreen,
    isEditing: isEditing,
    wrapperType: wrapperType,
    suggestedAction: suggestedAction,
    loadingRecipients: loadingRecipients,
    theme: theme,
  };

  useAsyncOnMount(async () => {
    const { totalMessages } =
      await API.monitor.checkTotalDailyTwilioMessagesSent({});
    setTotalDailyMessages(totalMessages);
    setLoadingDailyMessages(false);
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useAsyncEffect(async () => {
    // Fetch the recipients from the categories to display the number it will be sent to
    if (event && finalSubSelectionIds.length > 0) {
      setLoadingCategoryRecipients(true);
      const { recipients } = await API.text.convertTextCategories({
        selectedCategories: finalSubSelectionIds,
        excludedCategories: massTextSettings.excludedSubSelectionIds,
        userId: accountData.uid,
        eventId: event.id,
      });
      setCategoryRecipients(recipients);
    }
    setLoadingCategoryRecipients(false);
  }, [
    event,
    accountData.uid,
    finalSubSelectionIds,
    massTextSettings.excludedSubSelectionIds,
  ]);

  const madeChanges = useMemo(() => {
    if (screen === MassTextMessagingScreenType.SUMMARY) {
      return (
        (!suggestedAction && finalRecipients.length > 0) ||
        massTextSettings.message !== defaultMessage ||
        massTextSettings.mediaUrl !== ""
      );
    } else if (screen === MassTextMessagingScreenType.SEND_SUMMARY) {
      return isEditing && !isEqual(massTextSettings, editingMassText);
    } else if (screen === MassTextMessagingScreenType.SEND_SCHEDULE) {
      return (
        massTextSettings.scheduled !== scheduleTextInfo.scheduleToggle ||
        massTextSettings.catchUpEnabled !== scheduleTextInfo.catchUpChecked ||
        (massTextSettings.sentAt !== "" &&
          massTextSettings.sentAt !== scheduleTextInfo.sendTime.toISOString())
      );
    }
    return false;
  }, [
    defaultMessage,
    editingMassText,
    finalRecipients.length,
    isEditing,
    massTextSettings,
    scheduleTextInfo.catchUpChecked,
    scheduleTextInfo.scheduleToggle,
    scheduleTextInfo.sendTime,
    screen,
    suggestedAction,
  ]);

  return (
    <div style={{ height: "100%", overflow: "hidden" }}>
      <MassTextsMessagingHeader
        wrapperType={wrapperType}
        setEditingCampaign={setEditingCampaign}
        duplicateOnPress={duplicateOnPress}
        screen={screen}
        setScreen={setScreen}
        massText={massTextSettings}
        updateMassTextSettings={updateMassTextSettings}
        resetState={resetState}
        madeChanges={madeChanges}
        finalRecipients={finalRecipients}
        suggestedAction={suggestedAction}
        totalDailyMessages={totalDailyMessages}
        loadingDailyMessages={loadingDailyMessages}
        loadingCategoryRecipients={loadingCategoryRecipients}
        scheduleTextInfo={scheduleTextInfo}
        setScheduleTextInfo={setScheduleTextInfo}
        isEditing={isEditing}
        setConfirmPopupTextConfirmation={setConfirmPopupTextConfirmation}
      />
      <HorizontalDivider />
      <MassTextMessagingWrapper wrapperType={wrapperType}>
        {screen === MassTextMessagingScreenType.SUMMARY ? (
          // Case 1: New Mass Text Drafting Screen
          <MassTextMessageComposition
            {...sharedSummaryPropsPack}
            event={event}
          />
        ) : ((wrapperType === MassTextsWrapperType.MODAL &&
            massTextSettings.type === MassTextType.EVENTTEXT) ||
            wrapperType === MassTextsWrapperType.SIDE_PANEL) &&
          isEditingScheduledText &&
          (screen === MassTextMessagingScreenType.SEND_SUMMARY ||
            screen === MassTextMessagingScreenType.LINK_ANALYTICS) ? (
          // Case 2: Editing Scheduled Text Screen
          <MassTextMessageComposition
            {...sharedSummaryPropsPack}
            event={event}
          />
        ) : screen === MassTextMessagingScreenType.SEND_SUMMARY ||
          screen === MassTextMessagingScreenType.LINK_ANALYTICS ? (
          // Case 3: Sent Mass Text Summary Screen
          <MassTextsSummary {...sharedSummaryPropsPack} />
        ) : null}
        {/* Mass Texts Send Schedule Screen */}
        {screen === MassTextMessagingScreenType.SEND_SCHEDULE ? (
          <MassTextsSendSchedule
            massText={massTextSettings}
            scheduleTextInfo={scheduleTextInfo}
            setScheduleTextInfo={setScheduleTextInfo}
            isEditing={isEditing}
          />
        ) : null}
        {/* Mass Text Recipients List */}
        {screen === MassTextMessagingScreenType.RECIPIENTS_SEND_SUMMARY ? (
          <MassTextsRecipients
            massText={massTextSettings}
            allSelectedPeople={netSelectedPeople}
            setProfileSelected={setProfileSelected}
          />
        ) : null}
      </MassTextMessagingWrapper>
    </div>
  );
};

export default MassTextMessagingScreens;
