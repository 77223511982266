import React from "react";
import "../css/AgreeToTerms.css";
import { Checkbox } from "@mui/material";
import { Icon } from "@iconify/react";
import checkbox from "@iconify/icons-mdi/checkbox-outline";
import {
  MARKIT_PRIVACY_POLICY,
  MARKIT_TERMS_OF_USE,
} from "@markit/common.utils";
import { useTheme } from "../hooks/useTheme";
import { Colors } from "../utils/colors";

export default function AgreeToTerms({
  agreed,
  setAgreed,
  error,
  setError,
  disabled,
  nonRefundable,
}: {
  agreed: boolean;
  setAgreed: (value: boolean) => void;
  error: boolean;
  setError: (error: boolean) => void;
  disabled?: boolean;
  nonRefundable?: boolean;
}) {
  const { theme } = useTheme();

  return (
    <div className="agree-to-terms">
      <Checkbox
        id="agree-to-terms"
        className="checkbox"
        checked={agreed}
        disabled={disabled}
        onChange={(e) => {
          if (error) {
            setError(false);
          }
          setAgreed(e.target.checked);
        }}
        size="small"
        sx={{
          padding: 0,
          color: error ? Colors.RED1 : Colors.GRAY1,
          borderWidth: 0.5,
          "&.Mui-checked": {
            ...theme.PrimaryText,
          },
        }}
        checkedIcon={<Icon icon={checkbox} height={20} color={Colors.BLACK} />}
      />
      <label htmlFor="agree-to-terms">
        <p style={{ color: Colors.GRAY2, fontSize: 12 }}>
          I have read, understood, and agree to be bound by the Markit{" "}
          <TermsLink href={MARKIT_TERMS_OF_USE}>Terms of Conditions</TermsLink>{" "}
          and <TermsLink href={MARKIT_PRIVACY_POLICY}>Privacy Policy</TermsLink>
          .{nonRefundable ? " This charge is non-refundable by Markit." : ""}{" "}
          <span style={error ? { color: Colors.RED1 } : undefined}>
            (*Required)
          </span>
        </p>
      </label>
    </div>
  );
}

export function TermsLink({
  href,
  children,
}: {
  href: string;
  children: React.ReactNode;
}) {
  const { theme } = useTheme();

  return (
    <a
      href={href}
      target="_blank"
      rel="noreferrer"
      style={{ ...theme.CheckboxText }}
    >
      {children}
    </a>
  );
}
