import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Campaign, MassText, MassTextAttachment } from "@markit/common.types";
import { AppState } from "../store";

export type MassTextDetails = {
  massText: MassText;
  attachments: MassTextAttachment[];
};

export type CampaignDetails = {
  campaignId: string;
  numRecipients: number;
  massTextDetails: MassTextDetails[];
};

export type CampaignState = {
  campaigns: Campaign[];
  campaignDetails: CampaignDetails[];
};

export const initialCampaignState: CampaignState = {
  campaigns: [],
  campaignDetails: [],
};

export const campaignSlice = createSlice({
  name: "campaign",
  initialState: initialCampaignState,
  reducers: {
    modifyCampaign: (state, action: PayloadAction<Campaign>) => {
      state.campaigns = state.campaigns.map((item) =>
        item.id === action.payload.id ? { ...item, ...action.payload } : item
      );
    },
    modifyCampaignDetails: (state, action: PayloadAction<CampaignDetails>) => {
      state.campaignDetails = state.campaignDetails.map((item) =>
        item.campaignId === action.payload.campaignId
          ? { ...item, ...action.payload }
          : item
      );
    },
    addMultipleCampaigns: (state, action: PayloadAction<Campaign[]>) => {
      state.campaigns = Array.from(
        [...state.campaigns, ...action.payload]
          .reduce((map, item) => {
            map.set(item.id, item);
            return map;
          }, new Map())
          .values()
      );
    },
    addMultipleCampaignDetails: (
      state,
      action: PayloadAction<CampaignDetails[]>
    ) => {
      state.campaignDetails = Array.from(
        [...state.campaignDetails, ...action.payload]
          .reduce((map, item) => {
            map.set(item.campaignId, item);
            return map;
          }, new Map())
          .values()
      );
    },
    removeCampaign: (state, action: PayloadAction<string>) => {
      const indexOne = state.campaigns.findIndex(
        (fetched) => fetched.id === action.payload
      );
      if (indexOne !== -1) {
        state.campaigns.splice(indexOne, 1);
      }

      const indexTwo = state.campaignDetails.findIndex(
        (fetched) => fetched.campaignId === action.payload
      );
      if (indexTwo !== -1) {
        state.campaignDetails.splice(indexTwo, 1);
      }
    },
    removeAllCampaigns: (state) => {
      state.campaigns = [];
      state.campaignDetails = [];
    },
  },
});

export const campaignActions = campaignSlice.actions;
export const campaignReducer = campaignSlice.reducer;

export const getCampaignState = (state: AppState) => state.campaigns;
