import LargePopupModalContainer from "../Containers/LargePopupModalContainer";
import React, { useSelector, useDispatch } from "react-redux";
import {
  getAccountState,
  setContactCustomMessage,
} from "../../redux/slices/accountSlice";
import { Colors } from "../../utils/colors";
import { TextPluginOptions } from "@markit/common.types";
import { useCallback, useMemo, useState } from "react";
import { HorizontalDivider } from "../Dividers/HorizontalDivider";
import isEqual from "lodash.isequal";
import RectangleButton from "../Buttons/RectangleButton";
import { BackButton } from "../Buttons/BackButton";
import { InsertPluginTextInput } from "./MassTexts/Message/InsertPluginTextInput";
import {
  communityWelcomePlaceholder,
  detectedCensored,
  hasSubscription,
} from "@markit/common.utils";
import ComplianceMessageTag from "../Compliance/ComplianceMessageTag";
import ConfirmDeleteModal from "../Containers/ConfirmPopups/ConfirmDeleteModal";
import { Icon } from "@iconify/react";
import { AttachmentsActionBar } from "./MassTexts/Message/AttachmentsActionBar";
import { useTheme } from "../../hooks/useTheme";
import ContentViolationAlert from "../DeliveryScore/ContentViolationAlert";
import TestTextButton from "./MassTexts/TestTextButton";

type FollowTextPopupProps = {
  isVisible: boolean;
  setIsVisible: (isVisible: boolean) => void;
};

const FollowTextPopup = (props: FollowTextPopupProps) => {
  const { isVisible, setIsVisible } = props;
  const { accountData } = useSelector(getAccountState).account;
  const { theme } = useTheme();
  const dispatch = useDispatch();
  const [followText, setFollowText] = useState(
    accountData.contactCustomMessage ??
      communityWelcomePlaceholder(hasSubscription(accountData))
  );
  // States for attaching image/videos/gifs
  const [customImage, setCustomImage] = useState(
    accountData.contactCustomMediaUrl
  );
  const [isRemoveMediaModalOpen, setIsRemoveMediaModalOpen] = useState(false);
  const [cursorPosition, setCursorPosition] = useState(followText.length - 1);
  const [showBackAlert, setShowBackAlert] = useState(false);
  const [contentAlert, setContentAlert] = useState(false);

  const styles = {
    headerText: { color: Colors.GRAY1, fontSize: 14, paddingInline: 14 },
  };

  const madeChanges = useMemo(
    () =>
      !isEqual(accountData.contactCustomMessage, followText) ||
      accountData.contactCustomMediaUrl !== customImage,
    [
      accountData.contactCustomMediaUrl,
      accountData.contactCustomMessage,
      customImage,
      followText,
    ]
  );

  const backOnPress = useCallback(() => {
    if (madeChanges) {
      setShowBackAlert(true);
    } else {
      setIsVisible(false);
    }
  }, [madeChanges, setIsVisible]);

  const saveOnPress = useCallback(() => {
    if (detectedCensored(followText)) {
      setContentAlert(true);
      return;
    }
    dispatch(setContactCustomMessage(accountData, followText, customImage));
    setIsVisible(false);
  }, [accountData, customImage, dispatch, followText, setIsVisible]);

  return (
    <>
      <LargePopupModalContainer
        showModal={isVisible}
        containerStyles={{ padding: 0 }}
        headerComp={
          <div className="AlignedRowSpaced">
            <BackButton onPress={backOnPress} />
            <div className="AlignedRow" style={{ gap: 7 }}>
              <TestTextButton
                message={followText}
                image={customImage}
                save={customImage !== accountData.contactCustomMediaUrl}
              />
              <RectangleButton
                buttonLabel="Save"
                onPress={saveOnPress}
                altTextColor={Colors.WHITE}
                altColor={Colors.BLACK}
                disabled={!madeChanges || followText === ""}
                altPaddingHorz={14}
                altPaddingVert={10}
                altBorderRadius={100}
              />
            </div>
          </div>
        }
        valueComp={
          <div className="ColumnNormal">
            <div className="ColumnNormal" style={{ gap: 16 }}>
              <span style={styles.headerText}>Follow Text</span>
              <HorizontalDivider altMargin={0} />
              <span style={styles.headerText}>Sent Upon Follow (Required)</span>
              <HorizontalDivider altMargin={0} />
            </div>
            <div
              style={{
                height: "calc(100vh - 280px)",
                overflow: "scroll",
                padding: 14,
              }}
            >
              <InsertPluginTextInput
                message={followText}
                setMessage={(message: string) => setFollowText(message)}
                placeholder={communityWelcomePlaceholder(
                  hasSubscription(accountData)
                )}
                cursorPosition={cursorPosition}
                setCursorPosition={setCursorPosition}
                pluginWords={
                  new Set<string>(
                    TextPluginOptions.map((option) => option.name)
                  )
                }
                image={customImage}
                removeImage={() => setIsRemoveMediaModalOpen(true)}
                fixedBottomText={<ComplianceMessageTag />}
                hideEventPlugins
              />
            </div>
            <div
              style={{
                position: "absolute",
                bottom: 0,
                left: 0,
                right: 0,
                padding: 14,
              }}
            >
              <AttachmentsActionBar
                message={followText}
                image={customImage}
                setImage={setCustomImage}
              />
            </div>
          </div>
        }
      />
      <ConfirmDeleteModal
        heading="Are you sure you want to exit?"
        subtext="Your changes won't be saved. This cannot be undone."
        deleteButtonText="Exit"
        hideModal={!showBackAlert}
        setIsVisible={setShowBackAlert}
        deleteOnPress={() => setIsVisible(false)}
        icon={<Icon icon="ion:exit-outline" height={40} />}
      />
      <ConfirmDeleteModal
        heading="Remove Media?"
        subtext="This cannot be undone"
        deleteButtonText={"Remove"}
        hideModal={!isRemoveMediaModalOpen}
        setIsVisible={(open: boolean) => {
          setIsRemoveMediaModalOpen(open);
        }}
        deleteOnPress={() => {
          setCustomImage("");
        }}
        theme={theme}
      />
      <ContentViolationAlert
        message={followText}
        isVisible={contentAlert}
        setIsVisible={setContentAlert}
      />
    </>
  );
};

export default FollowTextPopup;
