import { useCallback, useState } from "react";
import {
  Event,
  MassText,
  MassTextAttachment,
  TextTimelineItem,
} from "@markit/common.types";
import { getCountFromServer, onSnapshot, query, where } from "../firebase";
import {
  textTimelineEndItem,
  textTimelineStartItem,
} from "@markit/common.utils";
import { generate } from "shortid";
import { getCampaignMassTextsRef, getEventData } from "../utils/FirebaseUtils";
import { getMassTextAttachments } from "../utils/textingUtils";
import useAsyncOnMount from "./useAsyncEffectOnMount";

type useLiveUpdatingEventTextsProps = {
  eventId: string;
};

export const useLiveUpdatingEventTexts = (
  props: useLiveUpdatingEventTextsProps
) => {
  const { eventId } = props;
  const [eventTextsLoading, setEventTextsLoading] = useState(true);
  const [regularTextsLoading, setRegularTextsLoading] = useState(true);
  const [eventMassTexts, setEventMassTexts] = useState<MassText[]>([]);
  const [textTimelineToShow, setTextTimelineToShow] = useState<
    (MassText | TextTimelineItem)[]
  >([]);

  const [regularMassTextsInRange, setRegularMassTextsInRange] = useState<
    MassText[]
  >([]);

  const [massTextAttachments, setMassTextAttachments] = useState<
    MassTextAttachment[]
  >([]);

  const createEventStartAndEndItems = useCallback(
    (e: Event, currEventTexts: MassText[]) => {
      const eventStart: TextTimelineItem = textTimelineStartItem(
        e.start,
        generate()
      );
      const eventEnd: TextTimelineItem = textTimelineEndItem(e.end, generate());
      setTextTimelineToShow(
        [...currEventTexts, eventStart, eventEnd].sort((x, y) => {
          return new Date(x.sentAt).getTime() - new Date(y.sentAt).getTime();
        })
      );
    },
    []
  );

  useAsyncOnMount(async () => {
    const eventData = await getEventData(eventId);

    if (eventData) {
      const userIdToShow = eventData.createdBy; // TODO (jonathan): maybe change in future if we want cohosts and others to have their own text timelines

      // get all event texts
      const massTextsRefs = getCampaignMassTextsRef(userIdToShow, "");
      const eventTextsQuery = query(
        massTextsRefs,
        where("eventRefId", "==", eventData.id)
      );

      const snapshot = await getCountFromServer(eventTextsQuery);
      if (snapshot.data().count === 0) {
        createEventStartAndEndItems(eventData, []);
        setEventMassTexts([]);
        setEventTextsLoading(false);
      }

      const unsubscribeEventTexts = onSnapshot(
        eventTextsQuery,
        async (snapshot) => {
          const currEventTexts = snapshot.docs.map((doc) => doc.data());
          setEventMassTexts(currEventTexts);
          createEventStartAndEndItems(eventData, currEventTexts);
          setEventTextsLoading(false);
        }
      );

      // get all mass texts sent after event
      const massTextsQuery = query(
        massTextsRefs,
        where("createdAt", ">=", eventData.createdAt)
      );

      const snapshotTwo = await getCountFromServer(eventTextsQuery);
      if (snapshotTwo.data().count === 0) {
        setRegularMassTextsInRange([]);
        setRegularTextsLoading(false);
      }

      const unsubscribeMassTexts = onSnapshot(
        massTextsQuery,
        async (snapshot) => {
          const currMassTexts = snapshot.docs.map((doc) => doc.data());
          setRegularMassTextsInRange(currMassTexts);

          // get the relevant mass text attachments from all event texts
          const massTextAttachments = await Promise.all(
            currMassTexts.map(async (massText) => {
              const attachments = await getMassTextAttachments(
                userIdToShow,
                massText
              );
              return attachments;
            })
          );
          const flatAttachments = massTextAttachments.reduce(
            (acc, attachments) => {
              return acc.concat(attachments);
            },
            []
          );
          setMassTextAttachments(flatAttachments);
          setRegularTextsLoading(false);
        }
      );

      return () => {
        unsubscribeEventTexts();
        unsubscribeMassTexts();
      };
    }
  });

  return {
    eventTextsLoading,
    regularTextsLoading,
    eventMassTexts,
    textTimelineToShow,
    regularMassTextsInRange,
    massTextAttachments,
  };
};
