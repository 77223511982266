import React, { memo, useMemo } from "react";
import {
  AccountData,
  Follower,
  FollowType,
  FormInputType,
  SavedFormQuestion,
  SavedFormResponse,
} from "@markit/common.types";
import { useTheme } from "../../hooks/useTheme";
import { CopyCustomLinkify } from "../Links/CopyCustomLinkify";
import { formatPhoneNumber } from "../../utils/FormatPhoneNumber";
import CustomLinkify from "../Links/CustomLinkify";

type EssentialDataResponseProps = {
  savedQuestion: SavedFormQuestion;
  formResponse: SavedFormResponse | null | undefined;
  userData: AccountData;
  followerData: Follower | undefined;
  copyable?: boolean; // also means it's in view profile and should be bolder
  detailView?: boolean; // used to show full response and not one line preview
};

const EssentialDataResponse = memo(function EssentialDataResponseFn(
  props: EssentialDataResponseProps
) {
  const {
    savedQuestion,
    formResponse,
    userData,
    followerData,
    copyable,
    detailView,
  } = props;
  const { theme } = useTheme();

  const isCollectPhoneFormQuestion = useMemo(
    () =>
      savedQuestion.formInput.inputType === FormInputType.PHONE &&
      userData &&
      userData.phoneNumber !== "",
    [savedQuestion.formInput.inputType, userData]
  );

  // If the question is type Phone and the follower FollowType is Eventbrite or Upload, show the phone number
  const showCollectedPhoneNumber = useMemo(
    () =>
      isCollectPhoneFormQuestion &&
      (followerData?.followType === FollowType.EVENTBRITE ||
        followerData?.followType === FollowType.UPLOAD),
    [followerData?.followType, isCollectPhoneFormQuestion]
  );

  const noResponse = useMemo(
    () =>
      (formResponse === null ||
        formResponse === undefined ||
        (formResponse &&
          formResponse.responses.every((response: any) => response === ""))) &&
      !showCollectedPhoneNumber,
    [formResponse, showCollectedPhoneNumber]
  );

  const styles = {
    bodyText: {
      color: noResponse
        ? theme.SubText.color
        : copyable
        ? theme.PrimaryText.color
        : theme.SubText.color,
      fontSize: 14,
      fontWeight: copyable ? 500 : 400,
    },
  };

  const formResponseToShow = useMemo(
    () =>
      noResponse
        ? "Not Collected"
        : userData && isCollectPhoneFormQuestion
        ? formatPhoneNumber(userData.phoneNumber)
        : formResponse
        ? formResponse.responses.join(", ")
        : "",
    [formResponse, isCollectPhoneFormQuestion, noResponse, userData]
  );

  return copyable ? (
    <CopyCustomLinkify
      text={formResponseToShow}
      textStyles={{ ...styles.bodyText, width: "360px" }}
      disableCopy={noResponse}
      showFullContent={detailView}
    />
  ) : (
    <div style={{ maxWidth: "18vw" }}>
      <CustomLinkify>
        <span
          className={!detailView ? "TextOverflowEllipsis" : ""}
          style={styles.bodyText}
        >
          {formResponseToShow}
        </span>
      </CustomLinkify>
    </div>
  );
});

export default EssentialDataResponse;
